import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Quote } from "../../models/quote";
import { TempQuote } from "../../models/temp-quote";
import { EventBus } from "../../services/event_bus";
import { UcEvent } from "../../static-services/events";
import { CommonUtility } from "../../utilities/common.utility";

@Component({
    selector: "app-modal-quick-quote-component",
    templateUrl: "./modal-quick-quote.component.html",
    styleUrls: ["./modal-quick-quote.component.scss"]
})
export class ModalQuickQuoteComponent implements OnInit {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    @Input("tempQuote") tempQuote: TempQuote;
    tempQuickQuote: TempQuote = new TempQuote();
    @Output() quickEventEmitter: EventEmitter<any> = new EventEmitter();
    currentTab = 1;
    @ViewChild("tab1") tab1: ElementRef;
    @ViewChild("tab2") tab2: ElementRef;
    @ViewChild("tab3") tab3: ElementRef;
    @ViewChild("tab4") tab4: ElementRef;
    @ViewChild("tab5") tab5: ElementRef;
    @ViewChild("tab6") tab6: ElementRef;
    @ViewChild("tab7") tab7: ElementRef;
    @ViewChild("tab8") tab8: ElementRef;
 //   @ViewChild("tab9") tab9: ElementRef;
    tab1Finished = false;
    tab2Finished = false;
    tab3Finished = false;
    tab4Finished = false;
    tab5Finished = false;
    tab6Finished = false;
    tab7Finished = false;
    tab8Finished = false;
 //   tab9Finished = false;
    switching = false;
    constructor(private eventBus: EventBus) { }
    ngOnInit(): void { }
    cleanClassList(tab: string): void {
        this[tab].nativeElement.classList.remove("slideInLeft");
        this[tab].nativeElement.classList.remove("slideInRight");
        this[tab].nativeElement.classList.remove("slideOutLeft");
        this[tab].nativeElement.classList.remove("slideOutRight");
    }
    next(next?: number): void {
        if (!next) {
            next = 1;
        }
        if (this.switching) {
            return;
        }
        for (let i = this.currentTab; i < this.currentTab + next; i++) {
            if (!this["tab" + i + "Finished"]) {
                return;
            }
        }
        this.switching = true;
        this.cleanClassList("tab" + this.currentTab);
        this.cleanClassList("tab" + (this.currentTab + next));
        this["tab" + this.currentTab].nativeElement.classList.add(
            "slideOutLeft"
        );
        this["tab" + (this.currentTab + next)].nativeElement.classList.add(
            "slideInRight"
        );
        this["tab" + (this.currentTab + next)].nativeElement.classList.add(
            "active"
        );
        setTimeout(() => {
            this["tab" + this.currentTab].nativeElement.classList.remove(
                "active"
            );
            this.currentTab = this.currentTab + next;
            this.switching = false;
        }, 500);
    }
    back(prev?: number): void {
        if (!prev) {
            prev = 1;
        }
        if (this.switching) {
            return;
        }
        this.switching = true;
        this.cleanClassList("tab" + this.currentTab);
        this.cleanClassList("tab" + (this.currentTab - prev));
        this["tab" + this.currentTab].nativeElement.classList.add(
            "slideOutRight"
        );
        this["tab" + (this.currentTab - prev)].nativeElement.classList.add(
            "slideInLeft"
        );
        this["tab" + (this.currentTab - prev)].nativeElement.classList.add(
            "active"
        );
        setTimeout(() => {
            this["tab" + this.currentTab].nativeElement.classList.remove(
                "active"
            );
            this.currentTab = this.currentTab - prev;
            this.switching = false;
        }, 500);
    }
    go(tab: number): void {
        if (this.currentTab !== tab) {
            if (this.currentTab > tab) {
                this.back(this.currentTab - tab);
            } else {
                this.next(tab - this.currentTab);
            }
        }
    }

    clean(): void {
        // Insured Name
        if (!GlobalStaticService.clientQuote) {
            this.quote.insuredName = "";
            this.quote.insuredNameFinished = false;
            this.quote.newClientFlag = false;
            this.quote.client = null;
            this.quote.clientName = null;
            this.quote.tradingNames = [];
            this.quote.errorInsuredName = false;
            this.tab1Finished = false;
        }
        this.tab2Finished = false;
        this.tab3Finished = false;
        this.tab4Finished = false;
        this.tab5Finished = false;
        this.tab6Finished = false;
        this.tab7Finished = false;
        this.tab8Finished = false;
  //      this.tab9Finished = false;
        // Insured Type
        this.quote.insuredType = null;
        this.quote.errorInsuredType = false;
        // Insured Declaration
        this.quote.insuredDeclaration = null;
        this.quote.insuredDeclarationDetails = null;
        this.quote.errorInsuredDeclaration = false;
        this.quote.insuredDeclarationModalViewed = false;
        // Building License
        this.quote.unlicensedBuilder = null;
        this.quote.licenseLending = null;
        this.quote.errorBuildingLicense = false;
        this.quote.buildingLicenseReferral = false;
        // Business of Insured
        this.quote.businessType = null;
        this.quote.businessTypeId = null;
        this.quote.businessTypeReferralMd = false;
        this.quote.businessTypeReferralL = false;
        this.quote.businessTypeReferralCPL = false;
        this.quote.businessTypeReferral = false;
        this.quote.errorBusinessOfInsured = false;
        // Address
        this.quote.address = null;
        this.quote.addressReferral = false;
        this.quote.addressFinished = false;
        this.quote.suburbId = null;
        this.quote.stateId = null;
        this.quote.errorAddress = false;
        // Work location
        this.quote.workLocationNT = null;
        this.quote.workLocationQLDa = null;
        this.quote.workLocationQLDb = null;
        this.quote.workLocationWAa = null;
        this.quote.workLocationWAb = null;
        this.quote.workLocationSA = null;
        this.quote.workLocationNSW = null;
        this.quote.workLocationVIC = null;
        this.quote.workLocationACT = null;
        this.quote.workLocationTAS = null;
        this.quote.workLocationReferral = false;
        // Project Type
        this.resetProjectType();
        // Material Damage
        this.resetMaterialDamage();
        // Liability
        this.quote.errorLiability = false;
        this.quote.liabilityEstimation = null;
        this.quote.liabilityEstimationReferral = false;
        this.quote.liabilityFinished = false;
        this.quote.publicLiabilityReferral = false;
        this.quote.publicLiability = null;
        this.quote.publicLiabilityId = null;
        this.quote.vibrationWeakeningRemovalOfSupportReferral = false;
        this.quote.vibrationWeakeningRemovalOfSupport = null;
        this.quote.vibrationWeakeningRemovalOfSupportId = null;
        this.quote.propertyInCareCustodyControlReferral = false;
        this.quote.propertyInCareCustodyControl = null;
        this.quote.propertyInCareCustodyControlId = null;
        this.quote.productLiabilityReferral = false;
        this.quote.productLiability = null;
        this.quote.productLiabilityId = null;
        // Related
        this.quote.insuredDeclaration = null;
        this.quote.insuranceType = null;
        this.quote.ongoingWork = null;
        this.quote.ongoingWorkDetails = null;
        this.quote.tailorYourCover = 1000;
        this.quote.tailorYourCoverReferral = false;
        this.quote.cover2 = false;
        this.quote.cover3 = false;
        this.quote.cover3Referral = false;
        this.quote.cover4 = false;
        this.quote.cover4Referral = false;
        this.quote.cover5 = false;
        this.quote.cover5Referral = false;
        this.quote.cover6 = false;
        this.quote.cover6Referral = false;
        this.quote.occupiedStructures = null;
        this.quote.referral = [];
        //
        this.quote.quick = false;
        GlobalStaticService.cleanQuoteAutoSave(this.quote.type === "single");
    }
    close(): void {
        this.quickEventEmitter.emit(false);
        GlobalStaticService.closeQuickQuoteModal();
        this.clean();
        this.go(1);
        setTimeout(() => {
            this.cleanClassList("tab1");
            this.cleanClassList("tab2");
            this.cleanClassList("tab3");
            this.cleanClassList("tab4");
            this.cleanClassList("tab5");
            this.cleanClassList("tab6");
            this.cleanClassList("tab7");
            this.cleanClassList("tab8");
 //           this.cleanClassList("tab9");
        }, 600);
        this.eventBus.cast(UcEvent.QUICK_QUOTE_MODAL_CLOSE);
    }
    create(): void {
        // Sync project type params
        this.tempQuote.componentProjectType.tab1Finished = this.tempQuickQuote.componentProjectType.tab1Finished;
        this.tempQuote.componentProjectType.tab2Finished = this.tempQuickQuote.componentProjectType.tab2Finished;
        this.tempQuote.componentProjectType.tab3Finished = this.tempQuickQuote.componentProjectType.tab3Finished;
        this.tempQuote.componentProjectType.number = this.tempQuickQuote.componentProjectType.number;
        this.tempQuote.componentProjectType.nrbId = this.tempQuickQuote.componentProjectType.nrbId;
        this.tempQuote.componentProjectType.ncibId = this.tempQuickQuote.componentProjectType.ncibId;
        this.tempQuote.componentProjectType.nsrfaId = this.tempQuickQuote.componentProjectType.nsrfaId;
        this.tempQuote.componentProjectType.arId = this.tempQuickQuote.componentProjectType.arId;
        this.tempQuote.componentProjectType.acibId = this.tempQuickQuote.componentProjectType.acibId;
        this.tempQuote.componentProjectType.otherId = this.tempQuickQuote.componentProjectType.otherId;
        this.tempQuote.componentProjectType.total = this.tempQuickQuote.componentProjectType.total;
        this.tempQuote.componentProjectType.percentTabEnabled = this.tempQuickQuote.componentProjectType.percentTabEnabled;
        this.tempQuote.componentProjectType.otherTabEnabled = this.tempQuickQuote.componentProjectType.otherTabEnabled;
        this.tempQuote.componentProjectType.ownerBuilderSpecialError = this.tempQuickQuote.componentProjectType.ownerBuilderSpecialError;
        //
        this.quickEventEmitter.emit(true);
        GlobalStaticService.closeQuickQuoteModal();
    }
    continueEventEmitter(tab: number, event: any): void {
        if (event.finished && !event.noScroll) {
            this["tab" + tab + "Finished"] = true;
  //          if (tab !== 9) {
            if (tab !== 8) {
                if (tab === 2 && this.quote.insuredType === "ownerBuilder") {
                    // this.tab3Finished = true;
//                    this.go(5);
                    this.go(tab + 2);
                } else {
                    this.go(tab + 1);
                }
            } else {
                this.create();
            }
        } else {
            this["tab" + tab + "Finished"] = false;
        }
    }
    specialEventEmitter(event: any): void {
        switch (event.tab) {
            // building license
            /*
            case 3:
                this.tab3Finished = this.quote.insuredType != "ownerBuilder" &&
                    (this.quote.unlicensedBuilder == null
                        || (this.quote.type === "single" && this.quote.insuredType != "ownerBuilder" && this.quote.licenseLending == null)
                    );
                break;
            */

            // Business of Insured
         //   case 4:
            case 3:
          //      this.tab4Finished = !!this.quote.businessType;
                this.tab3Finished = !!this.quote.businessType;
                break;

 //           case 6:
            case 5:
                // Project Type
                this.tab4Finished = false;
                this.resetProjectType();
                this.tempQuickQuote.componentProjectType.go(1);
                this.tempQuickQuote.componentProjectType.tab1Finished = false;
                this.tempQuickQuote.componentProjectType.otherTabEnabled = false;
                break;
  //          case 8:
            case 7:
                // MD
 //               this.tab7Finished = false;
                this.tab6Finished = false;
                this.resetMaterialDamage();
                break;
        }
    }
    resetProjectType(): void {
        this.quote['_scheme'] = null;
        this.quote.projectType = null;
        this.quote.projectTypeReferral = false;
        this.quote.projectTypeDetails = null;
        this.quote.errorProjectType = false;
        this.quote.projectTypeNRB = false;
        this.quote.projectTypePercentNRB = 0;
        this.quote.projectTypeNCIB = false;
        this.quote.projectTypePercentNCIB = 0;
        this.quote.projectTypeNSRFA = false;
        this.quote.projectTypePercentNSRFA = 0;
        this.quote.projectTypeAR = false;
        this.quote.projectTypePercentAR = 0;
        this.quote.projectTypeACIB = false;
        this.quote.projectTypePercentACIB = 0;
        this.quote.projectTypeOther = false;
        this.quote.projectTypePercentOther = 0;
        this.quote['_scheme'] = null;
    }
    resetMaterialDamage(): void {
        this.quote.materialDamageEstimation = null;
        this.quote.errorMaterialDamage = false;
        this.quote.projectCost = null;
        this.quote.existingStructures = null;
        this.quote.existingStructuresA = null;
        this.quote.existingStructuresB = null;
        this.quote.existingStructuresC = null;
        this.quote.existingStructuresD = null;
        this.quote.poorConditionReferral = false;
        this.quote.principalSupplied = null;
        this.quote.contractorsPlant = null;
        this.quote.materialDamageEstimationReferral = false;
        this.quote.projectCostReferral = false;
        this.quote.existingStructuresReferral = false;
        this.quote.principalSuppliedReferral = false;
        this.quote.contractorsPlantReferral = false;
        this.quote['_scheme'] = null;
    }
}
