import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
    enableProdMode();
    // disable console log when running in prod profile
    if (environment.env === 'prod') {
        window.console.log = () => { };
        window.console.debug = () => { };
        window.console.trace = () => { };
        window.console.table = () => { };
        window.console.dir = () => { };
        window.console.dirxml = () => { };
        window.console.count = () => { };
        window.console.group = () => { };
        window.console.groupCollapsed = () => { };
        window.console.groupEnd = () => { };
    }
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => {
        if (err.status && (err.status === 404 || err.status === 401)) {
            // ignore
        } else {
            console.error(err);
        }
    });
