import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AnnualQuotePageComponent } from "../pages/annual-quote/annual-quote.page";
import { ClaimsPageComponent } from "../pages/claims/claims.page";
import { ClientsPageComponent } from "../pages/clients/clients.page";
import { DashboardPageComponent } from "../pages/dashboard/dashboard.page";
import { HomePageComponent } from "../pages/home/home.page";
import { PoliciesPageComponent } from "../pages/policies/policies.page";
import { SingleQuotePageComponent } from "../pages/single-quote/single-quote.page";
import { QuotesPageComponent } from "../pages/quotes/quotes.page";

const dashboardRoutes: Routes = [
    {
        path: "dashboard",
        component: DashboardPageComponent,
        children: [
            {
                path: "home",
                component: HomePageComponent
            },
            {
                path: "quotes",
                component: QuotesPageComponent
            },
            {
                path: "quotes/single/:type",
                component: SingleQuotePageComponent
            },
            {
                path: "quotes/annual/:type",
                component: AnnualQuotePageComponent
            },
            {
                path: "claims",
                component: ClaimsPageComponent
            },
            {
                path: "clients",
                component: ClientsPageComponent
            },
            {
                path: "policies",
                component: PoliciesPageComponent
            },
            {
                path: "certificate-of-currency",
                component: PoliciesPageComponent
            }
        ]
    }
];

export const dashboardRoute: ModuleWithProviders = RouterModule.forRoot(
    dashboardRoutes
);
