import {
    Directive,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output
} from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { AppComponentBase } from "../app/component-base.component";

@Directive({
    selector: "[debounceKeyUp]"
})
export class DebounceKeyUpDirective extends AppComponentBase implements OnInit {
    @Input() debounceTime: number = 500;
    @Output() debouncedKeyUp: EventEmitter<Event> = new EventEmitter();
    private eventSubject: Subject<Event> = new Subject();

    ngOnInit() {
        this._rs(
            this.eventSubject
                .pipe(debounceTime(this.debounceTime))
                .subscribe(evt => {
                    this.debouncedKeyUp.emit(evt);
                })
        );
    }

    @HostListener("keyup", ["$event"])
    keyUpEvent(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        this.eventSubject.next(e);
    }
}
