import { Component, Input } from "@angular/core";
import { QuoteService } from "../../services/quote.service";

import { Quote } from "../../models/quote";
import { GlobalStaticService } from "../../static-services/global.static-service";

@Component({
    selector: "app-quote-summary-component",
    templateUrl: "./quote-summary.component.html",
    styleUrls: ["./quote-summary.component.scss"]
})
export class QuoteSummaryComponent {
    GlobalStaticSrv = GlobalStaticService;
    @Input("heading") heading: string;
    @Input("showRef") showRef: boolean;
    @Input("quote") quote: Quote;
    constructor(public quoteSrv: QuoteService) {}
}
