import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { Quote } from "../../models/quote";
import { GlobalStaticService } from "../../static-services/global.static-service";

@Component({
    selector: "app-quick-quote-business-of-insured-component",
    templateUrl: "./quick-quote-business-of-insured.component.html",
    styleUrls: ["./quick-quote-business-of-insured.component.scss"]
})
export class QuickQuoteBusinessOfInsuredComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor() {}
    ngOnChanges(): void {}
    next(): void {
        this.quote.errorBusinessOfInsured = !this.quote.businessType;
        this.continueEventEmitter.emit({
            finished: !!this.quote.businessType,
            referral: this.quote.businessTypeReferral
        });
    }
    inputFocused(event: any): void {
        event.target.blur();
        if (this.quote.insuredType !== "ownerBuilder") {
            GlobalStaticService.openAddBusinessTypeModal(false);
        }
    }
}
