import { Component, Input } from "@angular/core";

import { GlobalStaticService } from "../../static-services/global.static-service";
import { Quote } from "../../models/quote";
import { CommonUtility } from "../../utilities/common.utility";
import { QuoteService } from "../../services/quote.service";
import { QuoteMappingStaticService } from "../../static-services/quote-mapping.static-service";
import { saveAs } from 'file-saver';
import { formatNumber } from '@angular/common';
import '../../assets/js/docxtemplater.js';
import '../../assets/js/pizzip.js';
import '../../assets/js/pizzip-utils.js';
declare let docxtemplater: any;
declare let PizZip: any;
declare let PizZipUtils: any;
declare let docx: any;

@Component({
    selector: "app-quote-copy-component",
    templateUrl: "./quote-copy.component.html",
    styleUrls: ["./quote-copy.component.scss"]
})
export class QuoteCopyComponent {
    QuoteMappingStaticSrv = QuoteMappingStaticService;
    CommonUtility = CommonUtility;
    @Input("quote") quote: Quote;
    @Input("heading") heading: string;
    GlobalStaticSrv = GlobalStaticService;
    constructor(
        private quoteSrv: QuoteService
    ) { }

    beforeCopy(): void {
        if (this.quoteSrv.embargoCheckQuote(this.quote)) {
            this.copy();
        } else {
            this.quoteSrv.embargoReferral(this.quote);
        }
    }

    copy(): void {
        this.new_generate();
        GlobalStaticService.infoModal = {
            title: null,
            content: `
                <div class="text-center f-mgb-20"><i class="u-icon icon-100 circle icon-copied"></i></div>
                <div class="text-center"><h2 class="f-mgb-10">Your summary has been downloaded!</h2></div>
                <div class="text-center modal-text">
                    You can now paste this information<br>outside of MECONline
                </div>
            `,
            buttons: [
                {
                    style: "",
                    text: "Ok",
                    callback: () => {
                        GlobalStaticService.closeInfoModal();
                    }
                }
            ]
        };
        GlobalStaticService.openInfoModal();
    }
    loadFile(url, callback) {
        PizZipUtils.getBinaryContent(url, callback);
    }
    data(): any {
        const data: any = QuoteMappingStaticService.deepCopy(
            this.quote
        );
        data.business = this.quote.businessType;
        data.wordType = this.quote.type === 'single' ? 'Single' : 'Annual';
        data.brokerName = GlobalStaticService.brokerProfile.fullName;
        data.brokerAddress = GlobalStaticService.brokerProfile.address;
        data.wordQuoteDate = this.quote.quoteDate ? CommonUtility.getDateBasedOnTFormat(this.quote.quoteDate) : 'N/A';
        data.period = `${CommonUtility.getFullDate(this.quote.policyStart)} to ${CommonUtility.getFullDate(this.quote.policyEnd)} at 4pm Local Time`;
        data.wordBasePremium = `$${formatNumber(this.quote.rating.basePremium, "en-US", "1.2-2")}`;
        data.wordFireServiceLevy = `$${formatNumber(this.quote.rating.fireServiceLevy, "en-US", "1.2-2")}`;
        data.wordGst = `$${formatNumber(this.quote.rating.gst, "en-US", "1.2-2")}`;
        data.wordStampDuty = `$${formatNumber(this.quote.rating.stampDuty, "en-US", "1.2-2")}`;
        data.wordMeconAdminFee = `$${formatNumber(this.quote.rating.meconAdminFee, "en-US", "1.2-2")}`;
        data.wordTotalPayable = `$${formatNumber(this.quote.rating.totalPayable, "en-US", "1.2-2")}`;
        data.wordMeconAdminFeeGst = `$${formatNumber(this.quote.rating.meconAdminFeeGst, "en-US", "1.2-2")}`;
        data.wordBrokerCommission = `$${formatNumber(this.quote.rating.brokerCommission, "en-US", "1.2-2")}`;
        data.wordBrokerCommissionGst = `$${formatNumber(this.quote.rating.brokerCommissionGst, "en-US", "1.2-2")}`;
        data.wordDue = `$${formatNumber((this.quote.rating.totalPayable - this.quote.rating.brokerCommission - this.quote.rating.brokerCommissionGst), "en-US", "1.2-2")}`;
        data.wordSectionsMeconAdminFeeGst = `$${formatNumber(this.quote.sectionsMeconAdminFeeGst, "en-US", "1.2-2")}`;
        data.mdsection = [];
        data.sections.forEach((value, index) => {
            value.index = index + 1;
            value.wordBasePremium = `$${formatNumber(data.sections[index].rating.basePremium, "en-US", "1.2-2")}`;
            if (index === 0) {
                value.wordFireServiceLevy = `$${formatNumber(data.sections[index].rating.fireServiceLevy, "en-US", "1.2-2")}`;
            }
            value.wordGst = `$${formatNumber(data.sections[index].rating.gst, "en-US", "1.2-2")}`;
            value.wordStampDuty = `$${formatNumber(data.sections[index].rating.stampDuty, "en-US", "1.2-2")}`;
            value.wordMeconAdminFee = `$${formatNumber(data.sections[index].rating.meconAdminFee, "en-US", "1.2-2")}`;
            value.wordTotalPayable = `$${formatNumber(data.sections[index].rating.totalPayable, "en-US", "1.2-2")}`;
        });
        data.mdsection = data.sections.splice(0, 1);
        if (this.quote.type === 'annual') {
            data.wordOngingWork = `${this.quote.ongoingWork === 'run-off' ? 'Run-off - Total values for all Projects commenced during the Policy Period' : 'Turnover - Total annual turnover from all Projects'}`;
        }
        if (this.quote.type === 'single') {
            data.wordProjectType = `${this.quote.projectType !== 'other' ? QuoteMappingStaticService.projectTypeNameSingle(this.quote.projectType) : 'Other - ' + this.quote.projectTypeDetails}`;
        }
        if (this.quote.type === 'annual') {
            data.wordProjectType = [];
            if (this.quote.projectTypeNRB) {
                data.wordProjectType.push({ text: `${QuoteMappingStaticService.projectTypeNameAnnual('nrb')} - ${this.quote.projectTypePercentNRB || 100}%` });
            }
            if (this.quote.projectTypeNCIB) {
                data.wordProjectType.push({ text: `${QuoteMappingStaticService.projectTypeNameAnnual('ncib')} - ${this.quote.projectTypePercentNCIB || 100}%` });
            }
            if (this.quote.projectTypeNSRFA) {
                data.wordProjectType.push({ text: `${QuoteMappingStaticService.projectTypeNameAnnual('nsrfa')} - ${this.quote.projectTypePercentNSRFA || 100}%` });
            }
            if (this.quote.projectTypeAR) {
                data.wordProjectType.push({ text: `${QuoteMappingStaticService.projectTypeNameAnnual('ar')} - ${this.quote.projectTypePercentAR || 100}%` });
            }
            if (this.quote.projectTypeACIB) {
                data.wordProjectType.push({ text: `${QuoteMappingStaticService.projectTypeNameAnnual('acib')} - ${this.quote.projectTypePercentACIB || 100}%` });
            }
            if (this.quote.projectTypeOther) {
                data.wordProjectType.push({ text: `Other - ${this.quote.projectTypeDetails} - ${this.quote.projectTypePercentOther || 100}%` });
            }
        }
        data.showDefectLiability = true;
        if (this.quote.type === 'single') {
            if (this.quote.insuredType !== 'ownerBuilder' && this.quote.insuranceType !== 'l') {
                data.wordDefectLiabilityPeriod = `${this.quote.defectsLiabilityPeriod} months`;
            } else {
                data.wordDefectLiabilityPeriod = `Not Applicable`;
                data.showDefectLiability = false;
            }
        }
        data.wordMD = this.quote.insuranceType === 'l' ? ' - NOT COVERED' : '';
        if (this.quote.type === 'annual') {
            if (this.quote.insuranceType === 'l') {
                data.wordEstimated = 'Not Covered';
            } else {
                data.wordEstimated = `${this.quote.ongoingWork === 'run-off' ? 'Run-off - Total values for all Projects commenced during the Policy Period - ' + GlobalStaticService.priceFormat(this.quote.materialDamageEstimation) : 'Turnover - Total Annual Turnover of all Projects - ' + GlobalStaticService.priceFormat(this.quote.materialDamageEstimation)}`;
            }
        }
        data.wordMPV = `${this.quote.insuranceType !== 'l' ? GlobalStaticService.priceFormat(this.quote.projectCost) + ' E.E.E' : 'Not Covered'}`;
        if (this.quote.insuredType === 'ownerBuilder1') {
            data.wordPSM = `Already Included`;
        } else {
            data.wordPSM = `${(this.quote.insuranceType !== 'l' && this.quote.principalSupplied) ? GlobalStaticService.priceFormat(this.quote.principalSupplied) + ' E.E.E' : 'Not Covered'} `;
        }
        data.wordES = `${(this.quote.insuranceType !== 'l' && this.quote.existingStructures) ? GlobalStaticService.priceFormat(this.quote.existingStructures) + 'E.E.E' : 'Not Covered'} `;
        data.wordCPTR = `${(this.quote.insuranceType !== 'l' && this.quote.contractorsPlant) ? GlobalStaticService.priceFormat(this.quote.contractorsPlant) + ' E.E.E' : 'Not Covered'}`;
        data.wordVE = `${this.quote.insuranceType !== 'l' ? GlobalStaticService.priceFormat((GlobalStaticService.numberFormat(this.quote.projectCost) + GlobalStaticService.numberFormat(this.quote.principalSupplied)) * 0.2) + ' E.E.E' : 'Not Covered'} `;
        data.wordRD = `${this.quote.insuranceType !== 'l' ? GlobalStaticService.priceFormat((GlobalStaticService.numberFormat(this.quote.projectCost) + GlobalStaticService.numberFormat(this.quote.principalSupplied) + GlobalStaticService.numberFormat(this.quote.contractorsPlant) + GlobalStaticService.numberFormat(this.quote.existingStructures)) * 0.1) + ' E.E.E' : 'Not Covered'} `;
        data.wordPF = `${this.quote.insuranceType !== 'l' ? GlobalStaticService.priceFormat((GlobalStaticService.numberFormat(this.quote.projectCost) + GlobalStaticService.numberFormat(this.quote.principalSupplied)) * 0.1) + ' E.E.E' : 'Not Covered'} `;
        data.wordEC = `${this.quote.insuranceType !== 'l' ? GlobalStaticService.priceFormat((GlobalStaticService.numberFormat(this.quote.projectCost) + GlobalStaticService.numberFormat(this.quote.principalSupplied) + GlobalStaticService.numberFormat(this.quote.existingStructures)) * 0.05) + ' E.E.E' : 'Not Covered'}`;
        data.wordMC = `${this.quote.insuranceType !== 'l' ? GlobalStaticService.priceFormat((GlobalStaticService.numberFormat(this.quote.projectCost) + GlobalStaticService.numberFormat(this.quote.principalSupplied) + GlobalStaticService.numberFormat(this.quote.existingStructures)) * 0.05) + ' E.E.E' : 'Not Covered'} `;
        for (let i = 0; i < data.tailorYourCoverMdList.length; i++) {
            // 17 is the length of "Deductible Amount"
            data.tailorYourCoverMdList[i].value = `${GlobalStaticService.priceFormat(data.tailorYourCoverMdList[i].value, 17)}`;
            data.tailorYourCoverMdList[i].text = `${data.tailorYourCoverMdList[i].description}`;
        }
        data.wordPL = this.quote.insuranceType === 'md' ? ' - NOT COVERED' : '';
        if (this.quote.type === 'annual') {
            if (this.quote.insuranceType === 'md') {
                data.wordEstimatedPL = 'Not Covered';
            } else {
                data.wordEstimatedPL = `${this.quote.ongoingWork === 'run-off' ? 'Run-off - Total values for all Projects commenced during the Policy Period - ' + GlobalStaticService.priceFormat(this.quote.materialDamageEstimation) : 'Total Annual Turnover of all Projects - ' + GlobalStaticService.priceFormat(this.quote.materialDamageEstimation)}`;
            }
        }
        data.wordPuL = `${this.quote.insuranceType !== 'md' ? GlobalStaticService.priceFormat(this.quote.publicLiability) + ' E.E.O' : 'Not Covered'}`;
        if (this.quote.type === 'annual') {
            data.wordPrL = `${(this.quote.insuranceType !== 'md' && this.quote.productLiability) ? GlobalStaticService.priceFormat(this.quote.productLiability) + ' A.O.P.I.' : 'Not Covered'}`;
        }
        data.wordVWRS = `${(this.quote.insuranceType !== 'md' && this.quote.vibrationWeakeningRemovalOfSupport) ? GlobalStaticService.priceFormat(this.quote.vibrationWeakeningRemovalOfSupport) + ' A.O.P.I.' : 'Not Covered'}`;
        data.wordPCCC = `${(this.quote.insuranceType !== 'md' && this.quote.propertyInCareCustodyControl) ? GlobalStaticService.priceFormat(this.quote.propertyInCareCustodyControl) + ' A.O.P.I.' : 'Not Covered'}`;
        let hasCPL = this.quote.cpl && this.quote.cpl > 0;
        data.wordCPL = 'Not Covered';
        if (hasCPL) {
            data.wordCPL = `${GlobalStaticService.priceFormat(this.quote.cpl) + ' A.O.P.I.'}`;
        }
        if (this.quote.insuranceType !== 'md') {
            for (let i = 0; i < data.tailorYourCoverLList.length; i++) {
                // 17 is the length of "Deductible Amount"
                data.tailorYourCoverLList[i].value = `${GlobalStaticService.priceFormat(data.tailorYourCoverLList[i].value, 17)}`;
                data.tailorYourCoverLList[i].text = `${data.tailorYourCoverLList[i].description}`;
            }
            for (let i = 0; i < data.tailorYourCoverCPLList.length; i++) {
                // 17 is the length of "Deductible Amount"
                data.tailorYourCoverCPLList[i].value = `${GlobalStaticService.priceFormat(data.tailorYourCoverCPLList[i].value, 17)}`;
                data.tailorYourCoverCPLList[i].text = `${data.tailorYourCoverCPLList[i].description}`;
            }
        }
        data.hasTailorYourCoverLList = Quote.hasTailorYourCoverLList(this.quote);
        data.hasTailorYourCoverMdList = Quote.hasTailorYourCoverMdList(this.quote);
        data.hasTailorYourCoverCPLList = Quote.hasTailorYourCoverCPLList(this.quote);
        data.policyWording = data.policyWording || 'N/A';
        data.wordings = this.wording();
        data.facility = this.facility();


console.log("data", data);
console.log("this quote", this.quote);

        return data;
    }
    new_generate(): any {
        if (this.quote.type === 'single') {
            if (this.quote.insuranceType !== 'md') {
                this.loadFile('../../assets/template/single.docx', (error, content) => {
                    if (error) { throw error; }
                    this.saveFile(content);
                });
            } else {
                this.loadFile('../../assets/template/single-md.docx', (error, content) => {
                    if (error) { throw error; }
                    this.saveFile(content);
                });
            }
        } else {
            if (this.quote.insuranceType !== 'md') {
                this.loadFile('../../assets/template/annual.docx', (error, content) => {
                    if (error) { throw error; }
                    this.saveFile(content);
                });
            } else {
                this.loadFile('../../assets/template/annual-md.docx', (error, content) => {
                    if (error) { throw error; }
                    this.saveFile(content);
                });
            }
        }
    }
    saveFile(content: any): any {
        const zip = new PizZip(content);
        let doc;
        doc = new docxtemplater(zip);
        doc.setData(this.data());
        doc.render();
        const out = doc.getZip().generate({
            type: "blob",
            mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        saveAs(out, (this.heading.indexOf('Policy') !== -1 ? this.quote.policyId : this.quote.quoteId) + " - copy text.docx");
    }

    wording(): any {
        const wordings = this.quote.wording.replace(/\<BR\>/gi, "\n").replace(/&nbsp;/g, " ").replace(/<span[^>]*>/gi, '').replace(/<\/span>/gi, '').split(/\<STRONG\>/i).filter(e => {
            return e !== "";
        }).map(e => {
            return e.trim();
        });
        for (let i = 0; i < wordings.length; i++) {
            let reverseWording = '';
            reverseWording = wordings[i].split('').reverse().join('');
            if (reverseWording.toLowerCase().indexOf('>gnorts/<') === 0 && i !== wordings.length - 1) {
                wordings[i + 1] = wordings[i].replace(/\<\/STRONG\>/i, ' ') + wordings[i + 1];
                wordings.splice(i, 1);
            }
        }
        const wording = [];
        for (let i = 0; i < wordings.length; i++) {
            const group: string[] = wordings[i].split(/\<\/STRONG\>/i);
            if (group.length >= 1) {
                for (let j = 0; j < group.length; j++) {
                    if (j !== group.length - 1) {
                        wording.push({ title: `${group[j]}`, text: '' });
                    } else {
                        wording.push({ title: '', text: `${group[j]}` });
                    }
                }
            } else {
                wording.push({ title: `${group[i]}`, text: '' });
            }
        }
        return wording;
    }

    facility(): any {
        const facility = [];
        for (let i = 0; i < this.quote.facility.length; i++) {
            facility.push({ text: `${this.quote.facility[i].entityName}, ABN ${this.quote.facility[i].abn}, AFSL ${this.quote.facility[i].afsl}`, value: `${this.quote.facility[i].percent.toFixed(2) + '%'}` });
        }
        return facility;
    }
}
