// Modules
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
// Common Library;
import {
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MAT_DATE_LOCALE
} from "@angular/material";
import { IonicStorageModule } from "@ionic/storage";
// App
import { AppComponent } from "./app.component";
// Dialog
import { AppDialogComponent } from "../components/app-dialog/app-dialog.component";
// Routes
import { route } from "../routes/app.route";
import { dashboardRoute } from "../routes/dashboard.route";
// Pages
import { AnnualQuotePageComponent } from "../pages/annual-quote/annual-quote.page";
import { ClaimsPageComponent } from "../pages/claims/claims.page";
import { ClientsPageComponent } from "../pages/clients/clients.page";
import { DashboardPageComponent } from "../pages/dashboard/dashboard.page";
import { HomePageComponent } from "../pages/home/home.page";
import { LoginPageComponent } from "../pages/login/login.page";
import { PoliciesPageComponent } from "../pages/policies/policies.page";
import { SingleQuotePageComponent } from "../pages/single-quote/single-quote.page";
import { QuotesPageComponent } from "../pages/quotes/quotes.page";
// Pipes
import { AbnPipe } from "../pipes/abn.pipe";
import { DateOnlyPipe } from "../pipes/date-only.pipe";
import { SanitizeHtmlPipe } from "../pipes/sanitize-html.pipe";
import { SanitizeStylePipe } from "../pipes/sanitize-style.pipe";
// Directives
import { ScrollDirective } from "../directives/scroll.directive";
import { NameOnlyDirective } from "../directives/name-only.directive";
import { FirstLetterCapitalDirective } from "../directives/first-letter-capital.directive";
import { CurrencyDisplayDirective } from "../directives/currency-display.directive";
import { TabOutputDirective } from "../directives/tab-output.directive";
import { EnterOutputDirective } from "../directives/enter-output.directive";
// Components
import { MainHeaderComponent } from "../components/main-header/main-header.component";
import { MainFooterComponent } from "../components/main-footer/main-footer.component";
import { InfoModalComponent } from "../components/info-modal/info-modal.component";
import { SubmitLoadingComponent } from "../components/submit-loading/submit-loading.component";
import { AddClientModalComponent } from "../components/add-client-modal/add-client-modal.component";
import { AddBusinessTypeModalComponent } from "../components/add-business-type-modal/add-business-type-modal.component";
import { AddAddressModalComponent } from "../components/add-address-modal/add-address-modal.component";
import { AddWorkLocationModalComponent } from "../components/add-work-location-modal/add-work-location-modal.component";
import { ClaimComponent } from "../components/claim/claim.component";
import { ClientComponent } from "../components/client/client.component";
import { QuoteComponent } from "../components/quote/quote.component";
import { PolicyComponent } from "../components/policy/policy.component";
import { CreateCertificateOfCurrencyComponent } from "../components/create-certificate-of-currency/create-certificate-of-currency.component";
import { QuoteSummaryComponent } from "../components/quote-summary/quote-summary.component";
import { QuoteInsuredNameComponent } from "../components/quote-insured-name/quote-insured-name.component";
import { QuoteInsuredTypeComponent } from "../components/quote-insured-type/quote-insured-type.component";
import { QuoteBusinessOfInsuredComponent } from "../components/quote-business-of-insured/quote-business-of-insured.component";
import { QuoteBuildingLicenseComponent } from "../components/quote-building-license/quote-building-license.component";
import { QuoteInsuredDeclarationComponent } from "../components/quote-insured-declaration/quote-insured-declaration.component";
import { QuotePolicyDateComponent } from "../components/quote-policy-date/quote-policy-date.component";
import { QuoteDefectsLiabilityPeriodComponent } from "../components/quote-defects-liability-period/quote-defects-liability-period.component";
import { QuoteMaximumProjectDurationComponent } from "../components/quote-maximum-project-duration/quote-maximum-project-duration.component";
import { QuoteProjectTypeComponent } from "../components/quote-project-type/quote-project-type.component";
import { QuoteAddressComponent } from "../components/quote-address/quote-address.component";
import { QuoteInsuranceTypeComponent } from "../components/quote-insurance-type/quote-insurance-type.component";
import { QuoteOngoingWorkComponent } from "../components/quote-ongoing-work/quote-ongoing-work.component";
import { QuoteOccupiedStructuresComponent } from "../components/quote-occupied-structures/quote-occupied-structures.component";
import { QuoteProjectInvolvementsComponent } from "../components/quote-project-involvements/quote-project-involvements.component";
import { QuoteMaterialDamageComponent } from "../components/quote-material-damage/quote-material-damage.component";
import { QuoteSubLimitComponent } from "../components/quote-sub-limit/quote-sub-limit.component";
import { QuoteLiabilityComponent } from "../components/quote-liability/quote-liability.component";
import { QuoteTailorYourCoverComponent } from "../components/quote-tailor-your-cover/quote-tailor-your-cover.component";
import { QuoteEnhanceYourCoverComponent } from "../components/quote-enhance-your-cover/quote-enhance-your-cover.component";
import { QuoteBrokerChargesComponent } from "../components/quote-broker-charges/quote-broker-charges.component";
import { QuoteBrokerCommentsComponent } from "../components/quote-broker-comments/quote-broker-comments.component";
import { QuoteAttachmentsComponent } from "../components/quote-attachments/quote-attachments.component";
import { AddEnhanceCoverModalComponent } from "../components/add-enhance-cover-modal/add-enhance-cover-modal.component";
import { QuoteMajorityOfWorkComponent } from "../components/quote-majority-of-work/quote-majority-of-work.component";
import { QuoteWorkLocationComponent } from "../components/quote-work-location/quote-work-location.component";
import { QuotePremiumDepositComponent } from "../components/quote-premium-deposit/quote-premium-deposit.component";
import { DidYouKnowComponent } from "../components/did-you-know/did-you-know.component";
import { TutorialComponent } from "../components/tutorial/tutorial.component";
import { ModalLoadTemplateComponent } from "../components/modal-load-template/modal-load-template.component";
import { ModalSaveTemplateComponent } from "../components/modal-save-template/modal-save-template.component";
import { ModalEmailQuoteComponent } from "../components/modal-email-quote/modal-email-quote.component";
import { ModalBindPolicyComponent } from "../components/modal-bind-policy/modal-bind-policy.component";
import { QuotePreviewComponent } from "../components/quote-preview/quote-preview.component";
import { TemplateComponent } from "../components/template/template.component";
import { QuoteCopyComponent } from "../components/quote-copy/quote-copy.component";
import { ModalEmailPolicyComponent } from "../components/modal-email-policy/modal-email-policy.component";
import { PolicyPolicyDateComponent } from "../components/policy-policy-date/policy-policy-date.component";
import { PolicyInterestedPartyComponent } from "../components/policy-interested-party/policy-interested-party.component";
import { PolicyMaterialDamageComponent } from "../components/policy-material-damage/policy-material-damage.component";
import { PolicyAddressComponent } from "../components/policy-address/policy-address.component";
import { ModalEmailCocComponent } from "../components/modal-email-coc/modal-email-coc.component";
import { TutorialLauncherComponent } from "../components/tutorial-launcher/tutorial-launcher.component";
import { ModalQuickQuoteComponent } from "../components/modal-quick-quote/modal-quick-quote.component";
import { QuickQuoteAddressComponent } from "../components/quick-quote-address/quick-quote-address.component";
import { QuickQuoteInsuredNameComponent } from "../components/quick-quote-insured-name/quick-quote-insured-name.component";
import { QuickQuoteBuildingLicenseComponent } from "../components/quick-quote-building-license/quick-quote-building-license.component";
import { QuickQuoteInsuredTypeComponent } from "../components/quick-quote-insured-type/quick-quote-insured-type.component";
import { QuickQuoteLiabilityComponent } from "../components/quick-quote-liability/quick-quote-liability.component";
import { QuickQuoteMaterialDamageComponent } from "../components/quick-quote-material-damage/quick-quote-material-damage.component";
import { QuickQuoteProjectTypeComponent } from "../components/quick-quote-project-type/quick-quote-project-type.component";
import { QuickQuoteBusinessOfInsuredComponent } from "../components/quick-quote-business-of-insured/quick-quote-business-of-insured.component";
import { QuickQuoteWorkLocationComponent } from "../components/quick-quote-work-location/quick-quote-work-location.component";

import { httpInterceptorProviders } from "../http-interceptors/index";
import { DebounceKeyUpDirective } from "../directives/debound-keyup.directive";
import { QuickQuoteInsuredDeclarationComponent } from "../components/quick-quote-insured-declaration/quick-quote-insured-declaration.component";

@NgModule({
    declarations: [
        // App
        AppComponent,
        // Dialog
        AppDialogComponent,
        // Pages
        AnnualQuotePageComponent,
        ClaimsPageComponent,
        ClientsPageComponent,
        DashboardPageComponent,
        HomePageComponent,
        LoginPageComponent,
        PoliciesPageComponent,
        SingleQuotePageComponent,
        QuotesPageComponent,
        // Components
        MainHeaderComponent,
        MainFooterComponent,
        InfoModalComponent,
        SubmitLoadingComponent,
        AddClientModalComponent,
        AddBusinessTypeModalComponent,
        AddWorkLocationModalComponent,
        AddAddressModalComponent,
        AddEnhanceCoverModalComponent,
        ClaimComponent,
        ClientComponent,
        QuoteComponent,
        PolicyComponent,
        TemplateComponent,
        CreateCertificateOfCurrencyComponent,
        PolicyPolicyDateComponent,
        PolicyInterestedPartyComponent,
        PolicyMaterialDamageComponent,
        PolicyAddressComponent,
        QuotePreviewComponent,
        QuoteSummaryComponent,
        QuoteInsuredNameComponent,
        QuoteInsuredTypeComponent,
        QuoteBuildingLicenseComponent,
        QuoteBusinessOfInsuredComponent,
        QuoteInsuredDeclarationComponent,
        QuotePolicyDateComponent,
        QuoteDefectsLiabilityPeriodComponent,
        QuoteMaximumProjectDurationComponent,
        QuoteProjectTypeComponent,
        QuoteAddressComponent,
        QuoteInsuranceTypeComponent,
        QuoteOngoingWorkComponent,
        QuoteOccupiedStructuresComponent,
        QuoteProjectInvolvementsComponent,
        QuoteMaterialDamageComponent,
        QuoteSubLimitComponent,
        QuoteLiabilityComponent,
        QuoteTailorYourCoverComponent,
        QuoteEnhanceYourCoverComponent,
        QuoteBrokerChargesComponent,
        QuoteBrokerCommentsComponent,
        QuoteAttachmentsComponent,
        QuoteWorkLocationComponent,
        QuoteMajorityOfWorkComponent,
        QuotePremiumDepositComponent,
        DidYouKnowComponent,
        TutorialComponent,
        ModalLoadTemplateComponent,
        ModalSaveTemplateComponent,
        ModalEmailQuoteComponent,
        ModalEmailPolicyComponent,
        ModalEmailCocComponent,
        ModalBindPolicyComponent,
        QuoteCopyComponent,
        TutorialLauncherComponent,
        ModalQuickQuoteComponent,
        QuickQuoteAddressComponent,
        QuickQuoteInsuredNameComponent,
        QuickQuoteBuildingLicenseComponent,
        QuickQuoteInsuredTypeComponent,
        QuickQuoteInsuredDeclarationComponent,
        QuickQuoteLiabilityComponent,
        QuickQuoteMaterialDamageComponent,
        QuickQuoteProjectTypeComponent,
        QuickQuoteBusinessOfInsuredComponent,
        QuickQuoteWorkLocationComponent,
        // Pipes
        AbnPipe,
        DateOnlyPipe,
        SanitizeHtmlPipe,
        SanitizeStylePipe,
        // Directives
        ScrollDirective,
        NameOnlyDirective,
        FirstLetterCapitalDirective,
        CurrencyDisplayDirective,
        TabOutputDirective,
        EnterOutputDirective,
        DebounceKeyUpDirective
    ],
    imports: [
        // Modules
        BrowserModule,
        BrowserAnimationsModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        // Library
        IonicStorageModule.forRoot({
            driverOrder: ["localstorage"]
        }),
        // Routes
        route,
        dashboardRoute
    ],
    providers: [
        // Library
        { provide: MAT_DATE_LOCALE, useValue: "en-AU" },
        httpInterceptorProviders
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
