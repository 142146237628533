import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Quote } from "../../models/quote";
import { QuoteService } from "../../services/quote.service";
import { TempQuote } from "../../models/temp-quote";

@Component({
    selector: "app-quick-quote-insured-type-component",
    templateUrl: "./quick-quote-insured-type.component.html",
    styleUrls: ["./quick-quote-insured-type.component.scss"]
})
export class QuickQuoteInsuredTypeComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    @Input("tempQuickQuote") tempQuickQuote: TempQuote;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() specialEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor(private quoteSrv: QuoteService) {}
    ngOnChanges(): void {}
    change(): void {
        if (this.quote.type === "single") {
            // Material Damage
            this.specialEventEmitter.emit({
 //               tab: 7
                tab: 6
            });
            if (this.quote.insuredType === "ownerBuilder") {
                for (
                    let i = 0;
                    i < this.quote.businessOfInsuredOption.length;
                    i++
                ) {
                    if (
                        this.quote.businessOfInsuredOption[i].name ===
                        "Owner Builder"
                    ) {
                        this.quote.businessType = this.quote.businessOfInsuredOption[
                            i
                        ].name;
                        this.quote.businessTypeId = this.quote.businessOfInsuredOption[
                            i
                        ].id;
                        this.quote.businessTypeReferralMd = this.quote.businessOfInsuredOption[
                            i
                        ].mdReferralOnline;
                        this.quote.businessTypeReferralL = this.quote.businessOfInsuredOption[
                            i
                        ].plReferralOnline;
                        this.quote.businessTypeReferralCPL = this.quote.businessOfInsuredOption[
                            i
                        ].cplReferralOnline;
                        this.quote.businessTypeReferral = false;
                        break;
                    }
                }
            } else {
                if (this.quote.businessType === "Owner Builder") {
                    this.quote.businessType = "";
                    this.quote.businessTypeId = null;
                    this.quote.businessTypeReferralMd = false;
                    this.quote.businessTypeReferralL = false;
                    this.quote.businessTypeReferralCPL = false;
                    this.quote.businessTypeReferral = false;
                }
            }
            // business-of-insured
            this.specialEventEmitter.emit({
//                tab: 4
                tab: 3
            });
        }
        // Building License
/*
        this.specialEventEmitter.emit({
            tab: 3
        });
*/
        // Project Type
        this.specialEventEmitter.emit({
  //          tab: 5
            tab: 4
        });
        this.quote.errorInsuredType = false;
        this.continueEventEmitter.emit({
            finished: true
        });
        this.tempQuickQuote.componentProjectType.ownerBuilderSpecialError = false;
    }
}
