import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { Quote } from "../../models/quote";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { QUOTE_REFERRAL } from "../../configs/quote-referral";

@Component({
    selector: "app-quote-maximum-project-duration-component",
    templateUrl: "./quote-maximum-project-duration.component.html",
    styleUrls: ["./quote-maximum-project-duration.component.scss"]
})
export class QuoteMaximumProjectDurationComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    mpdFlag = false;
    @Input("quote") quote: Quote;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor() {}
    ngOnChanges(): void {}
    openMpdSelection(event: any): void {
        event.target.blur();
        this.mpdFlag = true;
    }
    mpdChoose(option: any) {
        if (this.mpdFlag) {
            this.mpdFlag = false;
            this.quote.maximumProjectDuration = Number(option.value);
            this.quote.maximumProjectDurationId = option.valueId;
            this.quote.mpdReferral =
                this.quote.maximumProjectDuration > QUOTE_REFERRAL.mpdLimit;
            if (this.quote.mpdReferral) {
                GlobalStaticService.infoModal = {
                    title: "REFERRAL WARNING",
                    content:
                        "The Maximum Project Duration selected will create a referral to MECON"
                };
                GlobalStaticService.openInfoModal();
            }
            this.continueEventEmitter.emit({
                finished: true,
                referral: this.quote.mpdReferral,
                noScroll: true
            });
        }
    }
    next() {
        this.continueEventEmitter.emit({
            finished: true,
            referral: this.quote.mpdReferral
        });
    }
}
