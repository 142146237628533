import { AppComponentBase } from '../app-component-base.component';
import { Component } from '@angular/core';
import {Dialog} from './app-dialog.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './app-dialog.component.html',
  styleUrls: ['./app-dialog.component.scss'],
})
export class AppDialogComponent extends AppComponentBase {
  Dialog = Dialog;

  click($event): void {
    Dialog.dismiss($event);
  }
}
