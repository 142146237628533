import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Policy } from "../../models/policy";
import { Dialog } from "../app-dialog/app-dialog.service";
import { CommonUtility } from "../../utilities/common.utility";

@Component({
    selector: "app-policy-material-damage-component",
    templateUrl: "./policy-material-damage.component.html",
    styleUrls: ["./policy-material-damage.component.scss"]
})
export class PolicyMaterialDamageComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    @Input("policy") policy: Policy;
    @ViewChild("single2") single2: ElementRef;
    @ViewChild("single3") single3: ElementRef;
    @ViewChild("single4") single4: ElementRef;
    @ViewChild("single5") single5: ElementRef;
    @ViewChild("projectCost") projectCost: ElementRef;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() offsetRecheckEventEmitter: EventEmitter<any> = new EventEmitter();
    specialErrorHintPC = false;
    specialErrorHintES = false;
    specialErrorHintPSM = false;
    specialErrorHintCPTE = false;
    constructor() {}
    ngOnChanges(): void {}

    private changeValue(event: any, valueProp: string, limitProp: string, warningMessage: string) {
        if (CommonUtility.shouldIgnoreKeyCode(event.keyCode)) {
            return;
        }
        let value = GlobalStaticService.numberFormatDecimal(event.target.value);
        let limit = this.policy[limitProp];
        if (value > limit) {
            Dialog.warn(warningMessage);
            this.policy[valueProp] = limit;
            event.target.value = GlobalStaticService.priceFormat(limit);
            return;
        }
        this.policy[valueProp] = value;
        this.specialErrorHintPC = !value;
        this.checkFinished();
    }

    changeProjectCost(event: any): void {
        this.changeValue(
            event, "projectCost", "limitProjectCost", 
            "Sum insured cannot exceed current Policy limit for Project Cost");
    }
    changeExistingStructures(event: any): void {
        this.changeValue(
            event, "existingStructures", "limitExistingStructures", 
            "Sum insured cannot exceed current Policy limit for Existing Structures");
    }
    changePrincipalSupplied(event: any): void {
        this.changeValue(
            event, "principalSupplied", "limitPrincipalSupplied", 
            "Sum insured cannot exceed current Policy limit for Principal Supplied Materials");
    }
    changeContractorsPlant(event: any): void {
        this.changeValue(
            event, "contractorsPlant", "limitContractorsPlant", 
            "Sum insured cannot exceed current Policy limit for Contractors Plant, Tools & Equipment");
    }
    
    checkFinished(): void {
        this.policy.materialDamageFinished = true;
        if (this.policy.insuranceType !== "l") {
            if (this.policy.projectCost) {
                this.policy.materialDamageFinished = true;
            } else {
                this.policy.materialDamageFinished = false;
            }
        }
        if (!this.policy.materialDamageFinished) {
            this.emit();
        } else {
            this.policy.errorMaterialDamage = false;
        }
    }
    fieldSwitch(order: number): void {
        if (order === 3 && this.policy.insuredType === "ownerBuilder1") {
            order++;
        }
        this["single" + order].nativeElement.focus();
    }
    errorFix($event: any): void {
        this.specialErrorHintPC = false;
        this.specialErrorHintES = false;
        this.specialErrorHintPSM = false;
        this.specialErrorHintCPTE = false;
        let val = $event.target ? $event.target.value: 0;
        if (val) {
            $event.target.value = GlobalStaticService.priceFormat(val);
        }
    }
    emit(): void {
        this.continueEventEmitter.emit({
            finished: this.policy.materialDamageFinished
        });
    }
    continueForm(): void {
        if (!this.policy.materialDamageFinished) {
            this.policy.errorMaterialDamage = true;
        }
        this.emit();
    }
    placeholder(limit: number): string {
        if (limit > 0) {
            return "Insert amount $";
        } else {
            return "Not covered";
        }
    }
}
