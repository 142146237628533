import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Policy } from "../../models/policy";
import { CommonApi } from "../../api/common.api";
import { STATE } from "../../configs/state";
import { QUOTE_MAPPING } from "../../configs/quote-mapping";

@Component({
    selector: "app-policy-address-component",
    templateUrl: "./policy-address.component.html",
    styleUrls: ["./policy-address.component.scss"]
})
export class PolicyAddressComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    @Input("policy") policy: Policy;
    addresses: any[] = [];
    searchActive = false;
    resultActive = false;
    finished = false;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() goEventEmitter: EventEmitter<any> = new EventEmitter();
    address$$: any;
    constructor(private commonApi: CommonApi) { }
    ngOnChanges(): void { }
    searchFocus(): void {
        if (this.policy.address && this.policy.address.length >= 3) {
            this.searchActive = true;
            this.search();
        } else {
            this.searchActive = false;
        }
    }
    searchChange(): void {
        this.searchFocus();
        this.continueEventEmitter.emit({
            finished: false
        });
    }
    search(): void {
        this.resultActive = true;
        if (this.address$$) {
            this.address$$.unsubscribe();
        }
        this.address$$ = this.commonApi
            .getAddresses(this.policy.address)
            .subscribe(res => {
                this.addresses = res.completions;
                this.resultActive = false;
            });
    }
    searchBlur(): void {
        setTimeout(() => {
            this.searchActive = false;
            this.resultActive = false;
            this.addresses = [];
        }, 200);
    }
    chooseAddress(address: any): void {
        this.policy.address = address.full_address;
        const suburbArray = address.full_address.split(", ");
        const suburbArray2 = suburbArray[suburbArray.length - 1].split(" ");
        const suburb = suburbArray2
            .splice(0, suburbArray2.length - 2)
            .join(" ");
        const postcodeArray = address.full_address.split(" ");
        const postcode = postcodeArray[postcodeArray.length - 1];
        const state = suburbArray2[0];
        this.commonApi.getSuburbPostcode(postcode, suburb).subscribe(
            res => {
                this.policy.suburbData = res;
                this.policy.suburbData.postcode = res.postcode;
                this.policy.suburbData.state = state;
                this.policy.suburbData.stateName = STATE[state].stateName;
                this.policy.suburbData.suburb = suburb.toUpperCase();
                if (this.policy.type === "single" && res.spReferralOnline) {
                    this.referral(true);
                    return;
                } else if (this.policy.type === "annual" && res.apReferralOnline) {
                    this.referral(true);
                    return;
                }
                if (
                    this.policy.type === "single" ||
                    (this.policy.type === "annual" && this.policy.legacy)
                ) {
                    if (!this.policy.territorialLimit) {
                        this.referral();
                        return;
                    }
                    switch (Number(this.policy.territorialLimit)) {
                        case QUOTE_MAPPING.territorialLimit.qldNotAbove:
                        case QUOTE_MAPPING.territorialLimitLegacy.qldNotAbove:
                            // QLD + Above false
                            if (
                                this.policy.suburbData.stateId !== 4 ||
                                this.policy.suburbData.isAbove25thParallel
                            ) {
                                this.referral();
                                return;
                            }
                            break;
                        case QUOTE_MAPPING.territorialLimitLegacy.qldNswNotAbove:
                            // QLD + NSW + Above false
                            if (
                                (this.policy.suburbData.stateId !== 4 && this.policy.suburbData.stateId !== 2) ||
                                this.policy.suburbData.isAbove25thParallel
                            ) {
                                this.referral();
                                return;
                            }
                            break;
                        case QUOTE_MAPPING.territorialLimit.qld:
                        case QUOTE_MAPPING.territorialLimitLegacy.qld:
                            // QLD
                            if (this.policy.suburbData.stateId !== 4) {
                                this.referral();
                                return;
                            }
                            break;
                        case QUOTE_MAPPING.territorialLimit.nt:
                        case QUOTE_MAPPING.territorialLimitLegacy.nt:
                            // NT
                            if (this.policy.suburbData.stateId !== 3) {
                                this.referral();
                                return;
                            }
                            break;
                        case QUOTE_MAPPING.territorialLimit.nsw:
                        case QUOTE_MAPPING.territorialLimitLegacy.nsw:
                            // NSW
                            if (this.policy.suburbData.stateId !== 2) {
                                this.referral();
                                return;
                            }
                            break;
                        case QUOTE_MAPPING.territorialLimitLegacy.nswVic:
                            // NSW + VIC
                            if (this.policy.suburbData.stateId !== 2 && this.policy.suburbData.stateId !== 7) {
                                this.referral();
                                return;
                            }
                            break;
                        case QUOTE_MAPPING.territorialLimit.act:
                        case QUOTE_MAPPING.territorialLimitLegacy.act:
                            // ACT
                            if (this.policy.suburbData.stateId !== 1) {
                                this.referral();
                                return;
                            }
                            break;
                        case QUOTE_MAPPING.territorialLimit.vic:
                        case QUOTE_MAPPING.territorialLimitLegacy.vic:
                            // VIC
                            if (this.policy.suburbData.stateId !== 7) {
                                this.referral();
                                return;
                            }
                            break;
                        case QUOTE_MAPPING.territorialLimitLegacy.vicTas:
                            // VIC + TAS
                            if (this.policy.suburbData.stateId !== 7 && this.policy.suburbData.stateId !== 6) {
                                this.referral();
                                return;
                            }
                            break;
                        case QUOTE_MAPPING.territorialLimit.tas:
                        case QUOTE_MAPPING.territorialLimitLegacy.tas:
                            // TAS
                            if (this.policy.suburbData.stateId !== 6) {
                                this.referral();
                                return;
                            }
                            break;
                        case QUOTE_MAPPING.territorialLimit.sa:
                        case QUOTE_MAPPING.territorialLimitLegacy.sa:
                            // SA
                            if (this.policy.suburbData.stateId !== 5) {
                                this.referral();
                                return;
                            }
                            break;
                        case QUOTE_MAPPING.territorialLimit.waNotAbove:
                        case QUOTE_MAPPING.territorialLimitLegacy.waNotAbove:
                            // WA + Above false
                            if (
                                this.policy.suburbData.stateId !== 8 ||
                                this.policy.suburbData.isAbove25thParallel
                            ) {
                                this.referral();
                                return;
                            }
                            break;
                        case QUOTE_MAPPING.territorialLimit.wa:
                        case QUOTE_MAPPING.territorialLimitLegacy.wa:
                            // WA
                            if (this.policy.suburbData.stateId !== 8) {
                                this.referral();
                                return;
                            }
                            break;
                        case QUOTE_MAPPING.territorialLimitLegacy.nswAct:
                            // NSW + ACT
                            if (this.policy.suburbData.stateId !== 2 || this.policy.suburbData.stateId !== 1) {
                                this.referral();
                                return;
                            }
                            break;
                        case QUOTE_MAPPING.territorialLimit.auNotAbove:
                        case QUOTE_MAPPING.territorialLimitLegacy.auNotAbove:
                            // AU + Above false
                            if (this.policy.suburbData.isAbove25thParallel) {
                                this.referral();
                                return;
                            }
                            break;
                        case QUOTE_MAPPING.territorialLimitLegacy.saNsw:
                            // NSW + SA
                            if (this.policy.suburbData.stateId !== 2 && this.policy.suburbData.stateId !== 5) {
                                this.referral();
                                return;
                            }
                            break;
                        case QUOTE_MAPPING.territorialLimit.au:
                        case QUOTE_MAPPING.territorialLimitLegacy.au:
                            // Within Australia + Above true
                            if (this.policy.suburbData.isAbove25thParallel) {
                                this.referral();
                                return;
                            }
                            break;
                        default:
                            // Other
                            this.referral();
                            return;
                    }
                    this.policy.errorAddress = false;
                    this.finished = true;
                    this.continueEventEmitter.emit({
                        finished: true,
                        noScroll: true
                    });
                } else {
                    // annual + non legacy
                    switch (res.stateId) {
                        case 1:
                            // ACT
                            if (!this.policy.workLocationACT) {
                                this.referral();
                                return;
                            }
                            break;
                        case 2:
                            // NSW
                            if (!this.policy.workLocationNSW) {
                                this.referral();
                                return;
                            }
                            break;
                        case 3:
                            // NT
                            if (!this.policy.workLocationNT) {
                                this.referral();
                                return;
                            }
                            break;
                        case 4:
                            // QLD;
                            if (res.isAbove25thParallel) {
                                if (!this.policy.workLocationQLDa) {
                                    this.referral();
                                    return;
                                }
                            } else {
                                if (!this.policy.workLocationQLDb) {
                                    this.referral();
                                    return;
                                }
                            }
                            break;
                        case 5:
                            // SA
                            if (!this.policy.workLocationSA) {
                                this.referral();
                                return;
                            }
                            break;
                        case 6:
                            // TAS
                            if (!this.policy.workLocationTAS) {
                                this.referral();
                                return;
                            }
                            break;
                        case 7:
                            // VIC
                            if (!this.policy.workLocationVIC) {
                                this.referral();
                                return;
                            }
                            break;
                        case 8:
                            // WA
                            if (res.isAbove25thParallel) {
                                if (!this.policy.workLocationWAa) {
                                    this.referral();
                                    return;
                                }
                            } else {
                                if (!this.policy.workLocationWAb) {
                                    this.referral();
                                    return;
                                }
                            }
                            break;
                    }
                    this.policy.errorAddress = false;
                    this.finished = true;
                    this.continueEventEmitter.emit({
                        finished: true,
                        noScroll: true
                    });
                }
            },
            err => {
                switch (err.status) {
                    case 404:
                        this.policy.suburbData = {};
                        this.policy.suburbData.postcode = postcode;
                        this.policy.suburbData.state = state;
                        this.policy.suburbData.stateName =
                            STATE[state].stateName;
                        this.policy.suburbData.suburb = suburb.toUpperCase();
                        this.policy.suburbData.stateId = STATE[state].stateId;
                        this.policy.stateId = this.policy.suburbData.stateId;
                        this.referral(true);
                        return;
                    default:
                        GlobalStaticService.openErrorSuburbModal();
                        break;
                }
            }
        );
        this.searchActive = false;
        this.resultActive = false;
        this.addresses = [];
    }
    referral(special?: boolean): void {
        this.finished = false;
        if (special) {
            GlobalStaticService.infoModal = {
                title: "REFERRAL WARNING",
                content:
                    "Based on the specified location, you will need to contact MECON to discuss the current policy."
            };
        } else {
            GlobalStaticService.infoModal = {
                title: "REFERRAL WARNING",
                content:
                    "This location falls outside the location of projects to be insured. You will need to contact MECON to review the current policy limit."
            };
        }
        GlobalStaticService.openInfoModal();
        this.continueEventEmitter.emit({
            finished: false,
            noScroll: true
        });
    }
    clean(): void {
        this.policy.address = null;
        this.continueEventEmitter.emit({
            finished: false
        });
    }
    goEvent(): void {
        this.goEventEmitter.emit(2);
    }
}
