import { Injectable, Pipe, PipeTransform } from "@angular/core";

@Injectable({
    providedIn: "root"
})
@Pipe({
    name: "dateOnly"
})
export class DateOnlyPipe implements PipeTransform {
    constructor() {}

    transform(date: string): string {
        const originalDate = date.split("T")[0].split("-");
        return `${originalDate[2]}/${originalDate[1]}/${originalDate[0]}`;
    }
}
