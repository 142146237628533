import { Component, EventEmitter, Input, Output } from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Template } from "../../models/template";
import { Quote } from "../../models/quote";
import { QuoteMappingStaticService } from "../../static-services/quote-mapping.static-service";
import * as moment from "moment";
import { CommonUtility } from "../../utilities/common.utility";

@Component({
    selector: "app-modal-load-template-component",
    templateUrl: "./modal-load-template.component.html",
    styleUrls: ["./modal-load-template.component.scss"]
})
export class ModalLoadTemplateComponent {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    template = "";
    templates: Template[] = [];
    searchActive = false;
    resultActive = false;
    templateFinished = false;
    selectedTemplate: Template;
    @Output() loadTemplateEmitter: EventEmitter<Quote> = new EventEmitter();
    constructor() {}
    closeModal(): void {
        GlobalStaticService.closeLoadTemplateModal();
        this.clean();
    }
    load(): void {
        if (this.selectedTemplate) {
            this.selectedTemplate.quote.createdInvalidDate = moment().add(30, "days");
            const loadQuote = QuoteMappingStaticService.deepCopyTemplate(this.selectedTemplate);
            this.loadTemplateEmitter.emit(loadQuote);
            GlobalStaticService.closeLoadTemplateModal();
            GlobalStaticService.openLoadTemplateDoneModal();
            this.clean();
        }
    }
    clean(): void {
        this.template = "";
        this.templates = [];
        this.selectedTemplate = null;
        this.searchActive = false;
        this.resultActive = false;
        this.templateFinished = false;
    }
    searchFocus(): void {
        if (this.template.length >= 3) {
            this.searchActive = true;
            this.search();
        } else {
            this.searchActive = false;
        }
    }
    searchChange(): void {
        this.templateFinished = false;
        this.searchFocus();
    }
    search(): void {
        this.resultActive = true;
        this.templates = GlobalStaticService.templates.filter(template => {
            return (
                template.type.toLowerCase() === this.quote.type &&
                template.templateTitle
                    .toLowerCase()
                    .indexOf(this.template.toLowerCase()) !== -1
            );
        });
        this.resultActive = false;
    }
    searchBlur(): void {
        setTimeout(() => {
            this.searchActive = false;
            this.resultActive = false;
            this.templates = [];
        }, 200);
    }
    chooseTemplate(template: Template): void {
        this.selectedTemplate = template;
        this.template = template.templateTitle;
        this.templateFinished = true;
        this.searchActive = false;
        this.resultActive = false;
        this.templates = [];
    }
}
