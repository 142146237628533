import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";

import { Template } from "../../models/template";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Router } from "@angular/router";
import { QuoteMappingStaticService } from "../../static-services/quote-mapping.static-service";
import { CommonApi } from "../../api/common.api";

@Component({
    selector: "app-template-component",
    templateUrl: "./template.component.html",
    styleUrls: ["./template.component.scss"]
})
export class TemplateComponent implements OnChanges {
    @Input("template") template: Template;
    @Output() backEventEmitter: EventEmitter<boolean> = new EventEmitter();
    @Output() goEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() templateEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor(private router: Router, private commonApi: CommonApi) {}
    ngOnChanges(): void {}
    cleanData(): void {
        this.templateEventEmitter.emit(null);
    }
    back(): void {
        setTimeout(() => {
            this.cleanData();
        }, 500);
        this.backEventEmitter.emit(true);
    }
    go(nextPage: string, nextId: any): void {
        setTimeout(() => {
            this.cleanData();
        }, 500);
        this.goEventEmitter.emit({
            currentPage: "templatePage",
            nextPage: nextPage,
            nextId: nextId
        });
    }
    createQuote(): void {
        GlobalStaticService.clientQuote = false;
        GlobalStaticService.template = true;
        if (this.template.type === "Single") {
            GlobalStaticService.autoSingleQuote = QuoteMappingStaticService.deepCopyTemplate(this.template);
            this.router.navigate(["/dashboard/quotes/single/create"]);
        } else {
            GlobalStaticService.autoAnnualQuote = QuoteMappingStaticService.deepCopyTemplate(this.template);
            this.router.navigate(["/dashboard/quotes/annual/create"]);
        }
    }
    deleteTemplate(): void {
        GlobalStaticService.infoModal = {
            title: "WARNING",
            content: `Do you want to delete this quote template?`,
            buttons: [
                {
                    style: "",
                    text: "Cancel",
                    callback: () => {
                        GlobalStaticService.closeInfoModal();
                    }
                },
                {
                    style: "purple",
                    text: "Delete",
                    callback: () => {
                        GlobalStaticService.closeInfoModal();
                        this.back();
                        GlobalStaticService.templateDelete(
                            this.template,
                            this.commonApi
                        );
                    }
                }
            ]
        };
        GlobalStaticService.openInfoModal();
    }
}
