import { Directive, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
    selector: "[appEnterOutput]"
})
export class EnterOutputDirective {
    @Output() enterOutput: EventEmitter<any> = new EventEmitter();
    constructor() {}
    @HostListener("keydown", ["$event"]) onKeyDown(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.enterOutput.emit(true);
        }
    }
}
