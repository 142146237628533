import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Quote } from "../../models/quote";

@Component({
    selector: "app-quote-sub-limit-component",
    templateUrl: "./quote-sub-limit.component.html",
    styleUrls: ["./quote-sub-limit.component.scss"]
})
export class QuoteSubLimitComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor() {}
    ngOnChanges(): void {}
    next(): void {
        this.continueEventEmitter.emit({
            finished: true
        });
    }
}
