import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { Quote } from "../../models/quote";
import { QuoteService } from "../../services/quote.service";
import { GlobalStaticService } from "../../static-services/global.static-service";

@Component({
    selector: "app-quote-insurance-type-component",
    templateUrl: "./quote-insurance-type.component.html",
    styleUrls: ["./quote-insurance-type.component.scss"]
})
export class QuoteInsuranceTypeComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    referral = false;
    @Input("quote") quote: Quote;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() specialEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor(private quoteSrv: QuoteService) {}
    ngOnChanges(): void {}
    changeInsuranceType(): void {
        // Defects Liability Period
        if (
            this.quote.insuranceType !== "l" &&
            this.quote.insuredType !== "ownerBuilder"
        ) {
            this.quoteSrv.setDefaultDefectsLiabilityPeriod(this.quote);
        } else {
            this.quoteSrv.clearDefectsLiabilityPeriod(this.quote);
        }
        this.specialEventEmitter.emit({
            tab: 2,
            order: this.quote.type === "single" ? 3 : 4,
            finished: true,
            referral: false
        });
        // Material Damage
        this.specialEventEmitter.emit({
            tab: 4,
            order: this.quote.type === "single" ? 1 : 2,
            finished: this.quote.insuranceType === "l",
            referral: false,
            page:
                this.quote.type === "annual" && this.quote.insuranceType === "l"
                    ? 2
                    : 1
        });
        // Liability
        this.specialEventEmitter.emit({
            tab: 4,
            order: this.quote.type === "single" ? 3 : 4,
            finished: this.quote.insuranceType === "md",
            referral: false,
            page:
                this.quote.type === "annual" &&
                this.quote.insuranceType === "md"
                    ? 2
                    : 1
        });
        // Enhance Cover
        this.specialEventEmitter.emit({
            tab: 4,
            order: this.quote.type === "single" ? 5 : 6,
            finished: true,
            referral: false
        });
        // Business Type Referral with Insurance TYpe
        if (
            (this.quote.businessTypeReferralMd &&
                (this.quote.insuranceType === "md" ||
                    this.quote.insuranceType === "mdl")) ||
            (this.quote.businessTypeReferralL &&
                (this.quote.insuranceType === "l" ||
                    this.quote.insuranceType === "mdl"))
        ) {
            this.quote.insuranceTypeReferral = true;
            GlobalStaticService.infoModal = {
                title: "REFERRAL WARNING",
                content:
                    "The insurance cover required for current business type will be referred to MECON for quoting",
                buttons: [
                    {
                        text: "Confirm",
                        style: "purple",
                        callback: () => {
                            this.continueEventEmitter.emit({
                                finished: true,
                                referral: this.quote.insuranceTypeReferral
                            });
                            GlobalStaticService.closeInfoModal();
                        }
                    }
                ]
            };
            GlobalStaticService.openInfoModal();
        } else {
            this.quote.insuranceTypeReferral = false;
            this.continueEventEmitter.emit({
                finished: true,
                referral: this.quote.insuranceTypeReferral
            });
        }
        this.quote.errorInsuranceType = false;
    }
}
