import { Component, OnDestroy, OnInit, HostListener } from "@angular/core";
import { Router } from "@angular/router";

import { CommonApi } from "../../api/common.api";
import { InterfaceStaticService } from "../../static-services/interface.static-service";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { QuoteMappingStaticService } from "../../static-services/quote-mapping.static-service";

import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/interval";

import { Storage } from "@ionic/storage";
import { QUOTE_MAPPING } from "../../configs/quote-mapping";
import { environment } from "../../environments/environment";
import { SchemeTrackerService } from "../../services/scheme-tracker.service";
import { LookupService } from "../../services/lookup.service";
declare let window: any;

@Component({
    selector: "app-dashboard-page-component",
    templateUrl: "./dashboard.page.html",
    styleUrls: ["./dashboard.page.scss"]
})
export class DashboardPageComponent implements OnInit, OnDestroy {
    InterfaceStaticSrv = InterfaceStaticService;
    autoLogout$$: any;
    statusCheck$$: any;
    back$$: any;
    session$$: any;
    loading = true;
    loadingProgress = 0;
    loadingRequired = 0;
    loadingFinished = 0;
    loadingError = false;
    constructor(
        private commonApi: CommonApi,
        private storage: Storage,
        private router: Router,
        private lookupService: LookupService,
        private schemeTracker: SchemeTrackerService
    ) {}
    ngOnInit(): void {
        console.clear();

        this.statusCheck$$ = function(e:any = {}) {
            this.commonApi.getStatus().subscribe(
                res => {
                    if ( GlobalStaticService.infoModal.title === "System is under maintenance" ) {
                        GlobalStaticService.closeInfoModal();
                        this.authenticate();
                    }
                },
                err => {
                    if (err.status === 503) {
                        GlobalStaticService.cancelAutoLogoutAction();
                        GlobalStaticService.infoModal = {
                            title: "System is under maintenance",
                            content:
                                "The site you're trying to reach is temporarily unavailable. Sorry for the inconvenience. We'll be back up and running as fast as possible.",
                            noClose: true,
                            buttons: []
                        };
                        GlobalStaticService.openInfoModal();
                    } else {
                        if (GlobalStaticService.getToken()) {
                            this.router.navigate(["/"]);
                            GlobalStaticService.infoModal = {
                                title: "Session ended",
                                content:
                                    "You have been logged out because you signed in on another device or the system is upgrading.",
                                noClose: true,
                                buttons: [
                                    {
                                        style: "purple",
                                        text: "OK",
                                        callback: () => {
                                            this.commonApi
                                                .getLogout()
                                                .subscribe(res => {
                                                    GlobalStaticService.logout();
                                                    document.body.className = "";
                                                    GlobalStaticService.closeInfoModal();
                                                }, err => {
                                                    GlobalStaticService.logout();
                                                    document.body.className = "";
                                                    GlobalStaticService.closeInfoModal();
                                                });
                                        }
                                    }
                                ]
                            };
                            GlobalStaticService.openInfoModal();
                        }
                    }
                }
            );
        }

        // Check the session every few seconds
        this.session$$ = Observable.interval(10000).subscribe(e => this.statusCheck$$(e));

        //this.authenticate();
        // replaced use of this.authenticate() with this.statusCheck$$ so that we actually check the session status on load.
        this.statusCheck$$()

        this.autoLogout$$ = Observable.interval(1000).subscribe(e => {
            this.authenticate();
        });

        this.back$$ = Observable.fromEvent(window, "popstate").subscribe(
            res => {
                document.body.className = "";
            }
        );

console.log("Dashboard", this)

        // Only do all the lookups if they're actually logged in
        if( GlobalStaticService.getToken(true) ){

            this.loadingRequired++;
            if( this.lookupService.synchronise() ){
                this.loadingFinished++;
                // Don't checkLoading on this one as 1 out of 1 is 100% and this is not an syncronous item.
            }else{
                this.registerLoadingError(new Error("synchronise failed"), "Synchronise failed")
            }

            this.loadingRequired++;
            this.commonApi.getProfile().subscribe(res => {
                GlobalStaticService.brokerProfile = res;
                if ( res && !res.contactCategoryText) {
                    GlobalStaticService.brokerProfile.contactCategoryText = "";
                }
                /* window.Intercom("shutdown");
                window.Intercom("boot", {
                    name: GlobalStaticService.brokerProfile.fullName,
                    email: GlobalStaticService.brokerProfile.email,
                    phone: GlobalStaticService.brokerProfile.phone,
                    user_id:
                        GlobalStaticService.brokerProfile.contactId +
                        "-" +
                        environment.env,
                    BrokerID: GlobalStaticService.brokerProfile.contactId,
                    Mobile: GlobalStaticService.brokerProfile.mobile,
                    Address: GlobalStaticService.brokerProfile.address,
                    UCState: GlobalStaticService.brokerProfile.state,
                    ts_session_id: GlobalStaticService.brokerProfile.sessionId,
                    app_id: "kxdbx3uz"
                }); */
                GlobalStaticService.getTemplates(
                    this.commonApi,
                    GlobalStaticService.brokerProfile.contactId
                );
                GlobalStaticService.loadFromStorage(this.schemeTracker);
                this.loadingFinished++;
// TODO: not perfect as getTemplates looks like it is has and ajax request?
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Get profile failed")
            });

            this.loadingRequired++;
            this.commonApi.getProduct(41).subscribe(res => {
                QuoteMappingStaticService.singleLegacyLookups = res;
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Product 41 failed")
            });

            this.loadingRequired++;
            this.commonApi.getProduct(42).subscribe(res => {
                QuoteMappingStaticService.annualLegacyLookups = res;
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Product 42 failed")
            });

            this.loadingRequired++;
            this.commonApi.getProduct(47).subscribe(res => {
                QuoteMappingStaticService.singleLookups = res;
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Product 47 failed")
            });

            this.loadingRequired++;
            this.commonApi.getProduct(48).subscribe(res => {
                QuoteMappingStaticService.annualLookups = res;
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Product 48 failed")
            });

            this.loadingRequired++;
            this.commonApi.getLookup(541).subscribe(res => {
                QuoteMappingStaticService.embargo = res.fields;
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 541 failed")
            });

            this.loadingRequired++;
            this.commonApi.getLookup(540).subscribe(res => {
                QuoteMappingStaticService.above25th = res.fields;
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 540 failed")
            });

            this.loadingRequired++;
            this.commonApi.getLookup(1918).subscribe(res => {
                QuoteMappingStaticService.projectType = res.fields;
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 1918 failed")
            });

            this.loadingRequired++;
            this.commonApi.getIic().subscribe(res => {
                GlobalStaticService.infos = res;
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Get IIC failed")
            });

            this.loadingRequired++;
            this.commonApi.getReferral().subscribe(res => {
                GlobalStaticService.quoteOptionsMiscReferral = res;
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Get Referral failed")
            });

            this.loadingRequired++;
            this.commonApi.getLookup(2490).subscribe(res => {
                GlobalStaticService.quoteOptionsDynamicReferral = res.fields;
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 2490 failed")
            });

            // Dynamic lookup value Id
            this.loadingRequired++;
            this.commonApi.getLookup(490).subscribe(res => {
                for (let i = 0; i < res.fields.length; i++) {
                    if (res.fields[i].fieldId === 4234) {
                        for (let j = 0; j < res.fields[i].values.length; j++) {
                            switch (res.fields[i].values[j].value) {
                                case "NO":
                                    QUOTE_MAPPING.boolean.no =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "YES":
                                    QUOTE_MAPPING.boolean.yes =
                                        res.fields[i].values[j].valueId;
                                    break;
                            }
                        }
                    }
                }
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 490 failed")
            });

            this.loadingRequired++;
            this.commonApi.getLookup(635).subscribe(res => {
                for (let i = 0; i < res.fields.length; i++) {
                    if (res.fields[i].fieldId === 4623) {
                        for (let j = 0; j < res.fields[i].values.length; j++) {
                            switch (res.fields[i].values[j].value) {
                                case "Not Covered":
                                    QUOTE_MAPPING.cpl["0"] =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "$250,000":
                                    QUOTE_MAPPING.cpl["250000"] =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "$500,000":
                                    QUOTE_MAPPING.cpl["500000"] =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "$1,000,000":
                                    QUOTE_MAPPING.cpl["1000000"] =
                                        res.fields[i].values[j].valueId;
                                    break;
                            }
                        }
                    }
                }
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 635 failed")
            });

            this.loadingRequired++;
            this.commonApi.getLookup(671).subscribe(res => {
                for (let i = 0; i < res.fields.length; i++) {
                    if (res.fields[i].fieldId === 27623) {
                        for (let j = 0; j < res.fields[i].values.length; j++) {
                            switch (res.fields[i].values[j].value) {
                                case "Principal":
                                    QUOTE_MAPPING.insuredType.principal =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Owner Builder":
                                    QUOTE_MAPPING.insuredType.ownerBuilder =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Contractor":
                                    QUOTE_MAPPING.insuredType.contractor =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Subcontractor":
                                    QUOTE_MAPPING.insuredType.subcontractor =
                                        res.fields[i].values[j].valueId;
                                    break;
                            }
                        }
                    }
                }
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 671 failed")
            });

            this.loadingRequired++;
            this.commonApi.getLookup(1905).subscribe(res => {
                for (let i = 0; i < res.fields.length; i++) {
                    if (res.fields[i].fieldId === 13857) {
                        for (let j = 0; j < res.fields[i].values.length; j++) {
                            switch (res.fields[i].values[j].value) {
                                case "Construction of new residential building":
                                    QUOTE_MAPPING.projectType.nrb =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Construction of new commercial building":
                                    QUOTE_MAPPING.projectType.ncib =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Refurbishment, Fitout and/or Alterations to existing building (non-structural)":
                                    QUOTE_MAPPING.projectType.nsrfa =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Structural Alterations and/or Additions to existing residential building":
                                    QUOTE_MAPPING.projectType.ar =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Structural Alterations and/or Additions to existing commercial building":
                                    QUOTE_MAPPING.projectType.acib =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Other – please select an alternative value":
                                    QUOTE_MAPPING.projectType.other =
                                        res.fields[i].values[j].valueId;
                                    break;
                            }
                        }
                    }
                }
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 1905 failed")
            });

            this.loadingRequired++;
            this.commonApi.getLookup(1298).subscribe(res => {
                for (let i = 0; i < res.fields.length; i++) {
                    if (res.fields[i].fieldId === 9307) {
                        for (let j = 0; j < res.fields[i].values.length; j++) {
                            switch (res.fields[i].values[j].value) {
                                case "Poor":
                                    QUOTE_MAPPING.existingStructuresB.Poor =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Average ":
                                    QUOTE_MAPPING.existingStructuresB.Average =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Good":
                                    QUOTE_MAPPING.existingStructuresB.Good =
                                        res.fields[i].values[j].valueId;
                                    break;
                            }
                        }
                    }
                }
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 1298 failed")
            });

            this.loadingRequired++;
            this.commonApi.getLookup(668).subscribe(res => {
                for (let i = 0; i < res.fields.length; i++) {
                    if (res.fields[i].fieldId === 4682) {
                        GlobalStaticService.quoteOptionsMDValue =
                            res.fields[i].values;
                        for (let j = 0; j < res.fields[i].values.length; j++) {
                            switch (Number(res.fields[i].values[j].value)) {
                                case 1000:
                                    QUOTE_MAPPING.tailorYourCoverMdValue["1000"] =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case 2500:
                                    QUOTE_MAPPING.tailorYourCoverMdValue["2500"] =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case 5000:
                                    QUOTE_MAPPING.tailorYourCoverMdValue["5000"] =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case 10000:
                                    QUOTE_MAPPING.tailorYourCoverMdValue["10000"] =
                                        res.fields[i].values[j].valueId;
                                    break;
                            }
                        }
                    }
                }
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 668 failed")
            });

            this.loadingRequired++;
            this.commonApi.getLookup(1291).subscribe(res => {
                for (let i = 0; i < res.fields.length; i++) {
                    if (res.fields[i].fieldId === 9296) {
                        GlobalStaticService.quoteOptionsPLValue =
                            res.fields[i].values;
                        for (let j = 0; j < res.fields[i].values.length; j++) {
                            switch (Number(res.fields[i].values[j].value)) {
                                case 1000:
                                    QUOTE_MAPPING.tailorYourCoverLValue["1000"] =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case 2500:
                                    QUOTE_MAPPING.tailorYourCoverLValue["2500"] =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case 5000:
                                    QUOTE_MAPPING.tailorYourCoverLValue["5000"] =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case 10000:
                                    QUOTE_MAPPING.tailorYourCoverLValue["10000"] =
                                        res.fields[i].values[j].valueId;
                                    break;
                            }
                        }
                    }
                }
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 1291 failed")
            });

            this.loadingRequired++;
            this.commonApi.getLookup(2451).subscribe(res => {
                for (let i = 0; i < res.fields.length; i++) {
                    if (res.fields[i].fieldId === 18264) {
                        GlobalStaticService.quoteOptionsMDDes =
                            res.fields[i].values;
                        for (let j = 0; j < res.fields[i].values.length; j++) {
                            switch (res.fields[i].values[j].value.toLowerCase()) {
                                case "each and every event":
                                    QUOTE_MAPPING.tailorYourCoverMd.default = res.fields[i].values[j].valueId;
                                    break;
                                case "minor hazard each and every event":
                                    QUOTE_MAPPING.tailorYourCoverMd.a = res.fields[i].values[j].valueId;
                                    break;
                                case "major hazard each and every event":
                                    QUOTE_MAPPING.tailorYourCoverMd.b = res.fields[i].values[j].valueId;
                                    break;
                            }
                        }
                    }
                }
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 2451 failed")
            });

            this.loadingRequired++;
            this.commonApi.getLookup(3687).subscribe(res => {
                for (let i = 0; i < res.fields.length; i++) {
                    if (res.fields[i].fieldId === 27472) {
                        GlobalStaticService.quoteOptionsPLDes =
                            res.fields[i].values;
                        for (let j = 0; j < res.fields[i].values.length; j++) {
                            switch (res.fields[i].values[j].value.toLowerCase()) {
                                case "each and every occurrence":
                                    QUOTE_MAPPING.tailorYourCoverL.a =
                                        res.fields[i].values[j].valueId;
                                    break;
                            }
                        }
                    }
                }
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 3687 failed")
            });

            this.loadingRequired++;
            this.commonApi.getLookup(670).subscribe(res => {
                for (let i = 0; i < res.fields.length; i++) {
                    if (res.fields[i].fieldId === 4683) {
                        for (let j = 0; j < res.fields[i].values.length; j++) {
                            switch (res.fields[i].values[j].value) {
                                case "Run-off - Total values for all Projects commenced during the Policy Period":
                                    QUOTE_MAPPING.ongoingWork["run-off"] =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Turnover - Total annual turnover from all Projects":
                                    QUOTE_MAPPING.ongoingWork.turnover =
                                        res.fields[i].values[j].valueId;
                                    break;
                            }
                        }
                    }
                }
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 670 failed")
            });

            this.loadingRequired++;
            this.commonApi.getLookup(2457).subscribe(res => {
                for (let i = 0; i < res.fields.length; i++) {
                    if (res.fields[i].fieldId === 18282) {
                        for (let j = 0; j < res.fields[i].values.length; j++) {
                            switch (res.fields[i].values[j].value) {
                                case "100% Minimum Deposit (No rebate)":
                                    QUOTE_MAPPING.premiumDeposit.a =
                                        res.fields[i].values[j].valueId;
                                    break;
                            }
                        }
                    }
                }
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 2457 failed")
            });

            this.loadingRequired++;
            this.commonApi.getLookup(1294).subscribe(res => {
                for (let i = 0; i < res.fields.length; i++) {
                    if (res.fields[i].fieldId === 9299) {
                        for (let j = 0; j < res.fields[i].values.length; j++) {
                            switch (res.fields[i].values[j].value) {
                                case "0 > 3":
                                    QUOTE_MAPPING.maximumExcavationDepth["0-3"] =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "6 > 10":
                                    QUOTE_MAPPING.maximumExcavationDepth["6-10"] =
                                        res.fields[i].values[j].valueId;
                                    break;
                            }
                        }
                    }
                }
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 1294 failed")
            });

            this.loadingRequired++;
            this.commonApi.getLookup(491).subscribe(res => {
                for (let i = 0; i < res.fields.length; i++) {
                    if (res.fields[i].fieldId === 4235) {
                        for (let j = 0; j < res.fields[i].values.length; j++) {
                            switch (res.fields[i].values[j].value) {
                                case "Principal":
                                    QUOTE_MAPPING.insuredTypeLegacy.principal =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Owner Builder":
                                    QUOTE_MAPPING.insuredTypeLegacy.ownerBuilder =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Contractor":
                                    QUOTE_MAPPING.insuredTypeLegacy.contractor =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Subcontractor":
                                    QUOTE_MAPPING.insuredTypeLegacy.subcontractor =
                                        res.fields[i].values[j].valueId;
                                    break;
                            }
                        }
                    }
                }
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 491 failed")
            });

            this.loadingRequired++;
            this.commonApi.getLookup(492).subscribe(res => {
                for (let i = 0; i < res.fields.length; i++) {
                    if (res.fields[i].fieldId === 4238) {
                        for (let j = 0; j < res.fields[i].values.length; j++) {
                            switch (res.fields[i].values[j].value) {
                                case "Building Residential: New":
                                    QUOTE_MAPPING.projectTypeLegacy.nrb =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Building Office/Commercial: New":
                                    QUOTE_MAPPING.projectTypeLegacy.ncib[0] =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Building Industrial: New":
                                    QUOTE_MAPPING.projectTypeLegacy.ncib[1] =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Building Non-structural: Refurb Fitout/Alterations":
                                    QUOTE_MAPPING.projectTypeLegacy.nsrfa =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Building Residential: Structural Alterations/Additions":
                                    QUOTE_MAPPING.projectTypeLegacy.ar =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Building Office/Commercial: Structural Alterations/Additions":
                                    QUOTE_MAPPING.projectTypeLegacy.acib[0] =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Building Industrial: Structural Alterations/Additions":
                                    QUOTE_MAPPING.projectTypeLegacy.acib[1] =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Other (will be referred)":
                                    QUOTE_MAPPING.projectTypeLegacy.other =
                                        res.fields[i].values[j].valueId;
                                    break;
                            }
                        }
                    }
                }
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 492 failed")
            });

            this.loadingRequired++;
            this.commonApi.getLookup(500).subscribe(res => {
                for (let i = 0; i < res.fields.length; i++) {
                    if (res.fields[i].fieldId === 4249) {
                        for (let j = 0; j < res.fields[i].values.length; j++) {
                            switch (res.fields[i].values[j].value) {
                                case "Run Off - Total values for all Projects commenced during the Policy Period":
                                    QUOTE_MAPPING.ongoingWorkLegacy["run-off"] =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Turnover - Total annual turnover from all Projects":
                                    QUOTE_MAPPING.ongoingWorkLegacy.turnover =
                                        res.fields[i].values[j].valueId;
                                    break;
                            }
                        }
                    }
                }
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 500 failed")
            });

            this.loadingRequired++;
            this.commonApi.getLookup(2475).subscribe(res => {
                for (let i = 0; i < res.fields.length; i++) {
                    if (res.fields[i].fieldId === 18330) {
                        for (let j = 0; j < res.fields[i].values.length; j++) {
                            switch (res.fields[i].values[j].value) {
                                case "Within Queensland but not north of 25th parallel south":
                                case "Within Queensland but not north of 26th parallel south":
                                    QUOTE_MAPPING.territorialLimit.qldNotAbove =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within New South Wales":
                                    QUOTE_MAPPING.territorialLimit.nsw =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within ACT":
                                    QUOTE_MAPPING.territorialLimit.act =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within Queensland":
                                    QUOTE_MAPPING.territorialLimit.qld =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within Western Australia":
                                    QUOTE_MAPPING.territorialLimit.wa =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within Northern Territory":
                                    QUOTE_MAPPING.territorialLimit.nt =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within Victoria":
                                    QUOTE_MAPPING.territorialLimit.vic =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within Tasmania":
                                    QUOTE_MAPPING.territorialLimit.tas =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within South Australia":
                                    QUOTE_MAPPING.territorialLimit.sa =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within Western Australia but not north of 25th parallel south":
                                case "Within Western Australia but not north of 26th parallel south":
                                    QUOTE_MAPPING.territorialLimit.waNotAbove =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within Australia but not north of 25th parallel south":
                                case "Within Australia but not north of 26th parallel south":
                                    QUOTE_MAPPING.territorialLimit.auNotAbove =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within Australia":
                                    QUOTE_MAPPING.territorialLimit.au =
                                        res.fields[i].values[j].valueId;
                                    break;
                            }
                        }
                    }
                }
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 2475 failed")
            });

            this.loadingRequired++;
            this.commonApi.getLookup(557).subscribe(res => {
                for (let i = 0; i < res.fields.length; i++) {
                    if (res.fields[i].fieldId === 4407) {
                        for (let j = 0; j < res.fields[i].values.length; j++) {
                            switch (res.fields[i].values[j].value) {
                                case "Within Queensland but not north of 25th parallel south":
                                case "Within Queensland but not north of 26th parallel south":
                                    QUOTE_MAPPING.territorialLimitLegacy.qldNotAbove =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within Queensland and New South Wales but not north of 25th parallel south":
                                case "Within Queensland and New South Wales but not north of 26th parallel south":
                                    QUOTE_MAPPING.territorialLimitLegacy.qldNswNotAbove =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within New South Wales":
                                    QUOTE_MAPPING.territorialLimitLegacy.nsw =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within New South Wales and Victoria":
                                    QUOTE_MAPPING.territorialLimitLegacy.nswVic =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within ACT":
                                    QUOTE_MAPPING.territorialLimitLegacy.act =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within Victoria":
                                    QUOTE_MAPPING.territorialLimitLegacy.vic =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within Queensland":
                                    QUOTE_MAPPING.territorialLimitLegacy.qld =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within Western Australia":
                                    QUOTE_MAPPING.territorialLimitLegacy.wa =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within Northern Territory":
                                    QUOTE_MAPPING.territorialLimitLegacy.nt =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within Tasmania":
                                    QUOTE_MAPPING.territorialLimitLegacy.tas =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within South Australia":
                                    QUOTE_MAPPING.territorialLimitLegacy.sa =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within Western Australia but not north of 25th parallel south":
                                case "Within Western Australia but not north of 26th parallel south":
                                    QUOTE_MAPPING.territorialLimitLegacy.waNotAbove =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within Victoria and Tasmania":
                                    QUOTE_MAPPING.territorialLimitLegacy.vicTas =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within New South Wales and ACT":
                                    QUOTE_MAPPING.territorialLimitLegacy.nswAct =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within Australia but not north of 25th parallel south":
                                case "Within Australia but not north of 26th parallel south":
                                    QUOTE_MAPPING.territorialLimitLegacy.auNotAbove =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within South Australia and New South Wales":
                                    QUOTE_MAPPING.territorialLimitLegacy.saNsw =
                                        res.fields[i].values[j].valueId;
                                    break;
                                case "Within Australia":
                                    QUOTE_MAPPING.territorialLimitLegacy.au =
                                        res.fields[i].values[j].valueId;
                                    break;
                            }
                        }
                    }
                }
                this.loadingFinished++;
                this.checkLoading();
            }, err => {
                this.registerLoadingError(err, "Lookup 557 failed")
            });


// simulate a lookup failing
//this.loadingRequired++;

// simulate a loading error
//this.registerLoadingError(new Error("nothing"), "test loading error...")

            // Refresh the browser if it doesn't load all the lookups correctly
            setTimeout(()=>{
                if( this.loading && GlobalStaticService.getToken(true) && this.router.url !== "/" ){
                    console.warn("Failed to load all lookups. Prompting user.");

                    GlobalStaticService.infoModal = {
                        title: "Loading",
                        content:
                            "Loading is taking longer than expected. Would you like to try again?",
                        noClose: true,
                        buttons: [
                            {
                                style: "purple",
                                text: "OK",
                                callback: () => {
                                    window.location.reload()
                                }
                            },
                            {
                                style: "purple",
                                text: "Wait",
                                callback: () => {
                                    document.body.className = "";
                                    GlobalStaticService.closeInfoModal();
                                }
                            }
                        ]
                    };
                    GlobalStaticService.openInfoModal();
                }
            }, 60000)

            // Tutorial
            this.storage.get("tutorial").then(tutorial => {
                if (tutorial) {
                    GlobalStaticService.autoSingle = tutorial.autoSingle;
                    GlobalStaticService.autoAnnual = tutorial.autoAnnual;
                    GlobalStaticService.autoReferral = tutorial.autoReferral;
                    GlobalStaticService.autoSummary = tutorial.autoSummary;
                }
            });
        } // logged in check
    }

    registerLoadingError(err:Error, code:string =""): void {
        console.error(err)
        console.log(err)
        console.log("registerLoadingError", arguments)

        if( this.loadingError || this.router.url === "/" || !GlobalStaticService.getToken() ){
            return
        }

        this.loadingError = true;

        GlobalStaticService.infoModal = {
            title: "Loading",
            content:
                `There was a problem loading a resource.
                <br>
                <br>
                <small class="minor">${code}</small>`,
            noClose: true,
            buttons: [
                {
                    style: "purple",
                    text: "Retry",
// It seems that some users were getting a cached failed response.. so subsequent tries also were failing
// Cache-Control headers have been updated on the resources for no caching.
                    callback: () => {
                        window.location.href = "/dashboard/home"
                    }
                },
                {
                    style: "purple",
                    text: "Cancel",
                    callback: () => {
                        GlobalStaticService.closeInfoModal();
                    }
                }
            ]
        };
        GlobalStaticService.openInfoModal();
    }

    checkLoading(): void {
        this.loading = this.loadingRequired > this.loadingFinished;
        this.loadingProgress = Math.round(this.loadingFinished / Math.max(this.loadingRequired, 1) * 100);

        console.log(`loading stats:
            loading ${this.loading},
            loadingRequired ${this.loadingRequired},
            loadingFinished ${this.loadingFinished},
            loadingProgress ${this.loadingProgress}
        `)

        if( !this.loading ){
            document.body.className = "";
            GlobalStaticService.closeInfoModal();
        }
    }

    ngOnDestroy(): void {
        if (this.back$$) {
            this.back$$.unsubscribe();
        }
        if (this.session$$) {
            this.session$$.unsubscribe();
        }
        if (this.autoLogout$$) {
            this.autoLogout$$.unsubscribe();
        }
    }

    @HostListener("window:unload", ["$event"])
    unloadHandler(event) {
// ALWAYS KEEP DEV AND PROD THE SAME BEHAVIOUR!
        //if (environment.production) {
            console.log("window:unload")
            let token: string = GlobalStaticService.getToken();
            navigator.sendBeacon(
                this.commonApi.getUnloadUrl() + "?_session=" + token
            );
        //}
    }

    authenticate(): void {
        if (!GlobalStaticService.getToken(true)) {
            this.router.navigate(["/"]);
            GlobalStaticService.logout();
            this.commonApi.getLogout();
            if (this.session$$) {
                this.session$$.unsubscribe();
            }
        }
    }
}
