import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Dialog } from "../app-dialog/app-dialog.service";
import { Quote } from "../../models/quote";

@Component({
    selector: "app-quote-attachments-component",
    templateUrl: "./quote-attachments.component.html",
    styleUrls: ["./quote-attachments.component.scss"]
})
export class QuoteAttachmentsComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    @Output() offsetRecheckEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor() {}
    ngOnChanges(): void {}
    upload(event: any): void {
        if (this.quote.attachments) {
            let nameCheckResult = true;
            for (let i = 0; i < event.target.files.length; i++) {
                const nameArray = event.target.files[i].name.split('.');
                if (this.nameCheck(nameArray[nameArray.length - 1])) {
                    for (let j = 0; j < this.quote.attachments.length; j++) {
                        if (
                            this.quote.attachments[j].name === event.target.files[i].name &&
                            this.quote.attachments[j].size ===
                                event.target.files[i].size
                        ) {
                            this.quote.attachments.splice(j, 1);
                        }
                    }
                    this.quote.attachments.push(event.target.files[i]);
                } else {
                    nameCheckResult = false;
                }
            }
            if (!nameCheckResult) {
                GlobalStaticService.infoModal = {
                    title: "File Type Warning",
                    content:
                        "Some files selected could not be uploaded due to file type."
                };
                GlobalStaticService.openInfoModal();
            }
        }
        this.offsetRecheckEventEmitter.emit(5);
        this.continueEventEmitter.emit({
            finished: true,
            noScroll: true,
//            referral: this.quote.attachments && this.quote.attachments.length
        });
        event.target.value = "";
    }
    nameCheck(name: string): boolean {
        if (name !== 'png' && name !== 'jpeg' && name !== 'jpg' && name !== 'pdf' && name !== 'zip' && name !== 'tiff' && name !== 'html' && name !== 'xhtml' && name !== 'xml' && name !== 'rtf' && name !== 'rtf' && name !== 'gif' && name !== 'xlsx' && name !== 'doc' && name !== 'docx' && name !== 'xls') {
            return false;
        } else {
            return true;
        }
    }
    loadDisclaimer(): void {
        this.quote._attachDeclarationConfirmed = true;
        Dialog.open({
            title: "Disclaimer",
            message: "<div class='left-align'>The cover and terms shown in your placing slip must match those of this quotation. Differences will not be included in the policy. If alterations are required please request them separately. Only those that are acceptable to us will be included in the policy. <br/><br/>Even if you have attached documents to your quotes, these will not alter the quote unless we have read these and decide to make alterations. It is your responsibility to check that any changes required in the documents are brought to our attention.</div>",
            buttons: ["Ok"]
        });
    }
    delete(order: number): void {
        this.quote.attachments.splice(order, 1);
        this.offsetRecheckEventEmitter.emit(5);
        this.continueEventEmitter.emit({
            finished: true,
            noScroll: true,
//            referral: this.quote.attachments && this.quote.attachments.length
        });
    }
}
