import { Component, Input, OnInit } from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Quote } from "../../models/quote";

@Component({
    selector: "app-tutorial-component",
    templateUrl: "./tutorial.component.html",
    styleUrls: ["./tutorial.component.scss"]
})
export class TutorialComponent implements OnInit {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    constructor() {}
    ngOnInit(): void {}
    closeTutorial(): void {
        GlobalStaticService.removeTutorialBodyLock();
        GlobalStaticService.isTutorialActive = false;
    }
    gotoPage(page: number): void {
        GlobalStaticService.tutorialPage = page;
    }
    next(): void {
        if (GlobalStaticService.tutorialPage === 8) {
            return;
        }
        GlobalStaticService.tutorialPage++;
    }
    previous(): void {
        if (
            (GlobalStaticService.tutorialPage > 2 &&
                GlobalStaticService.tutorialStage === 1) ||
            (GlobalStaticService.tutorialPage === 8 &&
                GlobalStaticService.tutorialStage === 3)
        ) {
            GlobalStaticService.tutorialPage--;
        }
    }
}
