import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { Quote } from "../../models/quote";
import { GlobalStaticService } from "../../static-services/global.static-service";

@Component({
    selector: "app-quote-building-license-component",
    templateUrl: "./quote-building-license.component.html",
    styleUrls: ["./quote-building-license.component.scss"]
})
export class QuoteBuildingLicenseComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() specialEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor() {}
    ngOnChanges(changes): void {
    }
    change(): void {
        this.quote.buildingLicenseReferral = this.quote.unlicensedBuilder || this.quote.licenseLending;

        this.continueEventEmitter.emit({
            finished: false,
            referral: this.quote.buildingLicenseReferral
        });
    }
    next(): void {
        // If Owner Builder is selected as the Insured Type then skip both questions, as they are not relevant.
        this.quote.errorBuildingLicense = this.quote.insuredType != "ownerBuilder" &&
            (this.quote.unlicensedBuilder == null
                // This question only shows for single quote
                || (this.quote.type === "single" && this.quote.insuredType != "ownerBuilder" && this.quote.licenseLending == null)
            );

        this.quote.buildingLicenseReferral = this.quote.unlicensedBuilder || this.quote.licenseLending;

        this.continueEventEmitter.emit({
            finished: !this.quote.errorBuildingLicense,
            referral: this.quote.buildingLicenseReferral
        });
    }
}
