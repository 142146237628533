import {
    Directive,
    EventEmitter,
    HostListener,
    Input,
    Output
} from "@angular/core";

@Directive({
    selector: "[appTabOutput]"
})
export class TabOutputDirective {
    @Input("appTabOutput") appTabOutput = "non-shift";
    @Output() tabOutput: EventEmitter<any> = new EventEmitter();
    @Output() tabShiftOutput: EventEmitter<any> = new EventEmitter();
    constructor() {}
    @HostListener("keydown", ["$event"]) onKeyDown(event) {
        if (this.appTabOutput === "both") {
            this.disableShiftTab(event);
            this.disableTab(event);
        } else if (this.appTabOutput === "shift") {
            this.disableShiftTab(event);
        } else {
            this.disableTab(event);
        }
    }
    disableShiftTab(event): void {
        if (event.keyCode === 9 && event.shiftKey) {
            event.preventDefault();
            this.tabShiftOutput.emit(true);
        }
    }
    disableTab(event): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            event.preventDefault();
            this.tabOutput.emit(true);
        }
    }
}
