import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Quote } from "../../models/quote";
import { CommonUtility } from "../../utilities/common.utility";
@Component({
    selector: "app-quote-insured-declaration-component",
    templateUrl: "./quote-insured-declaration.component.html",
    styleUrls: ["./quote-insured-declaration.component.scss"]
})
export class QuoteInsuredDeclarationComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    currentTab = 1;
    @ViewChild("tab1") tab1: ElementRef;
    tab2Finished = false;
    @ViewChild("tab2") tab2: ElementRef;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() goEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor() {}
    get modalViewedFlag(): boolean {
        return this.quote.insuredDeclarationModalViewed;
    }
    ngOnChanges(): void {
        if (
            this.quote.insuredDeclaration === true ||
            this.quote.insuredDeclaration === false
        ) {
            this.quote.insuredDeclarationModalViewed = false;
        }
        if (this.quote.insuredDeclarationDetails) {
            this.tab2Finished = true;
            this.go(2);
        }
    }
    readMore(): void {
        this.quote.insuredDeclarationModalViewed = true;
        GlobalStaticService.infoModal = {
            title: "Insured Declaration",
            content: `<ul class="m-quote-detail-list sub read-more">
                        <li>
                          <div class="m-fields-group">
                            <div class="icon">A.</div>
                            <div class="item">Experienced any loss, damage, circumstance, liability or claim against you (whether insured or not) that could be covered by any of the policies now proposed?</div>
                          </div>
                        </li>
                        <li>
                          <div class="m-fields-group">
                            <div class="icon">B.</div>
                            <div class="item">Had an insurer decline any claim, cancel any insurance policy or impose special terms to any insurance policy?</div>
                          </div>
                        </li>
                        <li>
                          <div class="m-fields-group">
                            <div class="icon">C.</div>
                            <div class="item">Been charged with, pleaded guilty to or been convicted of any criminal offence or had any criminal offence proved?</div>
                          </div>
                        </li>
                        <li>
                          <div class="m-fields-group">
                            <div class="icon">D.</div>
                            <div class="item">Been associated in any way with any: Outlaw Motorcycle Gang (“OMG”) or any member of an OMG; organised crime gang (“OCG”) or any member of an OCG, or other illegal association?</div>
                          </div>
                        <li>
                          <div class="m-fields-group">
                            <div class="icon">E.</div>
                            <div class="item">Been declared bankrupt?</div>
                          </div>
                        </li>
                        <li>
                          <div class="m-fields-group">
                            <div class="icon">F.</div>
                            <div class="item">Had a liquidator and/or receiver appointed and/or been placed into external administration?</div>
                          </div>
                        </li>
                        <li>
                          <div class="m-fields-group">
                            <div class="icon">G.</div>
                            <div class="item">Been a defendant in any civil court case?</div>
                          </div>
                        </li>
                      </ul>`,
            buttons: [
                {
                    style: "",
                    text: "Back",
                    callback: () => {
                        GlobalStaticService.closeInfoModal();
                    }
                }
            ]
        };
        GlobalStaticService.openInfoModal();
    }
    alert(): void {
        if (!this.modalViewedFlag) {
            this.quote.errorInsuredDeclaration = true;
        }
    }
    change(): void {
        this.quote.errorInsuredDeclaration = false;
        if (!this.quote.insuredDeclaration) {
            this.go(1);
            this.tab2Finished = false;
            this.quote.insuredDeclarationDetails = null;
            this.quote.errorInsuredDeclaration = false;
            this.quote.errorInsuredDeclaration = false;
            this.continueEventEmitter.emit({
                finished: true,
                noScroll: true
            });
        } else {
            this.go(2);
            this.continueEventEmitter.emit({
                finished: !!this.quote.insuredDeclarationDetails,
                noScroll: true
            });
        }
    }
    cleanClassList(tab: string): void {
        this[tab].nativeElement.classList.remove("slideInLeft");
        this[tab].nativeElement.classList.remove("slideInRight");
        this[tab].nativeElement.classList.remove("slideOutLeft");
        this[tab].nativeElement.classList.remove("slideOutRight");
    }
    next(next?: number): void {
        if (!next) {
            next = 1;
        }
        this.cleanClassList("tab" + this.currentTab);
        this.cleanClassList("tab" + (this.currentTab + next));
        this["tab" + this.currentTab].nativeElement.classList.add(
            "slideOutLeft"
        );
        this["tab" + (this.currentTab + next)].nativeElement.classList.add(
            "slideInRight"
        );
        this["tab" + (this.currentTab + next)].nativeElement.classList.add(
            "active"
        );
        setTimeout(() => {
            this["tab" + this.currentTab].nativeElement.classList.remove(
                "active"
            );
            this.currentTab = this.currentTab + next;
        }, 500);
    }
    back(prev?: number): void {
        if (!prev) {
            prev = 1;
        }
        this.cleanClassList("tab" + this.currentTab);
        this.cleanClassList("tab" + (this.currentTab - prev));
        this["tab" + this.currentTab].nativeElement.classList.add(
            "slideOutRight"
        );
        this["tab" + (this.currentTab - prev)].nativeElement.classList.add(
            "slideInLeft"
        );
        this["tab" + (this.currentTab - prev)].nativeElement.classList.add(
            "active"
        );
        setTimeout(() => {
            this["tab" + this.currentTab].nativeElement.classList.remove(
                "active"
            );
            this.currentTab = this.currentTab - prev;
        }, 500);
    }
    go(tab: number): void {
        if (this.currentTab !== tab) {
            if (this.currentTab > tab) {
                this.back(this.currentTab - tab);
            } else {
                this.next(tab - this.currentTab);
            }
        }
    }
    goEvent(): void {
        this.quote.errorInsuredDeclaration = false;
        this.goEventEmitter.emit(2);
    }
    continueForm(): void {
        this.tab2Finished = !!this.quote.insuredDeclarationDetails;
        if (this.quote.insuredDeclarationDetails) {
            this.quote.errorInsuredDeclaration = false;
        }
        this.continueEventEmitter.emit({
            finished: this.tab2Finished,
            referral: this.quote.insuredDeclaration,
            noScroll: true
        });
    }
}
