import { Client } from "./client";
import { Rating } from "./rating";

export class Quote {
    // List Attributes
    policyId: number;
    clientName: string;
    effectiveDate: string;
    inceptionDate: string;
    termExpiryDate: string;
    //  Single Attributes
    origQuoteId: number;
    actionRequired: boolean;
    autoRenewStatusId: number;
    meconAdminFee: number;
    baseAnnualAgreedPremiumAmount: number;
    baseAnnualCalcPremiumAmount: number;
    baseCalcPremiumAmount: number;
    baseCurrencyBaseAnnualAgreedPremiumAmount: number;
    baseCurrencyBaseAnnualCalcPremiumAmount: number;
    baseCurrencyBaseCalcPremiumAmount: number;
    baseCurrencyBasePremiumAmount: number;
    baseCurrencyClientPaid: number;
    baseCurrencyClientPayable: number;
    baseCurrencyGrossPremiumAmount: number;
    baseCurrencyIntroducerPaid: number;
    baseCurrencyIntroducerPayable: number;
    baseCurrencyManagingEntityPaid: number;
    baseCurrencyManagingEntityPayable: number;
    baseCurrencyNetPremiumAmount: number;
    baseCurrencyProviderPaid: number;
    baseCurrencyProviderPayable: number;
    baseCurrencyTotalPremiumAmount: number;
    basePremiumAmount: number;
    bindRequestDate: string;
    bindSourceId: number;
    bindSourceText: string;
    briefDescription: string;
    brokerContactFirstName: string;
    brokerContactId: number;
    brokerContactLastName: string;
    brokerContactName: string;
    brokerContactSalutation: string;
    brokerContactTitle: string;
    brokerId: number;
    brokerPolicyReference: string;
    clientContactName: string;
    clientOwes: number;
    clientPaid: number;
    clientPaidFlag: boolean;
    clientPayable: number;
    clientPremiumPendingFlag: boolean;
    closingOkFlag: boolean;
    coInsuranceFlag: boolean;
    coInsuranceLimitTotal: number;
    coInsuranceOurAllocation: number;
    coInsurancePremiumTotal: number;
    coInsurancePrimaryFlag: boolean;
    coInsuranceVisibleFlag: boolean;
    commissionAmount: number;
    companyId: number;
    companyName: string;
    currencyCode: string;
    currencyId: number;
    currencySymbol: string;
    currencyText: number;
    debtCollectionFlag: boolean;
    defaultPolicyAccountId: number;
    description: string;
    documentGroupId: number;
    documentGroupName: string;
    editableFlag: boolean;
    excessAmount: number;
    exchangeRate: number;
    externalInterfaceFlag: boolean;
    externallyManagedFlag: boolean;
    fSRATypeText: string;
    facilityCode: string;
    facilityPolicyNumber: number;
    feeAmount: number;
    gSTAmount: number;
    gSTExemptPercent: number;
    grossPremiumAmount: number;
    hasBeenReversedFlag: boolean;
    instalmentBillingInvoice: boolean;
    introducerPaid: number;
    introducerPaidFlag: boolean;
    introducerPayable: number;
    invoiceId: number;
    invoiceOnBindFlag: boolean;
    ledgerCurrencyCode: string;
    ledgerCurrencyId: number;
    ledgerCurrencySymbol: string;
    ledgerCurrencyText: string;
    ledgerId: number;
    ledgerText: string;
    limitAmount: number;
    managingEntityName: string;
    managingEntityPaid: number;
    managingEntityPaidFlag: boolean;
    managingEntityPayable: number;
    masterFlag: boolean;
    masterPolicyReference: string;
    netPremiumAmount: number;
    notRenewableFlag: boolean;
    onlineCreatedFlag: boolean;
    onlineDocumentGroupId: number;
    onlineDocumentGroupName: string;
    optionId: number;
    optionText: string;
    origPolicyId: number;
    parentPolicyId: number;
    policyStatusId: number;
    policyStatusText: string;
    policyStatusTrackingDate: string;
    policyStatusTrackingNote: string;
    premiumFundingCode: string;
    printQueueAdminName: string;
    productCode: string;
    productId: number;
    productName: string;
    productNotRenewableFlag: boolean;
    productOptionId: number;
    productOptionTitle: string;
    productTypeId: number;
    productTypeName: string;
    providerOwes: number;
    providerPaid: number;
    providerPaidFlag: boolean;
    providerPayable: number;
    providerPolicyReference: string;
    providerQuoteReference: string;
    quoteDate: string;
    quoteDateDisplay: any;
    quoteId: number;
    quoteSourceId: number;
    quoteTypeId: number;
    quoteTypeNote: string;
    quoteTypeText: string;
    quoteStatusText: string;
    readyToInvoiceFlag: boolean;
    relatedQuoteId: number;
    siteDataId: number;
    taxAmount: number;
    taxAndFeeAmount: number;
    temporaryCover: boolean;
    termMonths: number;
    totalPremiumAmount: number;
    underwriterId: number;
    underwriterName: string;
    unlmtdRetroactiveDateFlag: boolean;
    //
    bindPolicyId: number;
    needs = {};
    option: any = {};
    // Single or Annual
    type: string;
    legacy = false;
    // Insured Name
    clientId: number;
    client: Client = new Client();
    insuredName = "";
    insuredNameFinished = false;
    newClientFlag = false;
    // Insured Type
    insuredType: string;
    // insuredType = 'ownerBuilder';
    // Building License
    buildingLicenseReferral = false;
    unlicensedBuilder: boolean;
    licenseLending: boolean;
    // Business Type
    businessType: string;
    businessTypeId: number;
    businessOfInsuredOption = [];
    businessOfInsuredFilteredOption = [];
    businessTypeReferral = false;
    businessTypeReferralMd = false;
    businessTypeReferralL = false;
    businessTypeReferralCPL = false;
    businessTypeReferralWithInsuranceType: string;
    // Insured Declaration
    insuredDeclaration: boolean;
    insuredDeclarationDetails: string;
    insuredDeclarationModalViewed: boolean;
    // Insurance Type
    insuranceType: string;
    insuranceTypeReferral = false;
    // Policy Date
    createdInvalidDate: any;
    policyStart: any;
    tempPolicyStart: any;
    policyEnd: any;
    tempPolicyEnd: any;
    policyPeriod: number;
    policyDateReferral = false;
    quoteExpiryDate: string;
    policyDateFinished = false;
    valid: boolean;
    embargo: boolean;
    // Defects Liability
    defectsLiabilityPeriod: number;
    defectsLiabilityPeriodId: number;
    defectsLiabilityPeriodOption = [];
    dlpReferral = false;
    // Maximum Project Duration
    maximumProjectDuration: number;
    maximumProjectDurationId: number;
    maximumProjectDurationOption = [];
    mpdReferral = false;
    // Ongoing Work
    ongoingWork: any;
    ongoingWorkDetails: boolean;
    ongoingWorkReferral: boolean;
    // Occupied Structures
    occupiedStructures: boolean;
    // Address
    address: string;
    addressFinished = false;
    addressReferral: boolean;
    stateId: number;
    postcode: any;
    town: string;
    // Work Location
    workLocationNT = null;
    workLocationQLDa = null;
    workLocationQLDb = null;
    workLocationWAa = null;
    workLocationWAb = null;
    workLocationSA = null;
    workLocationNSW = null;
    workLocationVIC = null;
    workLocationACT = null;
    workLocationTAS = null;
    workLocationReferral = false;
    // Majority of Work
    suburb: string;
    suburbId: number;
    majorityOfWorkReferral = false;
    suburbData: any = {};
    // Project Type
    // Single
    projectType: string;
    // Annual
    projectTypeNRB = false;
    projectTypePercentNRB: number;
    projectTypeNCIB = false;
    projectTypePercentNCIB: number;
    projectTypeNSRFA = false;
    projectTypePercentNSRFA: number;
    projectTypeAR = false;
    projectTypePercentAR: number;
    projectTypeACIB = false;
    projectTypePercentACIB: number;
    projectTypeOther = false;
    projectTypePercentOther: number;
    maxProjectId: number;
    // Common
    projectTypeDetails: string;
    projectTypeReferral = false;
    // Project Involvement
    pit1q1 = false;
    pit1q2 = false;
    pit1q3 = false;
    pit1q4 = false;
    pit2q1 = false;
    pit2q2 = false;
    pit2q3 = false;
    pit2q4 = false;
    pit3q1 = false;
    pit3q2 = false;
    pit3q3 = false;
    pit3q4 = false;
    pit4q1 = false;
    pit4q2 = false;
    pit4q3 = false;
    pit4q4 = false;
    pit5q1 = false;
    pit5q2 = false;
    piDetails: string;
    piReferral = false;
    // Premium Deposit
    premiumDeposit = "100% Minimum and Deposit Premium";
    // Material Damaged
    materialDamageFinished = false;
    materialDamageEstimation: number;
    projectCost: number;
    existingStructures: number;
    existingStructuresA: boolean;
    existingStructuresB: string;
    existingStructuresC: boolean;
    existingStructuresD: boolean;
    principalSupplied: number;
    contractorsPlant: number;
    projectCostReferral = false;
    materialDamageEstimationReferral = false;
    existingStructuresReferral = false;
    principalSuppliedReferral = false;
    contractorsPlantReferral = false;
    poorConditionReferral = false;
    // Liability
    liabilityFinished = false;
    liabilityEstimation: number;
    publicLiability: any;
    publicLiabilityOption = [];
    publicLiabilityId: number;
    vibrationWeakeningRemovalOfSupport: any;
    vibrationWeakeningRemovalOfSupportOption = [];
    vibrationWeakeningRemovalOfSupportId: number;
    productLiability: any;
    productLiabilityOption = [];
    productLiabilityId: number;
    propertyInCareCustodyControl: any;
    propertyInCareCustodyControlOption = [];
    propertyInCareCustodyControlId: number;
    publicLiabilityReferral = false;
    vibrationWeakeningRemovalOfSupportReferral = false;
    productLiabilityReferral = false;
    propertyInCareCustodyControlReferral = false;
    liabilityEstimationReferral = false;
    // Tailor Your Cover
    scheme = false;
    tailorYourCover = 1000;
    tailorYourCoverMdList: any[] = [];
    tailorYourCoverLList: any[] = [];
    tailorYourCoverCPLList: any[] = [];
    tailorYourCoverReferral = false;
    // Enhance Cover
    cpl = 500000;
    cplOption = [];
    cover1 = false;
    enhance1A: boolean;
    enhance1B: boolean;
    enhance1C: boolean;
    enhance1D: boolean;
    enhance1Referral = false;
    cover2 = false;
    cover3 = false;
    cover3Referral = false;
    cover4 = false;
    cover4Referral = false;
    enhance4A: boolean;
    enhance4Referral = false;
    cover5 = false;
    cover5Referral = false;
    cover6 = false;
    cover6Referral = false;
    // Broker Charges
    brokerFee: number;
    brokerCommission: string;
    brokerCommissionId: number;
    brokerCommissionOption = [];
    brokerReference: string;
    // Broker Comments
    brokerComments: string;
    //
    attachments: any[] = [];
    // Referral
    referral = [];
    referralStatus: boolean;
    _referral: boolean;
    _error: boolean;
    _referralOverridden: boolean;
    // Rating
    rating: Rating = new Rating();
    facility: any[] = [];
    wording: string;
    sections: any[] = [];
    sectionsMeconAdminFeeGst = 0;
    policyWording: string;
    // Progress
    page1Progress = 0;
    page2Progress = 0;
    page3Progress = 0;
    page4Progress = 0;
    page5Progress = 0;
    reachSummary = false;
    // Status
    page1Status = [];
    page2Status = [];
    page3Status = [];
    page4Status = [];
    page5Status = [];
    // Flag
    page1OkFlag = false;
    page2OkFlag = false;
    page3OkFlag = false;
    page4OkFlag = false;
    page5OkFlag = false;
    // Interested Party
    interestedParties = [];
    tradingNames: any[] = [];
    // Error Hint
    errorInsuredName = false;
    errorInsuredType = false;
    errorBuildingLicense = false;
    errorBusinessOfInsured = false;
    errorInsuredDeclaration = false;
    errorInsuranceType = false;
    errorProjectType = false;
    errorAddress = false;
    errorWorkLocation = false;
    errorMajorityOfWork = false;
    errorOngoingWork = false;
    errorOccupiedStructure = false;
    errorProjectInvolvement = false;
    errorMaterialDamage = false;
    errorLiability = false;
    errorEnhanceYourCover = false;
    errorBrokerComments = false;
    errorAttachments = false;
    errorPolicyDate = false;
    errorEmbargo = false;
    //
    territorialLimit: number;
    territorialLimitDescription: string;
    //
    quick = false;
    quickConfirm = false;

    // scheme
    _scheme;
    _raw_referrals :any;

    // other extensions
    _attachDeclarationConfirmed: boolean;

    constructor() {
        this.policyStart = new Date();
        this.policyEnd = new Date();
        this.policyEnd.setFullYear(this.policyEnd.getFullYear() + 1);
    }

    public static hasTailorYourCoverMdList(quote: any): boolean {
        return !!quote.tailorYourCoverMdList && quote.tailorYourCoverMdList.length > 0;
    }

    public static hasTailorYourCoverLList(quote: any):boolean {
        return !!quote.tailorYourCoverLList && quote.tailorYourCoverLList.length > 0;
    }

    public static hasTailorYourCoverCPLList(quote: any): boolean {
        return !!quote.tailorYourCoverCPLList && quote.tailorYourCoverCPLList.length > 0;
    }


}
