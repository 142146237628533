import { Component, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from "@angular/core";

import { GlobalStaticService } from "../../static-services/global.static-service";
declare let document: any;

@Component({
    selector: "app-info-modal-component",
    templateUrl: "./info-modal.component.html",
    styleUrls: ["./info-modal.component.scss"]
})
export class InfoModalComponent implements AfterViewInit {
    GlobalStaticSrv = GlobalStaticService;
    @ViewChild('modal') modal: ElementRef;
    constructor(
        private cd: ChangeDetectorRef
    ) { }

    ngAfterViewInit(): void {
        this.cd.detectChanges();
    }

    ngAfterContentInit(): void {
        this.cd.detectChanges();
    }

    get modalHeight(): number {
        if (document.body.className.indexOf("info-modal") !== -1) {
            return this.modal.nativeElement.offsetHeight;
        } else {
            return 0;
        }
    }
}
