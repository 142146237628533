import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    AfterViewInit
} from "@angular/core";
import { CommonApi } from "../../api/common.api";
import { Quote } from "../../models/quote";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { QuoteService } from "../../services/quote.service";
import { CommonUtility } from "../../utilities/common.utility";

@Component({
    selector: "app-quote-majority-of-work-component",
    templateUrl: "./quote-majority-of-work.component.html",
    styleUrls: ["./quote-majority-of-work.component.scss"]
})
export class QuoteMajorityOfWorkComponent implements OnChanges, AfterViewInit {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    suburbs: any[] = [];
    searchActive = false;
    resultActive = false;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() specialEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() goEventEmitter: EventEmitter<any> = new EventEmitter();
    state: string;
    scope: string;
    constructor(private commonApi: CommonApi, private quoteSrv: QuoteService) { }
    ngOnChanges(): void { }
    ngAfterViewInit(): void {
        this.resetHint();
        if (this.quote.suburbData && this.quote.suburbData.postcode) {
            if (!this.quoteSrv.embargoCheckQuote(this.quote)) {
                this.quote.majorityOfWorkReferral = true;
                this.continueEventEmitter.emit({
                    finished: true,
                    noScroll: true,
                    referral: this.quote.majorityOfWorkReferral,
                    eventPage: 2
                });
            }
        }
    }
    searchFocus(): void {
        this.resetHint();
        if (this.quote.suburb && this.quote.suburb.length >= 3) {
            this.searchActive = true;
            this.search();
        } else {
            this.searchActive = false;
        }
    }
    searchChange(): void {
        this.searchFocus();
        this.quote.majorityOfWorkReferral = false;
        this.quote.suburbId = null;
        this.continueEventEmitter.emit({
            finished: false,
            referral: false
        });
    }
    resetHint(): void {
        this.scope = null;
        this.state = null;
        const max = Math.max(
            this.quote.workLocationNT,
            this.quote.workLocationQLDa + this.quote.workLocationQLDb,
            this.quote.workLocationWAa + this.quote.workLocationWAb,
            this.quote.workLocationSA,
            this.quote.workLocationNSW,
            this.quote.workLocationVIC,
            this.quote.workLocationACT,
            this.quote.workLocationTAS
        );
        if (this.quote.workLocationNT === max) {
            if (this.scope) {
                this.scope = this.scope + ",nt1, nt2";
                this.state = this.state + ", NT";
            } else {
                this.scope = "nt1, nt2";
                this.state = "NT";
            }
        }
        if (this.quote.workLocationQLDa + this.quote.workLocationQLDb === max) {
            let qldScope;
            if (this.quote.workLocationQLDa && this.quote.workLocationQLDb) {
                qldScope = 'qld1,qld2';
            } else if (this.quote.workLocationQLDa) {
                qldScope = 'qld1';
            } else {
                qldScope = 'qld2';
            }
            if (this.scope) {
                this.scope = this.scope + "," + qldScope;
                this.state = this.state + ", QLD";
            } else {
                this.scope = qldScope;
                this.state = "QLD";
            }
        }
        if (this.quote.workLocationWAa + this.quote.workLocationWAb === max) {
            let waScope;
            if (this.quote.workLocationWAa && this.quote.workLocationWAb) {
                waScope = 'wa1,wa2';
            } else if (this.quote.workLocationWAa) {
                waScope = 'wa1';
            } else {
                waScope = 'wa2';
            }
            if (this.scope) {
                this.scope = this.scope + "," + waScope;
                this.state = this.state + ", WA";
            } else {
                this.scope = waScope;
                this.state = "WA";
            }
        }
        if (this.quote.workLocationSA === max) {
            if (this.scope) {
                this.scope = this.scope + ",sa";
                this.state = this.state + ", SA";
            } else {
                this.scope = "sa";
                this.state = "SA";
            }
        }
        if (this.quote.workLocationNSW === max) {
            if (this.scope) {
                this.scope = this.scope + ",nsw";
                this.state = this.state + ", NSW";
            } else {
                this.scope = "nsw";
                this.state = "NSW";
            }
        }
        if (this.quote.workLocationVIC === max) {
            if (this.scope) {
                this.scope = this.scope + ",vic";
                this.state = this.state + ", VIC";
            } else {
                this.scope = "vic";
                this.state = "VIC";
            }
        }
        if (this.quote.workLocationACT === max) {
            if (this.scope) {
                this.scope = this.scope + ",act";
                this.state = this.state + ", ACT";
            } else {
                this.scope = "act";
                this.state = "ACT";
            }
        }
        if (this.quote.workLocationTAS === max) {
            if (this.scope) {
                this.scope = this.scope + ",tas";
                this.state = this.state + ", TAS";
            } else {
                this.scope = "tas";
                this.state = "TAS";
            }
        }
    }
    search(): void {
        this.resultActive = true;
        this.resetHint();
        this.commonApi
            .getPostcodes(this.quote.suburb, this.scope)
            .subscribe(res => {
                this.suburbs = res;
                this.resultActive = false;
            });
    }
    searchBlur(): void {
        setTimeout(() => {
            this.searchActive = false;
            this.resultActive = false;
            this.suburbs = [];
        }, 200);
    }
    chooseSuburb(suburb: any): void {
        this.quote.suburbData = suburb;
        this.quote.suburb = `${suburb.suburb} / ${suburb.state} / ${
            suburb.postcode
            }`;
        this.quote.errorMajorityOfWork = false;
        this.quote.majorityOfWorkReferral = suburb.apReferralOnline;
        if (!this.quote.majorityOfWorkReferral) {
            this.quote.majorityOfWorkReferral = !this.quoteSrv.embargoCheckQuote(this.quote);
        }
        this.quote.suburbId = suburb.townId;
        // Enhance Cover
        this.specialEventEmitter.emit({
            tab: 4,
            order: this.quote.type === "single" ? 5 : 6,
            finished: true,
            referral: false
        });
        this.continueEventEmitter.emit({
            finished: true,
            referral: this.quote.majorityOfWorkReferral,
            noScroll: true
        });
        this.searchActive = false;
        this.resultActive = false;
        this.suburbs = [];
    }
    clean(): void {
        this.quote.suburb = null;
        this.quote.majorityOfWorkReferral = false;
        this.quote.suburbId = null;
        this.resetHint();
        this.continueEventEmitter.emit({
            finished: false,
            referral: false
        });
    }
    goEvent(): void {
        this.resetHint();
        this.goEventEmitter.emit(3);
    }
}
