import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Quote } from "../../models/quote";
import { CommonApi } from "../../api/common.api";
import { QuoteService } from "../../services/quote.service";
import { STATE } from "../../configs/state";
import { CommonUtility } from "../../utilities/common.utility";

@Component({
    selector: "app-quick-quote-address-component",
    templateUrl: "./quick-quote-address.component.html",
    styleUrls: ["./quick-quote-address.component.scss"]
})
export class QuickQuoteAddressComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    addresses: any[] = [];
    searchActive = false;
    resultActive = false;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() specialEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() goEventEmitter: EventEmitter<any> = new EventEmitter();
    address$$: any;
    constructor(private commonApi: CommonApi, private quoteSrv: QuoteService) {}
    ngOnChanges(): void {}
    searchFocus(): void {
        if (this.quote.address && this.quote.address.length >= 3) {
            this.searchActive = true;
            this.search();
        } else {
            this.searchActive = false;
        }
    }
    searchChange(): void {
        this.searchFocus();
        this.quote.addressReferral = false;
        this.quote.addressFinished = false;
        this.quote.suburbId = null;
        this.continueEventEmitter.emit({
            finished: this.quote.addressFinished,
            referral: this.quote.addressReferral
        });
    }
    search(): void {
        this.resultActive = true;
        if (this.address$$) {
            this.address$$.unsubscribe();
        }
        this.address$$ = this.commonApi
            .getAddresses(this.quote.address)
            .subscribe(res => {
                this.addresses = res.completions;
                this.resultActive = false;
            });
    }
    searchBlur(): void {
        setTimeout(() => {
            this.searchActive = false;
            this.resultActive = false;
            this.addresses = [];
        }, 200);
    }
    chooseAddress(address: any): void {
        this.quote.address = address.full_address;
        const suburbArray = address.full_address.split(", ");
        const suburbArray2 = suburbArray[suburbArray.length - 1].split(" ");
        const suburb = suburbArray2
            .splice(0, suburbArray2.length - 2)
            .join(" ");
        const postcodeArray = address.full_address.split(" ");
        const postcode = postcodeArray[postcodeArray.length - 1];
        const state = suburbArray2[0];
        this.commonApi.getSuburbPostcode(postcode, suburb).subscribe(
            res => {
                this.quote.suburbData = res;
                this.quote.suburbData.postcode = res.postcode;
                this.quote.suburbData.state = state;
                this.quote.suburbData.stateName = STATE[state].stateName;
                this.quote.suburbData.suburb = suburb.toUpperCase();
                this.quote.addressReferral = res.spReferralOnline;
                if (!res.spReferralOnline) {
                    this.quote.addressReferral = !this.quoteSrv.embargoCheckQuote(this.quote);
                }
                this.quote.addressFinished = true;
                this.quote.suburbId = res.townId;
                this.quote.stateId = res.stateId;
                this.quote.errorAddress = false;
                this.continueEventEmitter.emit({
                    finished: this.quote.addressFinished,
                    noScroll: true,
                    referral: this.quote.addressReferral
                });
            },
            err => {
                switch (err.status) {
                    case 404:
                        this.quote.suburbData = {};
                        this.quote.suburbData.postcode = postcode;
                        this.quote.suburbData.state = state;
                        this.quote.suburbData.stateName =
                            STATE[state].stateName;
                        this.quote.suburbData.suburb = suburb.toUpperCase();
                        this.quote.suburbData.stateId = STATE[state].stateId;
                        this.quote.stateId = this.quote.suburbData.stateId;
                        this.quote.addressReferral = true;
                        this.quote.addressFinished = true;
                        this.quote.errorAddress = false;
                        this.continueEventEmitter.emit({
                            finished: this.quote.addressFinished,
                            noScroll: true,
                            referral: this.quote.addressReferral
                        });
                        break;
                    default:
                        GlobalStaticService.openErrorSuburbModal();
                        break;
                }
            }
        );
        this.searchActive = false;
        this.resultActive = false;
        this.addresses = [];
    }
    clean(): void {
        this.quote.address = null;
        this.quote.addressReferral = false;
        this.quote.addressFinished = false;
        this.quote.suburbId = null;
        this.continueEventEmitter.emit({
            finished: this.quote.addressFinished,
            referral: this.quote.addressReferral
        });
    }
    goEvent(): void {
        this.continueEventEmitter.emit({
            finished: this.quote.addressFinished,
            referral: this.quote.addressReferral
        });
    }
}
