import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Quote } from "../../models/quote";
import { TempQuote } from "../../models/temp-quote";

@Component({
    selector: "app-quote-project-involvements-component",
    templateUrl: "./quote-project-involvements.component.html",
    styleUrls: ["./quote-project-involvements.component.scss"]
})
export class QuoteProjectInvolvementsComponent
    implements OnChanges, AfterViewInit {
    GlobalStaticSrv = GlobalStaticService;
    viewInit = false;
    currentTab = 1;
    @Input("quote") quote: Quote;
    @Input("tempQuote") tempQuote: TempQuote;
    @ViewChild("tab1") tab1: ElementRef;
    tab1Finished = false;
    @ViewChild("tab2") tab2: ElementRef;
    tab2Finished = false;
    @ViewChild("tab3") tab3: ElementRef;
    tab3Finished = false;
    @ViewChild("tab4") tab4: ElementRef;
    tab4Finished = false;
    @ViewChild("tab5") tab5: ElementRef;
    tab5Finished = false;
    @ViewChild("tab6") tab6: ElementRef;
    tab6Finished = false;
    finalTabEnabled = false;
    switching = false;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() goEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor() {}
    ngOnChanges(): void {
        this.tempQuote.componentProjectInvolvement = this;
        if (this.viewInit) {
            this.init();
        }
    }
    ngAfterViewInit(): void {
        this.viewInit = true;
        this.init();
    }
    init(): void {
        if (this.quote.type === "single") {
            if (this.quote.page3Status.indexOf(3) !== -1) {
                this.tab1Finished = true;
                this.tab2Finished = true;
                if (this.quote.insuredType !== "ownerBuilder") {
                    this.tab3Finished = true;
                    this.tab4Finished = true;
                    this.tab5Finished = true;
                } else {
                    this.tab3Finished = false;
                    this.tab4Finished = false;
                    this.tab5Finished = false;
                }
                if (this.quote.piDetails) {
                    this.tab6Finished = true;
                    this.finalTabEnabled = true;
                    this.go(6);
                } else {
                    this.tab6Finished = false;
                    this.finalTabEnabled = false;
                    if (this.quote.insuredType !== "ownerBuilder") {
                        this.go(5);
                    } else {
                        this.go(2);
                    }
                }
            }
        } else {
            if (this.quote.page3Status.indexOf(2) !== -1) {
                this.tab1Finished = true;
                this.tab2Finished = true;
                this.tab3Finished = true;
                this.tab4Finished = true;
                if (this.quote.piDetails) {
                    this.tab5Finished = true;
                    this.finalTabEnabled = true;
                    this.go(5);
                } else {
                    this.tab5Finished = false;
                    this.finalTabEnabled = false;
                    this.go(4);
                }
            }
        }
    }
    cleanClassList(tab: string): void {
        this[tab].nativeElement.classList.remove("slideInLeft");
        this[tab].nativeElement.classList.remove("slideInRight");
        this[tab].nativeElement.classList.remove("slideOutLeft");
        this[tab].nativeElement.classList.remove("slideOutRight");
    }
    next(next?: number): void {
        if (!this.switching) {
            this.switching = true;
            if (!next) {
                next = 1;
            }
            this.quote.errorProjectInvolvement = false;
            this["tab" + this.currentTab + "Finished"] = true;
            if (this.quote.type === "single") {
                if (
                    this.currentTab === 1 &&
                    this.quote.insuredType === "ownerBuilder"
                ) {
                    this.tab2Finished = true;
                    this.finalTabCheck();
                    if (this.tab1Finished) {
                        this.continueEventEmitter.emit({
                            finished:
                                !this.finalTabEnabled || this.quote.piDetails,
                            referral: this.quote.piReferral,
                            noScroll: true
                        });
                    }
                }
                if (
                    this.currentTab === 2 &&
                    this.quote.insuredType === "ownerBuilder"
                ) {
                    next = 4;
                }
                if (this.currentTab === 4) {
                    this.tab5Finished = true;
                    this.finalTabCheck();
                    if (
                        this.tab1Finished &&
                        this.tab2Finished &&
                        this.tab3Finished &&
                        this.tab4Finished
                    ) {
                        this.continueEventEmitter.emit({
                            finished:
                                !this.finalTabEnabled || this.quote.piDetails,
                            referral: this.quote.piReferral,
                            noScroll: true
                        });
                    }
                }
            } else {
                if (this.currentTab === 3) {
                    this.tab4Finished = true;
                    this.finalTabCheck();
                    if (this.tab1Finished && this.tab2Finished && this.tab3Finished) {
                        this.continueEventEmitter.emit({
                            finished:
                                !this.finalTabEnabled || this.quote.piDetails,
                            referral: this.quote.piReferral,
                            noScroll: true
                        });
                    }
                }
            }

            this.cleanClassList("tab" + this.currentTab);
            this.cleanClassList("tab" + (this.currentTab + next));
            this["tab" + this.currentTab].nativeElement.classList.add(
                "slideOutLeft"
            );
            this["tab" + (this.currentTab + next)].nativeElement.classList.add(
                "slideInRight"
            );
            this["tab" + (this.currentTab + next)].nativeElement.classList.add(
                "active"
            );
            setTimeout(() => {
                this["tab" + this.currentTab].nativeElement.classList.remove(
                    "active"
                );
                this.currentTab = this.currentTab + next;
                this.switching = false;
            }, 500);
        }
    }
    back(prev?: number): void {
        if (!this.switching) {
            this.switching = true;
            if (!prev) {
                prev = 1;
            }
            if (
                prev === 1 &&
                this.currentTab === 6 &&
                this.quote.insuredType === "ownerBuilder"
            ) {
                prev = 4;
            }
            this.cleanClassList("tab" + this.currentTab);
            this.cleanClassList("tab" + (this.currentTab - prev));
            this["tab" + this.currentTab].nativeElement.classList.add(
                "slideOutRight"
            );
            this["tab" + (this.currentTab - prev)].nativeElement.classList.add(
                "slideInLeft"
            );
            this["tab" + (this.currentTab - prev)].nativeElement.classList.add(
                "active"
            );
            setTimeout(() => {
                this["tab" + this.currentTab].nativeElement.classList.remove(
                    "active"
                );
                this.currentTab = this.currentTab - prev;
                this.switching = false;
            }, 500);
        }
    }
    go(tab: number): void {
        if (this.currentTab !== tab) {
            if (this.currentTab > tab) {
                this.back(this.currentTab - tab);
            } else {
                this.next(tab - this.currentTab);
            }
        }
    }
    change(q?: string): void {
        this.quote.errorProjectInvolvement = false;
        this.finalTabCheck();
        this.referralCheck();
        if (!this.finalTabEnabled) {
            this.quote.piDetails = null;
            this.quote.piReferral = false;
            if (this.quote.type === "single") {
                this.tab6Finished = false;
            } else {
                this.tab6Finished = false;
            }
        }
        this.continueEventEmitter.emit({
            finished: !this.finalTabEnabled || this.quote.piDetails,
            referral: this.quote.piReferral,
            noScroll: true
        });
    }
    referralCheck(): void {
        if (this.quote.type === "single") {
            this.quote.piReferral =
                this.quote.pit1q3 ||
                this.quote.pit1q4 ||
                this.quote.pit2q2 ||
                this.quote.pit2q3 ||
                this.quote.pit2q4 ||
                this.quote.pit3q1 ||
                this.quote.pit3q2 ||
                this.quote.pit3q3 ||
                this.quote.pit3q4 ||
                this.quote.pit4q1 ||
                this.quote.pit4q2 ||
                this.quote.pit4q3 ||
                this.quote.pit4q4 ||
                this.quote.pit5q1 ||
                this.quote.pit5q2;
        } else {
            this.quote.piReferral =
                this.quote.pit1q1 ||
                this.quote.pit1q2 ||
                this.quote.pit1q3 ||
                this.quote.pit1q4 ||
                this.quote.pit2q1 ||
                this.quote.pit2q2 ||
                this.quote.pit2q3 ||
                this.quote.pit2q4 ||
                this.quote.pit3q1 ||
                this.quote.pit3q2 ||
                this.quote.pit3q3 ||
                this.quote.pit5q1 ||
                this.quote.pit5q2;
        }
    }
    finalTabCheck(): void {
        this.finalTabEnabled =
            this.quote.pit1q1 ||
            this.quote.pit1q2 ||
            this.quote.pit1q3 ||
            this.quote.pit1q4 ||
            this.quote.pit2q1 ||
            this.quote.pit2q2 ||
            this.quote.pit2q3 ||
            this.quote.pit2q4 ||
            this.quote.pit3q1 ||
            this.quote.pit3q2 ||
            this.quote.pit3q3 ||
            this.quote.pit3q4 ||
            this.quote.pit4q1 ||
            this.quote.pit4q2 ||
            this.quote.pit4q3 ||
            this.quote.pit4q4 ||
            this.quote.pit5q1 ||
            this.quote.pit5q2;
    }
    changeDetails(): void {
        if (this.quote.piDetails) {
            this.quote.errorProjectInvolvement = false;
        }
        if (this.quote.type === "single") {
            this.tab6Finished = !!this.quote.piDetails;
            this.continueEventEmitter.emit({
                finished: this.tab6Finished,
                referral: this.quote.piReferral,
                noScroll: true
            });
        } else {
            this.tab5Finished = !!this.quote.piDetails;
            this.continueEventEmitter.emit({
                finished: this.tab5Finished,
                referral: this.quote.piReferral,
                noScroll: true
            });
        }
    }
    goEvent(): void {
        this.goEventEmitter.emit(4);
    }
}
