import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/BehaviorSubject";

@Injectable({
    providedIn: "root"
})
export class InterfaceStaticService {
    static windowHeight: number;
    static windowWidth: number;
    static quotePadding: number;
    static quoteHeight: number;
    static isBodyLock = false;
    static resize$$;
    static quoteScroll$ = new BehaviorSubject(0);
    static noFooter = false;

    static bodyLock(isBodyLock: boolean): void {
        InterfaceStaticService.isBodyLock = isBodyLock;
        if (InterfaceStaticService.isBodyLock) {
            document.querySelector("body").classList.add("lock");
        } else {
            document.querySelector("body").classList.remove("lock");
        }
    }

    static noScroll(isNoScroll: boolean): void {
        if (isNoScroll) {
            document.querySelector("body").classList.add("no-scroll");
        } else {
            document.querySelector("body").classList.remove("no-scroll");
        }
    }

    static resize(): void {
        InterfaceStaticService.windowHeight =
            window.document.documentElement.clientHeight > 650
                ? window.document.documentElement.clientHeight
                : 650;
        InterfaceStaticService.windowWidth =
            window.document.documentElement.clientWidth;
        InterfaceStaticService.quoteHeight =
            InterfaceStaticService.windowHeight - 155 - 200;
    }
}
