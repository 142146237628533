import { Injectable } from "@angular/core";
import { Quote } from "../models/quote";

@Injectable({
    providedIn: "root"
})
export class CommonUtility {
    static getFullDate(date: Date) {
        return (
            date.getDate() +
            "-" +
            CommonUtility.getMonth(date) +
            "-" +
            date.getFullYear()
        );
    }
    static getFullDateNum(date: Date, split?: string) {
        return (
            CommonUtility.getTensPlaceNum(date.getDate()) +
            (split ? split : "-") +
            CommonUtility.getTensPlaceNum(date.getMonth() + 1) +
            (split ? split : "-") +
            date.getFullYear()
        );
    }
    static getDateBasedOnTFormat(date: string): string {
        const dateArray = date.split("T")[0].split("-");
        const newDate = new Date();
        newDate.setFullYear(Number(dateArray[0]));
        newDate.setMonth(Number(dateArray[1]) - 1);
        newDate.setDate(Number(dateArray[2]));
        return `${CommonUtility.getTensPlaceNum(
            newDate.getDate()
        )}-${CommonUtility.getTensPlaceNum(
            newDate.getMonth() + 1
        )}-${newDate.getFullYear()}`;
    }
    static getDateObjectBasedOnTFormat(date: string): Date {
        const dateArray = date.split("T")[0].split("-");
        const newDate = new Date();
        newDate.setFullYear(Number(dateArray[0]));
        newDate.setMonth(Number(dateArray[1]) - 1);
        newDate.setDate(Number(dateArray[2]));
        return newDate;
    }
    static getTensPlaceNum(number: number): string {
        if (number.toString().length === 1) {
            return "0" + number.toString();
        } else {
            return number.toString();
        }
    }
    static getMonth(date: Date): string {
        const month = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        return month[date.getMonth()];
    }
    static getMeridianHour(dateString: string): string {
        return (
            dateString.split(" ")[1].split(":")[0] + dateString.split(" ")[2]
        );
    }
    static priceFormat(price: any): string {
        if (typeof price === "string") return price;
        const tempArray = Number(price)
            .toString()
            .split(".");
        let temp = price.toString().split(".")[0];
        const length = temp.length;
        let time = 0;
        let result = "";
        if (length % 3) {
            result = temp.substr(0, length % 3);
            temp = temp.substr(length % 3);
        }
        time = Math.floor(length / 3);
        for (let i = 0; i < time; i++) {
            if (result) {
                result = result + "," + temp.substr(3 * i, 3);
            } else {
                result = temp.substr(3 * i, 3);
            }
        }
        if (tempArray.length === 1) {
            return `$${result}`;
        } else {
            return `$${result}${tempArray[1]}`;
        }
    }
    static isDateBeforeToday(date: Date): boolean {
        const today = new Date();
        return (
            date.getFullYear() < today.getFullYear() ||
            (date.getFullYear() === today.getFullYear() &&
                date.getMonth() < today.getMonth()) ||
            (date.getFullYear() === today.getFullYear() &&
                date.getMonth() === today.getMonth() &&
                date.getDate() < today.getDate())
        );
    }
    /**
     * Need this dumb function to workaround http.get query params restriction:
     * - it doesn't support param with non-string types, e.g. number
     * @param params a param object
     */
    static queryParams(params: any): any {
        return params;
    }

    static urlWithoutScheme(url: string): string {
        if (!url) return url;
        let pos = url.indexOf("//");
        return url.substring(pos);
    }
    static trace(message?: string, ...optionalParams: any[]): void {
        console.groupCollapsed(message, ...optionalParams);
        console.trace();
        console.groupEnd();
    }

    static cloneQuote(quote: Quote): any {
        const policyStart = quote.policyStart;
        const policyEnd = quote.policyEnd;
        const attachments = quote.attachments;
        const copyQuote = JSON.parse(JSON.stringify(quote));
        copyQuote.policyStart = policyStart;
        copyQuote.policyEnd = policyEnd;
        copyQuote.attachments = attachments;
        return copyQuote;
    }

    static shouldIgnoreKeyCode(keyCode: number): boolean {
        return (
            !keyCode ||
            keyCode === 9 ||
            keyCode === 13 ||
            keyCode === 16 ||
            keyCode === 35 ||
            keyCode === 36 ||
            keyCode === 37 ||
            keyCode === 38 ||
            keyCode === 39 ||
            keyCode === 40
        );
    }
}
