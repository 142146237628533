import { Directive, HostListener, ElementRef } from "@angular/core";
import { GlobalStaticService } from "../static-services/global.static-service";

@Directive({
    selector: "[appCurrencyDisplay]"
})
export class CurrencyDisplayDirective {
    constructor(private el: ElementRef) {}
    @HostListener("keyup", ["$event"]) keyup(event) {
        if (this.el.nativeElement.value) {
            if (/^(\d+|\d+\.\d{0,2})$/.test(this.el.nativeElement.value)) {
                this.el.nativeElement.value = GlobalStaticService.priceFormat(
                    this.el.nativeElement.value
                );
            }
        }
    }
    @HostListener("focus", ["$event"]) focus(event) {
        if (this.el.nativeElement.value) {
            this.el.nativeElement.value = GlobalStaticService.numberFormat(
                this.el.nativeElement.value
            );
        }
    }
}
