import { Rating } from "./rating";

export class Policy {
    // List Attributes
    policyId: number;
    clientName: string;
    effective: boolean;
    effectiveDate: string;
    inceptionDate: string;
    termExpiryDate: string;
    //  Single Attributes
    actionRequired: boolean;
    autoRenewStatusId: number;
    meconAdminFee: number;
    baseAnnualAgreedPremiumAmount: number;
    baseAnnualCalcPremiumAmount: number;
    baseCalcPremiumAmount: number;
    baseCurrencyBaseAnnualAgreedPremiumAmount: number;
    baseCurrencyBaseAnnualCalcPremiumAmount: number;
    baseCurrencyBaseCalcPremiumAmount: number;
    baseCurrencyBasePremiumAmount: number;
    baseCurrencyClientPaid: number;
    baseCurrencyClientPayable: number;
    baseCurrencyGrossPremiumAmount: number;
    baseCurrencyIntroducerPaid: number;
    baseCurrencyIntroducerPayable: number;
    baseCurrencyManagingEntityPaid: number;
    baseCurrencyManagingEntityPayable: number;
    baseCurrencyNetPremiumAmount: number;
    baseCurrencyProviderPaid: number;
    baseCurrencyProviderPayable: number;
    baseCurrencyTotalPremiumAmount: number;
    basePremiumAmount: number;
    bindRequestDate: string;
    bindSourceId: number;
    bindSourceText: string;
    briefDescription: string;
    brokerContactFirstName: string;
    brokerContactId: number;
    brokerContactLastName: string;
    brokerContactName: string;
    brokerContactSalutation: string;
    brokerContactTitle: string;
    brokerId: number;
    brokerPolicyReference: string;
    clientContactName: string;
    clientId: number;
    clientOwes: number;
    clientPaid: number;
    clientPaidFlag: boolean;
    clientPayable: number;
    clientPremiumPendingFlag: boolean;
    closingOkFlag: boolean;
    coInsuranceFlag: boolean;
    coInsuranceLimitTotal: number;
    coInsuranceOurAllocation: number;
    coInsurancePremiumTotal: number;
    coInsurancePrimaryFlag: boolean;
    coInsuranceVisibleFlag: boolean;
    commissionAmount: number;
    companyId: number;
    companyName: string;
    currencyCode: string;
    currencyId: number;
    currencySymbol: string;
    currencyText: number;
    debtCollectionFlag: boolean;
    defaultPolicyAccountId: number;
    description: string;
    documentGroupId: number;
    documentGroupName: string;
    editableFlag: boolean;
    excessAmount: number;
    exchangeRate: number;
    externalInterfaceFlag: boolean;
    externallyManagedFlag: boolean;
    fSRATypeText: string;
    facilityCode: string;
    facilityPolicyNumber: number;
    feeAmount: number;
    gSTAmount: number;
    gSTExemptPercent: number;
    grossPremiumAmount: number;
    hasBeenReversedFlag: boolean;
    instalmentBillingInvoice: boolean;
    insuredName: string;
    introducerPaid: number;
    introducerPaidFlag: boolean;
    introducerPayable: number;
    invoiceId: number;
    invoiceOnBindFlag: boolean;
    ledgerCurrencyCode: string;
    ledgerCurrencyId: number;
    ledgerCurrencySymbol: string;
    ledgerCurrencyText: string;
    ledgerId: number;
    ledgerText: string;
    limitAmount: number;
    managingEntityName: string;
    managingEntityPaid: number;
    managingEntityPaidFlag: boolean;
    managingEntityPayable: number;
    masterFlag: boolean;
    masterPolicyReference: string;
    netPremiumAmount: number;
    notRenewableFlag: boolean;
    onlineCreatedFlag: boolean;
    onlineDocumentGroupId: number;
    onlineDocumentGroupName: string;
    optionId: number;
    optionText: string;
    origPolicyId: number;
    parentPolicyId: number;
    policyStatusId: number;
    policyStatusText: string;
    policyStatusTrackingDate: string;
    policyStatusTrackingNote: string;
    premiumFundingCode: string;
    printQueueAdminName: string;
    productCode: string;
    productId: number;
    productName: string;
    productNotRenewableFlag: boolean;
    productOptionId: number;
    productOptionTitle: string;
    productTypeId: number;
    productTypeName: string;
    providerOwes: number;
    providerPaid: number;
    providerPaidFlag: boolean;
    providerPayable: number;
    providerPolicyReference: string;
    quoteDate: string;
    quoteId: number;
    quoteSourceId: number;
    quoteTypeId: number;
    quoteTypeNote: string;
    quoteTypeText: string;
    quoteStatusText: string;
    readyToInvoiceFlag: boolean;
    relatedQuoteId: number;
    siteDataId: number;
    taxAmount: number;
    taxAndFeeAmount: number;
    temporaryCover: boolean;
    termMonths: number;
    totalPremiumAmount: number;
    underwriterId: number;
    underwriterName: string;
    unlmtdRetroactiveDateFlag: boolean;
    //
    needs = {};
    option: any = {};
    // Single or Annual
    type: string;
    legacy = false;
    // Insured Type
    insuredType: string;
    // Business Type
    businessType: string;
    // Insured Declaration
    insuredDeclaration: boolean;
    // Insurance Type
    insuranceType: string;
    // Policy Date
    policyStart: any;
    policyEnd: any;
    start: any;
    end: any;
    valid: boolean;
    // Defects Liability
    defectsLiabilityPeriod: number;
    // Maximum Project Duration
    maximumProjectDuration: number;
    // Ongoing work
    ongoingWork: any;
    // Occupied Structures
    occupiedStructures: boolean;
    // Address
    address: string;
    addressFinished = false;
    // Work Location
    workLocationNT = null;
    workLocationQLDa = null;
    workLocationQLDb = null;
    workLocationWAa = null;
    workLocationWAb = null;
    workLocationSA = null;
    workLocationNSW = null;
    workLocationVIC = null;
    workLocationACT = null;
    workLocationTAS = null;
    // Majority of work
    suburb: string;
    suburbId: number;
    suburbData: any;
    postcode: any;
    town: string;
    stateId: number;
    // Project Type
    // Single
    projectType: string;
    // Annual
    projectTypeNRB = false;
    projectTypePercentNRB: number;
    projectTypeNCIB = false;
    projectTypePercentNCIB: number;
    projectTypeNSRFA = false;
    projectTypePercentNSRFA: number;
    projectTypeAR = false;
    projectTypePercentAR: number;
    projectTypeACIB = false;
    projectTypePercentACIB: number;
    projectTypeOther = false;
    projectTypePercentOther: number;
    // Project Involvement
    pit1q1 = false;
    pit1q2 = false;
    pit1q3 = false;
    pit1q4 = false;
    pit2q1 = false;
    pit2q2 = false;
    pit2q3 = false;
    pit2q4 = false;
    pit3q1 = false;
    pit3q2 = false;
    pit3q3 = false;
    pit3q4 = false;
    pit4q1 = false;
    pit4q2 = false;
    pit4q3 = false;
    pit4q4 = false;
    pit5q1 = false;
    pit5q2 = false;
    // Premium Deposit
    premiumDeposit = "100% Minimum and Deposit Premium";
    // Material Damaged
    materialDamageFinished = false;
    materialDamageEstimation: number;
    projectCost: number;
    limitProjectCost: number;
    existingStructures: number;
    existingStructuresA: boolean;
    existingStructuresB: string;
    existingStructuresC: boolean;
    existingStructuresD: boolean;
    limitExistingStructures: number;
    principalSupplied: number;
    limitPrincipalSupplied: number;
    contractorsPlant: number;
    limitContractorsPlant: number;
    // Liability
    liabilityEstimation: number;
    publicLiability: any;
    vibrationWeakeningRemovalOfSupport: any;
    productLiability: any;
    propertyInCareCustodyControl: any;
    // Tailor Your Cover
    tailorYourCover = 1000;
    tailorYourCoverMdList: any[] = [];
    tailorYourCoverLList: any[] = [];
    tailorYourCoverCPLList: any[] = [];

    // Enhance Cover
    cpl: number;
    cover1 = false;
    enhance1A: boolean;
    enhance1B: boolean;
    enhance1C: boolean;
    enhance1D: boolean;
    cover2 = false;
    cover3 = false;
    cover4 = false;
    enhance4A: boolean;
    cover5 = false;
    cover6 = false;
    // Broker Charges
    brokerFee: number;
    brokerCommission = "Scheme commission";
    brokerReference: string;
    // Broker Comments
    brokerComments: string;
    //
    attachments: any[] = [];
    // Rating
    rating: Rating = new Rating();
    facility: any[] = [];
    // Progress
    page1Progress = 0;
    // Status
    page1Status = [];
    // Flag
    page1OkFlag = false;
    // Interested Party
    interestedParties = [];
    tradingNames: any[] = [];
    // Error Hint
    errorMaterialDamage = false;
    errorAddress = false;
    //
    territorialLimit: number;
    territorialLimitDescription: string;

    constructor() {}


}
