export class Rating {
    basePremium = 0;
    fireServiceLevy = 0;
    gst = 0;
    stampDuty = 0;
    meconAdminFee = 0;
    meconAdminFeeGst = 0;
    totalPayable = 0;
    nettToMecon = 0;
    brokerCommission = 0;
    brokerCommissionGst = 0;
}
