import { Injectable } from "@angular/core";
import { PartialObserver, Subscription, Subject } from "rxjs";
import { CommonUtility } from "../utilities/common.utility";
import { Storage } from "@ionic/storage";
import { EventBus } from "./event_bus";

@Injectable({
    providedIn: "root"
})
export class SchemeTrackerService {

    private schemeState: Subject<any> = new Subject();
    private scheme: any;
    private schemeId: any;
    private productId: number;
    private projectTypeId: number;
    private insuredTypeId: number;

    constructor(private eventBus: EventBus) {
    }

    public shouldGetScheme(
        productId: number,
        projectTypeId: number,
        insuredTypeId: number
    ): boolean {
        return this.productId != productId
            || this.projectTypeId != projectTypeId
            || this.insuredTypeId != insuredTypeId;
    }

    public resetScheme(productId: number): void {
        this.schemeId = null;
        this.productId = 0;
        this.projectTypeId = 0;
        this.insuredTypeId = 0;
        this.eventBus.cast("reset-scheme", productId);
        this.schemeState.next(null);
    }

    public getScheme(): any {
        return this.scheme;
    }

    public setScheme(
        scheme: any,
        productId: number,
        projectTypeId: number,
        insuredTypeId: number
    ): boolean {
        let schemeId = scheme['id'];
        if (this.schemeId === schemeId) {
            return false;
        }
        this.scheme = scheme;
        this.schemeId = schemeId;
        this.productId = productId;
        this.projectTypeId = projectTypeId;
        this.insuredTypeId = insuredTypeId;
        setTimeout(() => {
            this.schemeState.next(scheme)
        });
        return true;
    }

    public saveIntoStorage(storage: Storage): void {
        let schemeCopy: any = {
            scheme: this.scheme,
            schemeId: this.schemeId,
            productId: this.productId,
            projectTypeId: this.projectTypeId,
            insuredTypeId: this.insuredTypeId
        };
        storage.set("scheme-tracker", schemeCopy);
    }

    public loadFromStorage(storage: Storage): void {
        storage.get("scheme-tracker").then(schemeCopy => {
            if (schemeCopy) {
                this.scheme = schemeCopy.scheme;
                this.productId = schemeCopy.productId;
                this.projectTypeId = schemeCopy.projectTypeId;
                this.insuredTypeId = schemeCopy.insuredTypeId;
                this.schemeId = schemeCopy.schemeId;
                setTimeout(() => this.schemeState.next(this.scheme));
            }
        })
    }

    public reset(): void {
        this.productId = 0;
        this.projectTypeId = 0;
        this.insuredTypeId = 0;
        this.schemeState.next(null);
    }

    public subscribe(observer?: PartialObserver<any>): Subscription {
        return this.schemeState.subscribe(observer);
    }

}
