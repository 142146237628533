import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Policy } from "../../models/policy";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonApi } from "../../api/common.api";
import { Router } from "@angular/router";
declare let gtag: any;

@Component({
    selector: "app-modal-email-coc-component",
    templateUrl: "./modal-email-coc.component.html",
    styleUrls: ["./modal-email-coc.component.scss"]
})
export class ModalEmailCocComponent implements OnInit {
    GlobalStaticSrv = GlobalStaticService;
    @Input("policy") policy: Policy;
    emailForm: FormGroup;
    finished = false;
    constructor(
        private fb: FormBuilder,
        private commonApi: CommonApi,
        private router: Router
    ) {}
    ngOnInit(): void {
        this.emailForm = this.fb.group({
            choice: ["broker", Validators.required],
            custom: [null, [Validators.email]]
        });
        this.emailForm.controls["choice"].valueChanges.subscribe(value => {
            if (value === "broker") {
                this.emailForm.controls["custom"].setValidators([]);
            } else {
                this.emailForm.controls["custom"].setValidators([
                    Validators.required,
                    Validators.email
                ]);
            }
        });
    }
    close(): void {
        GlobalStaticService.closeEmailCocModal();
        this.clean();
    }
    focus(choice: string): void {
        if (this.emailForm.controls["choice"].value !== choice) {
            this.emailForm.setValue({
                choice: choice,
                custom: ""
            });
        }
    }
    clean(): void {
        this.emailForm.setValue({
            choice: "broker",
            custom: ""
        });
    }
    submit(): void {
        if (
            this.emailForm.controls["choice"].value !== "broker" &&
            this.emailForm.controls["custom"].invalid
        ) {
        } else {
            if (this.emailForm.get("choice").value === "broker") {
                GlobalStaticService.cocFormData.append(
                    "email",
                    GlobalStaticService.brokerProfile.email
                );
            } else {
                GlobalStaticService.cocFormData.append(
                    "email",
                    this.emailForm.get("custom").value
                );
            }

            GlobalStaticService.closeEmailCocModal();
            GlobalStaticService.openSubmitLoading("Generating COC");
            this.commonApi
                .postCoc(this.policy.policyId, GlobalStaticService.cocFormData)
                .subscribe(
                    res => {
                        GlobalStaticService.closeSubmitLoading();
                        gtag("set", { user_id: GlobalStaticService.brokerProfile.contactId });
                        gtag("event", "Download_COC", {});
                        GlobalStaticService.infoModal = {
                            title: "",
                            content: `
                    <div class="text-center"><i class="u-icon icon-88 icon-circle-purple-success"></i></div>
                    <h2 class="text-center f-mgt-10 f-mgb-10">COC documents sent!</h2>
                `,
                            buttons: [
                                {
                                    style: "purple",
                                    text: "Ok",
                                    callback: () => {
                                        this.clean();
                                        this.router.navigate([
                                            "/dashboard/home"
                                        ]);
                                        GlobalStaticService.closeInfoModal();
                                    }
                                }
                            ]
                        };
                        GlobalStaticService.openInfoModal();
                    },
                    err => {
                        GlobalStaticService.closeSubmitLoading();
                        GlobalStaticService.openUnexpectedErrorModal();
                    }
                );
        }
    }
}
