import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Quote } from "../../models/quote";

@Component({
    selector: "app-quote-premium-deposit-component",
    templateUrl: "./quote-premium-deposit.component.html",
    styleUrls: ["./quote-premium-deposit.component.scss"]
})
export class QuotePremiumDepositComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    dlpFlag = false;
    @Input("quote") quote: Quote;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor() {}
    ngOnChanges(): void {}
    openDLPSelection(): void {
        this.dlpFlag = true;
    }
    dlpChoose(value: string) {
        if (this.dlpFlag) {
            this.dlpFlag = false;
            this.quote.premiumDeposit = value;
        }
    }
    next() {
        this.continueEventEmitter.emit({
            finished: true
        });
    }
}
