import { Directive, HostListener } from "@angular/core";
import { InterfaceStaticService } from "../static-services/interface.static-service";

@Directive({
    selector: "[appScroll]"
})
export class ScrollDirective {
    constructor() {}
    @HostListener("scroll", ["$event"]) onScroll(event) {
        // do tracking
        InterfaceStaticService.quoteScroll$.next(event.target.scrollTop);
    }
}
