import { Component, EventEmitter, Input, Output } from "@angular/core";
import { QuoteService } from "../../services/quote.service";

import { Quote } from "../../models/quote";
import { CommonUtility } from "../../utilities/common.utility";

@Component({
    selector: "app-quote-preview-component",
    templateUrl: "./quote-preview.component.html",
    styleUrls: ["./quote-preview.component.scss"]
})
export class QuotePreviewComponent {
    @Input("showEdit") showEdit = false;
    @Input("showTemplate") showTemplate = false;
    @Input("type") type: string;
    @Input("quote") quote: Quote;
    @Output() editEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor(public quoteSrv: QuoteService) {
    }
    ngOnInit(): void{
console.log("quote-preview", this.quote);
    }
    edit(tab: number, order: number) {
        this.editEventEmitter.emit({
            tab: tab,
            order: order
        });
    }
}
