import { DialogConfig } from './app-dialog-config';
import { Observable, Subject, Observer } from 'rxjs';
import { first } from 'rxjs/operators';

export class Dialog {
  static config: DialogConfig = new DialogConfig();
  private static dismissed: boolean = true;
  private static clickedButtonSubject: Subject<string> = new Subject();

  static get isDismissed(): boolean {
    return Dialog.dismissed;
  }

  /**
   * Open app dialog modal with configuration specified.
   * @param config the dialog config
   * @returns an `Obserable` indicates which button has been clicked.
   */
  static open(config: Partial<DialogConfig>): Observable<string> {
    Dialog.dismissed = false;
    Dialog.updateConfig(config);
    let observable: Observable<string> = Dialog.clickedButtonSubject
      .asObservable()
      .pipe(first());
    return new Observable((observer: Observer<string>) => {
      observable.subscribe({
        next: (button: string) => {
          observer.next(button);
          observer.complete();
        },
      });
      return {
        unsubscribe: () => {
          Dialog.clickedButtonSubject.next(null);
        },
      };
    });
  }

  /**
   * Open the dialog and set the title to "WARNING" if not set in the config
   * @param input either a partial `DialogConfig` or a message as string
   */
  static warn(input: Partial<DialogConfig> | string): Observable<string> {
    return Dialog._open("WARNING", input);
  }

  /**
   * Open the dialog and set the title to "To Be Implemented" if not set in the config
   * @param input either a partial `DialogConfig` or a message as string
   */
  static tbd(input: Partial<DialogConfig> | string): Observable<string> {
    return Dialog._open("To Be Implemented", input);
  }

  static dismiss(buttonClicked: string): void {
    Dialog.clickedButtonSubject.next(buttonClicked);
    Dialog.reset();
  }

  private static _open(title: string, input: Partial<DialogConfig> | string): Observable<string> {
    let config: Partial<DialogConfig> = {};
    if (typeof input === 'string') {
      config = {"message": input};
    } else {
      config = input;
    }
    if (!config.title) {
      config.title = title;
    }
    return Dialog.open(config);
  }

  private static reset(): void {
    Dialog.resetConfig();
    Dialog.dismissed = true;
  }

  private static updateConfig(config: Partial<DialogConfig>): void {
    let config0: DialogConfig = Dialog.config;
    if (config.title) config0.title = config.title;
    if (config.message) config0.message = config.message;
    if (config.buttons) config0.buttons = config.buttons;
    if (config.width) config0.width = config.width;
    config0.noCloseButton = config.noCloseButton;
  }

  private static resetConfig(): void {
    Dialog.updateConfig(new DialogConfig);
  }
}
