import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Quote } from "../../models/quote";
import { TempQuote } from "../../models/temp-quote";
import { QUOTE_REFERRAL } from "../../configs/quote-referral";
import { SchemeTrackerService } from "../../services/scheme-tracker.service";
import { CommonUtility } from "../../utilities/common.utility";
import { Subscription } from "rxjs";
import { _getComponentHostLElementNode } from "@angular/core/src/render3/instructions";
import { EventBus } from "../../services/event_bus";
import { UcEvent } from "../../static-services/events";
import { QuoteService } from "../../services/quote.service";

@Component({
    selector: "app-quote-material-damage-component",
    templateUrl: "./quote-material-damage.component.html",
    styleUrls: ["./quote-material-damage.component.scss"]
})
export class QuoteMaterialDamageComponent implements OnChanges, AfterViewInit {
    viewInit = false;
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    @Input("tempQuote") tempQuote: TempQuote;
    currentTab = 1;
    @ViewChild("tab1") tab1: ElementRef;
    tab1Finished = false;
    @ViewChild("tab2") tab2: ElementRef;
    tab2Enabled = false;
    @ViewChild("single2") single2: ElementRef;
    @ViewChild("single3") single3: ElementRef;
    @ViewChild("single4") single4: ElementRef;
    @ViewChild("single5") single5: ElementRef;
    @ViewChild("annual1") annual1: ElementRef;
    @ViewChild("annual2") annual2: ElementRef;
    @ViewChild("annual3") annual3: ElementRef;
    @ViewChild("annual4") annual4: ElementRef;
    @ViewChild("annual5") annual5: ElementRef;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() offsetRecheckEventEmitter: EventEmitter<any> = new EventEmitter();
    specialErrorHintEstimation = false;
    specialErrorHintPC = false;
    specialErrorHintES = false;
    specialErrorHintPSM = false;
    specialErrorHintCPTE = false;
    private referralLimitChanged$$: Subscription;
    constructor(
        private schemeTracker: SchemeTrackerService,
        private elementRef: ElementRef,
        private quoteService: QuoteService,
        private eventBus: EventBus
    ) { }
    ngDoCheck(): void {
        this.quoteService.getEditScheme(this.quote.productId, this.quote);
    }
    ngOnChanges(): void {
        if (this.tempQuote) {
            this.tempQuote.componentMaterialDamaged = this;
        }

        if (this.viewInit) {
            this.init();
        }
    }
    ngAfterViewInit(): void {
        if (!this.viewInit) {
            this.viewInit = true;
            this.init();
        }
    }
    init(): void {
        this.referralLimitChanged$$ = this.eventBus.on(UcEvent.REFERRAL_LIMIT_CHANGED).subscribe({ next: () => this.checkReferral('all') });
        if (this.quote.type === "annual") {
            if (this.quote.page4Status.indexOf(2) !== -1) {
                this.tab1Finished = true;
                this.go(2);
            }
        }
    }
    ngOnDestroy(): void {
        if (this.referralLimitChanged$$) {
            this.referralLimitChanged$$.unsubscribe();
            this.referralLimitChanged$$ = null;
        }
    }
    cleanClassList(tab: string): void {
        this[tab].nativeElement.classList.remove("slideInLeft");
        this[tab].nativeElement.classList.remove("slideInRight");
        this[tab].nativeElement.classList.remove("slideOutLeft");
        this[tab].nativeElement.classList.remove("slideOutRight");
    }
    next(next?: number): void {
        if (!next) {
            next = 1;
        }
        if (!this["tab" + this.currentTab + "Finished"]) {
            return;
        }
        this.cleanClassList("tab" + this.currentTab);
        this.cleanClassList("tab" + (this.currentTab + next));
        this["tab" + this.currentTab].nativeElement.classList.add(
            "slideOutLeft"
        );
        this["tab" + (this.currentTab + next)].nativeElement.classList.add(
            "slideInRight"
        );
        this["tab" + (this.currentTab + next)].nativeElement.classList.add(
            "active"
        );
        setTimeout(() => {
            this["tab" + this.currentTab].nativeElement.classList.remove(
                "active"
            );
            this.currentTab =
                this.currentTab + next > 2 ? 2 : this.currentTab + next;
        }, 500);
    }
    back(prev?: number): void {
        if (!prev) {
            prev = 1;
        }
        this.cleanClassList("tab" + this.currentTab);
        this.cleanClassList("tab" + (this.currentTab - prev));
        this["tab" + this.currentTab].nativeElement.classList.add(
            "slideOutRight"
        );
        this["tab" + (this.currentTab - prev)].nativeElement.classList.add(
            "slideInLeft"
        );
        this["tab" + (this.currentTab - prev)].nativeElement.classList.add(
            "active"
        );
        setTimeout(() => {
            this["tab" + this.currentTab].nativeElement.classList.remove(
                "active"
            );
            this.currentTab = this.currentTab - prev;
        }, 500);
    }
    go(tab: number): void {
        if (this.currentTab !== tab) {
            if (this.currentTab > tab) {
                this.back(this.currentTab - tab);
            } else {
                this.next(tab - this.currentTab);
            }
        }
    }
    estimationChange(event): void {
        //this.quote.materialDamageEstimation = GlobalStaticService.numberFormatDecimal(event.target.value);
        // this.quote.materialDamageEstimation = GlobalStaticService.acceptNumberOnlyInline(
        //     this.quote.materialDamageEstimation
        // );
        if (this.quote.materialDamageEstimation) {
            this.tab1Finished = true;
            this.tab2Enabled = true;
            this.specialErrorHintEstimation = false;
            this.quote.errorMaterialDamage = false;
        } else {
            this.tab1Finished = false;
            this.tab2Enabled = false;
            this.quote.materialDamageEstimationReferral = false;
            this.specialErrorHintEstimation = true;
        }
        this.quote.liabilityEstimation = this.quote.materialDamageEstimation;
        this.tempQuote.componentLiability.estimationChange();
        this.checkFinished();
        this.checkReferral("materialDamageEstimation");
    }
    keyupEstimation(event: any): void {
        if (CommonUtility.shouldIgnoreKeyCode(event.keyCode)) {
            return;
        }
        let val = GlobalStaticService.numberFormatDecimal(event.target.value);
        if (val) {
            event.target.value = GlobalStaticService.priceFormat(val);
            if (val !== this.quote.materialDamageEstimation) {
                let callReferralModal = val > this.quote.materialDamageEstimation;
                this.quote.materialDamageEstimation = val;
                this.estimationChange(callReferralModal);
            }
        }
    }
    singleEstimationSwitch(): void {
        if (!!this.quote.existingStructures && GlobalStaticService.numberFormat(this.quote.existingStructures) !== 0) {
            this.tab1Finished = true;
            this.tab2Enabled = true;
            this.go(2);
            this.quote.poorConditionReferral = false;
            this.specialErrorHintES = false;
        } else {
            this.tab1Finished = true;
            this.tab2Enabled = false;
            this.checkFinished();
            this.continueForm();
        }
    }
    estimationSwitch(): void {
        if (this.quote.materialDamageEstimation) {
            this.tab1Finished = true;
            this.tab2Enabled = true;
            this.go(2);
            setTimeout(() => {
                this.annual1.nativeElement.focus();
            }, 300);
            this.specialErrorHintEstimation = false;
            this.quote.errorMaterialDamage = false;
        } else {
            this.tab1Finished = false;
            this.tab2Enabled = false;
            this.quote.materialDamageEstimationReferral = false;
            this.specialErrorHintEstimation = true;
        }
        this.checkFinished();
    }
    fieldSwitch(order: number): void {
        if (this.quote.type === "single") {
            if (order === 3 && this.quote.insuredType === "ownerBuilder1") {
                order++;
            }
            this["single" + order].nativeElement.focus();
        } else {
            this["annual" + order].nativeElement.focus();
        }
    }
    changeProjectCost(event: any): void {
        if (CommonUtility.shouldIgnoreKeyCode(event.keyCode)) {
            return;
        }
        let val = GlobalStaticService.numberFormatDecimal(event.target.value);
        event.target.value = GlobalStaticService.priceFormat(val);
        if (val !== this.quote.projectCost) {
            this.quote.projectCost = val;
            this.checkReferral('projectCost');
        }
        if (QUOTE_REFERRAL.percentES) {
            this.quote.existingStructures =
                (this.quote.projectCost * QUOTE_REFERRAL.percentES) / 100;
            this.specialErrorHintES = false;
        }
        if (QUOTE_REFERRAL.percentPSM) {
            this.quote.principalSupplied =
                (this.quote.projectCost * QUOTE_REFERRAL.percentPSM) / 100;
            this.specialErrorHintPSM = false;
        }
        if (QUOTE_REFERRAL.percentPlant) {
            this.quote.contractorsPlant =
                (this.quote.projectCost * QUOTE_REFERRAL.percentPlant) / 100;
            this.specialErrorHintCPTE = false;
        }
        if (!this.quote.projectCost) {
            this.quote.projectCostReferral = false;
            this.specialErrorHintPC = true;
        } else {
            this.specialErrorHintPC = false;
        }
    }
    changeExistingStructures(event: any): void {
        if (CommonUtility.shouldIgnoreKeyCode(event.keyCode)) {
            return;
        }
        let val = GlobalStaticService.numberFormatDecimal(event.target.value);
        event.target.value = GlobalStaticService.priceFormat(val);
        if (val !== this.quote.existingStructures) {
            this.quote.existingStructures = val;
            this.checkReferral('existingStructures');
        }
        if (!this.quote.existingStructures && this.quote.existingStructures != 0) {
            this.quote.existingStructuresReferral = false;
            this.quote.existingStructuresA = null;
            this.quote.existingStructuresB = null;
            this.quote.existingStructuresC = null;
            this.quote.existingStructuresD = null;
            this.quote.poorConditionReferral = false;
            this.specialErrorHintES = true;
        } else {
            this.specialErrorHintES = false;
        }
        if (this.quote.type === "single") {
            this.offsetRecheckEventEmitter.emit(4);
        }
    }
    checkReferral(field: string, callReferralModal: boolean = true): void {
        switch (field) {
            case "materialDamageEstimation":
                if (
                    this.quote.materialDamageEstimation >
                    QUOTE_REFERRAL.materialDamageEstimation
                ) {
                    if (!this.quote.materialDamageEstimationReferral) {
                        this.quote.materialDamageEstimationReferral = true;
                        if (callReferralModal) {
                            this.callReferralModal();
                        }
                    }
                } else {
                    this.quote.materialDamageEstimationReferral = false;
                }
                break;
            case "projectCost":
                if (this.quote.projectCost > QUOTE_REFERRAL.projectCost) {
                    if (!this.quote.projectCostReferral) {
                        this.quote.projectCostReferral = true;
                        if (callReferralModal) {
                            this.callReferralModal();
                        }
                    }
                } else {
                    this.quote.projectCostReferral = false;
                }
                break;
            case "existingStructures":
                if (
                    this.quote.existingStructures >
                    QUOTE_REFERRAL.existingStructures
                ) {
                    if (!this.quote.existingStructuresReferral) {
                        this.quote.existingStructuresReferral = true;
                        if (callReferralModal) {
                            this.callReferralModal();
                        }
                    }
                } else {
                    this.quote.existingStructuresReferral = false;
                }
                break;
            case "principalSupplied":
                if (
                    this.quote.principalSupplied >
                    QUOTE_REFERRAL.principalSupplied
                ) {
                    if (!this.quote.principalSuppliedReferral) {
                        this.quote.principalSuppliedReferral = true;
                        if (callReferralModal) {
                            this.callReferralModal();
                        }
                    }
                } else {
                    this.quote.principalSuppliedReferral = false;
                }
                break;
            case "contractorsPlant":
                if (
                    this.quote.contractorsPlant >
                    QUOTE_REFERRAL.contractorsPlant
                ) {
                    if (!this.quote.contractorsPlantReferral) {
                        this.quote.contractorsPlantReferral = true;
                        if (callReferralModal) {
                            this.callReferralModal();
                        }
                    }
                } else {
                    this.quote.contractorsPlantReferral = false;
                }
                break;
            case "all":
                this.checkReferral("materialDamageEstimation", false);
                this.checkReferral("projectCost", false);
                this.checkReferral("existingStructures", false);
                this.checkReferral("principalSupplied", false);
                this.checkReferral("contractorsPlant", false);
                this.eventBus.cast(UcEvent.QUOTE_REFERRAL_FLAG_CHANGED);
                break;
        }
        if (callReferralModal) {
            this.checkFinished();
        }
    }
    callReferralModal(): void {
        GlobalStaticService.infoModal = {
            title: "REFERRAL WARNING",
            content: "This sum insured requires MECON to provide terms for you"
        };
        GlobalStaticService.openInfoModal();
    }
    chooseESA(value: boolean): void {
        this.quote.existingStructuresA = value;
        this.changeExistingExtend();
    }
    chooseESB(value: string): void {
        this.quote.existingStructuresB = value;
        this.changeExistingExtend();
    }
    changeExistingExtend(): void {
        this.quote.poorConditionReferral =
            this.quote.existingStructuresB === "Poor";
        this.checkFinished();
    }
    changePrincipalSupplied(event: any): void {
        if (CommonUtility.shouldIgnoreKeyCode(event.keyCode)) {
            return;
        }
        let val = GlobalStaticService.numberFormatDecimal(event.target.value);
        event.target.value = GlobalStaticService.priceFormat(val);
        if (val !== this.quote.principalSupplied) {
            this.quote.principalSupplied = val;
            this.checkReferral('principalSupplied');
        }
        // this.quote.principalSupplied = GlobalStaticService.acceptNumberOnlyInline(
        //     this.quote.principalSupplied
        // );
        if (!this.quote.principalSupplied) {
            this.quote.principalSuppliedReferral = false;
            this.specialErrorHintPSM = true;
        } else {
            this.specialErrorHintPSM = false;
        }
    }
    changeContractorsPlant(event: any): void {
        if (CommonUtility.shouldIgnoreKeyCode(event.keyCode)) {
            return;
        }
        let val = GlobalStaticService.numberFormatDecimal(event.target.value);
        event.target.value = GlobalStaticService.priceFormat(val);
        if (val !== this.quote.contractorsPlant) {
            this.quote.contractorsPlant = val;
            this.checkReferral('contractorsPlant');
        }
        if (!this.quote.contractorsPlant) {
            this.quote.contractorsPlantReferral = false;
            this.specialErrorHintCPTE = true;
        } else {
            this.specialErrorHintCPTE = false;
        }
    }
    checkFinished(): void {
        this.quote.materialDamageFinished = true;
        if (this.quote.insuranceType !== "l") {
            if (GlobalStaticService.numberFormat(this.quote.projectCost)) {
                if (
                    this.quote.type === "single" &&
                    this.quote.existingStructures &&
                    GlobalStaticService.numberFormat(
                        this.quote.existingStructures
                    ) !== 0
                ) {
                    this.quote.materialDamageFinished =
                        (this.quote.existingStructuresA === true || this.quote.existingStructuresA === false) &&
                        !!this.quote.existingStructuresB &&
                        (this.quote.existingStructuresC === true || this.quote.existingStructuresC === false) &&
                        (this.quote.existingStructuresC === true || (this.quote.existingStructuresD === true || this.quote.existingStructuresD === false));
                }
            } else {
                this.quote.materialDamageFinished = false;
            }
            if (this.quote.type === "annual") {
                if (!this.quote.materialDamageEstimation) {
                    this.quote.materialDamageFinished = false;
                    this.tab1Finished = false;
                } else {
                    this.tab1Finished = true;
                }
            }
        }
        if (!this.quote.materialDamageFinished) {
            this.emit();
        } else {
            this.emitNoScroll();
            this.quote.errorMaterialDamage = false;
        }
    }
    errorFix($event: any): void {
        this.specialErrorHintEstimation = false;
        this.specialErrorHintPC = false;
        this.specialErrorHintES = false;
        this.specialErrorHintPSM = false;
        this.specialErrorHintCPTE = false;
        let val = $event.target ? $event.target.value : 0;
        $event.target.value = GlobalStaticService.priceFormat(val);
// debug
//console.log("GlobalStaticService", GlobalStaticService)
    }
    emit(noScroll: boolean = false): void {
        if (!this.isVisible()) {
            // not visible
            return;
        }
        this.continueEventEmitter.emit({
            finished: this.quote.materialDamageFinished,
            referral:
                this.quote.projectCostReferral ||
                this.quote.existingStructuresReferral ||
                this.quote.principalSuppliedReferral ||
                this.quote.contractorsPlantReferral ||
                this.quote.poorConditionReferral ||
                this.quote.materialDamageEstimationReferral,
            noScroll: noScroll
        });
    }
    emitNoScroll(): void {
        this.emit(true);
    }
    continueForm(): void {
        if (!this.quote.materialDamageFinished) {
            this.quote.errorMaterialDamage = true;
        }
        this.emit();
    }
    private isVisible(): boolean {
        const element = this.elementRef.nativeElement;
        let pageElement = element.parentElement.parentElement.parentElement.parentElement;
        let visible: boolean = pageElement.classList.contains("active");
        return visible;
    }
}
