export const STATE = {
    ACT: {
        stateId: 1,
        stateName: "Australian Capital Territory"
    },
    NSW: {
        stateId: 2,
        stateName: "New South Wales"
    },
    NT: {
        stateId: 3,
        stateName: "Northern Territory"
    },
    QLD: {
        stateId: 4,
        stateName: "Queensland"
    },
    SA: {
        stateId: 5,
        stateName: "South Australia"
    },
    TAS: {
        stateId: 6,
        stateName: "Tasmania"
    },
    VIC: {
        stateId: 7,
        stateName: "Victoria"
    },
    WA: {
        stateId: 8,
        stateName: "Western Australia"
    }
};
