import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from "@angular/core";

import { GlobalStaticService } from "../../static-services/global.static-service";
import { Quote } from "../../models/quote";

@Component({
    selector: "app-add-enhance-cover-modal-component",
    templateUrl: "./add-enhance-cover-modal.component.html",
    styleUrls: ["./add-enhance-cover-modal.component.scss"]
})
export class AddEnhanceCoverModalComponent {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor() {}
    change(): void {
        this.quote.enhance1Referral =
            this.quote.enhance1A ||
            this.quote.enhance1B ||
            this.quote.enhance1C ||
            this.quote.enhance1D;
        let finished = false;
        if (
            (this.quote.enhance1A === true || this.quote.enhance1A === false) &&
            (this.quote.enhance1B === true || this.quote.enhance1B === false) &&
            (this.quote.enhance1C === true || this.quote.enhance1C === false) &&
            (this.quote.enhance1D === true || this.quote.enhance1D === false)
        ) {
            finished = true;
        }
        if (finished) {
            this.quote.errorEnhanceYourCover = false;
        }
        this.continueEventEmitter.emit({
            finished: finished,
            noScroll: true,
            referral:
                (this.quote.businessTypeReferralCPL && this.quote.cover1) ||
                this.quote.cover5Referral ||
                this.quote.cover3Referral ||
                this.quote.cover6Referral ||
                this.quote.enhance1Referral ||
                this.quote.cover4Referral ||
                this.quote.enhance4Referral
        });
    }
    closeModal(): void {
        if (
            (this.quote.enhance1A === true || this.quote.enhance1A === false) &&
            (this.quote.enhance1B === true || this.quote.enhance1B === false) &&
            (this.quote.enhance1C === true || this.quote.enhance1C === false) &&
            (this.quote.enhance1D === true || this.quote.enhance1D === false)
        ) {
            GlobalStaticService.closeEnhanceCoverModal();
        } else {
            this.quote.errorEnhanceYourCover = true;
        }
    }
}
