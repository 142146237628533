export class Client {
    // List Attributes
    clientId: number;
    clientName: string;
    abn: string;
    // Single Attributes
    type: string;
    clientTypeId: number;
    clientTypeText: string;
    acn: string;
    businessDescription: string;
    clientRatingId: number;
    clientShortName: string;
    clientSinceDate: string;
    clientStatusId: number;
    clientStatusTrackingNote: string;
    clientTurnover: number;
    clientWebAddress: string;
    deleteFlag: boolean;
    email: string;
    email2: string;
    fax: string;
    fsraTypeId: number;
    ignoreConcurrencyFlag: boolean;
    insertFlag: boolean;
    lastUpdateTs: string;
    marketingEmailFlag: boolean;
    marketingPostFlag: boolean;
    marketingTelephoneFlag: boolean;
    mobile: string;
    phone: string;
    phone2: string;
    referenceNo: string;
    rejectInstalmentBillingFlag: boolean;
    tradingNames: any[] = [];
    tradingNamesPlainList: string;
}
