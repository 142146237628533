import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Policy } from "../../models/policy";
import * as moment from "moment";

@Component({
    selector: "app-policy-interested-party-component",
    templateUrl: "./policy-interested-party.component.html",
    styleUrls: ["./policy-interested-party.component.scss"]
})
export class PolicyInterestedPartyComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    @Input("policy") policy: Policy;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() offsetEventEmitter: EventEmitter<any> = new EventEmitter();
    ipName: string;
    ipType: string;
    ipTypeName: string;
    continueWarning: boolean;
    addWarning: boolean;
    constructor() {}
    ngOnInit(): void {
        this.addWarning = false;
        this.continueWarning = false;
    }
    ngOnChanges(): void {}
    next(): void {
        this.addWarning = false;

        if( this.ipName || this.ipType || (this.ipType === 'other' && this.ipTypeName) ){
            this.continueWarning = true;
            return;
        }else{
            this.continueWarning = false;
        }

        this.clean();
        this.continueEventEmitter.emit({
            finished: true
        });
    }
    changeType(): void {
        if (this.ipType !== "other") {
            this.ipTypeName = "";
        }
        this.offsetEventEmitter.emit(true);
    }
    clean(): void {
        this.ipName = null;
        this.ipType = null;
        this.ipTypeName = null;
    }
    addIp(): void {
        this.addWarning = false;
        this.continueWarning = false;

        if (
            !this.ipName ||
            !this.ipType ||
            (this.ipType === "other" && !this.ipTypeName)
        ) {
            this.addWarning = true;
        } else {
            const ip = {
                name: this.ipName,
                type: this.ipType === "other" ? this.ipTypeName : this.ipType
            };
            this.removeIp(ip);
            if (!this.policy.interestedParties) {
                this.policy.interestedParties = [];
            }
            this.policy.interestedParties.push(ip);
            this.clean();
            this.offsetEventEmitter.emit(true);
        }
    }
    removeIp(ip: any) {
        if (
            this.policy.interestedParties &&
            this.policy.interestedParties.length
        ) {
            for (let i = 0; i < this.policy.interestedParties.length; i++) {
                if (
                    this.policy.interestedParties[i].type === ip.type &&
                    this.policy.interestedParties[i].name === ip.name
                ) {
                    this.policy.interestedParties.splice(i, 1);
                    this.offsetEventEmitter.emit(true);
                    return;
                }
            }
        }
    }
}
