import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Policy } from "../../models/policy";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonApi } from "../../api/common.api";

@Component({
    selector: "app-modal-email-policy-component",
    templateUrl: "./modal-email-policy.component.html",
    styleUrls: ["./modal-email-policy.component.scss"]
})
export class ModalEmailPolicyComponent implements OnInit {
    GlobalStaticSrv = GlobalStaticService;
    @Input("policy") policy: Policy;
    emailForm: FormGroup;
    finished = false;
    constructor(private fb: FormBuilder, private commonApi: CommonApi) {}
    ngOnInit(): void {
        this.emailForm = this.fb.group({
            choice: ["broker", Validators.required],
            custom: [null, [Validators.email]]
        });
        this.emailForm.controls["choice"].valueChanges.subscribe(value => {
            if (value === "broker") {
                this.emailForm.controls["custom"].setValidators([]);
            } else {
                this.emailForm.controls["custom"].setValidators([
                    Validators.required,
                    Validators.email
                ]);
            }
        });
    }
    close(): void {
        GlobalStaticService.closeEmailPolicyModal();
        this.clean();
    }
    focus(choice: string): void {
        if (this.emailForm.controls["choice"].value !== choice) {
            this.emailForm.setValue({
                choice: choice,
                custom: ""
            });
        }
    }
    clean(): void {
        this.emailForm.setValue({
            choice: "broker",
            custom: ""
        });
    }
    submit(): void {
        if (
            this.emailForm.controls["choice"].value !== "broker" &&
            this.emailForm.controls["custom"].invalid
        ) {
        } else {
            GlobalStaticService.closeEmailPolicyModal();
            GlobalStaticService.openSubmitLoading("Email Policy Documents");
            // call email api
            this.commonApi
                .postPolicyEmail(
                    this.policy.policyId,
                    this.emailForm.get("choice").value !== "broker",
                    this.emailForm.get("custom").value
                )
                .subscribe(
                    res => {
                        GlobalStaticService.closeSubmitLoading();
                        GlobalStaticService.infoModal = {
                            title: "",
                            content: `
                    <div class="text-center"><i class="u-icon icon-88 icon-circle-purple-success"></i></div>
                    <h2 class="text-center f-mgt-10 f-mgb-10">Email policy documents sent!</h2>
                    <div class="text-center f-mgb-40"><small class="minor">Please check your inbox</small></div>
                `,
                            buttons: [
                                {
                                    style: "purple",
                                    text: "Ok",
                                    callback: () => {
                                        this.clean();
                                        GlobalStaticService.closeInfoModal();
                                    }
                                }
                            ]
                        };
                        GlobalStaticService.openInfoModal();
                    },
                    err => {
                        GlobalStaticService.closeSubmitLoading();
                        GlobalStaticService.openUnexpectedErrorModal();
                    }
                );
        }
    }
}
