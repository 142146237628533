import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Observable";

import { QuoteMappingStaticService } from "../static-services/quote-mapping.static-service";

import { CONFIG } from "../configs/config";
import { Quote } from "../models/quote";
import { Client } from "../models/client";
import { Broker } from "../models/broker";
import { SchemeTrackerService } from "../services/scheme-tracker.service";
import { CommonUtility } from "../utilities/common.utility";
import { Observer } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class CommonApi {
    private static instance: CommonApi;
    private url = CONFIG.API_BASE;

    constructor(
        private http: HttpClient,
        private schemeTracker: SchemeTrackerService
    ) {
        CommonApi.instance = this;
    }

    public static getInstance(): CommonApi {
        return CommonApi.instance;
    }

    getVersion(): any {
        return this.http.get(`${this.url}/version`);
    }

    getState(): any {
        return this.http.get(`${this.url}/my/state`);
    }

    getContactId(id: number): any {
        return this.http.get(`${this.url}/my/categoryId?id=${id}`);
    }

    getQuoteFacility(optionId: number): any {
        return this.http.get(
            `${this.url}/quotes/options/${optionId}/facilities`
        );
    }
    getPolicyFacility(policyId: number): any {
        return this.http.get(`${this.url}/policies/${policyId}/facilities/`);
    }
    getProductFacility(productId: number, facilityId: number): any {
        return this.http.get(
            `${this.url}/products/${productId}/facilities/${facilityId}`
        );
    }
    getFacilityInsurer(facilityId: number): any {
        return this.http.get(
            `${this.url}/products/facilities/${facilityId}/insurers`
        );
    }
    postBroker(broker: Broker): any {
        return this.http.post(`${this.url}/brokers`, broker);
    }
    /* postBeginConversation(entityId, entityType): any {
        return this.http
            .post(`${this.url}/intercom/conversation/begin`, {
                entityId: entityId,
                entityType: entityType
            })
            .map(res => {
                console.debug("convo |>", res);
                return res;
            });
    }
    postEndConversation(): any {
        return this.http
            .post(`${this.url}/intercom/conversation/end`, null)
            .map(res => {
                console.debug("convo <|", res);
                return res;
            });
    } */

    postBrokerEnterNewQuote(): any {
        return this.http.post(`${this.url}/broker-states/new-quote`, null);
    }

    postBrokerLeaveSummary(): any {
        return this.http.post(
            `${this.url}/broker-states/left-summary-page`,
            null
        );
    }

    postBrokerEnterQuoteSummary(quoteId: any): any {
        return this.http.post(
            `${
                this.url
            }/broker-states/enter-quote-summary-page?quoteId=${quoteId}`,
            null
        );
    }

    postBrokerEnterPolicySummary(policyId: any): any {
        return this.http.post(
            `${
                this.url
            }/broker-states/enter-policy-summary-page?policyId=${policyId}`,
            null
        );
    }

    postForgotPassword(name: string, email: string, mobile: string): any {
        const data = {
            name: name,
            email: email,
            mobile: mobile
        };
        return this.http.post(`${this.url}/brokers/forgot-password`, data);
    }

    getAddresses(q: string): any {
        return this.http.get(`${this.url}/addresses?q=${q}`);
    }

    getDykRandom(): any {
        return this.http.get(`${this.url}/dyk_msgs/random`);
    }

    getSuburbPostcode(postcode: any, suburb: string): any {
        return this.http.get(
            `${this.url}/suburbs/postcode=${postcode};suburb=${suburb}`
        );
    }

    getPostcodes(q: string, scope: string): any {
        return this.http.get(`${this.url}/postcodes`, {
            params: CommonUtility.queryParams({
                search: q,
                scope: scope,
                uc: false
            })
        });
    }
    getPostcodesAll(q: string): any {
        const params = {
            search: q
        };
        return this.http.get(`${this.url}/postcodes/all`, {
            params: { search: q }
        });
    }
    getTemplates(brokerId: any): any {
        return this.http.get(
            `${this.url}/quote-templates?brokerId=${brokerId}`
        );
    }
    getTemplate(brokerId: any, templateId: any): any {
        return this.http.get(
            `${this.url}/quote-templates/${templateId}?brokerId=${brokerId}`
        );
    }
    postTemplate(brokerId: any, template: any): any {
        const data = {
            brokerId: brokerId.toString(),
            json: template
        };
        return this.http.post(`${this.url}/quote-templates`, data);
    }

    deleteTemplate(templateId: any): any {
        return this.http.delete(`${this.url}/quote-templates/${templateId}`);
    }

    postLogin(
        email: string,
        password: string,
        fingerprint: string,
        forceLogin: boolean
    ): any {
        const data = {
            username: email,
            password: password,
            fingerprint: fingerprint,
            forceLogin: forceLogin
        };
        return this.http.post(`${this.url}/login`, data);
    }

    getLogout(): any {
        return this.http.get(this.getLogoutUrl()).map(res => {
            return res;
        });
    }

    getLogoutUrl(): string {
        return `${this.url}/logout`;
    }

    getUnloadUrl(): string {
        return `${this.url}/unload`;
    }

    getStatus(): any {
        return this.http.get(`${this.url}/session/status`);
    }

    getGeoLoc(): any {
        return this.http.get(`${this.url}/my/loc`);
    }

    getScheme(
        productId: number,
        projectTypeId: number,
        insuredTypeId: number,
        forceReturn: boolean = false
    ): any {
        console.log("Trying to get scheme ...");
        if (
            !this.schemeTracker.shouldGetScheme(
                productId,
                projectTypeId,
                insuredTypeId
            )
        ) {
            console.log("Shouldn't get scheme !!!")
            return new Observable((observer: Observer<any>) => {
                observer.next(this.schemeTracker.getScheme());
            });
        }
        console.log("Should get scheme !!!")
        let result: Observable<any> = this.http
            .get(`${this.url}/schemes`, {
                params: CommonUtility.queryParams({
                    productId: productId,
                    projectTypeValueId: projectTypeId,
                    proposerInterestValueId: insuredTypeId
                })
            })
            .map(res => {
                if (
                    !this.schemeTracker.setScheme(
                        res,
                        productId,
                        projectTypeId,
                        insuredTypeId
                    )
                ) {
                    if (forceReturn) {
                        return this.schemeTracker.getScheme();
                    } else {
                        return null;
                    }
                }
                return res;
            });
        result.subscribe(
            res => {},
            err => {
                this.schemeTracker.reset();
            }
        );
        return result;
    }

    getSchemeAll(): any {
        return this.http.get(`${this.url}/schemes/all`);
    }

    putPassword(oldPassword: string, newPassword: string): any {
        const data = {
            oldPassword: oldPassword,
            newPassword: newPassword
        };
        return this.http.put(`${this.url}/my/password`, data);
    }

    getProfile(): any {
        return this.http.get(`${this.url}/my/profile`);
    }

    getAbn(abn: string): any {
        return this.http.get(`${this.url}/abn/${abn}`);
    }

    getIic(): any {
        return this.http.get(`${this.url}/iic`);
    }

    getReferral(): any {
        return this.http.get(`${this.url}/referral-misc-values`);
    }

    getClaims(q?: string): any {
        return this.http.get(`${this.url}/claims`, { params: { q: q } });
    }

    getClaim(id: any): any {
        return this.http.get(`${this.url}/claims/${id}`);
    }

    getClients(q?: any): any {
        return this.http.get(`${this.url}/clients`, { params: { q: q } });
    }

    getClient(id: any): any {
        return this.http.get(`${this.url}/clients/${id}`);
    }

    getClientPolicies(id: any): any {
        return this.http.get(`${this.url}/clients/${id}/policies`);
    }

    getClientQuotes(id: any): any {
        return this.http.get(`${this.url}/clients/${id}/quotes`);
    }

    getPolicies(q?: string, path?: string): any {
        let params;
        if (path && path === "policies") {
            params = {
                q: q
            };
        } else {
            params = {
                q: q,
                coc: true
            };
        }
        return this.http.get(`${this.url}/policies`, {
            params: CommonUtility.queryParams(params)
        });
    }

    getPolicy(id: any): any {
        return this.http.get(`${this.url}/policies/${id}`);
    }

    getPolicyRating(policyId: number, sectionId: number): any {
        return this.http.get(
            `${this.url}/policies/${policyId}/ratings?sectionId=${sectionId}`
        );
    }
    getPolicyWording(policyId: number): any {
        return this.http.get(`${this.url}/policies/${policyId}/wording`);
    }
    getPolicySections(policyId: number): any {
        return this.http.get(`${this.url}/policies/${policyId}/sections`);
    }
    getQuoteWording(quoteId: number): any {
        return this.http.get(`${this.url}/quotes/${quoteId}/wording`);
    }
    getQuoteSections(quoteId: number): any {
        return this.http.get(`${this.url}/quotes/${quoteId}/sections`);
    }
    getQuoteRating(optionId: number, sectionId: number): any {
        return this.http.get(
            `${
                this.url
            }/quotes/options/${optionId}/ratings?sectionId=${sectionId}`
        );
    }
    getInsurer(insurerId: number): any {
        return this.http.get(`${this.url}/insurer/${insurerId}`);
    }

    getQuotes(q?: string): any {
        return this.http.get(`${this.url}/quotes`, { params: { q: q } });
    }

    getQuote(id: any): any {
        return this.http.get(`${this.url}/quotes/${id}`);
    }

    getProduct(productId: number): any {
        return this.http.get(`${this.url}/products/${productId}/lookups`);
    }
    getLookup(lookupId: number): any {
        return this.http.get(`${this.url}/lookups/${lookupId}`);
    }
    getProductBusinessOfInsured(productId: number): any {
        return this.http.get(
            `${this.url}/products/${productId}/businessOfInsured`
        );
    }
    postReferral(quoteId: number, text: string, type: number=1): any {
        const data = {
            text: text,
            quoteId: quoteId,
            type: type
        };
        return this.http.post(`${this.url}/quotes/referrals`, data);
    }

    getSuburb(suburbId: number): any {
        return this.http.get(`${this.url}/suburb/${suburbId}`);
    }

    postQuotes(quote: Quote): any {
        let quotePost;
        if (quote.type === "single") {
            quotePost = QuoteMappingStaticService.processPostSingle(quote);
        } else {
            quotePost = QuoteMappingStaticService.processPostAnnual(quote);
        }
        let scheme = quote._scheme;
        if (scheme && scheme !== -1) {
            quotePost['_scheme'] = scheme;
        }
        return this.http.post(`${this.url}/quotes`, quotePost);
    }

    postQuoteAttachment(id: any, formData: any): any {
        return this.http.post(
            `${this.url}/quotes/${id}/attachments`,
            formData
        );
    }

    putQuotes(quote: Quote): any {
        let quotePost;
        if (quote.type === "single") {
            quotePost = QuoteMappingStaticService.processPostSingle(quote);
        } else {
            quotePost = QuoteMappingStaticService.processPostAnnual(quote);
        }
        quotePost.quoteId = quote.quoteId;
        return this.http.put(`${this.url}/quotes/${quote.quoteId}`, quotePost);
    }

    postClients(client: Client): any {
        const data = JSON.parse(JSON.stringify(client));
        data.tradingNames = data.tradingNames.map(name => {
            return name.name;
        });
        return this.http.post(`${this.url}/clients`, data);
    }

    postClientsBusinessName(clientId: number, nameList: string[]): any {
        const data = {
            nameList: nameList
        };
        return this.http.post(
            `${this.url}/clients/${clientId}/other-names`,
            data
        );
    }

    putQuoteOtherName(quoteId: number, nameList: number[]): any {
        const data = {
            nameList: nameList
        };
        return this.http.put(`${this.url}/quotes/${quoteId}/other-names`, data);
    }

    getQuoteOtherName(quoteId: number): any {
        return this.http.get(`${this.url}/quotes/${quoteId}/other-names`);
    }

    getPolicyOtherName(policyId: number): any {
        return this.http.get(`${this.url}/policies/${policyId}/other-names`);
    }

    postBind(
        id: number,
        optionId: number,
        interestedParty: any[],
        policyStart: any,
        policyEnd: any,
        isClient: boolean,
        email: string
    ): any {
        let data;
        if (isClient) {
            data = {
                optionId: optionId,
                interestedParties: interestedParty,
                policyStart: policyStart,
                policyEnd: policyEnd,
                isClient: isClient,
                email: email
            };
        } else {
            data = {
                optionId: optionId,
                interestedParties: interestedParty,
                policyStart: policyStart,
                policyEnd: policyEnd,
                isClient: isClient
            };
        }
        return this.http.post(`${this.url}/quotes/${id}/bind`, data);
    }

    postQuoteEmail(quoteId: number, isClient: boolean, email: string): any {
        let data;
        if (isClient) {
            data = {
                isClient: isClient,
                email: email
            };
        } else {
            data = {
                isClient: isClient
            };
        }
        return this.http.post(`${this.url}/quotes/${quoteId}/email`, data);
    }

    postPolicyEmail(policyId: number, isClient: boolean, email: string): any {
        let data;
        if (isClient) {
            data = {
                isClient: isClient,
                email: email
            };
        } else {
            data = {
                isClient: isClient
            };
        }
        return this.http.post(`${this.url}/policies/${policyId}/email`, data);
    }

    postCoc(policyId: number, data: any): any {
        return this.http.post(`${this.url}/policies/${policyId}/coc`, data);
    }

    putRate(quoteId: number, optionId: number): any {
        return this.http.put(
            `${this.url}/quotes/${quoteId}/options/${optionId}/ratings`,
            null
        );
    }
}
