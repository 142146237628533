import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../environments/environment";

import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/fromEvent";
import "rxjs/add/operator/throttleTime";

import { InterfaceStaticService } from "../static-services/interface.static-service";
import { GlobalStaticService } from "../static-services/global.static-service";
import smoothscroll from "smoothscroll-polyfill";
import { CommonApi } from "../api/common.api";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
    environment = environment;

    constructor(private router: Router, private commonApi: CommonApi) { }

    ngOnInit(): void {
        InterfaceStaticService.resize();
        InterfaceStaticService.resize$$ = Observable.fromEvent(window, "resize")
            .throttleTime(20)
            .subscribe(e => {
                InterfaceStaticService.resize();
            });

        this.router.events.subscribe(() => {
            InterfaceStaticService.noFooter = false;
        });

        this.commonApi.getVersion().subscribe(res => {
            GlobalStaticService.backoffice = res.backoffice;
            GlobalStaticService.mediator = res.mediator;
        });

        smoothscroll.polyfill();
    }
}
