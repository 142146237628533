export let QUOTE_REFERRAL = {
    materialDamageEstimation: 0,
    projectCost: 0,
    existingStructures: 0,
    principalSupplied: 0,
    contractorsPlant: 0,
    liabilityEstimation: 0,
    publicLiability: 0,
    vibrationWeakeningRemovalOfSupport: 0,
    productsLiability: 0,
    propertyInCareCustodyControl: 0,
    percentES: 0,
    percentPSM: 0,
    percentPlant: 0,
    tailorYourCover: 0,
    excessAmtAmount: { md: [], pl: [], cpl: [] },
    excessAmtAmountLkv: { md: [], pl: [], cpl: [] },
    excessAmtDescLkv: { md: [], pl: [], cpl: [] },
    eaLimitAp: 0,
    eaLimitSp: 0,
    eoLimitAp: 0,
    eoLimitSp: 0,
    ldLimitAp: 0,
    ldLimitSp: 0,
    dlpLimitAp: 0,
    dlpLimitSp: 0,
    sqLimit: 0,
    aqLimit: 12,
    mpdLimit: 0
};
