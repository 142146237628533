import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
} from "@angular/common/http";
import { GlobalStaticService } from "../static-services/global.static-service";
import { Router } from "@angular/router";
import { CONFIG } from "../configs/config";
import { CommonUtility } from "../utilities/common.utility";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let newReq = req;

        if (!req.headers.has("Authorization")) {
            let url: string = CommonUtility.urlWithoutScheme(req.url);
            if (url.startsWith(CONFIG.API_BASE)) {
                // sending request to API layer, let's add JWT token to the req header
                // Get the auth token from the service.
                const authToken = GlobalStaticService.getToken(true);

                // Clone the request and set the new header in one step.
                newReq = authToken
                    ? req.clone({ setHeaders: { Authorization: authToken } })
                    : req;
            }
        }

        if (!req.headers.has("Content-Type") && !req.url.endsWith('/attachments') && !req.url.endsWith('/coc')) {
            newReq = newReq.clone({
                setHeaders: { "Content-Type": "application/json" }
            });
        }

        // send cloned request with header to the next handler.
        return next.handle(newReq).do(
            (event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    let url: string = CommonUtility.urlWithoutScheme(event.url);
                    if (url.startsWith(CONFIG.API_BASE)) {
                        // response coming from API layer, let's refresh the JWT token
                        let token = event.headers.get("Authorization");
                        GlobalStaticService.setToken(token);
                    }
                }
            },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        this.router.navigate(["/"]);
                    }
                }
            }
        );
    }
}
