import { Component, Input } from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Template } from "../../models/template";
import { Quote } from "../../models/quote";
import { QuoteMappingStaticService } from "../../static-services/quote-mapping.static-service";
import { CommonApi } from "../../api/common.api";

@Component({
    selector: "app-modal-save-template-component",
    templateUrl: "./modal-save-template.component.html",
    styleUrls: ["./modal-save-template.component.scss"]
})
export class ModalSaveTemplateComponent {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    template: string;
    saveTemplate: Template = new Template();
    repeat = false;
    constructor(private commonApi: CommonApi) {}
    closeModal(): void {
        GlobalStaticService.closeSaveTemplateModal();
        this.clean();
    }
    save(): void {
        if (this.template) {
            this.saveTemplate.templateTitle = this.template;
            this.repeat = false;
            GlobalStaticService.templates.forEach((queueRecent, index) => {
                if (
                    queueRecent.templateTitle.toLowerCase() ===
                    this.saveTemplate.templateTitle.toLowerCase()
                ) {
                    this.repeat = true;
                }
            });
            if (this.repeat) {
                return;
            }
            if (this.quote.type === "single") {
                this.saveTemplate.type = "Single";
            } else {
                this.saveTemplate.type = "Annual";
            }
            this.saveTemplate.quote = QuoteMappingStaticService.deepCopy(
                this.quote
            );
            this.saveTemplate.dataCreated = new Date();
            GlobalStaticService.templateSave(this.saveTemplate, this.commonApi);
            this.saveTemplate.quote = null;
            GlobalStaticService.addRecent("recentTemplates", this.saveTemplate);
            GlobalStaticService.closeSaveTemplateModal();
            GlobalStaticService.openSaveTemplateDoneModal();
            this.clean();
        }
    }
    clean(): void {
        this.template = null;
        this.saveTemplate = new Template();
    }
}
