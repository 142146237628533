import { Subscription } from "rxjs";
import { OnDestroy } from "@angular/core";

/**
 * Base class for all components including pages.
 *
 * Provides `registerSubscription(Subscription)` method to allow
 * sub class register subscriptions, which will get unsubscribed
 * in the `ngOnDestroy()` method.
 */
export class AppComponentBase implements OnDestroy {
    private subscription: Subscription = new Subscription();

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    protected registerSubscription(subscription: Subscription): void {
        this.subscription.add(subscription);
    }

    /**
     * Alias of `registerSubscription(Subscription)` method.
     * @param subscription the subscription
     */
    protected _rs(subscription: Subscription): void {
        this.subscription.add(subscription);
    }
}
