import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Quote } from "../../models/quote";
import { QUOTE_REFERRAL } from "../../configs/quote-referral";
import * as moment from "moment";
import { CommonUtility } from "../../utilities/common.utility";

@Component({
    selector: "app-quote-policy-date-component",
    templateUrl: "./quote-policy-date.component.html",
    styleUrls: ["./quote-policy-date.component.scss"]
})
export class QuotePolicyDateComponent implements AfterViewInit {
    GlobalStaticSrv = GlobalStaticService;
    today: Date = new Date(); // today is the date created quote when edit, to be modified
    today30: Date = new Date();
    inception30 = false;
    beforeToday = false;
    @Input("quote") quote: Quote;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor() {
        this.today30.setDate(this.today30.getDate() + 30);
    }
    ngAfterViewInit(): void {
        if (this.quote.policyStart && this.quote.policyEnd) {
            this.quote.policyDateFinished = true;
            this.checkReferral(false);
            const referralPosition = this.quote.referral.indexOf("p2q2");
            if (referralPosition !== -1) {
                this.quote.referral.splice(referralPosition, 1);
            }
            if (this.quote.policyDateReferral) {
                this.quote.referral.push("p2q2");
            }
        }
    }
    dataChangeStart(e: any): void {
        let startDate = moment(e.targetElement.value, "DD/MM/YYYY");
        this.beforeToday = false;
        this.inception30 = false;
        if (!startDate.isValid()) {
            startDate = moment(this.today, "DD/MM/YYYY");
        }
        this.quote.policyStart = startDate.toDate();
        const endDate = startDate.add(1, "years");
        this.quote.policyEnd = endDate.toDate();
        startDate = moment(this.quote.policyStart, "DD/MM/YYYY");
        if (startDate.isBefore(this.today, "day")) {
            this.beforeToday = true;
            this.quote.errorPolicyDate = true;
            this.continueFalse();
        } else if (startDate.isAfter(this.today30, "day")) {
            this.inception30 = true;
            this.quote.errorPolicyDate = true;
            this.continueFalse();
        } else {
            this.checkReferral();
            this.continueTrueNoScroll();
        }
    }
    dataChangeEnd(e: any): void {
        let endDate = moment(e.targetElement.value, "DD/MM/YYYY");
        if (
            !endDate.isValid() ||
            (endDate.isValid() &&
                endDate.isBefore(this.quote.policyStart, "day"))
        ) {
            endDate = moment(this.quote.policyStart, "DD/MM/YYYY").add(
                1,
                "years"
            );
        }
        this.quote.policyEnd = endDate.toDate();
        if (this.quote.errorPolicyDate) {
            this.continueFalse();
        } else {
            this.checkReferral();
            this.continueTrueNoScroll();
        }
    }
    checkReferral(alert: boolean = true): void {
        const diffYear =
            this.quote.policyEnd.getFullYear() -
            this.quote.policyStart.getFullYear();
        let diffMonth =
            this.quote.policyEnd.getMonth() - this.quote.policyStart.getMonth();
        let diffDate = 0;
        if (
            this.quote.policyEnd.getDate() - this.quote.policyStart.getDate() >
            0
        ) {
            diffDate = 1;
        }
        diffMonth = diffYear * 12 + diffMonth + diffDate;
        let referralMonth;
        this.quote.policyDateReferral = false;
        if (this.quote.type === "single") {
            // Single
            referralMonth = QUOTE_REFERRAL.sqLimit;
        } else {
            // Annual
            referralMonth = QUOTE_REFERRAL.aqLimit;
        }
        if (diffMonth > referralMonth) {
            this.quote.policyDateReferral = true;
            if (alert) {
                this.referralModal(referralMonth);
            }
        }
    }
    referralModal(referralMonth: number): void {
        GlobalStaticService.infoModal = {
            title: "REFERRAL WARNING",
            content: `Please select a Policy end date less than ${referralMonth} months duration otherwise a referral to MECON is required`
        };
        GlobalStaticService.openInfoModal();
    }
    continueFalse(): void {
        this.quote.policyDateFinished = false;
        this.continueEventEmitter.emit({
            finished: false,
            referral: this.quote.policyDateReferral
        });
    }
    continueTrue(): void {
        this.quote.errorPolicyDate = false;
        this.quote.policyDateFinished = true;
        this.continueEventEmitter.emit({
            finished: true,
            referral: this.quote.policyDateReferral
        });
    }
    continueTrueNoScroll(): void {
        this.quote.errorPolicyDate = false;
        this.quote.policyDateFinished = true;
        this.continueEventEmitter.emit({
            finished: true,
            referral: this.quote.policyDateReferral,
            noScroll: true
        });
    }
    next(): void {
        if (this.quote.policyDateFinished) {
            this.continueTrue();
        }
    }
}
