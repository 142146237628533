import { Component, ElementRef, ViewChild, OnInit } from "@angular/core";

import { CommonApi } from "../../api/common.api";

import { InterfaceStaticService } from "../../static-services/interface.static-service";
import { GlobalStaticService } from "../../static-services/global.static-service";

import { Claim } from "../../models/claim";
import { Policy } from "../../models/policy";
import { Quote } from "../../models/quote";

@Component({
    selector: "app-claims-page-component",
    templateUrl: "./claims.page.html",
    styleUrls: ["./claims.page.scss"]
})
export class ClaimsPageComponent implements OnInit {
    GlobalStaticSrv = GlobalStaticService;
    claims: Claim[] = [];
    searchString = "";
    searchActive = false;
    resultActive = false;
    timeout: any;
    timeoutFlag = false;
    @ViewChild("main") main: ElementRef;
    @ViewChild("claimPage") claimPage: ElementRef;
    @ViewChild("clientPage") clientPage: ElementRef;
    @ViewChild("policyPage") policyPage: ElementRef;
    @ViewChild("quotePage") quotePage: ElementRef;
    @ViewChild("createCocPage") createCocPage: ElementRef;
    histories: any[] = [{ page: "main" }];
    viewQuoteId: string;
    viewClaimId: string;
    viewClientId: string;
    viewPolicyId: string;
    viewPolicy: Policy;
    quote: Quote = new Quote();
    requesting$$: any;
    @ViewChild("policyComponent") policyComponent;
    @ViewChild("clientComponent") clientComponent;
    @ViewChild("claimComponent") claimComponent;
    @ViewChild("quoteComponent") quoteComponent;
    switching = false;
    constructor(private commonApi: CommonApi) { }
    ngOnInit(): void {
        //this.commonApi.postEndConversation();
    }
    setPolicy(event: any): void {
        this.viewPolicy = event;
    }
    setQuote(event: any): void {
        this.quote = event;
    }
    back(): void {
        if (this.histories.length > 1 && !this.switching) {
            this.switching = true;
            setTimeout(() => {
                this.switching = false;
            }, 500);
            switch (this.histories[this.histories.length - 1].page) {
                case "claimPage":
                    this.claimComponent.back();
                    break;
                case "clientPage":
                    this.clientComponent.back();
                    break;
                case "policyPage":
                    this.policyComponent.back();
                    break;
                case "quotePage":
                    this.quoteComponent.back();
                    break;
            }
        }
    }
    searchChange(): void {
        if (this.searchString && this.searchString.length >= 3) {
            this.searchActive = true;
            this.search();
        } else {
            this.searchActive = false;
        }
    }
    search(): void {
        if (this.searchString) {
            this.resultActive = true;
            if (this.requesting$$) {
                this.requesting$$.unsubscribe();
            }
            this.requesting$$ = this.commonApi
                .getClaims(this.searchString)
                .subscribe(res => {
                    this.claims = res;
                    this.resultActive = false;
                });
        }
    }
    clean(): void {
        this.searchActive = false;
        this.resultActive = false;
        this.claims = [];
    }
    fullClean(): void {
        this.searchString = "";
        this.clean();
    }
    searchBlur(): void {
        this.timeoutFlag = true;
        this.timeout = setTimeout(() => {
            this.searchActive = false;
            this.resultActive = false;
            this.claims = [];
        }, 200);
    }
    searchClick(): void {
        if (this.searchString) {
            if (this.timeoutFlag) {
                clearTimeout(this.timeout);
                this.timeoutFlag = false;
            }
            if (!this.searchActive) {
                this.search();
            }
            this.searchActive = !this.searchActive;
            if (!this.searchActive) {
                this.resultActive = false;
                this.claims = [];
            }
        } else {
            this.claims = GlobalStaticService.recentClaims;
            this.searchActive = true;
        }
    }
    gotoClaim(claim: Claim): void {
        this.searchString = "";
        this.searchBlur();
        this.gotoPage({
            currentPage: "main",
            nextPage: "claimPage",
            nextId: claim.claimId
        });
    }
    setViewId(page: string, id: any): void {
        switch (page) {
            case "claimPage":
                this.viewClaimId = id;
                break;
            case "clientPage":
                this.viewClientId = id;
                break;
            case "policyPage":
                this.viewPolicyId = id;
                break;
            case "quotePage":
                this.viewQuoteId = id;
                break;
            case "createCocPage":
                this.viewPolicy = id;
                break;
        }
    }
    cleanViewId(page: string): void {
        switch (page) {
            case "claimPage":
                this.viewClaimId = null;
                break;
            case "clientPage":
                this.viewClientId = null;
                break;
            case "policyPage":
                this.viewPolicyId = null;
                break;
            case "quotePage":
                this.viewQuoteId = null;
                break;
            case "createCocPage":
                this.viewPolicy = null;
                break;
        }
    }
    cleanClassList(page: string): void {
        this[page].nativeElement.classList.remove("slideInLeft");
        this[page].nativeElement.classList.remove("slideInRight");
        this[page].nativeElement.classList.remove("slideOutLeft");
        this[page].nativeElement.classList.remove("slideOutRight");
    }
    gotoPage(event: any): void {
        this.setViewId(event.nextPage, event.nextId);

        this.cleanClassList(event.currentPage);
        this.cleanClassList(event.nextPage);
        this[event.currentPage].nativeElement.classList.add("slideOutLeft");
        this[event.nextPage].nativeElement.classList.add("slideInRight");
        setTimeout(() => {
            this[event.nextPage].nativeElement.classList.add("active");
        });
        setTimeout(() => {
            this[event.currentPage].nativeElement.classList.remove("active");
            this.cleanViewId(event.currentPage);
        }, 500);
        if (event.currentPage === "main") {
            InterfaceStaticService.noFooter = true;
        }

        this.histories.push({
            page: event.nextPage,
            id: event.nextId
        });
    }
    backtoPage(event: any): void {
        const previous = this.histories[this.histories.length - 2];
        const current = this.histories[this.histories.length - 1];
        this.setViewId(previous.page, previous.id);

        this.cleanClassList(previous.page);
        this.cleanClassList(current.page);
        this[previous.page].nativeElement.classList.add("slideInLeft");
        this[current.page].nativeElement.classList.add("slideOutRight");
        setTimeout(() => {
            this[previous.page].nativeElement.classList.add("active");
        });
        setTimeout(() => {
            this[current.page].nativeElement.classList.remove("active");
            this.cleanViewId(current.page);
            this.histories.splice(this.histories.length - 1, 1);
        }, 500);
        if (previous.page === "main") {
            InterfaceStaticService.noFooter = false;
        }
    }
    bindEventEmitter(event: boolean): void {
        if (event) {
            GlobalStaticService.isQuoteBind = true;
        }
    }
}
