import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output
} from "@angular/core";

import { CommonApi } from "../../api/common.api";
import { QuoteService } from "../../services/quote.service";

import { Quote } from "../../models/quote";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Router } from "@angular/router";
import { QuoteMappingStaticService } from "../../static-services/quote-mapping.static-service";
import { Rating } from "../../models/rating";
import { QUOTE_MAPPING } from "../../configs/quote-mapping";
import { CommonUtility } from "../../utilities/common.utility";
import { STATE } from "../../configs/state";
import * as moment from "moment";
import { LookupService } from "../../services/lookup.service";
declare let gtag: any;

@Component({
    selector: "app-quote-component",
    templateUrl: "./quote.component.html",
    styleUrls: ["./quote.component.scss"]
})
export class QuoteComponent implements OnChanges, OnDestroy {
    GlobalStaticSrv = GlobalStaticService;
    quote: Quote;
    loadingRequired = 0;
    loading = 0;
    @Input("id") id: any;
    @Output() backEventEmitter: EventEmitter<boolean> = new EventEmitter();
    @Output() goEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() quoteEventEmitter: EventEmitter<any> = new EventEmitter();
    quote$$: any;
    facility$$: any;
    otherName$$: any;
    client$$: any;
    section$$: any;
    wording$$: any;
    constructor(
        private router: Router,
        private commonApi: CommonApi,
        public quoteSrv: QuoteService,
        private lookupService: LookupService
    ) { }
    ngOnChanges(): void {
        if (this.id) {
            this.loading = 0;
            this.loadingRequired = 0;
            GlobalStaticService.isQuoteBind = false;
            this.quote$$ = this.commonApi.getQuote(this.id).subscribe(
                res => {
                    //this.commonApi.postBeginConversation(this.id, 'quote');
                    switch (res.productId) {
                        case 47:
                            try {
                                this.quote = QuoteMappingStaticService.processGetSingle(
                                    res
                                );
                            } catch (err) {
                                this.quote = res;
                                this.quote._error = true;
                                return;
                            }
                            this.quote.type = "single";
                            this.commonApi
                                .getScheme(
                                    47,
                                    this.quote.needs["115661"],
                                    this.quote.needs["115623"]
                                )
                                .subscribe(
                                    res2 => {
                                        this.quoteSrv.setSchemeLimit(
                                            this.quote,
                                            res2
                                        );
                                    },
                                    err => {
                                        this.quote._scheme = -1;
                                        this.quoteSrv.resetReferralLimit(47);
                                    }
                                );
                            break;
                        case 48:
                            try {
                                this.quote = QuoteMappingStaticService.processGetAnnual(
                                    res
                                );
                            } catch (err) {
                                this.quote = res;
                                this.quote._error = true;
                                return;
                            }
                            this.quote.type = "annual";
                            this.commonApi
                                .getScheme(
                                    48,
                                    this.quote.maxProjectId,
                                    this.quote.needs["116215"]
                                )
                                .subscribe(
                                    res2 => {
                                        this.quoteSrv.setSchemeLimit(
                                            this.quote,
                                            res2
                                        );
                                    },
                                    err => {
                                        this.quote._scheme = -1;
                                        this.quoteSrv.resetReferralLimit(48);
                                    }
                                );
                            break;
                        default:
                            this.quote = res;
                            GlobalStaticService.errorOpenQuote(this.quote);
                            this.back(false);
                            return;
                    }
                    this.quote.cplOption = this.lookupService.getTable(1295);
                    this.quote.createdInvalidDate = moment(
                        this.quote.quoteDate.split("T")[0]
                    ).add(30, "days");
                    if (this.quote.quoteExpiryDate) {
                        const quoteExpiryDate = moment(
                            CommonUtility.getDateObjectBasedOnTFormat(
                                this.quote.quoteExpiryDate
                            ),
                            "DD/MM/YYYY"
                        );
                        this.quote.valid = quoteExpiryDate.isAfter(
                            moment(),
                            "days"
                        );
                    } else {
                        this.quote.valid = !this.quote.createdInvalidDate.isBefore(
                            moment(),
                            "days"
                        );
                    }

// debugging
//this.quote.valid = true
//this.quote.quoteStatusText = "Working"

                    this.quote.option = res.option;
                    if (res.rating) {
                        this.quote.rating = QuoteMappingStaticService.processRatingSection(
                            res.rating
                        );
                    } else {
                        this.quote.rating = new Rating();
                    }
                    if (
                        this.quote.attachments &&
                        this.quote.attachments.length
                    ) {
                        this.quote.attachments.forEach(e => {
                            e.submitted = true;
                        });
                    }
                    this.loadingRequired++;
                    if (this.quote.option) {
                        this.facility$$ = this.commonApi
                            .getQuoteFacility(this.quote.option.OptionId)
                            .subscribe(
                                res2 => {
                                    this.quote.facility = [];
                                    if (res2.length) {
                                        this.quoteSrv
                                            .getFacility(
                                                res2,
                                                this.quote.productId
                                            )
                                            .then(facility => {
                                                this.quote.facility = facility;
                                                this.loading++;
                                            });
                                    } else {
                                        this.loading++;
                                    }
                                },
                                err2 => {
                                    this.quote._error = true;
                                    return;
                                    //this.back(false);
                                }
                            );
                    } else {
                        this.quote.facility = [];
                        this.loading++;
                    }
                    this.loadingRequired++;
                    this.otherName$$ = this.commonApi
                        .getQuoteOtherName(this.id)
                        .subscribe(
                            res2 => {
                                this.quote.tradingNames = res2;
                                this.loading++;
                            },
                            err2 => {
                                // ignore
                                this.loading++;
                                // this.back(false);
                            }
                        );
                    this.loadingRequired++;
                    this.client$$ = this.commonApi
                        .getClient(this.quote.clientId)
                        .subscribe(
                            res2 => {
                                this.quote.client = res2;
                                if (res2.tradingNames) {
                                    this.quote.client.tradingNames =
                                        res2.tradingNames;
                                }
                                this.loading++;
                            },
                            err => {
                                // ignore
                                this.loading++;
                                //this.back(false);
                            }
                        );
                    this.loadingRequired++;
                    if (this.quote.option) {
                        this.section$$ = this.commonApi
                            .getQuoteSections(this.quote.quoteId)
                            .subscribe(
                                res2 => {
                                    this.quote.sections = [];
                                    this.quote.sectionsMeconAdminFeeGst = 0;
                                    if (res2.length) {
                                        this.quote.sections = res2;
                                        let ratingLoading = 0;
                                        for (let i = 0; i < res2.length; i++) {
                                            this.commonApi
                                                .getQuoteRating(
                                                    this.quote.option.OptionId,
                                                    res2[i].productSectionId
                                                )
                                                .subscribe(
                                                    res3 => {
                                                        this.quote.sections[
                                                            i
                                                        ].rating = QuoteMappingStaticService.processRatingSection(
                                                            res3
                                                        );
                                                        this.quote.sectionsMeconAdminFeeGst =
                                                            this.quote
                                                                .sectionsMeconAdminFeeGst +
                                                            this.quote.sections[
                                                                i
                                                            ].rating
                                                                .meconAdminFeeGst;
                                                        ratingLoading++;
                                                        if (
                                                            ratingLoading ===
                                                            res2.length
                                                        ) {
                                                            this.loading++;
                                                        }
                                                    },
                                                    err3 => {
                                                        this.quote._error = true;
                                                        return;
                                                        //this.back(false);
                                                    }
                                                );
                                        }
                                    } else {
                                        this.loading++;
                                    }
                                },
                                err2 => {
                                    this.quote._error = true;
                                    return;
                                }
                            );
                    } else {
                        this.loading++;
                        this.quote.sections = [];
                    }
                    this.loadingRequired++;
                    this.wording$$ = this.commonApi
                        .getQuoteWording(this.quote.quoteId)
                        .subscribe(
                            res2 => {
                                this.quote.wording = "";
                                if (res2.length) {
                                    this.quote.wording = res2
                                        .filter(p => {
                                            return p.paragraphId === 1;
                                        })
                                        .map(p => {
                                            return p.paragraphText;
                                        })
                                        .join("")
                                        .replace(/font-family:/gi, "");
                                }
                                this.loading++;
console.log("getQuoteWording", this.quote.wording)
console.log("getQuoteWording", res2)
                            },
                            err2 => {
                                this.quote._error = true;
                                return;
                            }
                        );
                    this.quote.suburbData = {};
                    this.quote.suburbData.suburb = this.quote.town;
                    this.quote.suburbData.postcode = this.quote.postcode;
                    this.quote.suburbData.stateId = Number(this.quote.stateId);
                    for (const state in STATE) {
                        if (STATE.hasOwnProperty(state)) {
                            if (
                                STATE[state].stateId ===
                                Number(this.quote.stateId)
                            ) {
                                this.quote.suburbData.state = state;
                                this.quote.suburbData.stateName =
                                    STATE[state].stateName;
                                break;
                            }
                        }
                    }
                    if (this.quote.type === "single") {
                        this.quote.address =
                            this.quote.address +
                            this.quote.suburbData.suburb +
                            " " +
                            this.quote.suburbData.state +
                            " " +
                            this.quote.suburbData.postcode;
                    } else {
                        this.quote.suburb =
                            this.quote.suburbData.suburb +
                            " / " +
                            this.quote.suburbData.state +
                            " / " +
                            this.quote.suburbData.postcode;
                    }
                    GlobalStaticService.addRecent("recentQuotes", this.quote);
                    this.quoteEventEmitter.emit(this.quote);
                },
                err => {
                    this.quote._error = true;
                    return;
                }
            );
        }
    }
    ngOnDestroy(): void {
    }
    cleanData(): void {
        this.quote = null;
        this.loading = 0;
        this.loadingRequired = 0;
        if (this.quote$$) {
            this.quote$$.unsubscribe();
        }
        if (this.facility$$) {
            this.facility$$.unsubscribe();
        }
        if (this.otherName$$) {
            this.otherName$$.unsubscribe();
        }
        if (this.client$$) {
            this.client$$.unsubscribe();
        }
        if (this.section$$) {
            this.section$$.unsubscribe();
        }
        if (this.wording$$) {
            this.wording$$.unsubscribe();
        }
        this.quoteEventEmitter.emit(new Quote());
    }
    back(alert?: boolean): void {
        if (alert) {
            GlobalStaticService.openUnexpectedErrorModal();
        }
        setTimeout(() => {
            this.cleanData();
        }, 500);
        this.backEventEmitter.emit(true);
    }
    go(nextPage: string, nextId: any): void {
        setTimeout(() => {
            this.cleanData();
        }, 500);
        this.goEventEmitter.emit({
            currentPage: "quotePage",
            nextPage: nextPage,
            nextId: nextId
        });
    }
    edit(): void {
        gtag("set", {
            user_id: GlobalStaticService.brokerProfile.contactId
        });
        gtag("event", "EditQuote", {});
        if (this.quote.type === "single") {
            GlobalStaticService.autoSingleQuote = QuoteMappingStaticService.deepCopy(
                this.quote
            );
            this.router.navigate(["/dashboard/quotes/single/edit"]);
        } else {
            GlobalStaticService.autoAnnualQuote = QuoteMappingStaticService.deepCopy(
                this.quote
            );
            this.router.navigate(["/dashboard/quotes/annual/edit"]);
        }
    }
    bindPolicy(): void {
        if (
            !GlobalStaticService.isQuoteBind &&
            this.quote.quoteStatusText === "Quote"
        ) {
            this.beforeBindQuote();
        }
    }
    beforeBindQuote(): void {
        if (this.quoteSrv.embargoCheckQuote(this.quote)) {
            GlobalStaticService.bindPolicy(this.quote);
        } else {
            this.quoteSrv.embargoReferral(this.quote);
        }
    }
    beforeEmail(): void {
        if (
            !GlobalStaticService.isQuoteBind &&
            this.quote.quoteStatusText === "Quote"
        ) {
            if (this.quoteSrv.embargoCheckQuote(this.quote)) {
                GlobalStaticService.openEmailQuoteModal();
            } else {
                this.quoteSrv.embargoReferral(this.quote);
            }
        }
    }
}
