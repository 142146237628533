import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { Quote } from "../../models/quote";
import { GlobalStaticService } from "../../static-services/global.static-service";

@Component({
    selector: "app-quote-occupied-structures-component",
    templateUrl: "./quote-occupied-structures.component.html",
    styleUrls: ["./quote-occupied-structures.component.scss"]
})
export class QuoteOccupiedStructuresComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor() {}
    ngOnChanges(): void {}
    changeOccupiedStructures(): void {
        this.quote.errorOccupiedStructure = false;
        this.continueEventEmitter.emit({
            finished: true
        });
    }
}
