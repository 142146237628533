import { Injectable } from "@angular/core";

import { Quote } from "../models/quote";
import { Rating } from "../models/rating";
import { SingleQuote } from "../models/single-quote";
import { AnnualQuote } from "../models/annual-quote";
import { QUOTE_MAPPING } from "../configs/quote-mapping";
import { GlobalStaticService } from "./global.static-service";
import { QUOTE_REFERRAL } from "../configs/quote-referral";
import { CommonUtility } from "../utilities/common.utility";
import { LookupService } from "../services/lookup.service";
import { Template } from "../models/template";

@Injectable({
    providedIn: "root"
})
export class QuoteMappingStaticService {
    static singleLookups: any;
    static annualLookups: any;
    static singleLegacyLookups: any;
    static annualLegacyLookups: any;
    static embargo: any;
    static above25th: any;
    static projectType: any;
    constructor() { }

    static deepCopy(quote: Quote): any {
        return CommonUtility.cloneQuote(quote);
    }
    static deepCopyTemplate(template: Template): any {
        let quote = CommonUtility.cloneQuote(template.quote);
        delete quote['_scheme'];
        delete quote.insuredDeclaration;
        delete quote.insuredDeclarationDetails;
        delete quote.insuredDeclarationModalViewed;
        delete quote.unlicensedBuilder;
        delete quote.licenseLending;
        delete quote.errorBuildingLicense;
        delete quote.buildingLicenseReferral;
        delete quote._attachDeclarationConfirmed;
console.log("loaded", quote);
        quote.page1Status = quote.page1Status.filter((v: any) => {
            // decalration
            //return v !== 5
            return v !== 4
                // building license
                //&& v !== 3
                // name
                && v !== 1
        });
        return quote;
    }
    static mapOption(
        isPost: boolean,
        group: string,
        search: any,
        needKey?: number,
        lookups?: any
    ): any {
        if (isPost) {
// debug this failure
console.log("QUOTE_MAPPING ", QUOTE_MAPPING)
console.log("[group]", group)
console.log("[search]", search)
console.log("QUOTE_MAPPING[group][search]", QUOTE_MAPPING[group][search])
            return QUOTE_MAPPING[group][search];
            //return QUOTE_MAPPING[group][search] ?? 0;
        } else {
            for (const key in QUOTE_MAPPING[group]) {
                if (typeof QUOTE_MAPPING[group][key] === "number") {
                    if (QUOTE_MAPPING[group][key] === Number(search)) {
                        return key;
                    }
                } else {
                    for (let i = 0; i < QUOTE_MAPPING[group][key].length; i++) {
                        if (QUOTE_MAPPING[group][key][i] === Number(search)) {
                            return key;
                        }
                    }
                }
            }
        }
    }
    static mapBoolean(isPost: boolean, search: any): any {
        if (isPost) {
            return search
                ? QUOTE_MAPPING.boolean.yes
                : QUOTE_MAPPING.boolean.no;
        } else {
            return Number(search) === QUOTE_MAPPING.boolean.yes;
        }
    }
    static mapPostMd(search: any): any {
        return search.indexOf("md") !== -1;
    }
    static mapPostL(search: any): any {
        return search.indexOf("l") !== -1;
    }
    static mapGetInsuranceType(md: boolean, l: boolean): string {
        if (md && l) {
            return "mdl";
        }
        if (md) {
            return "md";
        }
        if (l) {
            return "l";
        }
    }
    static mapPostCurrency(value: string): number {
        if (value === "Not Covered") {
            return 0;
        } else {
            return GlobalStaticService.numberFormat(value);
        }
    }
    static processPostSingle(quote: Quote): any {
        console.log("processPostSingle", quote);
        const singleQuote = new SingleQuote();
        // InsuredName
        singleQuote.briefDescription = quote.briefDescription;
        singleQuote.insuredName = quote.insuredName;
        singleQuote.clientId = quote.client.clientId;
        // InsuredType
        singleQuote.needs["115623"] = QuoteMappingStaticService.mapOption(
            true,
            "insuredType",
            quote.insuredType
        );
        // Building License
        // Who knows which fields they are as there are multiple (products needs definition)... send them all!!!!
    /*
        singleQuote.needs["117581"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.unlicensedBuilder
        );
        singleQuote.needs["117588"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.unlicensedBuilder
        );
        // Building License - license lending
        singleQuote.needs["117582"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.licenseLending
        );
        singleQuote.needs["117583"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.licenseLending
        );
        singleQuote.needs["117584"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.licenseLending
        );
        singleQuote.needs["117589"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.licenseLending
        );
        singleQuote.needs["117590"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.licenseLending
        );
        singleQuote.needs["117591"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.licenseLending
        );
    */
        // BusinessOfInsured
        if (quote.businessTypeId) {
            singleQuote.needs["115659"] = quote.businessTypeId;
            singleQuote.needs["115660"] = "";
        } else {
            singleQuote.needs["115659"] = 0;
            singleQuote.needs["115660"] = quote.businessType;
        }
        // InsuredDeclaration
        singleQuote.needs["116163"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.insuredDeclaration
        );
        singleQuote.needs["116164"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.insuredDeclaration
        );
        singleQuote.needs["116165"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.insuredDeclaration
        );
        singleQuote.needs["116166"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.insuredDeclaration
        );
        singleQuote.needs["116167"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.insuredDeclaration
        );
        singleQuote.needs["117507"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.insuredDeclaration
        );
        singleQuote.needs["117508"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.insuredDeclaration
        );
        singleQuote.needs["117509"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.insuredDeclaration
        );
        singleQuote.needs["116193"] = quote.insuredDeclarationDetails || "";
        // InsuranceType
        singleQuote.needs["115668"] = QuoteMappingStaticService.mapPostMd(
            quote.insuranceType
        );
        singleQuote.needs["115669"] = QuoteMappingStaticService.mapPostL(
            quote.insuranceType
        );
        // PolicyDate
        if (quote.quoteDate) {
            singleQuote.quoteDate = quote.quoteDate;
        }
        if (quote.quoteExpiryDate) {
            singleQuote.quoteExpiryDate = quote.quoteExpiryDate;
        }
        singleQuote.inceptionDate = `${quote.policyStart.getFullYear()}-${quote.policyStart.getMonth() +
            1}-${quote.policyStart.getDate()}`;
        singleQuote.effectiveDate = `${quote.policyStart.getFullYear()}-${quote.policyStart.getMonth() +
            1}-${quote.policyStart.getDate()}`;
        singleQuote.termExpiryDate = `${quote.policyEnd.getFullYear()}-${quote.policyEnd.getMonth() +
            1}-${quote.policyEnd.getDate()}`;
        // DefectsLiabilityPeriod
        singleQuote.needs["115626"] = quote.defectsLiabilityPeriodId || 0;
        // ProjectKind
        singleQuote.needs["115661"] = QuoteMappingStaticService.mapOption(
            true,
            "projectType",
            quote.projectType
        );
        if (quote.projectType === "other") {
            singleQuote.needs["115621"] = quote.projectTypeDetails;
        } else {
            singleQuote.needs["115621"] = "";
        }
        // Address
        const addressArray = quote.address.split(",");
        let address = "";
        for (let i = 0; i < addressArray.length; i++) {
            if (i !== addressArray.length - 1) {
                if (!address) {
                    address = addressArray[i];
                } else {
                    address = address + "," + addressArray[i];
                }
            }
        }
        singleQuote.needs["115624"] = address;
        // town id
        singleQuote.needs["115665"] = quote.suburbId;
        // state id
        singleQuote.needs["115633"] = quote.suburbData.stateId;
        // postcode
        singleQuote.needs["115632"] = quote.suburbData.postcode;
        // state name
        singleQuote.needs["115634"] = quote.suburbData.stateName;
        // town name
        singleQuote.needs["115631"] = quote.suburbData.suburb;
        // state code
        singleQuote.needs["117064"] = quote.suburbData.state;
        // OngoingWork
        singleQuote.needs["115662"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.ongoingWork
        );
        // OccupiedStructure
        singleQuote.needs["115733"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.occupiedStructures
        );
        // ProjectInvolvement
        singleQuote.needs["115736"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.pit1q2
        );
        singleQuote.needs["115737"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.pit1q3
        );
        singleQuote.needs["115738"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.pit1q4
        );
        singleQuote.needs["115739"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.pit2q1
        );
        singleQuote.needs["115740"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.pit2q2
        );
        singleQuote.needs["115741"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.pit2q3
        );
        singleQuote.needs["115664"] = quote.pit2q3
            ? QuoteMappingStaticService.mapOption(
                true,
                "maximumExcavationDepth",
                "6-10"
            )
            : QuoteMappingStaticService.mapOption(
                true,
                "maximumExcavationDepth",
                "0-3"
            );
        if (quote.insuredType !== "ownerBuilder") {
            singleQuote.needs["115742"] = QuoteMappingStaticService.mapBoolean(
                true,
                quote.pit2q4
            );
            singleQuote.needs["115743"] = QuoteMappingStaticService.mapBoolean(
                true,
                quote.pit3q1
            );
            singleQuote.needs["115744"] = QuoteMappingStaticService.mapBoolean(
                true,
                quote.pit3q2
            );
            singleQuote.needs["115745"] = QuoteMappingStaticService.mapBoolean(
                true,
                quote.pit3q3
            );
            singleQuote.needs["115746"] = QuoteMappingStaticService.mapBoolean(
                true,
                quote.pit3q4
            );
            singleQuote.needs["115747"] = QuoteMappingStaticService.mapBoolean(
                true,
                quote.pit4q1
            );
            singleQuote.needs["115748"] = QuoteMappingStaticService.mapBoolean(
                true,
                quote.pit4q2
            );
            singleQuote.needs["115749"] = QuoteMappingStaticService.mapBoolean(
                true,
                quote.pit4q3
            );
            singleQuote.needs["115750"] = QuoteMappingStaticService.mapBoolean(
                true,
                quote.pit4q4
            );
            singleQuote.needs["117512"] = QuoteMappingStaticService.mapBoolean(
                true,
                quote.pit5q1
            );
            singleQuote.needs["117513"] = QuoteMappingStaticService.mapBoolean(
                true,
                quote.pit5q2
            );
        } else {
            singleQuote.needs["115742"] = 0;
            singleQuote.needs["115743"] = 0;
            singleQuote.needs["115744"] = 0;
            singleQuote.needs["115745"] = 0;
            singleQuote.needs["115746"] = 0;
            singleQuote.needs["115747"] = 0;
            singleQuote.needs["115748"] = 0;
            singleQuote.needs["115749"] = 0;
            singleQuote.needs["115750"] = 0;
            singleQuote.needs["117512"] = 0;
            singleQuote.needs["117513"] = 0;
        }
        singleQuote.needs["116168"] = quote.piDetails || "";
        // MaterialDamage
        if (quote.insuranceType !== "l") {
            singleQuote.needs["115671"] = Number(quote.projectCost);
            singleQuote.needs["115672"] = Number(quote.principalSupplied) || 0;
            singleQuote.needs["115673"] = Number(quote.existingStructures) || 0;
            singleQuote.needs["115674"] = Number(quote.contractorsPlant) || 0;
            if (quote.existingStructures) {
                singleQuote.needs[
                    "115684"
                ] = QuoteMappingStaticService.mapBoolean(
                    true,
                    quote.existingStructuresA
                );
                singleQuote.needs[
                    "115686"
                ] = QuoteMappingStaticService.mapOption(
                    true,
                    "existingStructuresB",
                    quote.existingStructuresB
                );
                singleQuote.needs[
                    "117578"
                ] = QuoteMappingStaticService.mapBoolean(
                    true,
                    quote.existingStructuresC
                );
                singleQuote.needs[
                    "117579"
                ] = QuoteMappingStaticService.mapBoolean(
                    true,
                    quote.existingStructuresD
                );
            } else {
                singleQuote.needs["115684"] = 0;
                singleQuote.needs["115686"] = 0;
            }
        }
        // Liability
        if (quote.insuranceType !== "md") {
            singleQuote.needs["115690"] = quote.publicLiabilityId;
            singleQuote.needs["115692"] =
                quote.vibrationWeakeningRemovalOfSupportId || 0;
            singleQuote.needs["115693"] =
                quote.propertyInCareCustodyControlId || 0;
        } else {
            singleQuote.needs["115690"] = 0;
            singleQuote.needs["115692"] = 0;
            singleQuote.needs["115693"] = 0;
        }
        // TailorYourCover
// There's no "l" or "liability only" insurance type anymore.
        if (quote.insuranceType !== "l") {
            if (!GlobalStaticService.scheme) {
                singleQuote.needs["tailorYourCoverMd"] = [
                    {
                        "115689": QuoteMappingStaticService.mapOption(
                            true,
                            "tailorYourCoverMd",
                            "default"
                        ),
                        "115688": QuoteMappingStaticService.mapOption(
                            true,
                            "tailorYourCoverMdValue",
                            quote.tailorYourCover.toString()
                        )
                    },
                    // As per karen request - no major excess for non-scheme broker
                    // {
                    //     "115689": QuoteMappingStaticService.mapOption(
                    //         true,
                    //         "tailorYourCoverMd",
                    //         "b"
                    //     ),
                    //     "115688": QuoteMappingStaticService.mapOption(
                    //         true,
                    //         "tailorYourCoverMdValue",
                    //         quote.tailorYourCover.toString()
                    //     )
                    // }
                ];
            } else {
                singleQuote.needs["tailorYourCoverMd"] = [];
                for (
                    let i = 0;
                    i < QUOTE_REFERRAL.excessAmtAmountLkv.md.length;
                    i++
                ) {
                    let value, des;
                    for (
                        let j = 0;
                        j < GlobalStaticService.quoteOptionsMDValue.length;
                        j++
                    ) {
                        if (
                            Number(QUOTE_REFERRAL.excessAmtAmountLkv.md[i].value) ===
                            Number(GlobalStaticService.quoteOptionsMDValue[j].valueId)
                        ) {
                            value = GlobalStaticService.quoteOptionsMDValue[j].valueId;
                            break;
                        }
                    }
                    for (
                        let j = 0;
                        j < GlobalStaticService.quoteOptionsMDDes.length;
                        j++
                    ) {
                        if (
                            Number(QUOTE_REFERRAL.excessAmtDescLkv.md[i].value) ===
                            Number(GlobalStaticService.quoteOptionsMDDes[j].valueId)
                        ) {
                            des = GlobalStaticService.quoteOptionsMDDes[j].valueId;
                            break;
                        }
                    }
                    singleQuote.needs["tailorYourCoverMd"].push({
                        "115689": des,
                        "115688": value
                    });
                }
            }
        }
        // Material damage only
        if (quote.insuranceType !== "md") {
            if (!GlobalStaticService.scheme) {
                // refer: UND-959
                // singleQuote.needs["tailorYourCoverL"] = [
                //     {
                //         "115705": QuoteMappingStaticService.mapOption(
                //             true,
                //             "tailorYourCoverL",
                //             "a"
                //         ),
                //         "115704": QuoteMappingStaticService.mapOption(
                //             true,
                //             "tailorYourCoverLValue",
                //             quote.tailorYourCover.toString()
                //         )
                //     }
                // ];
            } else {
                //singleQuote.needs["tailorYourCoverL"] = [];
                for (
                    let i = 0;
                    i < QUOTE_REFERRAL.excessAmtAmountLkv.pl.length;
                    i++
                ) {
                    let value, des;
                    for (
                        let j = 0;
                        j < GlobalStaticService.quoteOptionsPLValue.length;
                        j++
                    ) {
                        if (
                            Number(QUOTE_REFERRAL.excessAmtAmountLkv.pl[i].value ) === Number( GlobalStaticService.quoteOptionsPLValue[j].valueId )
                        ) {
                            value = GlobalStaticService.quoteOptionsPLValue[j].valueId;
                            break;
                        }
                    }
                    for (
                        let j = 0;
                        j < GlobalStaticService.quoteOptionsPLDes.length;
                        j++
                    ) {
                        if (
                            Number(QUOTE_REFERRAL.excessAmtDescLkv.pl[i].value) === Number(GlobalStaticService.quoteOptionsPLDes[j].valueId)
                        ) {
                            des = GlobalStaticService.quoteOptionsPLDes[j].valueId;
                            break;
                        }
                    }
                    // refer: UND-959
                    // singleQuote.needs["tailorYourCoverL"].push({
                    //     "115705": des,
                    //     "115704": value
                    // });
                }
            }
        } else {
            singleQuote.needs["tailorYourCoverL"] = [];
        }
        // EnhanceYourCover
        // Contractors Pollution Liability
        singleQuote.needs["115698"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.cover1
        );
        if (quote.cover1 && quote.insuranceType !== "md") {
            for (let i = 0; i < quote.cplOption.length; i++) {
                if (quote.cpl === Number(quote.cplOption[i].value)) {
                    singleQuote.needs["115699"] = quote.cplOption[i].valueId;
                }
            }
        } else {
            singleQuote.needs["115699"] = 0;
        }
        singleQuote.needs["115751"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.enhance1A
        );
        singleQuote.needs["115752"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.enhance1B
        );
        singleQuote.needs["115753"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.enhance1C
        );
        singleQuote.needs["115757"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.enhance1D
        );
        singleQuote.needs["115708"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.cover2
        );
        singleQuote.needs["115709"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.cover3
        );
        singleQuote.needs["115710"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.cover4
        );
        singleQuote.needs["115711"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.enhance4A
        );
        singleQuote.needs["115712"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.cover5
        );
        singleQuote.needs["115713"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.cover6
        );
        // BrokerCharge
        singleQuote.needs["116169"] = Number(quote.brokerFee) || 0;
        if (quote.brokerCommissionId) {
            singleQuote.needs["116195"] = quote.brokerCommissionId;
        }
        // Broker scheme !!!
        singleQuote.needs["116170"] = quote.brokerReference || "";
        // BrokerComment
        singleQuote.needs["115756"] = quote.brokerComments || "";
        // Referral Lock
        singleQuote.needs["115670"] = true;
        singleQuote._referral = !!quote.referral.length;
        // Send the referral question codes so we can manually trigger referrals
        singleQuote._referrals = quote.referral;

console.log("singleQuote", singleQuote)

        return singleQuote;
    }
    static processPostAnnual(quote: Quote): any {
        console.log("processPostAnnual", quote);
        const annualQuote = new AnnualQuote();
        // InsuredName
        annualQuote.briefDescription = quote.briefDescription;
        annualQuote.insuredName = quote.insuredName;
        annualQuote.clientId = quote.client.clientId;
        // InsuredType
        annualQuote.needs["116215"] = QuoteMappingStaticService.mapOption(
            true,
            "insuredType",
            quote.insuredType
        );
        // BusinessOfInsured
        if (quote.businessTypeId) {
            annualQuote.needs["116251"] = quote.businessTypeId;
            annualQuote.needs["116252"] = "";
        } else {
            annualQuote.needs["116251"] = 0;
            annualQuote.needs["116252"] = quote.businessType;
        }
        // Building License
        // Who knows which fields they are as there are multiple (products needs definition)... send them all!!!!
    /*
        annualQuote.needs["117576"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.unlicensedBuilder
        );
        annualQuote.needs["117577"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.unlicensedBuilder
        );
    */
        // InsuredDeclaration
        annualQuote.needs["116753"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.insuredDeclaration
        );
        annualQuote.needs["116754"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.insuredDeclaration
        );
        annualQuote.needs["116755"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.insuredDeclaration
        );
        annualQuote.needs["116756"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.insuredDeclaration
        );
        annualQuote.needs["116757"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.insuredDeclaration
        );
        annualQuote.needs["117504"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.insuredDeclaration
        );
        annualQuote.needs["117505"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.insuredDeclaration
        );
        annualQuote.needs["117506"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.insuredDeclaration
        );
        annualQuote.needs["116783"] = quote.insuredDeclarationDetails || "";
        // InsuranceType
        annualQuote.needs["116260"] = QuoteMappingStaticService.mapPostMd(
            quote.insuranceType
        );
        annualQuote.needs["116261"] = QuoteMappingStaticService.mapPostL(
            quote.insuranceType
        );
        // PolicyDate
        if (quote.quoteDate) {
            annualQuote.quoteDate = quote.quoteDate;
        }
        if (quote.quoteExpiryDate) {
            annualQuote.quoteExpiryDate = quote.quoteExpiryDate;
        }
        annualQuote.effectiveDate = `${quote.policyStart.getFullYear()}-${quote.policyStart.getMonth() +
            1}-${quote.policyStart.getDate()}`;
        annualQuote.inceptionDate = `${quote.policyStart.getFullYear()}-${quote.policyStart.getMonth() +
            1}-${quote.policyStart.getDate()}`;
        annualQuote.termExpiryDate = `${quote.policyEnd.getFullYear()}-${quote.policyEnd.getMonth() +
            1}-${quote.policyEnd.getDate()}`;
        // Maximum project duration
        annualQuote.needs["116796"] = quote.maximumProjectDurationId;
        // DefectsLiabilityPeriod
        annualQuote.needs["116218"] = quote.defectsLiabilityPeriodId || 0;
        // ProjectKind
        const projectType = [];
        let projectTypeOther = "";
        if (quote.projectTypeNRB) {
            projectType.push({
                "116802": QuoteMappingStaticService.mapOption(
                    true,
                    "projectType",
                    "nrb"
                ),
                "116803": quote.projectTypePercentNRB
            });
            projectTypeOther = "New Residential Building";
        }
        if (quote.projectTypeNCIB) {
            projectType.push({
                "116802": QuoteMappingStaticService.mapOption(
                    true,
                    "projectType",
                    "ncib"
                ),
                "116803": quote.projectTypePercentNCIB
            });
            if (projectTypeOther) {
                projectTypeOther =
                    projectTypeOther + ", New Commercial/Industrial Building";
            } else {
                projectTypeOther = "New Commercial/Industrial Building";
            }
        }
        if (quote.projectTypeNSRFA) {
            projectType.push({
                "116802": QuoteMappingStaticService.mapOption(
                    true,
                    "projectType",
                    "nsrfa"
                ),
                "116803": quote.projectTypePercentNSRFA
            });
            if (projectTypeOther) {
                projectTypeOther =
                    projectTypeOther +
                    ", Non Structural Refurb/Fitout Alterations";
            } else {
                projectTypeOther = "Non Structural Refurb/Fitout Alterations";
            }
        }
        if (quote.projectTypeAR) {
            projectType.push({
                "116802": QuoteMappingStaticService.mapOption(
                    true,
                    "projectType",
                    "ar"
                ),
                "116803": quote.projectTypePercentAR
            });
            if (projectTypeOther) {
                projectTypeOther =
                    projectTypeOther + ", Alterations to Residential";
            } else {
                projectTypeOther = "Alterations to Residential";
            }
        }
        if (quote.projectTypeACIB) {
            projectType.push({
                "116802": QuoteMappingStaticService.mapOption(
                    true,
                    "projectType",
                    "acib"
                ),
                "116803": quote.projectTypePercentACIB
            });
            if (projectTypeOther) {
                projectTypeOther =
                    projectTypeOther +
                    ", Alterations to Commercial/Industrial Building";
            } else {
                projectTypeOther =
                    "Alterations to Commercial/Industrial Building";
            }
        }
        if (quote.projectTypeOther) {
            projectType.push({
                "116802": QuoteMappingStaticService.mapOption(
                    true,
                    "projectType",
                    "other"
                ),
                "116803": quote.projectTypePercentOther
            });
            if (projectTypeOther) {
                annualQuote.needs["116213"] =
                    projectTypeOther + ", Other - " + quote.projectTypeDetails;
            } else {
                annualQuote.needs["116213"] =
                    "Other - " + quote.projectTypeDetails;
            }
        }
        if (projectType.length === 1) {
            projectType[0]["116803"] = 100;
        }
        annualQuote.needs["projectType"] = projectType;
        // WorkLocation
        annualQuote.needs["116818"] = quote.workLocationNT || 0;
        annualQuote.needs["116814"] = 0;
        annualQuote.needs["116816"] = quote.workLocationQLDa || 0;
        annualQuote.needs["116812"] = quote.workLocationQLDb || 0;
        annualQuote.needs["116817"] = quote.workLocationWAa || 0;
        annualQuote.needs["116813"] = quote.workLocationWAb || 0;
        annualQuote.needs["116815"] = quote.workLocationSA || 0;
        annualQuote.needs["116810"] = quote.workLocationVIC || 0;
        annualQuote.needs["116808"] = quote.workLocationNSW || 0;
        annualQuote.needs["116809"] = quote.workLocationACT || 0;
        annualQuote.needs["116811"] = quote.workLocationTAS || 0;

        // MajorityOfWork
        annualQuote.needs["116257"] = quote.suburbId;
        // state id
        annualQuote.needs["116225"] = quote.suburbData.stateId;
        // postcode
        annualQuote.needs["116224"] = quote.suburbData.postcode;
        // state code
        annualQuote.needs["116226"] = quote.suburbData.state;
        // town name
        annualQuote.needs["116223"] = quote.suburbData.suburb;
        // Post code town id
        annualQuote.needs["117278"] = quote.suburbId;
        // OngoingWork
        annualQuote.needs["116820"] = QuoteMappingStaticService.mapOption(
            true,
            "ongoingWork",
            quote.ongoingWork
        );
        if (quote.ongoingWork === "run-off") {
            annualQuote.needs["116325"] = QuoteMappingStaticService.mapBoolean(
                true,
                quote.ongoingWorkDetails
            );
        } else {
            annualQuote.needs["116325"] = 0;
        }
        // ProjectInvolvement
        annualQuote.needs["116332"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.pit1q3
        );
        annualQuote.needs["116333"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.pit1q1
        );
        annualQuote.needs["116256"] = quote.pit1q1
            ? QuoteMappingStaticService.mapOption(
                true,
                "maximumExcavationDepth",
                "6-10"
            )
            : QuoteMappingStaticService.mapOption(
                true,
                "maximumExcavationDepth",
                "0-3"
            );
        annualQuote.needs["116334"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.pit1q2
        );
        annualQuote.needs["116335"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.pit1q4
        );
        annualQuote.needs["116336"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.pit2q1
        );
        annualQuote.needs["116337"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.pit2q2
        );
        annualQuote.needs["116338"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.pit2q3
        );
        annualQuote.needs["116339"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.pit2q4
        );
        annualQuote.needs["116340"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.pit3q1
        );
        annualQuote.needs["116342"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.pit3q2
        );
        annualQuote.needs["117061"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.pit3q3
        );
        annualQuote.needs["117510"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.pit5q1
        );
        annualQuote.needs["117511"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.pit5q2
        );
        annualQuote.needs["116758"] = quote.piDetails || "";
        // Premium Deposit
        annualQuote.needs["116821"] = QuoteMappingStaticService.mapOption(
            true,
            "premiumDeposit",
            "a"
        );
        // MaterialDamage
        if (quote.insuranceType !== "l") {
            annualQuote.needs["116954"] = Number(
                quote.materialDamageEstimation
            );
            annualQuote.needs["116263"] = Number(quote.projectCost);
            annualQuote.needs["116264"] = Number(quote.principalSupplied) || 0;
            annualQuote.needs["116265"] = Number(quote.existingStructures) || 0;
            annualQuote.needs["116266"] = Number(quote.contractorsPlant) || 0;
        }
        // Liability
        if (quote.insuranceType !== "md") {
            annualQuote.needs["116956"] = Number(quote.liabilityEstimation);
            annualQuote.needs["116282"] = quote.publicLiabilityId;
            annualQuote.needs["116957"] = quote.productLiabilityId || 0;
            annualQuote.needs["116284"] =
                quote.vibrationWeakeningRemovalOfSupportId || 0;
            annualQuote.needs["116285"] =
                quote.propertyInCareCustodyControlId || 0;
        }
        // TailorYourCover
// There's not even insuranceType "l" anymore...
        if (quote.insuranceType !== "l") {
// schemes won't be used in future...
            if (!GlobalStaticService.scheme) {
                annualQuote.needs["tailorYourCoverMd"] = [
                    {
                        "116281": QuoteMappingStaticService.mapOption(
                            true,
                            "tailorYourCoverMd",
                            "default"
                        ),
                        "116280": QuoteMappingStaticService.mapOption(
                            true,
                            "tailorYourCoverMdValue",
                            quote.tailorYourCover.toString()
                        )
                    }
                    // As per Karen request - no major excess for non-scheme broker
                    // {
                    //     "116281": QuoteMappingStaticService.mapOption(
                    //         true,
                    //         "tailorYourCoverMd",
                    //         "b"
                    //     ),
                    //     "116280": QuoteMappingStaticService.mapOption(
                    //         true,
                    //         "tailorYourCoverMdValue",
                    //         quote.tailorYourCover.toString()
                    //     )
                    // }
                ];
            } else {
                annualQuote.needs["tailorYourCoverMd"] = [];
                for (
                    let i = 0;
                    i < QUOTE_REFERRAL.excessAmtAmountLkv.md.length;
                    i++
                ) {
                    let value, des;
                    for (
                        let j = 0;
                        j < GlobalStaticService.quoteOptionsMDValue.length;
                        j++
                    ) {
                        if (
                            Number(
                                QUOTE_REFERRAL.excessAmtAmountLkv.md[i].value
                            ) ===
                            Number(
                                GlobalStaticService.quoteOptionsMDValue[j]
                                    .valueId
                            )
                        ) {
                            value =
                                GlobalStaticService.quoteOptionsMDValue[j]
                                    .valueId;
                            break;
                        }
                    }
                    for (
                        let j = 0;
                        j < GlobalStaticService.quoteOptionsMDDes.length;
                        j++
                    ) {
                        if (
                            Number(
                                QUOTE_REFERRAL.excessAmtDescLkv.md[i].value
                            ) ===
                            Number(
                                GlobalStaticService.quoteOptionsMDDes[j].valueId
                            )
                        ) {
                            des =
                                GlobalStaticService.quoteOptionsMDDes[j]
                                    .valueId;
                            break;
                        }
                    }
                    annualQuote.needs["tailorYourCoverMd"].push({
                        "116281": des,
                        "116280": value
                    });
                }
            }
        }
        // Material Damage Only
        if (quote.insuranceType !== "md") {
            if (!GlobalStaticService.scheme) {
                // Refer: UND-959
                // annualQuote.needs["tailorYourCoverL"] = [
                //     {
                //         "116297": QuoteMappingStaticService.mapOption(
                //             true,
                //             "tailorYourCoverL",
                //             "a"
                //         ),
                //         "116296": QuoteMappingStaticService.mapOption(
                //             true,
                //             "tailorYourCoverLValue",
                //             quote.tailorYourCover.toString()
                //         )
                //     }
                // ];
            } else {
                annualQuote.needs["tailorYourCoverL"] = [];
                for (
                    let i = 0;
                    i < QUOTE_REFERRAL.excessAmtAmountLkv.pl.length;
                    i++
                ) {
                    let value, des;
                    for (
                        let j = 0;
                        j < GlobalStaticService.quoteOptionsPLValue.length;
                        j++
                    ) {
                        if (
                            Number(
                                QUOTE_REFERRAL.excessAmtAmountLkv.pl[i].value
                            ) ===
                            Number(
                                GlobalStaticService.quoteOptionsPLValue[j]
                                    .valueId
                            )
                        ) {
                            value =
                                GlobalStaticService.quoteOptionsPLValue[j]
                                    .valueId;
                            break;
                        }
                    }
                    for (
                        let j = 0;
                        j < GlobalStaticService.quoteOptionsPLDes.length;
                        j++
                    ) {
                        if (
                            Number(
                                QUOTE_REFERRAL.excessAmtDescLkv.pl[i].value
                            ) ===
                            Number(
                                GlobalStaticService.quoteOptionsPLDes[j].valueId
                            )
                        ) {
                            des =
                                GlobalStaticService.quoteOptionsPLDes[j]
                                    .valueId;
                            break;
                        }
                    }
                    // Refer: UND-959
                    // annualQuote.needs["tailorYourCoverL"].push({
                    //     "116297": des,
                    //     "116296": value
                    // });
                }
            }
        } else {
            annualQuote.needs["tailorYourCoverL"] = [];
        }

        // EnhanceYourCover
        // Contractors Pollution Liability
        annualQuote.needs["116290"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.cover1
        );
        if (quote.cover1 && quote.insuranceType !== "md") {
            for (let i = 0; i < quote.cplOption.length; i++) {
                if (quote.cpl === Number(quote.cplOption[i].value)) {
                    annualQuote.needs["116291"] = quote.cplOption[i].valueId;
                }
            }
        } else {
            annualQuote.needs["116291"] = 0;
        }
        annualQuote.needs["116343"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.enhance1A
        );
        annualQuote.needs["116344"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.enhance1B
        );
        annualQuote.needs["116345"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.enhance1C
        );
        annualQuote.needs["116349"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.enhance1D
        );
        annualQuote.needs["116300"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.cover2
        );
        annualQuote.needs["116301"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.cover3
        );
        annualQuote.needs["116302"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.cover4
        );
        annualQuote.needs["116303"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.enhance4A
        );
        annualQuote.needs["116304"] = QuoteMappingStaticService.mapBoolean(
            true,
            quote.cover5
        );
        // BrokerCharge
        annualQuote.needs["116759"] = quote.brokerFee || 0;
        if (quote.brokerCommissionId) {
            annualQuote.needs["116785"] = quote.brokerCommissionId;
        }
        annualQuote.needs["116760"] = quote.brokerReference || "";
        // BrokerComment
        annualQuote.needs["116348"] = quote.brokerComments || "";
        // Lock Online
        annualQuote.needs["116262"] = true;
        annualQuote._referral = !!quote.referral.length;
        // Send the referral question codes so we can manually trigger referrals
        annualQuote._referrals = quote.referral;
console.log("annualQuote", annualQuote)

        return annualQuote;
    }
    public static getMDExcessList(
        quote: Quote,
        lookupService: LookupService
    ): any[] {
        let vals;
        let descs;
        if (quote.productId == 48) {
            vals = quote.needs[116280];
            descs = quote.needs[116281];
        } else if (quote.productId == 47) {
            vals = quote.needs[115688];
            descs = quote.needs[115689];
        } else {
            throw new Error("not supported");
        }
        return this.getMdPlValues(vals, descs, LookupService.mdExcessAmountTable(), LookupService.mdExcessDescTable(), lookupService);
    }
    public static getPLExcessList(
        quote: Quote,
        lookupService: LookupService
    ): any[] {
        let vals;
        let descs;
        if (quote.productId == 48) {
            vals = quote.needs[116296];
            descs = quote.needs[116297];
        } else if (quote.productId == 47) {
            vals = quote.needs[115704];
            descs = quote.needs[115705];
        } else {
            throw new Error("not supported");
        }
        return this.getMdPlValues(vals, descs, LookupService.plExcessAmountTable(), LookupService.plExcessDescTable(), lookupService);
    }
    public static getCPLExcessList(
        quote: Quote,
        lookupService: LookupService
    ): any[] {
        let vals;
        let descs;
        if (quote.productId == 48) {
            vals = quote.needs[116298];
            descs = quote.needs[116299];
        } else if (quote.productId == 47) {
            vals = quote.needs[115706];
            descs = quote.needs[115707];
        } else {
            throw new Error("not supported");
        }
        return this.getMdPlValues(vals, descs, LookupService.cplExcessAmountTable(), LookupService.cplExcessDescTable(), lookupService);
    }
    private static getMdPlValues(
        valIds: any,
        descIds: any,
        amtTableId: number,
        descTableId: number,
        lookupService: LookupService,
    ): any[] {
        let res: any[] = [];
        for (let key in valIds) {
            if (valIds.hasOwnProperty(key)) {
                let valId = valIds[key];
                if (!valId) break;
                res.push({
                    value: lookupService.get(amtTableId, valId),
                    description: lookupService.get(descTableId, descIds[key])
                })
            }
        }
        return res;
    }
    static processGetSingle(quote: any): any {
        // Territorial Limit
        if (quote.needs["115625"]) {
            quote.territorialLimit = quote.needs["115625"];
            for (
                let i = 0;
                i <
                QuoteMappingStaticService.singleLookups[115625].valueLookups
                    .length;
                i++
            ) {
                if (
                    QuoteMappingStaticService.singleLookups[115625]
                        .valueLookups[i].valueId ===
                    Number(quote.needs["115625"])
                ) {
                    quote.territorialLimitDescription =
                        QuoteMappingStaticService.singleLookups[115625].valueLookups[
                            i
                        ].value;
                }
            }
        }
        // InsuredName
        // InsuredType
        quote.insuredType = QuoteMappingStaticService.mapOption(
            false,
            "insuredType",
            quote.needs["115623"]
        );
        // BusinessOfInsured
        quote.businessTypeId = Number(quote.needs["115659"]);
        for (
            let i = 0;
            i <
            QuoteMappingStaticService.singleLookups[115659].valueLookups.length;
            i++
        ) {
            if (
                QuoteMappingStaticService.singleLookups[115659].valueLookups[i]
                    .valueId === Number(quote.needs["115659"])
            ) {
                quote.businessType =
                    QuoteMappingStaticService.singleLookups[115659].valueLookups[
                        i
                    ].value;
            }
        }
        if (quote.needs["115660"]) {
            quote.businessType = quote.needs["115660"];
        }
        // InsuredDeclaration
        quote.insuredDeclaration = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116163"]
        );
        // InsuranceType
        if (quote.needs["115668"] && quote.needs["115669"]) {
            quote.insuranceType = "mdl";
        } else if (quote.needs["115668"]) {
            quote.insuranceType = "md";
        } else {
            quote.insuranceType = "l";
        }
        // policy date
        if (quote.quoteDate) {
            const quoteDate = quote.quoteDate.split("T")[0].split("-");
            quote.quoteDateDisplay = new Date();
            quote.quoteDateDisplay.setFullYear(Number(quoteDate[0]));
            quote.quoteDateDisplay.setDate(Number(quoteDate[2]));
            quote.quoteDateDisplay.setMonth(Number(Number(quoteDate[1]) - 1));
        }
        if (!quote.effectiveDate) {
            quote.effectiveDate = quote.inceptionDate;
        }
        if (quote.effectiveDate) {
            const startDate = quote.effectiveDate.split("T")[0].split("-");
            quote.policyStart = new Date();
            quote.policyStart.setFullYear(Number(startDate[0]));
            quote.policyStart.setDate(Number(startDate[2]));
            quote.policyStart.setMonth(Number(Number(startDate[1]) - 1));
        }
        if (quote.termExpiryDate) {
            const endDate = quote.termExpiryDate.split("T")[0].split("-");
            quote.policyEnd = new Date();
            quote.policyEnd.setFullYear(Number(endDate[0]));
            quote.policyEnd.setDate(Number(endDate[2]));
            quote.policyEnd.setMonth(Number(Number(endDate[1]) - 1));
        }
        // Defects liability period
        if (quote.needs["115626"]) {
            quote.defectsLiabilityPeriodId = Number(quote.needs["115626"]);
            for (
                let i = 0;
                i <
                QuoteMappingStaticService.singleLookups[115626].valueLookups
                    .length;
                i++
            ) {
                if (
                    QuoteMappingStaticService.singleLookups[115626]
                        .valueLookups[i].valueId ===
                    Number(quote.needs["115626"])
                ) {
                    quote.defectsLiabilityPeriod =
                        QuoteMappingStaticService.singleLookups[115626].valueLookups[
                            i
                        ].value;
                }
            }
        }
        // Project type
        quote.projectType = QuoteMappingStaticService.mapOption(
            false,
            "projectType",
            quote.needs["115661"]
        );
        if (!quote.projectType) {
            quote.projectType = "other";
            for (
                let i = 0;
                i <
                QuoteMappingStaticService.singleLookups[115661].valueLookups
                    .length;
                i++
            ) {
                if (
                    QuoteMappingStaticService.singleLookups[115661]
                        .valueLookups[i].valueId ===
                    Number(quote.needs["115661"])
                ) {
                    quote.projectTypeDetails =
                        QuoteMappingStaticService.singleLookups[115661].valueLookups[
                            i
                        ].value;
                }
            }
        } else if (quote.projectType === "other") {
            quote.projectTypeDetails = quote.needs["115621"];
        }
        // Address !!!
        quote.address = quote.needs["115624"] + ", ";
        quote.suburbId = quote.needs["115665"];
        quote.postcode = quote.needs["115632"];
        quote.town = quote.needs["115631"];
        quote.stateId = quote.needs["115633"];
        // Ongoing work
        quote.ongoingWork = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["115662"]
        );
        // Occupied Structures
        quote.occupiedStructures = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["115733"]
        );
        // Project Involvements
        quote.pit1q2 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["115736"]
        );
        quote.pit1q3 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["115737"]
        );
        quote.pit1q4 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["115738"]
        );
        quote.pit2q1 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["115739"]
        );
        quote.pit2q2 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["115740"]
        );
        quote.pit2q3 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["115741"]
        );
        if (quote.insuredType !== "ownerBuilder") {
            quote.pit2q4 = QuoteMappingStaticService.mapBoolean(
                false,
                quote.needs["115742"]
            );
            quote.pit3q1 = QuoteMappingStaticService.mapBoolean(
                false,
                quote.needs["115743"]
            );
            quote.pit3q2 = QuoteMappingStaticService.mapBoolean(
                false,
                quote.needs["115744"]
            );
            quote.pit3q3 = QuoteMappingStaticService.mapBoolean(
                false,
                quote.needs["115745"]
            );
            quote.pit3q4 = QuoteMappingStaticService.mapBoolean(
                false,
                quote.needs["115746"]
            );
            quote.pit4q1 = QuoteMappingStaticService.mapBoolean(
                false,
                quote.needs["115747"]
            );
            quote.pit4q2 = QuoteMappingStaticService.mapBoolean(
                false,
                quote.needs["115748"]
            );
            quote.pit4q3 = QuoteMappingStaticService.mapBoolean(
                false,
                quote.needs["115749"]
            );
            quote.pit4q4 = QuoteMappingStaticService.mapBoolean(
                false,
                quote.needs["115750"]
            );
            quote.pit5q1 = QuoteMappingStaticService.mapBoolean(
                false,
                quote.needs["117512"]
            );
            quote.pit5q2 = QuoteMappingStaticService.mapBoolean(
                false,
                quote.needs["117513"]
            );
        }
        // MD
        if (quote.insuranceType !== "l") {
            quote.projectCost = Number(quote.needs["115671"]);
            if (quote.needs["115672"]) {
                quote.principalSupplied = Number(quote.needs["115672"]);
            }
            if (quote.needs["115673"]) {
                quote.existingStructures = Number(quote.needs["115673"]);
                quote.existingStructuresA = QuoteMappingStaticService.mapBoolean(
                    false,
                    quote.needs["115684"]
                );
                quote.existingStructuresB = QuoteMappingStaticService.mapOption(
                    false,
                    "existingStructuresB",
                    quote.needs["115686"]
                );
                quote.existingStructuresC = QuoteMappingStaticService.mapBoolean(
                    false,
                    quote.needs["117578"]
                );
                quote.existingStructuresD = QuoteMappingStaticService.mapBoolean(
                    false,
                    quote.needs["117579"]
                );
            }
            if (quote.needs["115674"]) {
                quote.contractorsPlant = Number(quote.needs["115674"]);
            }
        }
        // L
        if (quote.insuranceType !== "md") {
            quote.publicLiabilityId = Number(quote.needs["115690"]);
            for (
                let i = 0;
                i <
                QuoteMappingStaticService.singleLookups[115690].valueLookups
                    .length;
                i++
            ) {
                if (
                    QuoteMappingStaticService.singleLookups[115690]
                        .valueLookups[i].valueId ===
                    Number(quote.needs["115690"])
                ) {
                    quote.publicLiability = Number(
                        QuoteMappingStaticService.singleLookups[115690]
                            .valueLookups[i].value
                    );
                    quote.publicLiability = GlobalStaticService.priceFormat(quote.publicLiability);
                }
            }
            if (quote.needs["115692"]) {
                quote.vibrationWeakeningRemovalOfSupportId = Number(
                    quote.needs["115692"]
                );
                for (
                    let i = 0;
                    i <
                    QuoteMappingStaticService.singleLookups[115692].valueLookups
                        .length;
                    i++
                ) {
                    if (
                        QuoteMappingStaticService.singleLookups[115692]
                            .valueLookups[i].valueId ===
                        Number(quote.needs["115692"])
                    ) {
                        quote.vibrationWeakeningRemovalOfSupport = Number(
                            QuoteMappingStaticService.singleLookups[115692]
                                .valueLookups[i].value
                        );
                        quote.vibrationWeakeningRemovalOfSupport = GlobalStaticService.priceFormat(quote.vibrationWeakeningRemovalOfSupport);
                    }
                }
            }
            if (quote.needs["115693"]) {
                quote.propertyInCareCustodyControlId = Number(
                    quote.needs["115693"]
                );
                for (
                    let i = 0;
                    i <
                    QuoteMappingStaticService.singleLookups[115693].valueLookups
                        .length;
                    i++
                ) {
                    if (
                        QuoteMappingStaticService.singleLookups[115693]
                            .valueLookups[i].valueId ===
                        Number(quote.needs["115693"])
                    ) {
                        quote.propertyInCareCustodyControl = Number(
                            QuoteMappingStaticService.singleLookups[115693]
                                .valueLookups[i].value
                        );
                        quote.propertyInCareCustodyControl = GlobalStaticService.priceFormat(quote.propertyInCareCustodyControl);
                    }
                }
            }
        }
        // TailorYourCover
        if (quote.insuranceType !== "l") {
            quote.tailorYourCover = Number(
                QuoteMappingStaticService.mapOption(
                    false,
                    "tailorYourCoverMdValue",
                    quote.needs["115688"][1]
                )
            );
            quote.tailorYourCoverMdList = [];
            for (const i in quote.needs["115688"]) {
                if (quote.needs["115688"].hasOwnProperty(i)) {
                    let value, description;
                    for (
                        let j = 0;
                        j <
                        QuoteMappingStaticService.singleLookups[115688]
                            .valueLookups.length;
                        j++
                    ) {
                        if (
                            QuoteMappingStaticService.singleLookups[115688]
                                .valueLookups[j].valueId ===
                            Number(quote.needs["115688"][i])
                        ) {
                            value = Number(
                                QuoteMappingStaticService.singleLookups[115688]
                                    .valueLookups[j].value
                            );
                        }
                    }
                    for (
                        let j = 0;
                        j <
                        QuoteMappingStaticService.singleLookups[115689]
                            .valueLookups.length;
                        j++
                    ) {
                        if (
                            QuoteMappingStaticService.singleLookups[115689]
                                .valueLookups[j].valueId ===
                            Number(quote.needs["115689"][i])
                        ) {
                            description =
                                QuoteMappingStaticService.singleLookups[115689]
                                    .valueLookups[j].value;
                        }
                    }
                    quote.tailorYourCoverMdList.push({
                        value: value,
                        description: description
                    });
                }
            }
        }
        if (quote.insuranceType !== "md") {
            quote.tailorYourCover = Number(
                QuoteMappingStaticService.mapOption(
                    false,
                    "tailorYourCoverLValue",
                    quote.needs["115704"][1]
                )
            );

            quote.tailorYourCoverLList = [];
            for (const i in quote.needs["115704"]) {
                if (quote.needs["115704"].hasOwnProperty(i)) {
                    let value, description;
                    for (
                        let j = 0;
                        j <
                        QuoteMappingStaticService.singleLookups[115704]
                            .valueLookups.length;
                        j++
                    ) {
                        if (
                            QuoteMappingStaticService.singleLookups[115704]
                                .valueLookups[j].valueId ===
                            Number(quote.needs["115704"][i])
                        ) {
                            value = Number(
                                QuoteMappingStaticService.singleLookups[115704]
                                    .valueLookups[j].value
                            );
                        }
                    }
                    for (
                        let j = 0;
                        j <
                        QuoteMappingStaticService.singleLookups[115705]
                            .valueLookups.length;
                        j++
                    ) {
                        if (
                            QuoteMappingStaticService.singleLookups[115705]
                                .valueLookups[j].valueId ===
                            Number(quote.needs["115705"][i])
                        ) {
                            description =
                                QuoteMappingStaticService.singleLookups[115705]
                                    .valueLookups[j].value;
                        }
                    }
                    quote.tailorYourCoverLList.push({
                        value: value,
                        description: description
                    });
                }
            }

            quote.tailorYourCoverCPLList = [];
            for (const i in quote.needs["115706"]) {
                if (quote.needs["115706"].hasOwnProperty(i)) {
                    let value, description;
                    for (
                        let j = 0;
                        j <
                        QuoteMappingStaticService.singleLookups[115706]
                            .valueLookups.length;
                        j++
                    ) {
                        if (
                            QuoteMappingStaticService.singleLookups[115706]
                                .valueLookups[j].valueId ===
                            Number(quote.needs["115706"][i])
                        ) {
                            value = Number(
                                QuoteMappingStaticService.singleLookups[115706]
                                    .valueLookups[j].value
                            );
                        }
                    }
                    for (
                        let j = 0;
                        j <
                        QuoteMappingStaticService.singleLookups[115707]
                            .valueLookups.length;
                        j++
                    ) {
                        if (
                            QuoteMappingStaticService.singleLookups[115707]
                                .valueLookups[j].valueId ===
                            Number(quote.needs["115707"][i])
                        ) {
                            description =
                                QuoteMappingStaticService.singleLookups[115707]
                                    .valueLookups[j].value;
                        }
                    }
                    quote.tailorYourCoverCPLList.push({
                        value: value,
                        description: description
                    });
                }
            }
        }
        // EnhanceYourCover
        quote.cover1 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["115698"]
        );
        if (quote.needs["115699"]) {
            for (
                let j = 0;
                j <
                QuoteMappingStaticService.singleLookups[115699].valueLookups
                    .length;
                j++
            ) {
                if (
                    QuoteMappingStaticService.singleLookups[115699]
                        .valueLookups[j].valueId ===
                    Number(quote.needs["115699"])
                ) {
                    quote.cpl = Number(
                        QuoteMappingStaticService.singleLookups[115699]
                            .valueLookups[j].value
                    );
                    break;
                }
            }
            if (!quote.cpl) {
                quote.cpl = 0;
            }
        } else {
            quote.cpl = 0;
        }
        quote.enhance1A = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["115751"]
        );
        quote.enhance1B = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["115752"]
        );
        quote.enhance1C = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["115753"]
        );
        quote.enhance1D = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["115757"]
        );
        quote.cover2 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["115708"]
        );
        quote.cover3 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["115709"]
        );
        quote.cover4 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["115710"]
        );
        quote.enhance4A = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["115711"]
        );
        quote.cover5 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["115712"]
        );
        quote.cover6 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["115713"]
        );
        // Broker Charges
        quote.brokerFee = quote.needs["116169"];
        if (
            GlobalStaticService.brokerProfile.contactCategoryText
                .toLowerCase()
                .indexOf("scheme") !== -1
        ) {
            quote.brokerCommission = "Scheme Commission";
        } else {
            if (quote.option && quote.option.needs["116195"]) {
                quote.brokerCommissionId = quote.option.needs["116195"];
                for (
                    let i = 0;
                    i <
                    QuoteMappingStaticService.singleLookups[116195].valueLookups
                        .length;
                    i++
                ) {
                    if (
                        QuoteMappingStaticService.singleLookups[116195]
                            .valueLookups[i].valueId ===
                        Number(quote.brokerCommissionId)
                    ) {
                        quote.brokerCommission =
                            Number(
                                QuoteMappingStaticService.singleLookups[116195]
                                    .valueLookups[i].value
                            ) *
                            100 +
                            "%";
                    }
                }
            } else {
                quote.brokerCommission = "15%";
            }
        }

        quote.brokerReference = quote.needs["116170"];
        // Broker Comments
        quote.brokerComments = quote.needs["115756"];
        // Referral
        // quote.referralStatus = quote.needs["115670"];
        quote.referralStatus = false;
        // Policy Wording
        if (quote.needs["115622"]) {
            for( let i = 0; i < QuoteMappingStaticService.singleLookups[115622].valueLookups.length; i++ ) {
                if ( QuoteMappingStaticService.singleLookups[115622].valueLookups[i].valueId === Number(quote.needs["115622"]) ) {
                    quote.policyWording = QuoteMappingStaticService.singleLookups[115622].valueLookups[i].value;
                }
            }
        }

console.log("processGetSingle quote", quote)
console.log("processGetSingle quote.needs['115622']", quote.needs["115622"])
console.log("processGetSingle QuoteMappingStaticService.singleLookups[115622]", QuoteMappingStaticService.singleLookups[115622])

        return quote;
    }
    static processGetAnnual(quote: any): any {
        // Territorial Limit
        if (quote.needs["116217"]) {
            quote.territorialLimit = quote.needs["116217"];
            for (let i = 0; i < QuoteMappingStaticService.annualLookups[116217].valueLookups.length; i++ ) {
                if ( QuoteMappingStaticService.annualLookups[116217].valueLookups[i].valueId === Number(quote.needs["116217"]) ) {
                    quote.territorialLimitDescription = QuoteMappingStaticService.annualLookups[116217].valueLookups[i].value;
                }
            }
        }
        // InsuredName
        // InsuredType
        quote.insuredType = QuoteMappingStaticService.mapOption(
            false,
            "insuredType",
            quote.needs["116215"]
        );
        // BusinessOfInsured
        quote.businessTypeId = Number(quote.needs["116251"]);
        for (
            let i = 0;
            i <
            QuoteMappingStaticService.annualLookups[116251].valueLookups.length;
            i++
        ) {
            if (
                QuoteMappingStaticService.annualLookups[116251].valueLookups[i]
                    .valueId === Number(quote.needs["116251"])
            ) {
                quote.businessType =
                    QuoteMappingStaticService.annualLookups[116251].valueLookups[
                        i
                    ].value;
            }
        }
        if (quote.needs["116252"]) {
            quote.businessType = quote.needs["116252"];
        }
        // InsuredDeclaration
        quote.insuredDeclaration = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116753"]
        );
        // InsuranceType
        if (quote.needs["116260"] && quote.needs["116261"]) {
            quote.insuranceType = "mdl";
        } else if (quote.needs["116260"]) {
            quote.insuranceType = "md";
        } else {
            quote.insuranceType = "l";
        }
        if (!quote.effectiveDate) {
            quote.effectiveDate = quote.inceptionDate;
        }
        // policy date
        if (quote.effectiveDate) {
            const startDate = quote.effectiveDate.split("T")[0].split("-");
            quote.policyStart = new Date();
            quote.policyStart.setFullYear(Number(startDate[0]));
            quote.policyStart.setDate(Number(startDate[2]));
            quote.policyStart.setMonth(Number(Number(startDate[1]) - 1));
        }
        if (quote.termExpiryDate) {
            const endDate = quote.termExpiryDate.split("T")[0].split("-");
            quote.policyEnd = new Date();
            quote.policyEnd.setFullYear(Number(endDate[0]));
            quote.policyEnd.setDate(Number(endDate[2]));
            quote.policyEnd.setMonth(Number(Number(endDate[1]) - 1));
        }
        // Maximum Project Duration
        quote.maximumProjectDurationId = Number(quote.needs["116796"]);
        for (
            let i = 0;
            i <
            QuoteMappingStaticService.annualLookups[116796].valueLookups.length;
            i++
        ) {
            if (
                QuoteMappingStaticService.annualLookups[116796].valueLookups[i]
                    .valueId === Number(quote.needs["116796"])
            ) {
                quote.maximumProjectDuration =
                    QuoteMappingStaticService.annualLookups[116796].valueLookups[
                        i
                    ].value;
            }
        }
        // Defects liability period
        if (quote.needs["116218"]) {
            quote.defectsLiabilityPeriodId = Number(quote.needs["116218"]);
            for (
                let i = 0;
                i <
                QuoteMappingStaticService.annualLookups[116218].valueLookups
                    .length;
                i++
            ) {
                if (
                    QuoteMappingStaticService.annualLookups[116218]
                        .valueLookups[i].valueId ===
                    Number(quote.needs["116218"])
                ) {
                    quote.defectsLiabilityPeriod =
                        QuoteMappingStaticService.annualLookups[116218].valueLookups[
                            i
                        ].value;
                }
            }
        }
        // Project type
        let otherPercent = 0;
        let max = 0;
        for (const i in quote.needs["116802"]) {
            if (quote.needs["116802"].hasOwnProperty(i)) {
                switch (Number(quote.needs["116802"][i])) {
                    case QuoteMappingStaticService.mapOption(
                        true,
                        "projectType",
                        "nrb"
                    ):
                        quote.projectTypeNRB = true;
                        quote.projectTypePercentNRB =
                            Number(quote.needs["116803"][i]) * 100;
                        if (max < quote.projectTypePercentNRB) {
                            max = quote.projectTypePercentNRB;
                            quote.maxProjectId = Number(
                                quote.needs["116802"][i]
                            );
                        }
                        break;
                    case QuoteMappingStaticService.mapOption(
                        true,
                        "projectType",
                        "ncib"
                    ):
                        quote.projectTypeNCIB = true;
                        quote.projectTypePercentNCIB =
                            Number(quote.needs["116803"][i]) * 100;
                        if (max < quote.projectTypePercentNCIB) {
                            max = quote.projectTypePercentNCIB;
                            quote.maxProjectId = Number(
                                quote.needs["116802"][i]
                            );
                        }
                        break;
                    case QuoteMappingStaticService.mapOption(
                        true,
                        "projectType",
                        "nsrfa"
                    ):
                        quote.projectTypeNSRFA = true;
                        quote.projectTypePercentNSRFA =
                            Number(quote.needs["116803"][i]) * 100;
                        if (max < quote.projectTypePercentNSRFA) {
                            max = quote.projectTypePercentNSRFA;
                            quote.maxProjectId = Number(
                                quote.needs["116802"][i]
                            );
                        }
                        break;
                    case QuoteMappingStaticService.mapOption(
                        true,
                        "projectType",
                        "ar"
                    ):
                        quote.projectTypeAR = true;
                        quote.projectTypePercentAR =
                            Number(quote.needs["116803"][i]) * 100;
                        if (max < quote.projectTypePercentAR) {
                            max = quote.projectTypePercentAR;
                            quote.maxProjectId = Number(
                                quote.needs["116802"][i]
                            );
                        }
                        break;
                    case QuoteMappingStaticService.mapOption(
                        true,
                        "projectType",
                        "acib"
                    ):
                        quote.projectTypeACIB = true;
                        quote.projectTypePercentACIB =
                            Number(quote.needs["116803"][i]) * 100;
                        if (max < quote.projectTypePercentACIB) {
                            max = quote.projectTypePercentACIB;
                            quote.maxProjectId = Number(
                                quote.needs["116802"][i]
                            );
                        }
                        break;
                    case QuoteMappingStaticService.mapOption(
                        true,
                        "projectType",
                        "other"
                    ):
                    default:
                        quote.projectTypeOther = true;
                        otherPercent =
                            otherPercent +
                            Number(quote.needs["116803"][i]) * 100;
                        if (quote.needs["116213"].indexOf("Other - ") !== -1) {
                            quote.projectTypeDetails = quote.needs[
                                "116213"
                            ].split("- ")[1];
                        } else {
                            quote.projectTypeDetails = quote.needs["116213"];
                        }
                        break;
                }
            }
        }
        quote.projectTypePercentOther = otherPercent;
        if (max < quote.projectTypePercentOther) {
            quote.maxProjectId = QUOTE_MAPPING.projectType.other;
        }
        // WorkLocation
        quote.workLocationNT =
            Number(quote.needs["116818"]) + Number(quote.needs["116814"]);
        quote.workLocationQLDa = Number(quote.needs["116816"]);
        quote.workLocationQLDb = Number(quote.needs["116812"]);
        quote.workLocationWAa = Number(quote.needs["116817"]);
        quote.workLocationWAb = Number(quote.needs["116813"]);
        quote.workLocationSA = Number(quote.needs["116815"]);
        quote.workLocationVIC = Number(quote.needs["116810"]);
        quote.workLocationNSW = Number(quote.needs["116808"]);
        quote.workLocationACT = Number(quote.needs["116809"]);
        quote.workLocationTAS = Number(quote.needs["116811"]);
        // MajorityOfWork
        quote.suburbId = quote.needs["116257"];
        quote.postcode = quote.needs["116224"];
        quote.town = quote.needs["116223"];
        quote.stateId = quote.needs["116225"];
        // Ongoing work
        quote.ongoingWork = QuoteMappingStaticService.mapOption(
            false,
            "ongoingWork",
            quote.needs["116820"]
        );
        quote.ongoingWorkDetails = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116325"]
        );
        // Project Involvements
        quote.pit1q3 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116332"]
        );
        quote.pit1q1 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116333"]
        );
        quote.pit1q2 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116334"]
        );
        quote.pit1q4 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116335"]
        );
        quote.pit2q1 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116336"]
        );
        quote.pit2q2 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116337"]
        );
        quote.pit2q3 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116338"]
        );
        quote.pit2q4 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116339"]
        );
        quote.pit3q1 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116340"]
        );
        quote.pit3q2 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116342"]
        );
        quote.pit3q3 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["117061"]
        );
        quote.pit5q1 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["117510"]
        );
        quote.pit5q2 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["117511"]
        );

        // Premium Deposit
        quote.premiumDeposit = "100% Minimum and Deposit Premium";
        // MD
        if (quote.insuranceType !== "l") {
            quote.materialDamageEstimation = Number(quote.needs["116954"]);
            quote.projectCost = Number(quote.needs["116263"]);
            if (quote.needs["116264"]) {
                quote.principalSupplied = Number(quote.needs["116264"]);
            }
            if (quote.needs["116265"]) {
                quote.existingStructures = Number(quote.needs["116265"]);
            }
            if (quote.needs["116266"]) {
                quote.contractorsPlant = Number(quote.needs["116266"]);
            }
        }
        // L
        if (quote.insuranceType !== "md") {
            quote.liabilityEstimation = Number(quote.needs["116956"]);
            quote.publicLiabilityId = Number(quote.needs["116282"]);
            for (
                let i = 0;
                i <
                QuoteMappingStaticService.annualLookups[116282].valueLookups
                    .length;
                i++
            ) {
                if (
                    QuoteMappingStaticService.annualLookups[116282]
                        .valueLookups[i].valueId ===
                    Number(quote.needs["116282"])
                ) {
                    quote.publicLiability = Number(
                        QuoteMappingStaticService.annualLookups[116282]
                            .valueLookups[i].value
                    );
                    quote.publicLiability = GlobalStaticService.priceFormat(quote.publicLiability);
                }
            }
            if (quote.needs["116284"]) {
                quote.vibrationWeakeningRemovalOfSupportId = Number(
                    quote.needs["116284"]
                );
                for (
                    let i = 0;
                    i <
                    QuoteMappingStaticService.annualLookups[116284].valueLookups
                        .length;
                    i++
                ) {
                    if (
                        QuoteMappingStaticService.annualLookups[116284]
                            .valueLookups[i].valueId ===
                        Number(quote.needs["116284"])
                    ) {
                        quote.vibrationWeakeningRemovalOfSupport = Number(
                            QuoteMappingStaticService.annualLookups[116284]
                                .valueLookups[i].value
                        );
                        quote.vibrationWeakeningRemovalOfSupport = GlobalStaticService.priceFormat(quote.vibrationWeakeningRemovalOfSupport);
                    }
                }
            }
            if (quote.needs["116957"]) {
                quote.productLiabilityId = Number(quote.needs["116957"]);
                for (
                    let i = 0;
                    i <
                    QuoteMappingStaticService.annualLookups[116957].valueLookups
                        .length;
                    i++
                ) {
                    if (
                        QuoteMappingStaticService.annualLookups[116957]
                            .valueLookups[i].valueId ===
                        Number(quote.needs["116957"])
                    ) {
                        quote.productLiability = Number(
                            QuoteMappingStaticService.annualLookups[116957]
                                .valueLookups[i].value
                        );
                        quote.productLiability = GlobalStaticService.priceFormat(quote.productLiability);
                    }
                }
            }
            if (quote.needs["116285"]) {
                quote.propertyInCareCustodyControlId = Number(
                    quote.needs["116285"]
                );
                for (
                    let i = 0;
                    i <
                    QuoteMappingStaticService.annualLookups[116285].valueLookups
                        .length;
                    i++
                ) {
                    if (
                        QuoteMappingStaticService.annualLookups[116285]
                            .valueLookups[i].valueId ===
                        Number(quote.needs["116285"])
                    ) {
                        quote.propertyInCareCustodyControl = Number(
                            QuoteMappingStaticService.annualLookups[116285]
                                .valueLookups[i].value
                        );
                        quote.propertyInCareCustodyControl = GlobalStaticService.priceFormat(quote.propertyInCareCustodyControl);
                    }
                }
            }
        }

        // TailorYourCover
        if (quote.insuranceType !== "l") {
            quote.tailorYourCover = Number(
                QuoteMappingStaticService.mapOption(
                    false,
                    "tailorYourCoverMdValue",
                    quote.needs["116280"][1]
                )
            );
            quote.tailorYourCoverMdList = [];
            for (const i in quote.needs["116280"]) {
                if (quote.needs["116280"].hasOwnProperty(i)) {
                    let value, description;
                    for (
                        let j = 0;
                        j <
                        QuoteMappingStaticService.annualLookups[116280]
                            .valueLookups.length;
                        j++
                    ) {
                        if (
                            QuoteMappingStaticService.annualLookups[116280]
                                .valueLookups[j].valueId ===
                            Number(quote.needs["116280"][i])
                        ) {
                            value = Number(
                                QuoteMappingStaticService.annualLookups[116280]
                                    .valueLookups[j].value
                            );
                        }
                    }
                    for (
                        let j = 0;
                        j <
                        QuoteMappingStaticService.annualLookups[116281]
                            .valueLookups.length;
                        j++
                    ) {
                        if (
                            QuoteMappingStaticService.annualLookups[116281]
                                .valueLookups[j].valueId ===
                            Number(quote.needs["116281"][i])
                        ) {
                            description =
                                QuoteMappingStaticService.annualLookups[116281]
                                    .valueLookups[j].value;
                        }
                    }
                    quote.tailorYourCoverMdList.push({
                        value: value,
                        description: description
                    });
                }
            }
        }
        if (quote.insuranceType !== "md") {
            quote.tailorYourCover = Number(
                QuoteMappingStaticService.mapOption(
                    false,
                    "tailorYourCoverLValue",
                    quote.needs["116296"][1]
                )
            );
            quote.tailorYourCoverLList = [];
            for (const i in quote.needs["116296"]) {
                if (quote.needs["116296"].hasOwnProperty(i)) {
                    let value, description;
                    for (
                        let j = 0;
                        j <
                        QuoteMappingStaticService.annualLookups[116296]
                            .valueLookups.length;
                        j++
                    ) {
                        if (
                            QuoteMappingStaticService.annualLookups[116296]
                                .valueLookups[j].valueId ===
                            Number(quote.needs["116296"][i])
                        ) {
                            value = Number(
                                QuoteMappingStaticService.annualLookups[116296]
                                    .valueLookups[j].value
                            );
                        }
                    }
                    for (
                        let j = 0;
                        j <
                        QuoteMappingStaticService.annualLookups[116297]
                            .valueLookups.length;
                        j++
                    ) {
                        if (
                            QuoteMappingStaticService.annualLookups[116297]
                                .valueLookups[j].valueId ===
                            Number(quote.needs["116297"][i])
                        ) {
                            description =
                                QuoteMappingStaticService.annualLookups[116297]
                                    .valueLookups[j].value;
                        }
                    }
                    quote.tailorYourCoverLList.push({
                        value: value,
                        description: description
                    });
                }
            }
            quote.tailorYourCoverCPLList = [];
            for (const i in quote.needs["116298"]) {
                if (quote.needs["116298"].hasOwnProperty(i)) {
                    let value, description;
                    for (
                        let j = 0;
                        j <
                        QuoteMappingStaticService.annualLookups[116298]
                            .valueLookups.length;
                        j++
                    ) {
                        if (
                            QuoteMappingStaticService.annualLookups[116298]
                                .valueLookups[j].valueId ===
                            Number(quote.needs["116298"][i])
                        ) {
                            value = Number(
                                QuoteMappingStaticService.annualLookups[116298]
                                    .valueLookups[j].value
                            );
                        }
                    }
                    for (
                        let j = 0;
                        j <
                        QuoteMappingStaticService.annualLookups[116299]
                            .valueLookups.length;
                        j++
                    ) {
                        if (
                            QuoteMappingStaticService.annualLookups[116299]
                                .valueLookups[j].valueId ===
                            Number(quote.needs["116299"][i])
                        ) {
                            description =
                                QuoteMappingStaticService.annualLookups[116299]
                                    .valueLookups[j].value;
                        }
                    }
                    quote.tailorYourCoverCPLList.push({
                        value: value,
                        description: description
                    });
                }
            }
        }
        // EnhanceYourCover
        quote.cover1 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116290"]
        );
        if (quote.needs["116291"]) {
            for (
                let j = 0;
                j <
                QuoteMappingStaticService.annualLookups[116291].valueLookups
                    .length;
                j++
            ) {
                if (
                    QuoteMappingStaticService.annualLookups[116291]
                        .valueLookups[j].valueId ===
                    Number(quote.needs["116291"])
                ) {
                    quote.cpl = Number(
                        QuoteMappingStaticService.annualLookups[116291]
                            .valueLookups[j].value
                    );
                    break;
                }
            }
            if (!quote.cpl) {
                quote.cpl = 0;
            }
        } else {
            quote.cpl = 0;
        }
        quote.enhance1A = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116343"]
        );
        quote.enhance1B = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116344"]
        );
        quote.enhance1C = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116345"]
        );
        quote.enhance1D = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116349"]
        );
        quote.cover2 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116300"]
        );
        quote.cover3 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116301"]
        );
        quote.cover4 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116302"]
        );
        quote.enhance4A = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116303"]
        );
        quote.cover5 = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["116304"]
        );
        // Broker Charges
        quote.brokerFee = quote.needs["116759"];
        if (
            GlobalStaticService.brokerProfile.contactCategoryText
                .toString()
                .indexOf("scheme") !== -1
        ) {
            quote.brokerCommission = "Scheme Commission";
        } else {
            if (quote.option && quote.option.needs["116785"]) {
                quote.brokerCommissionId = quote.option.needs["116785"];
                for (
                    let i = 0;
                    i <
                    QuoteMappingStaticService.annualLookups[116785].valueLookups
                        .length;
                    i++
                ) {
                    if (
                        QuoteMappingStaticService.annualLookups[116785]
                            .valueLookups[i].valueId ===
                        Number(quote.brokerCommissionId)
                    ) {
                        quote.brokerCommission =
                            Number(
                                QuoteMappingStaticService.annualLookups[116785]
                                    .valueLookups[i].value
                            ) *
                            100 +
                            "%";
                    }
                }
            } else {
                quote.brokerCommission = "15%";
            }
        }
        quote.brokerReference = quote.needs["116760"];
        // Broker Comments
        quote.brokerComments = quote.needs["116348"];
        // Referral
        // quote.referralStatus = quote.needs["116262"];
        quote.referralStatus = false;
        // Policy Wording
        if (quote.needs["116214"]) {
            for (
                let i = 0;
                i <
                QuoteMappingStaticService.annualLookups[116214].valueLookups
                    .length;
                i++
            ) {
                if (
                    QuoteMappingStaticService.annualLookups[116214]
                        .valueLookups[i].valueId ===
                    Number(quote.needs["116214"])
                ) {
                    quote.policyWording =
                        QuoteMappingStaticService.annualLookups[116214].valueLookups[
                            i
                        ].value;
                }
            }
        }
        return quote;
    }
    static processGetLegacySingle(quote: any): any {
        if (quote.needs["108846"]) {
            quote.territorialLimit = quote.needs["108846"];
            for (
                let i = 0;
                i <
                QuoteMappingStaticService.singleLegacyLookups[108846]
                    .valueLookups.length;
                i++
            ) {
                if (
                    QuoteMappingStaticService.singleLegacyLookups[108846]
                        .valueLookups[i].valueId ===
                    Number(quote.needs["108846"])
                ) {
                    quote.territorialLimitDescription =
                        QuoteMappingStaticService.singleLegacyLookups[108846].valueLookups[
                            i
                        ].value;
                }
            }
        }
        // InsuredType
        quote.insuredType = QuoteMappingStaticService.mapOption(
            false,
            "insuredTypeLegacy",
            quote.needs["105272"]
        );
        // BusinessOfInsured
        for (
            let i = 0;
            i <
            QuoteMappingStaticService.singleLegacyLookups[108926].valueLookups
                .length;
            i++
        ) {
            if (
                QuoteMappingStaticService.singleLegacyLookups[108926]
                    .valueLookups[i].valueId === Number(quote.needs["108926"])
            ) {
                quote.businessType =
                    QuoteMappingStaticService.singleLegacyLookups[108926].valueLookups[
                        i
                    ].value;
            }
        }
        if (quote.needs["108927"]) {
            quote.businessType = quote.needs["108927"];
        }
        // InsuredDeclaration
        quote.insuredDeclaration = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["105273"]
        );
        // InsuranceType
        if (quote.needs["105366"] && quote.needs["105367"]) {
            quote.insuranceType = "mdl";
        } else if (quote.needs["105366"]) {
            quote.insuranceType = "md";
        } else {
            quote.insuranceType = "l";
        }
        // policy date
        if (quote.quoteDate) {
            const quoteDate = quote.quoteDate.split("T")[0].split("-");
            quote.quoteDateDisplay = new Date();
            quote.quoteDateDisplay.setFullYear(Number(quoteDate[0]));
            quote.quoteDateDisplay.setDate(Number(quoteDate[2]));
            quote.quoteDateDisplay.setMonth(Number(Number(quoteDate[1]) - 1));
        }
        if (quote.effectiveDate) {
            const startDate = quote.effectiveDate.split("T")[0].split("-");
            quote.policyStart = new Date();
            quote.policyStart.setFullYear(Number(startDate[0]));
            quote.policyStart.setDate(Number(startDate[2]));
            quote.policyStart.setMonth(Number(Number(startDate[1]) - 1));
        }
        if (quote.termExpiryDate) {
            const endDate = quote.termExpiryDate.split("T")[0].split("-");
            quote.policyEnd = new Date();
            quote.policyEnd.setFullYear(Number(endDate[0]));
            quote.policyEnd.setDate(Number(endDate[2]));
            quote.policyEnd.setMonth(Number(Number(endDate[1]) - 1));
        }
        // Defects liability period
        if (quote.needs["107528"]) {
            quote.defectsLiabilityPeriod = quote.needs["107528"];
        }
        // Project type
        quote.projectType = QuoteMappingStaticService.mapOption(
            false,
            "projectTypeLegacy",
            quote.needs["105365"]
        );
        if (!quote.projectType) {
            quote.projectType = "other";
            for (
                let i = 0;
                i <
                QuoteMappingStaticService.singleLegacyLookups[105365]
                    .valueLookups.length;
                i++
            ) {
                if (
                    QuoteMappingStaticService.singleLegacyLookups[105365]
                        .valueLookups[i].valueId ===
                    Number(quote.needs["105365"])
                ) {
                    quote.projectTypeDetails =
                        QuoteMappingStaticService.singleLegacyLookups[105365].valueLookups[
                            i
                        ].value;
                }
            }
        } else if (quote.projectType === "other") {
            quote.projectTypeDetails = quote.needs["105288"];
        }
        // Address !!!
        quote.address = quote.needs["107525"] + ", ";
        quote.suburbId = quote.needs["114311"];
        // Ongoing work
        quote.ongoingWork = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["107529"]
        );
        // Occupied Structures
        quote.occupiedStructures = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["113208"]
        );
        // MD
        if (quote.insuranceType !== "l") {
            quote.projectCost = Number(quote.needs["107531"]);
            if (quote.needs["108972"]) {
                quote.principalSupplied = Number(quote.needs["108972"]);
            }
            if (quote.needs["108974"]) {
                quote.existingStructures = Number(quote.needs["108974"]);
            }
            if (quote.needs["114297"]) {
                quote.contractorsPlant = Number(quote.needs["114297"]);
            }
        }
        // L
        if (quote.insuranceType !== "md") {
            for (
                let i = 0;
                i <
                QuoteMappingStaticService.singleLegacyLookups[107577]
                    .valueLookups.length;
                i++
            ) {
                if (
                    QuoteMappingStaticService.singleLegacyLookups[107577]
                        .valueLookups[i].valueId ===
                    Number(quote.needs["107577"])
                ) {
                    quote.publicLiability = Number(
                        QuoteMappingStaticService.singleLegacyLookups[107577]
                            .valueLookups[i].value
                    );
                    quote.publicLiability = GlobalStaticService.priceFormat(quote.publicLiability);
                }
            }
            if (quote.needs["107579"]) {
                for (
                    let i = 0;
                    i <
                    QuoteMappingStaticService.singleLegacyLookups[107579]
                        .valueLookups.length;
                    i++
                ) {
                    if (
                        QuoteMappingStaticService.singleLegacyLookups[107579]
                            .valueLookups[i].valueId ===
                        Number(quote.needs["107579"])
                    ) {
                        quote.vibrationWeakeningRemovalOfSupport = Number(
                            QuoteMappingStaticService
                                .singleLegacyLookups[107579].valueLookups[i]
                                .value
                        );
                        quote.vibrationWeakeningRemovalOfSupport = GlobalStaticService.priceFormat(quote.vibrationWeakeningRemovalOfSupport);
                    }
                }
            }
            if (quote.needs["107580"]) {
                quote.propertyInCareCustodyControl = Number(
                    quote.needs["107580"]
                );
                quote.propertyInCareCustodyControl = GlobalStaticService.priceFormat(quote.propertyInCareCustodyControl);
            }
        }
        // TailorYourCover
        if (quote.insuranceType !== "l") {
            quote.tailorYourCover = Number(quote.needs["108685"][1]);
        } else {
            quote.tailorYourCover = Number(quote.needs["108823"][1]);
        }
        // Broker Charges
        quote.brokerFee = quote.needs["114107"];
        if (quote.needs["115468"]) {
            quote.cpl = Number(
                QuoteMappingStaticService.mapOption(
                    false,
                    "cpl",
                    quote.needs["115468"]
                )
            );
        }
        // Rating
        // quote.brokerCommission = quote.needs["116195"];
        return quote;
    }
    static processGetLegacyAnnual(quote: any): any {
        if (quote.needs["112796"]) {
            quote.territorialLimit = quote.needs["112796"];
            for (
                let i = 0;
                i <
                QuoteMappingStaticService.annualLegacyLookups[112796]
                    .valueLookups.length;
                i++
            ) {
                if (
                    QuoteMappingStaticService.annualLegacyLookups[112796]
                        .valueLookups[i].valueId ===
                    Number(quote.needs["112796"])
                ) {
                    quote.territorialLimitDescription =
                        QuoteMappingStaticService.annualLegacyLookups[112796].valueLookups[
                            i
                        ].value;
                }
            }
        }
        // InsuredName
        // InsuredType
        quote.insuredType = QuoteMappingStaticService.mapOption(
            false,
            "insuredTypeLegacy",
            quote.needs["109222"]
        );
        // BusinessOfInsured
        for (
            let i = 0;
            i <
            QuoteMappingStaticService.annualLegacyLookups[112876].valueLookups
                .length;
            i++
        ) {
            if (
                QuoteMappingStaticService.annualLegacyLookups[112876]
                    .valueLookups[i].valueId === Number(quote.needs["112876"])
            ) {
                quote.businessType =
                    QuoteMappingStaticService.annualLegacyLookups[112876].valueLookups[
                        i
                    ].value;
            }
        }
        // details
        if (quote.needs["112877"]) {
            quote.businessType = quote.needs["112877"];
        }
        // InsuredDeclaration
        quote.insuredDeclaration = QuoteMappingStaticService.mapBoolean(
            false,
            quote.needs["109223"]
        );
        // InsuranceType
        if (quote.needs["109316"] && quote.needs["109317"]) {
            quote.insuranceType = "mdl";
        } else if (quote.needs["109316"]) {
            quote.insuranceType = "md";
        } else {
            quote.insuranceType = "l";
        }
        // policy date
        if (quote.effectiveDate) {
            const startDate = quote.effectiveDate.split("T")[0].split("-");
            quote.policyStart = new Date();
            quote.policyStart.setFullYear(Number(startDate[0]));
            quote.policyStart.setDate(Number(startDate[2]));
            quote.policyStart.setMonth(Number(Number(startDate[1]) - 1));
        }
        if (quote.termExpiryDate) {
            const endDate = quote.termExpiryDate.split("T")[0].split("-");
            quote.policyEnd = new Date();
            quote.policyEnd.setFullYear(Number(endDate[0]));
            quote.policyEnd.setDate(Number(endDate[2]));
            quote.policyEnd.setMonth(Number(Number(endDate[1]) - 1));
        }
        // Maximum Project Duration
        for (
            let i = 0;
            i <
            QuoteMappingStaticService.annualLegacyLookups[109254].valueLookups
                .length;
            i++
        ) {
            if (
                QuoteMappingStaticService.annualLegacyLookups[109254]
                    .valueLookups[i].valueId === Number(quote.needs["109254"])
            ) {
                quote.maximumProjectDuration =
                    QuoteMappingStaticService.annualLegacyLookups[109254].valueLookups[
                        i
                    ].value;
            }
        }
        // Defects liability period
        if (quote.needs["109256"]) {
            quote.defectsLiabilityPeriod = quote.needs["109256"];
        }
        // ProjectType
        quote.projectType = QuoteMappingStaticService.mapOption(
            false,
            "projectTypeLegacy",
            quote.needs["109315"]
        );
        if (quote.projectType === "other") {
            quote.projectTypeDetails = quote.needs["114302"];
        }
        // MajorityOfWork
        quote.suburbId = quote.needs["113173"];
        // Ongoing work
        quote.ongoingWork = QuoteMappingStaticService.mapOption(
            false,
            "ongoingWorkLegacy",
            quote.needs["109237"]
        );
        // Premium Deposit
        quote.premiumDeposit = "100% Minimum and Deposit Premium";
        // MD
        if (quote.insuranceType !== "l") {
            quote.materialDamageEstimation = Number(quote.needs["109246"]);
            quote.projectCost = Number(quote.needs["109301"]);
            if (quote.needs["109318"]) {
                quote.principalSupplied = Number(quote.needs["109318"]);
            }
            if (quote.needs["109320"]) {
                quote.existingStructures = Number(quote.needs["109320"]);
            }
            if (quote.needs["114298"]) {
                quote.contractorsPlant = Number(quote.needs["114298"]);
            }
        }
        // L
        if (quote.insuranceType !== "md") {
            quote.liabilityEstimation = Number(quote.needs["109331"]);
            for (
                let i = 0;
                i <
                QuoteMappingStaticService.annualLegacyLookups[109332]
                    .valueLookups.length;
                i++
            ) {
                if (
                    QuoteMappingStaticService.annualLegacyLookups[109332]
                        .valueLookups[i].valueId ===
                    Number(quote.needs["109332"])
                ) {
                    quote.publicLiability = Number(
                        QuoteMappingStaticService.annualLegacyLookups[109332]
                            .valueLookups[i].value
                    );
                    quote.publicLiability = GlobalStaticService.priceFormat(quote.publicLiability);
                }
            }
            if (quote.needs["109334"]) {
                for (
                    let i = 0;
                    i <
                    QuoteMappingStaticService.annualLegacyLookups[109334]
                        .valueLookups.length;
                    i++
                ) {
                    if (
                        QuoteMappingStaticService.annualLegacyLookups[109334]
                            .valueLookups[i].valueId ===
                        Number(quote.needs["109334"])
                    ) {
                        quote.vibrationWeakeningRemovalOfSupport = Number(
                            QuoteMappingStaticService
                                .annualLegacyLookups[109334].valueLookups[i]
                                .value
                        );
                        quote.vibrationWeakeningRemovalOfSupport = GlobalStaticService.priceFormat(quote.vibrationWeakeningRemovalOfSupport);
                    }
                }
            }
            if (quote.needs["109333"]) {
                for (
                    let i = 0;
                    i <
                    QuoteMappingStaticService.annualLegacyLookups[109333]
                        .valueLookups.length;
                    i++
                ) {
                    if (
                        QuoteMappingStaticService.annualLegacyLookups[109333]
                            .valueLookups[i].valueId ===
                        Number(quote.needs["109333"])
                    ) {
                        quote.productLiability = Number(
                            QuoteMappingStaticService
                                .annualLegacyLookups[109333].valueLookups[i]
                                .value
                        );
                        quote.productLiability = GlobalStaticService.priceFormat(quote.productLiability);
                    }
                }
            }
            if (quote.needs["109335"]) {
                quote.propertyInCareCustodyControl = Number(
                    quote.needs["109335"]
                );
                quote.propertyInCareCustodyControl = GlobalStaticService.priceFormat(quote.propertyInCareCustodyControl);
            }
        }
        // TailorYourCover
        if (quote.insuranceType !== "l") {
            quote.tailorYourCover = Number(quote.needs["112635"][1]);
        } else {
            quote.tailorYourCover = Number(quote.needs["112773"][1]);
        }
        // Broker Charges
        quote.brokerFee = quote.needs["114112"];
        if (quote.needs["115482"]) {
            quote.cpl = Number(
                QuoteMappingStaticService.mapOption(
                    false,
                    "cpl",
                    quote.needs["115482"]
                )
            );
        }
        return quote;
    }
    static processRatingSection(rating: any[]): any {
        const result = new Rating();
        for (const i in rating) {
            if (rating.hasOwnProperty(i)) {
                switch (rating[i].TaxFeeId) {
                    case 0:
                        switch (rating[i].SortOrder) {
                            case 0:
                                result.basePremium =
                                    result.basePremium + rating[i].BaseAmount;
                                break;
                            case 6:
                                result.gst = result.gst + rating[i].GSTAmount;
                                result.totalPayable =
                                    result.totalPayable + rating[i].TotalAmount;
                                break;
                            case 9:
                                result.nettToMecon =
                                    result.nettToMecon + rating[i].TotalAmount;
                                break;
                        }
                        break;
                    case 2:
                        switch (rating[i].SortOrder) {
                            case 3:
                                result.fireServiceLevy =
                                    result.fireServiceLevy +
                                    rating[i].BaseAmount;
                                break;
                        }
                        break;
                    case 3:
                        switch (rating[i].SortOrder) {
                            case 3:
                                result.stampDuty =
                                    result.stampDuty + rating[i].BaseAmount;
                                break;
                        }
                        break;
                    case 4:
                        switch (rating[i].SortOrder) {
                            case 5:
                                result.meconAdminFee =
                                    result.meconAdminFee + rating[i].BaseAmount;
                                result.meconAdminFeeGst =
                                    result.meconAdminFeeGst +
                                    rating[i].GSTAmount;
                                break;
                        }
                        break;
                    case 14:
                        switch (rating[i].SortOrder) {
                            case 10:
                                result.brokerCommission =
                                    result.brokerCommission +
                                    rating[i].BaseAmount;
                                result.brokerCommissionGst =
                                    result.brokerCommissionGst +
                                    rating[i].GSTAmount;
                                break;
                        }
                        break;
                }
            }
        }
        return result;
    }
    static projectTypeNameSingle(type: string): string {
        for (
            let i = 0;
            i <
            QuoteMappingStaticService.singleLookups[115661].valueLookups.length;
            i++
        ) {
            if (
                QuoteMappingStaticService.singleLookups[115661].valueLookups[i]
                    .valueId ===
                QuoteMappingStaticService.mapOption(true, "projectType", type)
            ) {
                return QuoteMappingStaticService.singleLookups[115661]
                    .valueLookups[i].value;
            }
        }
        return "";
    }
    static projectTypeNameAnnual(type: string): string {
        for (
            let i = 0;
            i <
            QuoteMappingStaticService.annualLookups[116802].valueLookups.length;
            i++
        ) {
            if (
                QuoteMappingStaticService.annualLookups[116802].valueLookups[i]
                    .valueId ===
                QuoteMappingStaticService.mapOption(true, "projectType", type)
            ) {
                return QuoteMappingStaticService.annualLookups[116802]
                    .valueLookups[i].value;
            }
        }
        return "";
    }
}
