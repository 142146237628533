import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoginFields } from "../../models/login-fields";
import { SignupFields } from "../../models/signup-fields";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";

import { CommonApi } from "../../api/common.api";

import { GlobalStaticService } from "../../static-services/global.static-service";
import { InterfaceStaticService } from "../../static-services/interface.static-service";

import { Broker } from "../../models/broker";

import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/fromEvent";
import "rxjs/add/operator/throttleTime";

import Fingerprint2 from "fingerprintjs2";
declare let window: any;
declare let gtag: any;

@Component({
    selector: "app-login-page-component",
    templateUrl: "./login.page.html",
    styleUrls: ["./login.page.scss"]
})
export class LoginPageComponent implements OnInit, OnDestroy {
    GlobalStaticSrv = GlobalStaticService;
    signupFormHeight: SafeStyle;
    title = "login";
    loginForm: FormGroup;
    loginFields: LoginFields = new LoginFields();
    signupForm: FormGroup;
    resetForm: FormGroup;
    forgotForm: FormGroup;
    signupFields: SignupFields = new SignupFields();
    @ViewChild("login") login: ElementRef;
    fingerprint: string;
    @ViewChild("signup") signup: ElementRef;
    @ViewChild("confirm") confirm: ElementRef;
    @ViewChild("reset") reset: ElementRef;
    @ViewChild("forgot") forgot: ElementRef;
    activePage = "login";
    @ViewChild("header") header: ElementRef;
    signupFormHeightResize$$: any;
    submitting = false;
    resetPassword = false;
    resetPasswordDone = false;
    searchActive = false;
    resultActive = false;
    timeout: any;
    timeoutFlag = false;
    address$$: any;
    addresses: any[] = [];
    namePattern = "^[a-zA-Z\-\']+ ([a-zA-Z\-\' ])+$";
    emailPattern = "^.+@.+\\..+$";
    mobilePattern = /^((\d{4} \d{3} \d{3})|\d{10})$/;
    phonePattern = /^((\d{2} \d{4} \d{4})|\d{10})$/;
    textPattern = /^([a-z]|[A-Z]| |-)+$/;
    upperCasePattern = /[A-Z]/;
    lowerCasePattern = /[a-z]/;
    numericPattern = /[0-9]/;
    session$$: any;
    constructor(
        private fb: FormBuilder,
        private sanitizer: DomSanitizer,
        private router: Router,
        private commonApi: CommonApi
    ) {}

    ngOnInit(): void {
        /* this.commonApi.getGeoLoc().subscribe(res => {
            window.Intercom("shutdown");
            window.Intercom("boot", {
                name: "there",
                app_id: "kxdbx3uz",
                UCState: res['region_code']
            });
        }); */
        setTimeout(() => {
            Fingerprint2.get(components => {
                this.fingerprint = JSON.stringify(components);
            });
        }, 500);
        this.header.nativeElement.classList.add("slideInDown");
        this.resetLogin();
        this.resetSignup();
        this.resetForgot();
        this.resetReset();
        this.signupFormHeightResize();
        this.signupFormHeightResize$$ = Observable.fromEvent(window, "resize")
            .throttleTime(20)
            .subscribe(e => {
                this.signupFormHeightResize();
            });
        this.session$$ = Observable.interval(10000).subscribe(e => {
            this.commonApi.getStatus().subscribe(
                res => {
                    if (
                        GlobalStaticService.infoModal.title ===
                        "System is under maintenance"
                    ) {
                        GlobalStaticService.closeInfoModal();
                    }
                },
                err => {
                    if (err.status === 503) {
                        GlobalStaticService.infoModal = {
                            title: "System is under maintenance",
                            content:
                                "The site you're trying to reach is temporarily unavailable. Sorry for the inconvenience. We'll be back up and running as fast as possible.",
                            noClose: true,
                            buttons: []
                        };
                        GlobalStaticService.openInfoModal();
                    }
                }
            );
        });
    }
    ngOnDestroy(): void {
        this.signupFormHeightResize$$.unsubscribe();
        if (this.session$$) {
            this.session$$.unsubscribe();
        }
    }
    resetLogin(): void {
        this.loginForm = this.fb.group({
            email: [
                this.loginFields.email,
                [Validators.required, Validators.pattern(this.emailPattern)]
            ],
            password: [this.loginFields.password, [Validators.required]]
        });
    }
    resetReset(): void {
        this.resetForm = this.fb.group(
            {
                password: [
                    null,
                    [
                        Validators.required,
                        Validators.minLength(8),
                        Validators.pattern(this.upperCasePattern),
                        Validators.pattern(this.lowerCasePattern),
                        Validators.pattern(this.numericPattern)
                    ]
                ],
                repeatPassword: [null, [Validators.required]]
            },
            {
                validator: PasswordValidation.MatchPassword // your validation method
            }
        );
    }
    reformatMobile(form: string): void {
        if (this[form].get("mobile").value) {
            let value = this[form]
                .get("mobile")
                .value.split("")
                .filter(e => {
                    return /\d/.test(e);
                })
                .join("");
            if (value.length === 10) {
                value =
                    value.substr(0, 4) +
                    " " +
                    value.substr(4, 3) +
                    " " +
                    value.substr(7, 3);
            }
            this[form].patchValue({
                mobile: value
            });
        }
    }
    reformatPhone(): void {
        if (this.signupForm.get("phone").value) {
            let value = this.signupForm
                .get("phone")
                .value.split("")
                .filter(e => {
                    return /\d/.test(e);
                })
                .join("");
            if (value.length === 10) {
                value =
                    value.substr(0, 2) +
                    " " +
                    value.substr(2, 4) +
                    " " +
                    value.substr(6, 4);
            }
            this.signupForm.patchValue({
                phone: value
            });
        }
    }
    signupFormHeightResize(): void {
        if (InterfaceStaticService.windowHeight > 1100) {
            this.signupFormHeight = this.sanitizer.bypassSecurityTrustStyle(
                "640px"
            );
        } else {
            this.signupFormHeight = this.sanitizer.bypassSecurityTrustStyle(
                InterfaceStaticService.windowHeight / 2 + 35 + "px"
            );
        }
    }
    enterHomePage(): void {
        GlobalStaticService.setupAutoLogoutAction(this.commonApi);
        gtag("set", { user_id: GlobalStaticService.brokerProfile.contactId });
        //this.router.navigate(["/dashboard/home"]);
        // Using a window.location as this will recheck the session and reload any assets in case there was a deployment...
        window.location.href = "/dashboard/home"
    }
    submitLoginForm(): void {
        if (this.submitting) {
            return;
        }
        this.submitting = true;

        if (!this.submitFormCheck("loginForm")) {
            this.submitting = false;
            return;
        }
        this.commonApi
            .postLogin(
                this.loginForm.get("email").value.toLowerCase(),
                this.loginForm.get("password").value,
                this.fingerprint,
                false
            )
            .subscribe(
                res => {
                    // add ! for test
                    if (res.result) {
                        this.resetPassword = true;
                        this.submitting = false;
                        this.gotoReset();
                    } else {
                        this.enterHomePage();
                    }
                },
                err => {
                    this.submitting = false;

                    switch (err.status) {
                        case 401:
                            switch (err.error.error_code) {
                                case 100:
                                    GlobalStaticService.infoModal = {
                                        title: "WARNING",
                                        content:
                                            "Your email or password is incorrect"
                                    };
                                    GlobalStaticService.openInfoModal();
                                    break;
                                case 200:
                                    GlobalStaticService.infoModal = {
                                        title: "WARNING",
                                        content:
                                            "We're sorry, but this IP has been blocked due to the recent failed login attempts. Please try again in 5 minutes. Please contact MECON support if you have any questions."
                                    };
                                    GlobalStaticService.openInfoModal();
                                    break;
                                case 300:
                                    GlobalStaticService.infoModal = {
                                        title: "User already logged in",
                                        content:
                                            "You're currently logged in on another computer or device. Do you wish to terminate your other session so you can login here?",
                                        buttons: [
                                            {
                                                style: "",
                                                text: "Cancel",
                                                callback: () => {
                                                    GlobalStaticService.closeInfoModal();
                                                }
                                            },
                                            {
                                                style: "purple",
                                                text: "OK",
                                                callback: () => {
                                                    this.commonApi
                                                        .postLogin(
                                                            this.loginForm.get(
                                                                "email"
                                                            ).value,
                                                            this.loginForm.get(
                                                                "password"
                                                            ).value,
                                                            this.fingerprint,
                                                            true
                                                        )
                                                        .subscribe(res2 => {
                                                            if (res2.result) {
                                                                this.resetPassword = true;
                                                                this.gotoReset();
                                                            } else {
                                                                this.enterHomePage();
                                                            }
                                                        });
                                                    GlobalStaticService.closeInfoModal();
                                                }
                                            }
                                        ]
                                    };
                                    GlobalStaticService.openInfoModal();
                            }
                            break;
                        case 403:
                            GlobalStaticService.infoModal = {
                                title: "Login Issue",
                                content:
                                    "There has been an issue with your login, please <a href='https://www.mecon.com.au/contact' target='_blank'>contact your local Mecon office</a> for assistance.",
                                buttons: [
                                    {
                                        style: "",
                                        text: "Ok",
                                        callback: () => {
                                            GlobalStaticService.closeInfoModal();
                                        }
                                    }
                                ]
                            };
                            GlobalStaticService.openInfoModal();
                            break;
                        default:
                            GlobalStaticService.openUnexpectedErrorModal();
                            break;
                    }
                }
            );
    }
    submitSignupForm(): void {
        if (!this.submitFormCheck("signupForm")) {
            return;
        }
        if (this.submitting) {
            return;
        }
        this.submitting = true;
        const broker = new Broker();
        broker.fullName = this.signupForm.get("name").value;
        broker.email = this.signupForm.get("email").value.toLowerCase();
        broker.mobile = this.signupForm.get("mobile").value;
        broker.phone = this.signupForm.get("phone").value;
        broker.address = this.signupForm.get("address").value;
        this.commonApi.postBroker(broker).subscribe(
            res => {
                this.submitting = false;
                GlobalStaticService.signupAddressFinished = false;
                this.resetSignup();
                this.gotoConfirmFrom("signup");
            },
            err => {
                this.submitting = false;
                GlobalStaticService.openUnexpectedErrorModal();
            }
        );
    }
    submitResetForm(): void {
        if (!this.submitFormCheck("resetForm")) {
            return;
        }
        if (this.submitting) {
            return;
        }
        this.submitting = true;
        this.commonApi
            .putPassword(
                this.loginForm.get("password").value,
                this.resetForm.get("password").value
            )
            .subscribe(
                res => {
                    this.submitting = false;
                    this.resetPasswordDone = true;
                    this.gotoConfirmFrom("reset");
                },
                err => {
                    this.submitting = false;
                    switch (err.status) {
                        case 400:
                            GlobalStaticService.infoModal = {
                                title: "WARNING",
                                content:
                                    "Password must be different to previous password."
                            };
                            GlobalStaticService.openInfoModal();
                            break;
                        default:
                            GlobalStaticService.openUnexpectedErrorModal();
                            break;
                    }
                }
            );
    }
    submitForgotForm(): void {
        if (!this.submitFormCheck("forgotForm")) {
            return;
        }
        if (this.submitting) {
            return;
        }
        this.submitting = true;
        this.commonApi
            .postForgotPassword(
                this.forgotForm.get("name").value,
                this.forgotForm.get("email").value.toLowerCase(),
                this.forgotForm.get("mobile").value
            )
            .subscribe(
                res => {
                    this.submitting = false;
                    this.gotoConfirmFrom("forgot");
                },
                err => {
                    this.submitting = false;
                    GlobalStaticService.openUnexpectedErrorModal();
                }
            );
    }
    gotoReset(): void {
        this.title = "login";
        this.login.nativeElement.classList.remove("slideInLeft");
        this.login.nativeElement.classList.add("slideOutLeft");
        setTimeout(() => {
            this.activePage = "reset";
            this.reset.nativeElement.classList.remove("slideOutRight");
            this.reset.nativeElement.classList.remove("slideOutLeft");
            this.reset.nativeElement.classList.add("slideInRight");
        }, 350);
    }
    gotoSignup(): void {
        this.title = "signup";
        this.login.nativeElement.classList.remove("slideInLeft");
        this.login.nativeElement.classList.add("slideOutLeft");
        setTimeout(() => {
            this.activePage = "signup";
            this.signup.nativeElement.classList.remove("slideOutRight");
            this.signup.nativeElement.classList.remove("slideOutLeft");
            this.signup.nativeElement.classList.add("slideInRight");
        }, 350);
    }
    gotoForgot(): void {
        this.title = "forgot";
        this.login.nativeElement.classList.remove("slideInLeft");
        this.login.nativeElement.classList.add("slideOutLeft");
        setTimeout(() => {
            this.activePage = "forgot";
            this.forgot.nativeElement.classList.remove("slideOutRight");
            this.forgot.nativeElement.classList.remove("slideOutLeft");
            this.forgot.nativeElement.classList.add("slideInRight");
        }, 350);
    }
    backToLogin(): void {
        this.resetLogin();
        if (this.title === "signup") {
            for (const i in this.signupForm.controls) {
                if (this.signupForm.controls.hasOwnProperty(i)) {
                    if (this.signupForm.controls[i].dirty) {
                        GlobalStaticService.infoModal = {
                            title: "WARNING",
                            content:
                                "Are you sure you want to exit the form without saving? If you do, information will be lost.",
                            buttons: [
                                {
                                    style: "",
                                    text: "OK",
                                    callback: () => {
                                        this.backToLoginAction("signup");
                                        GlobalStaticService.closeInfoModal();
                                    }
                                },
                                {
                                    style: "purple",
                                    text: "Cancel"
                                }
                            ]
                        };
                        GlobalStaticService.openInfoModal();
                        return;
                    }
                }
            }
            this.backToLoginAction("signup");
        } else {
            this.backToLoginAction("forgot");
        }
    }
    resetSignup(): void {
        GlobalStaticService.signupAddressFinished = false;
        this.signupForm = this.fb.group(
            {
                name: [
                    this.signupFields.name,
                    [Validators.required, Validators.pattern(this.textPattern)]
                ],
                email: [
                    this.signupFields.email,
                    [Validators.required, Validators.pattern(this.emailPattern)]
                ],
                mobile: [
                    this.signupFields.mobile,
                    [
                        Validators.required,
                        Validators.pattern(this.mobilePattern)
                    ]
                ],
                phone: [
                    this.signupFields.phone,
                    [Validators.required, Validators.pattern(this.phonePattern)]
                ],
                address: [this.signupFields.address, [Validators.required]],
                agree: [this.signupFields.agree, [Validators.requiredTrue]]
            },
            {
                validator: AddressValidation.Selected // your validation method
            }
        );
    }
    resetForgot(): void {
        this.forgotForm = this.fb.group({
            name: [
                null,
                [Validators.required, Validators.pattern(this.namePattern)]
            ],
            email: [
                null,
                [Validators.required, Validators.pattern(this.emailPattern)]
            ],
            mobile: [
                null,
                [Validators.required, Validators.pattern(this.mobilePattern)]
            ]
        });
    }
    backToLoginAction(page: string): void {
        this.title = "login";
        this[page].nativeElement.classList.remove("slideInRight");
        this[page].nativeElement.classList.add("slideOutRight");
        setTimeout(() => {
            this.activePage = "login";
            this.login.nativeElement.classList.remove("slideInRight");
            this.login.nativeElement.classList.remove("slideOutLeft");
            this.login.nativeElement.classList.add("slideInLeft");
            if (page === "signup") {
                this.resetSignup();
            } else {
                this.resetForgot();
            }
        }, 350);
    }
    gotoConfirmFrom(page: string): void {
        if (page === "reset") {
            this.title = "login";
        } else if (page === "forgot") {
            this.title = "forgot";
        } else {
            this.title = "signup";
        }
        this[page].nativeElement.classList.remove("slideInRight");
        this[page].nativeElement.classList.add("slideOutLeft");
        setTimeout(() => {
            this.activePage = "confirm";
            this.confirm.nativeElement.classList.remove("slideOutRight");
            this.confirm.nativeElement.classList.add("slideInRight");
        }, 350);
    }
    finishToLogin(): void {
        this.title = "login";
        this.confirm.nativeElement.classList.remove("slideInRight");
        this.confirm.nativeElement.classList.add("slideOutRight");
        setTimeout(() => {
            this.activePage = "login";
            this.login.nativeElement.classList.remove("slideOutLeft");
            this.login.nativeElement.classList.remove("slideInLeft");
            this.login.nativeElement.classList.add("slideInLeft");
        }, 350);
    }
    submitFormCheck(form: string): boolean {
        let validation = true;
        for (const i in this[form].controls) {
            if (this[form].controls.hasOwnProperty(i)) {
                this[form].controls[i].markAsDirty();
                if (this[form].controls[i].invalid) {
                    validation = false;
                }
            }
        }
        return validation;
    }
    openTerms(): void {
        GlobalStaticService.infoModal = {
            title: "TERMS AND CONDITIONS",
            content: `<div class="m-modal-frame">
                        Your Duty of Disclosure<br><br>
                        We rely on the information you provide us with to decide whether to insure you and the terms on which we will insure you and you have a Duty of Disclosure to us. To comply with your Duty of Disclosure before you enter into the policy or vary, renew, extend, reinstate or replace it, you must tell us everything that you know, and which a reasonable person in the circumstances, could be expected to know, is relevant to our decision whether to insure you and, if so, on what terms.<br><br>
                        You do not have to tell us anything that is common knowledge that we should know through our business, that reduces the risk of a claim or that we tell you we do not need to know. If you fail to comply we may be entitled to reduce or deny any claim you make and/or cancel the policy. If you fraudulently keep information from us or deliberately make false statements we may avoid your contract and treat your insurance as if it never existed.<br><br>
                        The insurance we provide you takes in to account the answers and disclosures you provide to obtain a quotation. These answers and disclosures are called "material facts". Should you wish to alter a material fact, you must do so in writing to us. Alteration will only apply if we write to you and confirm the alteration is acceptable.
                    </div>`
        };
        GlobalStaticService.openInfoModal();
    }
    searchJudge(): void {
        if (
            this.signupForm.get("address").value &&
            this.signupForm.get("address").value.length >= 3
        ) {
            this.searchActive = true;
            this.search();
        } else {
            this.searchActive = false;
        }
    }
    searchFocus(): void {
        this.searchJudge();
    }
    searchChange(): void {
        GlobalStaticService.signupAddressFinished = false;
        this.searchJudge();
    }
    choose(address: any): void {
        GlobalStaticService.signupAddressFinished = true;
        this.signupForm.patchValue({
            address: address.full_address
        });
        this.searchBlur();
    }
    search(): void {
        this.resultActive = true;
        if (this.address$$) {
            this.address$$.unsubscribe();
        }
        this.address$$ = this.commonApi
            .getAddresses(this.signupForm.get("address").value)
            .subscribe(res => {
                this.addresses = res.completions;
                this.resultActive = false;
            });
    }
    clean(): void {
        this.searchActive = false;
        this.resultActive = false;
        this.addresses = [];
    }
    searchBlur(): void {
        this.timeoutFlag = true;
        this.timeout = setTimeout(() => {
            this.clean();
        }, 200);
    }
}
import { AbstractControl } from "@angular/forms";
import { getMatIconFailedToSanitizeUrlError } from "@angular/material";
import { StorageKey } from "../../constants";
export class PasswordValidation {
    static MatchPassword(AC: AbstractControl) {
        const password = AC.get("password").value;
        const repeatPassword = AC.get("repeatPassword").value;
        if (password !== repeatPassword) {
            AC.get("repeatPassword").setErrors({ MatchPassword: true });
        } else {
            return null;
        }
    }
}
export class AddressValidation {
    static Selected(AC: AbstractControl) {
        if (!GlobalStaticService.signupAddressFinished) {
            AC.get("address").setErrors({ Selected: true });
        } else {
            return null;
        }
    }
}
