export class Broker {
    contactId: number;
    contactCategoryId: number;
    contactCategoryText: string;
    address: string;
    email: string;
    fullName: string;
    mobile: string;
    phone: string;
    password: string;
    sessionId: string;
    state: string;
}
