import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Quote } from "../../models/quote";
import { QuoteService } from "../../services/quote.service";

@Component({
    selector: "app-quote-insured-type-component",
    templateUrl: "./quote-insured-type.component.html",
    styleUrls: ["./quote-insured-type.component.scss"]
})
export class QuoteInsuredTypeComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() specialEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor(private quoteSrv: QuoteService) {}
    ngOnChanges(): void {}
    change(): void {
        let q = this.quote;
        setTimeout(() => {
            console.log("insuredType: %s, businessType: %s", q.insuredType, q.businessType);
        }, 0);
        this.quote.businessType = "";
        if (this.quote.type === "single") {
            if (this.quote.insuredType === "ownerBuilder") {
                for (
                    let i = 0;
                    i < this.quote.businessOfInsuredOption.length;
                    i++
                ) {
                    if (this.quote.businessOfInsuredOption[i].name === "Owner Builder") {
                        this.quote.businessType = this.quote.businessOfInsuredOption[
                            i
                        ].name;
                        this.quote.businessTypeId = this.quote.businessOfInsuredOption[
                            i
                        ].id;
                        this.quote.businessTypeReferralMd = this.quote.businessOfInsuredOption[
                            i
                        ].mdReferralOnline;
                        this.quote.businessTypeReferralL = this.quote.businessOfInsuredOption[
                            i
                        ].plReferralOnline;
                        this.quote.businessTypeReferralCPL = this.quote.businessOfInsuredOption[
                            i
                        ].cplReferralOnline;
                        this.quote.businessTypeReferral = false;
                        break;
                    }
                }
            } else {
                if (this.quote.businessType === "Owner Builder") {
                    this.quote.businessType = "";
                    this.quote.businessTypeId = null;
                    this.quote.businessTypeReferralMd = false;
                    this.quote.businessTypeReferralL = false;
                    this.quote.businessTypeReferralCPL = false;
                    this.quote.businessTypeReferral = false;
                }
            }
            let referral = false;
            if (this.quote.referral.indexOf("p1q2") !== -1) {
                referral = true;
            }
            this.specialEventEmitter.emit({
                tab: 1,
                order: 3,
                finished: !!this.quote.businessType,
                referral: !this.quote.businessTypeReferral ? false : referral
            });
            // Defects Liability Period
            this.quoteSrv.clearDefectsLiabilityPeriod(this.quote);
            this.specialEventEmitter.emit({
                tab: 2,
                order: 3,
                finished: true,
                referral: false
            });
            if (
                this.quote.insuredType !== "ownerBuilder" &&
                this.quote.insuranceType !== "l"
            ) {
                this.quoteSrv.setDefaultDefectsLiabilityPeriod(this.quote);
            }
            // Business of Insured
            this.specialEventEmitter.emit({
                tab: 1,
                order: 3,
                finished: false,
                referral: false
            });
            // Project Type
            this.specialEventEmitter.emit({
                tab: 2,
                order: 4,
                finished: false,
                referral: false
            });
            // Project Involvement
            this.specialEventEmitter.emit({
                tab: 3,
                order: 3,
                finished: false,
                referral: false
            });
            // Material Damage
            this.specialEventEmitter.emit({
                tab: 4,
                order: 1,
                finished: false,
                referral: false
            });
            // Enhance Cover
            this.specialEventEmitter.emit({
                tab: 4,
                order: 5,
                finished: true,
                referral: false
            });
        } else {
            // Project Type
            this.specialEventEmitter.emit({
                tab: 2,
                order: 5,
                finished: false,
                referral: false
            });
        }
        this.quote.errorInsuredType = false;
        this.continueEventEmitter.emit({
            finished: true
        });
    }
}
