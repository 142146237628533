import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Quote } from "../../models/quote";
import { QUOTE_REFERRAL } from "../../configs/quote-referral";
import { EventBus } from "../../services/event_bus";
import { Subscription } from "rxjs";
import { UcEvent } from "../../static-services/events";
import { CommonUtility } from "../../utilities/common.utility";

@Component({
    selector: "app-quote-tailor-your-cover-component",
    templateUrl: "./quote-tailor-your-cover.component.html",
    styleUrls: ["./quote-tailor-your-cover.component.scss"]
})
export class QuoteTailorYourCoverComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    private referralLimitChanged$$: Subscription;
    @Input("quote") quote: Quote;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor(private eventBus: EventBus) { }
    ngAfterViewInit() {
        this.referralLimitChanged$$ = this.eventBus.on(UcEvent.REFERRAL_LIMIT_CHANGED).subscribe(() => this.checkReferral);
    }
    ngOnDestroy(): void {
        if (this.referralLimitChanged$$) {
            this.referralLimitChanged$$.unsubscribe();
        }
    }

    ngOnChanges(): void { }
    next(): void {
        this.checkReferral();
        this.continueEventEmitter.emit({
            finished: true,
            referral: false
        });
    }
    checkReferral(): void {
        if (GlobalStaticService.scheme) {
            this.quote.tailorYourCover = QUOTE_REFERRAL.tailorYourCover;
        }
    }
}
