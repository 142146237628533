import { Component } from "@angular/core";

import { CONFIG } from "../../configs/config";
import { GlobalStaticService } from "../../static-services/global.static-service";

@Component({
    selector: "app-main-footer-component",
    templateUrl: "./main-footer.component.html",
    styleUrls: ["./main-footer.component.scss"]
})
export class MainFooterComponent {
    CONFIG = CONFIG;
    GlobalStaticSrv = GlobalStaticService;
    constructor() {}
}
