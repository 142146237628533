import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Quote } from "../../models/quote";

@Component({
    selector: "app-add-work-location-modal-component",
    templateUrl: "./add-work-location-modal.component.html",
    styleUrls: ["./add-work-location-modal.component.scss"]
})
export class AddWorkLocationModalComponent {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    hoverArea: string;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() specialEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor() { }
    hoverActive(area: string): void {
        this.hoverArea = area;
    }
    hoverDeactive(): void {
        this.hoverArea = null;
    }
    save(): void {
        if (
            GlobalStaticService.workLocationNT +
            GlobalStaticService.workLocationQLDa +
            GlobalStaticService.workLocationQLDb +
            GlobalStaticService.workLocationWAa +
            GlobalStaticService.workLocationWAb +
            GlobalStaticService.workLocationSA +
            GlobalStaticService.workLocationNSW +
            GlobalStaticService.workLocationVIC +
            GlobalStaticService.workLocationACT +
            GlobalStaticService.workLocationTAS !==
            100
        ) {
            GlobalStaticService.infoModal = {
                title: "WARNING",
                content:
                    "The total turnover entered doesn't add up to 100%. Please check and re-enter."
            };
            GlobalStaticService.openInfoModal();
        } else {
            this.quote.workLocationNT = GlobalStaticService.workLocationNT;
            this.quote.workLocationQLDa = GlobalStaticService.workLocationQLDa;
            this.quote.workLocationQLDb = GlobalStaticService.workLocationQLDb;
            this.quote.workLocationWAa = GlobalStaticService.workLocationWAa;
            this.quote.workLocationWAb = GlobalStaticService.workLocationWAb;
            this.quote.workLocationSA = GlobalStaticService.workLocationSA;
            this.quote.workLocationNSW = GlobalStaticService.workLocationNSW;
            this.quote.workLocationVIC = GlobalStaticService.workLocationVIC;
            this.quote.workLocationACT = GlobalStaticService.workLocationACT;
            this.quote.workLocationTAS = GlobalStaticService.workLocationTAS;
            this.quote.errorWorkLocation = false;

            this.quote.workLocationReferral = GlobalStaticService.workLocationNT || GlobalStaticService.workLocationQLDa || GlobalStaticService.workLocationWAa;
            if (!GlobalStaticService.quickModal) {
                // Majority of Work
                this.specialEventEmitter.emit({
                    tab: 2,
                    order: 7,
                    finished: false,
                    referral: false
                });
                // Enhance Your Cover
                this.specialEventEmitter.emit({
                    tab: 4,
                    order: 6,
                    finished: false,
                    referral: false
                });
                this.continueEventEmitter.emit({
                    finished: true,
                    referral: this.quote.workLocationReferral
                });
            }
            GlobalStaticService.closeWorkLocationModal();
        }
    }
    focus(id: string): void {
        let el = document.getElementById("focus");
        if (el) {
            el.focus();
        }
    }
    keyDownWAB(event: any) {
        if (event.keyCode == 9) {
            event.stopPropagation();
            let e = document.getElementById("waa");
            if (e) {
                setTimeout(()=>e.focus(), 5);
            }
        }
    }
    keyDownSave(event: any) {
        if (event.keyCode == 13 || event.keyCode == 32) {
            this.save();
        }
    }
    keyUpAction(event: any, field: string): void {
        if (event.key === ".") {
            event.target.value = null;
            GlobalStaticService.infoModal = {
                title: "WARNING",
                content: "Please round to the nearest whole number."
            };
            GlobalStaticService.openInfoModal();
            switch (field) {
                case "waa":
                    GlobalStaticService.workLocationWAa = null;
                    break;
                case "wab":
                    GlobalStaticService.workLocationWAb = null;
                    break;
                case "nt":
                    GlobalStaticService.workLocationNT = null;
                    break;
                case "qlda":
                    GlobalStaticService.workLocationQLDa = null;
                    break;
                case "qldb":
                    GlobalStaticService.workLocationQLDb = null;
                    break;
                case "sa":
                    GlobalStaticService.workLocationSA = null;
                    break;
                case "nsw":
                    GlobalStaticService.workLocationNSW = null;
                    break;
                case "act":
                    GlobalStaticService.workLocationACT = null;
                    break;
                case "vic":
                    GlobalStaticService.workLocationVIC = null;
                    break;
                case "tas":
                    GlobalStaticService.workLocationTAS = null;
                    break;
            }
        } else {
            GlobalStaticService.acceptNumberOnly(event);
        }
    }
    close(): void {
        GlobalStaticService.workLocationNT = null;
        GlobalStaticService.workLocationQLDa = null;
        GlobalStaticService.workLocationQLDb = null;
        GlobalStaticService.workLocationWAa = null;
        GlobalStaticService.workLocationWAb = null;
        GlobalStaticService.workLocationSA = null;
        GlobalStaticService.workLocationNSW = null;
        GlobalStaticService.workLocationVIC = null;
        GlobalStaticService.workLocationACT = null;
        GlobalStaticService.workLocationTAS = null;
        GlobalStaticService.closeWorkLocationModal();
    }
}
