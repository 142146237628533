import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from "@angular/core";

import { GlobalStaticService } from "../../static-services/global.static-service";
import { Quote } from "../../models/quote";
import { EventBus } from "../../services/event_bus";
import { UcEvent } from "../../static-services/events";
import { Subscription } from "rxjs";

@Component({
    selector: "app-add-business-type-modal-component",
    templateUrl: "./add-business-type-modal.component.html",
    styleUrls: ["./add-business-type-modal.component.scss"]
})
export class AddBusinessTypeModalComponent implements OnInit, OnDestroy {
    GlobalStaticSrv = GlobalStaticService;
    searchString: string;
    @Input("quote") quote: Quote;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() specialEventEmitter: EventEmitter<any> = new EventEmitter();
    selectedOption: any = null;
    others: string;
    eventBusSub$: Subscription;
    constructor(private eventBus: EventBus) {}

    ngOnInit(): void {
        this.selectedOption = null;
        this.searchString = null;
        this.eventBusSub$ = this.eventBus.on(UcEvent.BUSINESS_TYPE_MODAL_CLOSED).subscribe(() => {
            this.selectedOption = null;
            this.searchString = null;
        })
    }

    ngOnDestroy(): void {
        this.eventBusSub$.unsubscribe();
    }

    search(): void {
        if (this.searchString) {
            this.quote.businessOfInsuredFilteredOption = this.quote.businessOfInsuredOption.filter(
                option => {
                    return (
                        option.name
                            .toLowerCase()
                            .indexOf(this.searchString.toLowerCase()) !== -1
                    );
                }
            );
        } else {
            this.quote.businessOfInsuredFilteredOption = this.quote.businessOfInsuredOption;
        }
    }
    chooseOption(option: any): void {
        if (option.name !== "Owner Builder") {
            this.selectedOption = option;
        }
    }
    select(): void {
        if (!this.selectedOption) {
            return;
        }
        if (this.selectedOption === "others" && !this.others) {
            return;
        }
        if (this.selectedOption !== "others") {
            this.quote.businessType = this.selectedOption.name;
            this.quote.businessTypeId = this.selectedOption.id;
            this.quote.businessTypeReferralMd = this.selectedOption.mdReferralOnline;
            this.quote.businessTypeReferralL = this.selectedOption.plReferralOnline;
            this.quote.businessTypeReferralCPL = this.selectedOption.cplReferralOnline;
            this.quote.businessTypeReferral = false;
            GlobalStaticService.addRecent(
                "recentBusinessOfInsured",
                this.quote.businessTypeId
            );
        } else {
            this.quote.businessType = this.others;
            this.quote.businessTypeId = null;
            this.quote.businessTypeReferralMd = false;
            this.quote.businessTypeReferralL = false;
            this.quote.businessTypeReferralCPL = false;
            this.quote.businessTypeReferral = true;
            GlobalStaticService.addRecent("recentBusinessOfInsured", 0);
        }
        // fire business of insured continue event to update referral
        this.quote.errorBusinessOfInsured = false;
        if (GlobalStaticService.businessOfInsuredIsNormal) {
            this.continueEventEmitter.emit({
                finished: !!this.quote.businessType,
                referral: this.quote.businessTypeReferral,
                noScroll: true
            });
            // Insurance Type
            this.specialEventEmitter.emit({
                tab: 2,
                order: 1,
                finished: false,
                referral: false
            });
            // Material Damage
            this.specialEventEmitter.emit({
                tab: 4,
                order: this.quote.type === "single" ? 1 : 2,
                finished: false,
                referral: false,
                page: 1
            });
            // Liability
            this.specialEventEmitter.emit({
                tab: 4,
                order: this.quote.type === "single" ? 3 : 4,
                finished: false,
                referral: false,
                page: 1
            });
            // Enhance Cover
            this.specialEventEmitter.emit({
                tab: 4,
                order: this.quote.type === "single" ? 5 : 6,
                finished: true,
                referral: false
            });
        }
        GlobalStaticService.closeAddBusinessTypeModal();
    }
}
