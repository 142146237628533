import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalStaticService } from "../../static-services/global.static-service";

import { CommonApi } from "../../api/common.api";
declare let gtag: any;

@Component({
    selector: "app-home-page-component",
    templateUrl: "./home.page.html",
    styleUrls: ["./home.page.scss"]
})
export class HomePageComponent implements OnInit {
    constructor(
        private router: Router,
        private commonApi: CommonApi
    ) { }
    ngOnInit(): void {
        //this.commonApi.postEndConversation();
    }

    navigateSingle(): void {
        GlobalStaticService.clientQuote = false;
        this.router.navigate(["/dashboard/quotes/single/create"]);
    }

    navigateAnnual(): void {
        GlobalStaticService.clientQuote = false;
        this.router.navigate(["/dashboard/quotes/annual/create"]);
    }
}
