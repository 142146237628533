import { Component, Input, OnInit } from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";

@Component({
    selector: "app-tutorial-launcher-component",
    templateUrl: "./tutorial-launcher.component.html",
    styleUrls: ["./tutorial-launcher.component.scss"]
})
export class TutorialLauncherComponent implements OnInit {
    GlobalStaticSrv = GlobalStaticService;
    constructor() {}
    ngOnInit(): void {}
}
