import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";

/*
 * Filter to Sanitize Style to prevent XSS attacks, while injecting style content through [style] property in Angular 2/4
 * Usage example:
 *   `<div [style.attribute]="unsafeStyleContent | sanitizeStyle"></div>`
 */

@Pipe({
    name: "sanitizeStyle"
})
export class SanitizeStylePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(style: string): SafeStyle {
        return this.sanitizer.bypassSecurityTrustStyle(style);
    }
}
