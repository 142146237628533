import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { Router } from "@angular/router";

import { CommonApi } from "../../api/common.api";

import { Client } from "../../models/client";
import { Policy } from "../../models/policy";
import { Quote } from "../../models/quote";

import { GlobalStaticService } from "../../static-services/global.static-service";

@Component({
    selector: "app-client-component",
    templateUrl: "./client.component.html",
    styleUrls: ["./client.component.scss"]
})
export class ClientComponent implements OnChanges {
    client: Client;
    clientPolicies: Policy[] = [];
    clientQuotes: Quote[] = [];
    @Input("id") id: string;
    @Output() backEventEmitter: EventEmitter<boolean> = new EventEmitter();
    @Output() goEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor(private router: Router, private commonApi: CommonApi) {}
    ngOnChanges(): void {
        if (this.id) {
            this.commonApi.getClient(this.id).subscribe(res => {
                this.client = res;
                if (this.client.tradingNames.length) {
                    this.client.tradingNamesPlainList = this.client.tradingNames
                        .map(name => {
                            return name.name;
                        })
                        .join(", ");
                } else {
                    this.client.tradingNamesPlainList = "N/A";
                }
                GlobalStaticService.addRecent("recentClients", this.client);
            });
            this.commonApi.getClientPolicies(this.id).subscribe(res => {
                this.clientPolicies = res;
            });
            this.commonApi.getClientQuotes(this.id).subscribe(res => {
                this.clientQuotes = res;
            });
        }
    }
    cleanData(): void {
        this.client = null;
        this.clientPolicies = [];
        this.clientQuotes = [];
    }
    back(): void {
        setTimeout(() => {
            this.cleanData();
        }, 500);
        this.backEventEmitter.emit(true);
    }
    go(nextPage: string, nextId: any): void {
        setTimeout(() => {
            this.cleanData();
        }, 500);
        this.goEventEmitter.emit({
            currentPage: "clientPage",
            nextPage: nextPage,
            nextId: nextId
        });
    }
    openQuoteChoiceModal(): void {
        GlobalStaticService.openCreateQuoteModal(this.router, this.client);
    }
}
