import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { Quote } from "../../models/quote";

@Component({
    selector: "app-quote-broker-comments-component",
    templateUrl: "./quote-broker-comments.component.html",
    styleUrls: ["./quote-broker-comments.component.scss"]
})
export class QuoteBrokerCommentsComponent implements OnChanges {
    referral = false;
    @Input("quote") quote: Quote;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor() {}
    ngOnChanges(): void {}
    change(): void {
        this.quote.errorBrokerComments = false;
        this.referral = !!this.quote.brokerComments;
        this.continueEventEmitter.emit({
            finished: false,
            referral: this.referral
        });
    }
    next(): void {
        this.quote.errorBrokerComments = false;
        this.continueEventEmitter.emit({
            finished: true,
            referral: this.referral
        });
    }
}
