import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Quote } from "../../models/quote";
import { QUOTE_REFERRAL } from "../../configs/quote-referral";
import { TempQuote } from '../../models/temp-quote';

@Component({
    selector: "app-quote-enhance-your-cover-component",
    templateUrl: "./quote-enhance-your-cover.component.html",
    styleUrls: ["./quote-enhance-your-cover.component.scss"]
})
export class QuoteEnhanceYourCoverComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    @Input("tempQuote") tempQuote: TempQuote;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() goEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() offsetRecheckEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor() { }
    ngOnChanges(): void {
        if (this.tempQuote) {
            this.tempQuote.componentEnhanceYourCover = this;
        }
    }
    changeCpl(): void {
        if (!this.quote.cover1) {
            this.quote.enhance1A = null;
            this.quote.enhance1B = null;
            this.quote.enhance1C = null;
            this.quote.enhance1D = null;
            this.quote.enhance1Referral = false;
            this.result(true);
        } else {
            this.result(false);
            GlobalStaticService.openEnhanceCoverModal();
        }
        this.offsetRecheckEventEmitter.emit(4);
    }
    changeEa(): void {
        if (this.quote.type === "single") {
            this.quote.cover3Referral =
                this.quote.cover3 &&
                this.quote.projectCost > QUOTE_REFERRAL.eaLimitSp;
        } else {
            this.quote.cover3Referral =
                this.quote.cover3 &&
                this.quote.projectCost > QUOTE_REFERRAL.eaLimitAp;
        }
        this.result(true);
    }
    changeEo(): void {
        if (this.quote.type === "single") {
            this.quote.cover4Referral =
                this.quote.cover4 &&
                this.quote.projectCost > QUOTE_REFERRAL.eoLimitSp;
        } else {
            this.quote.cover4Referral =
                this.quote.cover4 &&
                this.quote.projectCost > QUOTE_REFERRAL.eoLimitAp;
        }
        if (!this.quote.cover4) {
            this.quote.enhance4A = null;
            this.quote.cover4Referral = false;
            this.quote.enhance4Referral = false;
            this.result(true);
        } else {
            this.result(false);
        }
        this.offsetRecheckEventEmitter.emit(4);
    }
    changeEnhance4(): void {
        this.quote.errorEnhanceYourCover = false;
        this.quote.enhance4Referral = this.quote.enhance4A;
        this.result(true);
    }
    changeLd(): void {
        if (this.quote.type === "single") {
            this.quote.cover5Referral =
                this.quote.cover5 &&
                this.quote.projectCost > QUOTE_REFERRAL.ldLimitSp;
        } else {
            this.quote.cover5Referral =
                this.quote.cover5 &&
                this.quote.projectCost > QUOTE_REFERRAL.ldLimitAp;
        }
        this.result(true);
    }
    changePw(): void {
        this.quote.cover6Referral = this.quote.cover6;
        this.result(true);
    }
    changeScheme(): void {
        if (this.quote.type === "single") {
            this.quote.cover3Referral =
                this.quote.cover3 &&
                this.quote.projectCost > QUOTE_REFERRAL.eaLimitSp;
        } else {
            this.quote.cover3Referral =
                this.quote.cover3 &&
                this.quote.projectCost > QUOTE_REFERRAL.eaLimitAp;
        }
        this.quote.cover6Referral = this.quote.cover6;
        this.result(true);
    }
    result(finished: boolean): void {
        this.continueEventEmitter.emit({
            finished: finished,
            noScroll: true,
            eventPage: 4,
            referral:
                (this.quote.businessTypeReferralCPL && this.quote.cover1) ||
                this.quote.cover5Referral ||
                this.quote.cover3Referral ||
                this.quote.cover4Referral ||
                this.quote.enhance4Referral ||
                this.quote.cover6Referral ||
                this.quote.enhance1Referral
        });
    }
    goEvent(): void {
        this.goEventEmitter.emit(5);
    }
}
