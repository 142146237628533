import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";

import { CommonApi } from "../../api/common.api";

import { Claim } from "../../models/claim";

import { GlobalStaticService } from "../../static-services/global.static-service";

@Component({
    selector: "app-claim-component",
    templateUrl: "./claim.component.html",
    styleUrls: ["./claim.component.scss"]
})
export class ClaimComponent implements OnChanges {
    claim: Claim;
    @Input("id") id: any;
    @Output() backEventEmitter: EventEmitter<boolean> = new EventEmitter();
    @Output() goEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor(private commonApi: CommonApi) {}
    ngOnChanges(): void {
        if (this.id) {
            this.commonApi.getClaim(this.id).subscribe(res => {
                this.claim = res;
                GlobalStaticService.addRecent("recentClaims", this.claim);
            });
        }
    }
    cleanData(): void {
        this.claim = null;
    }
    back(): void {
        setTimeout(() => {
            this.cleanData();
        }, 500);
        this.backEventEmitter.emit(true);
    }
    go(nextPage: string, nextId: any): void {
        setTimeout(() => {
            this.cleanData();
        }, 500);
        this.goEventEmitter.emit({
            currentPage: "claimPage",
            nextPage: nextPage,
            nextId: nextId
        });
    }
}
