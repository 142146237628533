import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { InterfaceStaticService } from "../../static-services/interface.static-service";
import { Quote } from "../../models/quote";
import { TempQuote } from "../../models/temp-quote";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { QuoteMappingStaticService } from "../../static-services/quote-mapping.static-service";
import { QuoteService } from "../../services/quote.service";
import { CommonApi } from "../../api/common.api";
import { Rating } from "../../models/rating";
import { QUOTE_REFERRAL } from "../../configs/quote-referral";
import { Observable, of, Subscription } from "rxjs";
import { timeout, catchError } from 'rxjs/operators';
import * as moment from "moment";
import { EventBus } from "../../services/event_bus";
import { UcEvent } from "../../static-services/events";
import { CommonUtility } from "../../utilities/common.utility";

declare let gtag: any;

@Component({
    selector: "app-annual-quote-page-component",
    templateUrl: "./annual-quote.page.html",
    styleUrls: ["./annual-quote.page.scss"]
})
export class AnnualQuotePageComponent
    implements OnInit, AfterViewInit, OnDestroy {
    GlobalStaticSrv = GlobalStaticService;
    InterfaceStaticSrv = InterfaceStaticService;
    type: string;
    navigationHide = true;
    quote: Quote = new Quote();
    tempQuote: TempQuote = new TempQuote();
    currentPage = 1;
    quoteScroll$$: any;
    // statusBenchmark is how many questions there are on each page

    // builders license
    //statusBenchmark = [5, 7, 2, 6, 3];

    // original
    statusBenchmark = [4, 7, 2, 6, 3];

    page1Offset: number[] = [];
    page1Position = 0;
    @ViewChild("page1") page1: ElementRef;
    @ViewChild("insuredName") insuredName: ElementRef;
    @ViewChild("insuredNameWrapper") insuredNameWrapper: ElementRef;
    insuredNameHeight: number;
    @ViewChild("insuredType") insuredType: ElementRef;
    @ViewChild("insuredTypeWrapper") insuredTypeWrapper: ElementRef;
    insuredTypeHeight: number;
    @ViewChild("buildingLicense") buildingLicense: ElementRef;
    @ViewChild("buildingLicenseWrapper") buildingLicenseWrapper: ElementRef;
    buildingLicenseHeight: number;
    @ViewChild("businessOfInsured") businessOfInsured: ElementRef;
    @ViewChild("businessOfInsuredWrapper") businessOfInsuredWrapper: ElementRef;
    businessOfInsuredHeight: number;
    @ViewChild("insuredDeclaration") insuredDeclaration: ElementRef;
    @ViewChild("insuredDeclarationWrapper")
    insuredDeclarationWrapper: ElementRef;
    insuredDeclarationHeight: number;
    page2Offset: number[] = [];
    page2Position = 0;
    @ViewChild("page2") page2: ElementRef;
    @ViewChild("policyDate") policyDate: ElementRef;
    @ViewChild("policyDateWrapper") policyDateWrapper: ElementRef;
    policyDateHeight: number;
    @ViewChild("maximumProjectDuration") maximumProjectDuration: ElementRef;
    @ViewChild("maximumProjectDurationWrapper")
    maximumProjectDurationWrapper: ElementRef;
    maximumProjectDurationHeight: number;
    @ViewChild("defectsLiabilityPeriod") defectsLiabilityPeriod: ElementRef;
    @ViewChild("defectsLiabilityPeriodWrapper")
    defectsLiabilityPeriodWrapper: ElementRef;
    defectsLiabilityPeriodHeight: number;
    @ViewChild("projectType") projectType: ElementRef;
    @ViewChild("projectTypeWrapper") projectTypeWrapper: ElementRef;
    projectTypeHeight: number;
    @ViewChild("workLocation") workLocation: ElementRef;
    @ViewChild("workLocationWrapper") workLocationWrapper: ElementRef;
    workLocationHeight: number;
    @ViewChild("majorityOfWork") majorityOfWork: ElementRef;
    @ViewChild("majorityOfWorkWrapper") majorityOfWorkWrapper: ElementRef;
    majorityOfWorkHeight: number;
    page3Offset: number[] = [];
    page3Position = 0;
    @ViewChild("page3") page3: ElementRef;
    @ViewChild("insuranceType") insuranceType: ElementRef;
    @ViewChild("insuranceTypeWrapper") insuranceTypeWrapper: ElementRef;
    insuranceTypeHeight: number;
    @ViewChild("ongoingWork") ongoingWork: ElementRef;
    @ViewChild("ongoingWorkWrapper") ongoingWorkWrapper: ElementRef;
    ongoingWorkHeight: number;
    @ViewChild("projectInvolvements") projectInvolvements: ElementRef;
    @ViewChild("projectInvolvementsWrapper")
    projectInvolvementsWrapper: ElementRef;
    projectInvolvementsHeight: number;
    page4Offset: number[] = [];
    page4Position = 0;
    @ViewChild("page4") page4: ElementRef;
    @ViewChild("premiumDeposit") premiumDeposit: ElementRef;
    @ViewChild("premiumDepositWrapper") premiumDepositWrapper: ElementRef;
    premiumDepositHeight: number;
    @ViewChild("materialDamage") materialDamage: ElementRef;
    @ViewChild("materialDamageWrapper") materialDamageWrapper: ElementRef;
    materialDamageHeight: number;
    @ViewChild("subLimit") subLimit: ElementRef;
    @ViewChild("subLimitWrapper") subLimitWrapper: ElementRef;
    subLimitHeight: number;
    @ViewChild("liability") liability: ElementRef;
    @ViewChild("liabilityWrapper") liabilityWrapper: ElementRef;
    liabilityHeight: number;
    @ViewChild("tailorYourCover") tailorYourCover: ElementRef;
    @ViewChild("tailorYourCoverWrapper") tailorYourCoverWrapper: ElementRef;
    tailorYourCoverHeight: number;
    @ViewChild("enhanceYourCover") enhanceYourCover: ElementRef;
    @ViewChild("enhanceYourCoverWrapper") enhanceYourCoverWrapper: ElementRef;
    enhanceYourCoverHeight: number;
    page5Offset: number[] = [];
    page5Position = 0;
    @ViewChild("page5") page5: ElementRef;
    @ViewChild("brokerCharges") brokerCharges: ElementRef;
    @ViewChild("brokerChargesWrapper") brokerChargesWrapper: ElementRef;
    brokerChargesHeight: number;
    @ViewChild("brokerComments") brokerComments: ElementRef;
    @ViewChild("brokerCommentsWrapper") brokerCommentsWrapper: ElementRef;
    brokerCommentsHeight: number;
    @ViewChild("attachments") attachments: ElementRef;
    @ViewChild("attachmentsWrapper") attachmentsWrapper: ElementRef;
    attachmentsHeight: number;
    @ViewChild("page6") page6: ElementRef;
    page6Offset: number[] = [];
    page6Position = 0;
    @ViewChild("questionSummary") questionSummary: ElementRef;
    @ViewChild("quoteSummary") quoteSummary: ElementRef;
    switching = false;
    submittedQuote: Quote = new Quote();
    navigating = false;
    resize$$: any;
    referralLimitChanged$$: Subscription;

    constructor(
        public quoteSrv: QuoteService,
        private commonApi: CommonApi,
        private route: ActivatedRoute,
        private cd: ChangeDetectorRef,
        private eventBus: EventBus,
        private router: Router
    ) {
        this.quote.type = "annual";
    }
    ngOnInit(): void {
        this.quoteSrv.resetReferralLimit(48);
        this.referralLimitChanged$$ = this.eventBus
            .on(UcEvent.QUOTE_REFERRAL_FLAG_CHANGED)
            .subscribe(() => this.updateUiReferralFlags());
        this.resize$$ = Observable.fromEvent(window, "resize")
            .debounceTime(200)
            .subscribe(e => {
                this.offsetCheck(1);
                this.offsetCheck(2);
                this.offsetCheck(3);
                this.offsetCheck(4);
                this.offsetCheck(5);
                this.offsetCheck(6);
            });
        GlobalStaticService.isQuoteBind = false;
        GlobalStaticService.isDydEnabled = false;
        let pure = false;
        this.route.params.forEach((params: Params) => {
            this.type = params["type"];
            if (this.type === "create") {
                gtag("set", {
                    user_id: GlobalStaticService.brokerProfile.contactId
                });
                gtag("event", "NewQuote", {});
                //this.commonApi.postBeginConversation(null, null);
                if (GlobalStaticService.autoAnnualQuote) {
                    this.quote = QuoteMappingStaticService.deepCopy(
                        GlobalStaticService.autoAnnualQuote
                    );
                    this.quoteSrv.resetDate(this.quote);
                    if (GlobalStaticService.clientQuote) {
                        this.initialQuote();
                    } else {
                        this.quote.quick = false;
                    }
                    if (GlobalStaticService.template) {
                        GlobalStaticService.template = false;
                        GlobalStaticService.openLoadTemplateDoneModal();
                    }
                } else {
                    pure = true;
                    this.initialQuote();
                }
                this.quote.attachments = [];
            }
            if (this.type === "edit") {
                this.quote = QuoteMappingStaticService.deepCopy(
                    GlobalStaticService.autoAnnualQuote
                );
                GlobalStaticService.cleanQuoteAutoSave(
                    this.quote.type === "single"
                );
                this.quote = this.quoteSrv.editProcess(
                    this.quote,
                    this.statusBenchmark
                );
                this.submittedQuote = QuoteMappingStaticService.deepCopy(
                    this.quote
                );
                this.quote.quick = false;
                this.quoteSrv.resetDate(this.quote);
            }
            this.quote.createdInvalidDate = moment().add(30, "days");
            this.quote.valid = true;
        });
        InterfaceStaticService.noFooter = true;
        this.quote.productId = 48;
        this.commonApi.getProduct(48).subscribe(res => {
            this.quote.maximumProjectDurationOption = res[116796].valueLookups;
            if (!this.quote.maximumProjectDuration) {
                this.quoteSrv.setDefaultMaximumProjectDurationOption(
                    this.quote
                );
            }
            this.quote.defectsLiabilityPeriodOption = res[116218].valueLookups;
            if (!this.quote.defectsLiabilityPeriod) {
                this.quoteSrv.setDefaultDefectsLiabilityPeriod(this.quote);
            }
            this.quote.publicLiabilityOption = res[116282].valueLookups;
            this.quote.productLiabilityOption = res[116957].valueLookups;
            this.quote.vibrationWeakeningRemovalOfSupportOption =
                res[116284].valueLookups;
            this.quote.propertyInCareCustodyControlOption =
                res[116285].valueLookups;
            this.quote.brokerCommissionOption = res[116785].valueLookups;
            if (pure) {
                this.quoteSrv.resetBrokerCommission(this.quote);
            }
            this.quote.cplOption = res[116291].valueLookups;
        });
        this.commonApi.getProductBusinessOfInsured(48).subscribe(res => {
            this.quote.businessOfInsuredOption = this.quoteSrv.sortBusinessOfInsured(
                res
            );
            this.quote.businessOfInsuredFilteredOption = this.quoteSrv.sortBusinessOfInsured(
                res
            );
        });
        setTimeout(() => {
            this.quoteSrv.getEditScheme(48, this.quote);
        }, 1000);
        setTimeout(() => {
            this.navigationHide = false;
        }, 200);
    }
    initialQuote(): void {
        // mark the steps that are non mandatory as done
        this.quote.page2Status.push(2);
        this.quote.page2Status.push(3);
        this.quote.page2Status.push(4);

        this.quote.page4Status.push(1);
        this.quote.page4Status.push(3);
        this.quote.page4Status.push(5);
        this.quote.page4Status.push(6);

        this.quote.page5Status.push(1);
        this.quote.page5Status.push(3);
    }
    initialQuickQuote(): void {
        GlobalStaticService.clientQuote = true;
        GlobalStaticService.openQuickQuoteModal();
        this.quote.quick = true;
        //this.quote.insuredDeclaration = false;
        this.quote.insuranceType = "mdl";
        this.quote.ongoingWork = "run-off";
        this.quote.ongoingWorkDetails = true;
        this.quote.tailorYourCover = 1000;
    }
    ngAfterViewInit(): void {
        this.offsetCheck(1);
        this.offsetCheck(2);
        this.offsetCheck(3);
        this.offsetCheck(4);
        this.offsetCheck(5);
        this.offsetCheck(6);

        this.quoteScroll$$ = InterfaceStaticService.quoteScroll$.subscribe(
            e => {
                const index = "page" + this.currentPage + "Offset";
                const position = "page" + this.currentPage + "Position";
                if (this.currentPage === 6) {
                    for (let i = this[index].length - 1; i >= 0; i--) {
                        if (e + 130 < this[index][i]) {
                            this[position] = i - 1;
                            break;
                        } else {
                            this[position] = this[index].length - 1;
                        }
                    }
                }
            }
        );
        this.quoteSrv.autoQuote(this.quote);
        if (this.type === "edit") {
            this.quoteSrv.editProcessComponent(this.tempQuote);
        }
        GlobalStaticService.dydSetTimeout = setTimeout(() => {
            GlobalStaticService.isDydEnabled = !this.quote.reachSummary;
        }, 10000);
        this.cd.detectChanges();
    }
    ngOnDestroy(): void {
        if (this.quoteScroll$$) {
            this.quoteScroll$$.unsubscribe();
        }
        if (this.resize$$) {
            this.resize$$.unsubscribe();
        }
        if (this.referralLimitChanged$$) {
            this.referralLimitChanged$$.unsubscribe();
        }
        if (
            GlobalStaticService.autoAnnualQuote &&
            !GlobalStaticService.autoAnnualQuote.insuredName &&
            !GlobalStaticService.autoAnnualQuote.insuredType
        ) {
            GlobalStaticService.cleanQuoteAutoSave(false);
        }
    }
    navigationScroll(position: number): void {
        if (this.navigating) {
            return;
        }
        this.navigating = true;
        this.scrollTo(position);
        setTimeout(() => {
            this.navigating = false;
        }, 300);
    }
    callAlert(page: number, order: number): void {
        console.log("callAlert", arguments)
        switch (page) {
            case 1:
                switch (order) {
                    case 1:
                        this.quote.errorInsuredName = true;
                        break;
                    case 2:
                        this.quote.errorInsuredType = true;
                        break;
                    case 3:
                 /*        this.quote.errorBuildingLicense = true;
                        break;
                    case 4: */
                        this.quote.errorBusinessOfInsured = true;
                        break;
                 //   case 5:
                    case 4:
                        this.quote.errorInsuredDeclaration = true;
                        break;
                }
                break;
            case 2:
                switch (order) {
                    case 1:
                        this.quote.errorInsuranceType = true;
                        break;
                    case 5:
                        this.quote.errorProjectType = true;
                        break;
                    case 6:
                        this.quote.errorWorkLocation = true;
                        break;
                    case 7:
                        this.quote.errorMajorityOfWork = true;
                        break;
                }
                break;
            case 3:
                switch (order) {
                    case 1:
                        this.quote.errorOngoingWork = true;
                        break;
                    case 2:
                        this.quote.errorProjectInvolvement = true;
                        break;
                }
                break;
            case 4:
                switch (order) {
                    case 2:
                        this.quote.errorMaterialDamage = true;
                        break;
                    case 4:
                        this.quote.errorLiability = true;
                        break;
                    case 6:
                        this.quote.errorEnhanceYourCover = true;
                        break;
                }
                break;
            case 5:
                switch (order) {
                    case 2:
                        this.quote.errorBrokerComments = true;
                        break;
                    case 3:
                        this.quote.errorAttachments = true;
                        break;
                }
                break;
        }
    }
    scrollTest(order): boolean {
        if (this.currentPage !== 6) {
            for (let i = 0; i < order; i++) {
                if (
                    this.quote["page" + this.currentPage + "Status"].indexOf(
                        i + 1
                    ) === -1
                ) {
                    return false;
                }
            }
        }
        return true;
    }
    scrollTo(order: number): void {
        let go = 0;
        if (this.currentPage === 6) {
            go = order;
        } else {
            go = 0;
            for (let i = 0; i < order; i++) {
                if (
                    this.quote["page" + this.currentPage + "Status"].indexOf(
                        i + 1
                    ) === -1
                ) {
                    this.callAlert(this.currentPage, i + 1);
                    break;
                } else {
                    go = i + 1;
                }
            }
        }
        const start = this["page" + this.currentPage].nativeElement.scrollTop;
        const end = this["page" + this.currentPage + "Offset"][go];
        if (this.currentPage !== 6) {
            this["page" + this.currentPage + "Position"] = go;
        }
        this["page" + this.currentPage].nativeElement.scroll({
            top: end,
            left: 0,
            behavior: "smooth"
        });
    }
    jumpTo(tab: number, order: number) {
        this["page" + tab].nativeElement.scrollTop = this[
            "page" + tab + "Offset"
        ][order];
        this["page" + tab + "Position"] = order;
    }
    cleanClassList(page: string): void {
        this[page].nativeElement.classList.remove("slideInLeft");
        this[page].nativeElement.classList.remove("slideInRight");
        this[page].nativeElement.classList.remove("slideOutLeft");
        this[page].nativeElement.classList.remove("slideOutRight");
    }
    checkFinishedTest(aimPage: number, aimOrder: number): boolean {
        for (let i = 1; i < aimPage; i++) {
            if (!this.quote["page" + i + "OkFlag"]) {
                return false;
            }
        }
        const status = "page" + aimPage + "Status";
        if (this.quote[status].length < aimOrder) {
            return false;
        }
        this.quote[status].sort(function(a, b) {
            return a - b;
        });
        for (let i = 0; i < aimOrder; i++) {
            if (this.quote[status][i] !== i + 1) {
                return false;
            }
        }
        return true;
    }
    checkFinished(aimPage: number): boolean {
        let page = 0,
            order = 0;
        for (let i = 1; i < aimPage; i++) {
            if (!this.quote["page" + i + "OkFlag"]) {
                page = i;
                break;
            }
        }
        if (page) {
            const status = "page" + page + "Status";
            this.quote[status].sort(function(a, b) {
                return a - b;
            });
            for (let i = 0; i < this.statusBenchmark[page - 1]; i++) {
                if (this.quote[status][i] !== i + 1) {
                    order = i + 1;
                    break;
                }
            }
            this.callAlert(page, order);
            if (this.currentPage === page) {
                this.scrollTo(order);
            } else {
                this.edit(page, order);
            }
        }
        return !page;
    }
    next(next?: number): void {
        if (!next) {
            next = 1;
        }
        if (!this.checkFinished(this.currentPage + next)) {
            return;
        }
        if (!this.switching) {
            GlobalStaticService.hideDyd(this.currentPage);
            this.switching = true;
            this.cleanClassList("page" + this.currentPage);
            this.cleanClassList("page" + (this.currentPage + next));
            this["page" + this.currentPage].nativeElement.classList.add(
                "slideOutLeft"
            );
            this[
                "page" + (this.currentPage + next)
            ].nativeElement.classList.add("slideInRight");
            setTimeout(() => {
                this[
                    "page" + (this.currentPage + next)
                ].nativeElement.classList.add("active");
            });
            setTimeout(() => {
                this["page" + this.currentPage].nativeElement.classList.remove(
                    "active"
                );
                this.cleanClassList("page" + this.currentPage);
                this.cleanClassList("page" + (this.currentPage + next));
                this.currentPage = this.currentPage + next;
                this.switching = false;
                if (this.currentPage === 4 && !this.tempQuote.dyd4Flag) {
                    this.quoteSrv.getDyd();
                    GlobalStaticService.dydSetTimeout = setTimeout(() => {
                        GlobalStaticService.isDydEnabled = !this.quote
                            .reachSummary;
                        if (GlobalStaticService.isDydEnabled) {
                            this.tempQuote.dyd4Flag = true;
                        }
                    }, 10000);
                }
                // DYD disabled once journey reaches quote
                if (this.currentPage === 6) {
                    this.quote.reachSummary = true;
                    this.quoteSrv.reachSummary();
                    this.type = this.quoteSrv.diff(
                        this.type,
                        this.quote,
                        this.submittedQuote
                    );
                }
            }, 500);
        }
    }
    back(prev?: number): void {
        if (!prev) {
            prev = 1;
        }
        if (!this.switching) {
            GlobalStaticService.hideDyd(this.currentPage);
            this.switching = true;
            this.cleanClassList("page" + this.currentPage);
            this.cleanClassList("page" + (this.currentPage - prev));
            this["page" + this.currentPage].nativeElement.classList.add(
                "slideOutRight"
            );
            this[
                "page" + (this.currentPage - prev)
            ].nativeElement.classList.add("slideInLeft");
            setTimeout(() => {
                this[
                    "page" + (this.currentPage - prev)
                ].nativeElement.classList.add("active");
            });
            setTimeout(() => {
                this["page" + this.currentPage].nativeElement.classList.remove(
                    "active"
                );
                this.cleanClassList("page" + this.currentPage);
                this.cleanClassList("page" + (this.currentPage - prev));
                this.currentPage = this.currentPage - prev;
                this.switching = false;
            }, 500);
        }
    }
    prequote(): void {
        console.log("prequote", this)
        gtag("set", { user_id: GlobalStaticService.brokerProfile.contactId });
        gtag("event", "PreQuote", {});
        this.go(6);
    }
    nextTest(page: number, order: number): boolean {
        if (
            this.currentPage === 6 &&
            this.type === "edit" &&
            this.quote.referral.length
        ) {
            return false;
        } else if (!this.checkFinishedTest(page, order)) {
            return false;
        } else {
            return true;
        }
    }
    go(page: number): void {
        if (
            this.currentPage === 6 &&
            this.type === "edit" &&
            this.quote.referral.length
        ) {
            return;
        }
        if (this.currentPage !== page) {
            if (this.currentPage > page) {
                this.back(this.currentPage - page);
            } else {
                this.next(page - this.currentPage);
            }
        }
    }
    goEventEmitter(event: number): void {
        this.edit(event, 0);
    }
    goReferral(): void {
        this.quote.referral.sort();
        if (this.quote.referral.length !== 0) {
            const referral = this.quote.referral[0].split("p")[1].split("q");
            if (this.currentPage === Number(referral[0])) {
                this.scrollTo(Number(referral[1]) - 1);
            } else {
                this.edit(Number(referral[0]), Number(referral[1]) - 1);
            }
        }
    }
    get goReferralTest(): boolean {
        if (!this.quote.referral) return false;
        this.quote.referral.sort();
        if (this.quote.referral.length !== 0) {
            const referral = this.quote.referral[0].split("p")[1].split("q");
            if (
                this.quote["page" + Number(referral[0]) + "Progress"] >=
                Number(referral[1]) - 1
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    continueEventEmitter(fieldOrder: number, event: any) {
//console.log("continueEventEmitter", arguments);
        CommonUtility.trace("continueEventEmitter");
        if (!event.eventPage) {
            event.eventPage = this.currentPage;
        }
        this.quoteSrv.continueProcess(
            fieldOrder,
            event,
            this.quote,
            event.eventPage,
            this.statusBenchmark,
            this.type
        );
        this.type = this.quoteSrv.diff(
            this.type,
            this.quote,
            this.submittedQuote
        );
        let autoReferralResult = false;
        if (event.referral) {
            autoReferralResult = this.quoteSrv.autoReferral();
        }
//console.log("this", this);
        if ( event.noScroll || fieldOrder === this["page" + this.currentPage + "Offset"].length ){
            CommonUtility.trace("continueEventEmitter 2", arguments, autoReferralResult);
        }else{
            CommonUtility.trace("continueEventEmitter 3", arguments, autoReferralResult);
            if (event.finished && !autoReferralResult) {
                this.scrollTo(fieldOrder);
            }
        }
    }
    specialEventEmitter(event: any) {
        this.quoteSrv.continueProcess(
            event.order,
            event,
            this.quote,
            event.tab,
            this.statusBenchmark,
            this.type
        );
        this.type = this.quoteSrv.diff(
            this.type,
            this.quote,
            this.submittedQuote
        );
        if (this.currentPage !== event.tab) {
            this.jumpTo(event.tab, 0);
        }
        switch (event.tab) {
            case 2:
                switch (event.order) {
                    case 1:
                        this.resetInsuranceType();
                        break;
                    case 3:
                        this.resetMaximumProjectDuration();
                        break;
                    case 4:
                        this.resetDefectsLiabilityPeriod();
                        break;
                    case 5:
                        this.resetProjectType();
                        break;
                    case 7:
                        this.resetMajorityOfWork();
                        break;
                }
                break;
            case 3:
                switch (event.order) {
                    case 2:
                        this.resetProjectInvolvement();
                        break;
                }
                break;
            case 4:
                switch (event.order) {
                    case 2:
                        this.resetMaterialDamage(event.page);
                        break;
                    case 4:
                        this.resetLiability(event.page);
                        break;
                    case 6:
                        this.resetEnhanceYourCover();
                        break;
                }
        }
    }
    resetInsuranceType(): void {
        this.quote.insuranceType = "";
        this.quote.insuranceTypeReferral = false;
    }
    resetMaximumProjectDuration(): void {
        this.quote.mpdReferral = false;
    }
    resetDefectsLiabilityPeriod(): void {
        this.quote.dlpReferral = false;
    }
    resetProjectType(): void {
        this.tempQuote.componentProjectType.go(1);
        this.tempQuote.componentProjectType.tab1Finished = false;
        this.tempQuote.componentProjectType.tab2Finished = false;
        this.tempQuote.componentProjectType.tab3Finished = false;
        this.tempQuote.componentProjectType.percentTabEnabled = false;
        this.tempQuote.componentProjectType.otherTabEnabled = false;
        this.tempQuote.componentProjectType.number = 0;
        this.tempQuote.componentProjectType.ownerBuilderSpecialError = false;
        this.quote.projectTypeNRB = false;
        this.quote.projectTypeNCIB = false;
        this.quote.projectTypeNSRFA = false;
        this.quote.projectTypeAR = false;
        this.quote.projectTypeACIB = false;
        this.quote.projectTypeOther = false;
        this.quote.projectTypePercentNRB = null;
        this.quote.projectTypePercentNCIB = null;
        this.quote.projectTypePercentNSRFA = null;
        this.quote.projectTypePercentAR = null;
        this.quote.projectTypePercentACIB = null;
        this.quote.projectTypePercentOther = null;
        this.quote.projectTypeDetails = null;
        this.quote.projectTypeReferral = false;
        this.quote.errorProjectType = false;
        this.quote['_scheme'] = null;
    }
    resetMajorityOfWork(): void {
        this.quote.suburb = null;
        this.quote.errorMajorityOfWork = false;
        this.quote.majorityOfWorkReferral = false;
        this.quote.suburbId = null;
    }
    resetProjectInvolvement(): void {
        this.quote.pit1q1 = false;
        this.quote.pit1q2 = false;
        this.quote.pit1q3 = false;
        this.quote.pit1q4 = false;
        this.quote.pit2q1 = false;
        this.quote.pit2q2 = false;
        this.quote.pit2q3 = false;
        this.quote.pit2q4 = false;
        this.quote.pit3q1 = false;
        this.quote.pit3q2 = false;
        this.quote.pit3q3 = false;
        this.quote.pit3q4 = false;
        this.quote.pit4q1 = false;
        this.quote.pit4q2 = false;
        this.quote.pit4q3 = false;
        this.quote.pit4q4 = false;
        this.quote.piDetails = null;
        this.quote.piReferral = null;
    }
    resetMaterialDamage(page: number): void {
        if (page === 2) {
            this.tempQuote.componentMaterialDamaged.tab1Finished = true;
            this.tempQuote.componentMaterialDamaged.tab2Enabled = true;
            this.quote.materialDamageFinished = true;
        } else {
            this.tempQuote.componentMaterialDamaged.tab1Finished = false;
            this.tempQuote.componentMaterialDamaged.tab2Enabled = false;
            this.quote.materialDamageFinished = false;
        }
        this.tempQuote.componentMaterialDamaged.go(page);
        this.quote.materialDamageEstimation = null;
        this.quote.projectCost = null;
        this.quote.existingStructures = null;
        this.quote.existingStructuresA = null;
        this.quote.existingStructuresB = null;
        this.quote.existingStructuresC = null;
        this.quote.existingStructuresD = null;
        this.quote.principalSupplied = null;
        this.quote.contractorsPlant = null;
        this.quote.projectCostReferral = false;
        this.quote.existingStructuresReferral = false;
        this.quote.principalSuppliedReferral = false;
        this.quote.contractorsPlantReferral = false;
        this.quote.poorConditionReferral = false;
        this.quote['_scheme'] = null;
    }
    resetLiability(page: number): void {
        if (page === 2) {
            this.tempQuote.componentLiability.tab1Finished = true;
            this.tempQuote.componentLiability.tab2Enabled = true;
            this.quote.liabilityFinished = true;
        } else {
            this.tempQuote.componentLiability.tab1Finished = false;
            this.tempQuote.componentLiability.tab2Enabled = false;
            this.quote.liabilityFinished = false;
        }
        this.tempQuote.componentLiability.go(page);
        this.quote.liabilityEstimation = null;
        this.quote.publicLiability = null;
        this.quote.vibrationWeakeningRemovalOfSupport = null;
        this.quote.productLiability = null;
        this.quote.propertyInCareCustodyControl = null;
        this.quote.publicLiabilityReferral = false;
        this.quote.vibrationWeakeningRemovalOfSupportReferral = false;
        this.quote.productLiabilityReferral = false;
        this.quote.propertyInCareCustodyControlReferral = false;
        this.quote['_scheme'] = null;
    }
    resetEnhanceYourCover(): void {
        if (!GlobalStaticService.scheme) {
            this.quote.cover1 = false;
            this.quote.enhance1A = null;
            this.quote.enhance1B = null;
            this.quote.enhance1C = null;
            this.quote.enhance1D = null;
            this.quote.enhance1Referral = false;
            this.quote.cover2 = false;
            this.quote.cover3 = false;
            this.quote.cover3Referral = false;
            this.quote.cover6 = false;
            this.quote.cover6Referral = false;
        }
        this.quote.cover4 = false;
        this.quote.cover4Referral = false;
        this.quote.enhance4A = null;
        this.quote.enhance4Referral = false;
        this.quote.cover5 = false;
        this.quote.cover5Referral = false;
        const referralPosition = this.quote.referral.indexOf("p4q6");
        if (referralPosition !== -1) {
            this.quote.referral.splice(referralPosition, 1);
        }
        if (
            (this.quote.businessTypeReferralCPL && this.quote.cover1) ||
            this.quote.cover5Referral ||
            this.quote.cover3Referral ||
            this.quote.cover4Referral ||
            this.quote.enhance4Referral ||
            this.quote.cover6Referral ||
            this.quote.enhance1Referral
        ) {
            this.quote.referral.push("p4q6");
        }
    }
    offsetCheck(page: number): void {
        switch (page) {
            case 1:
                this.page1Offset = [];
                setTimeout(() => {
                    this.page1Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.insuredName.nativeElement
                        )
                    );
                    this.page1Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.insuredType.nativeElement
                        )
                    );
                    /* this.page1Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.buildingLicense.nativeElement
                        )
                    ); */
                    this.page1Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.businessOfInsured.nativeElement
                        )
                    );
                    this.page1Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.insuredDeclaration.nativeElement
                        )
                    );
                    this.insuredNameHeight = this.insuredNameWrapper.nativeElement.offsetHeight;
                    this.insuredTypeHeight = this.insuredTypeWrapper.nativeElement.offsetHeight;
                    //this.buildingLicenseHeight = this.buildingLicenseWrapper.nativeElement.offsetHeight;
                    this.businessOfInsuredHeight = this.businessOfInsuredWrapper.nativeElement.offsetHeight;
                    this.insuredDeclarationHeight = this.insuredDeclarationWrapper.nativeElement.offsetHeight;
                }, 100);
                break;
            case 2:
                this.page2Offset = [];
                setTimeout(() => {
                    this.page2Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.insuranceType.nativeElement
                        )
                    );
                    this.page2Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.policyDate.nativeElement
                        )
                    );
                    this.page2Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.maximumProjectDuration.nativeElement
                        )
                    );
                    this.page2Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.defectsLiabilityPeriod.nativeElement
                        )
                    );
                    this.page2Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.projectType.nativeElement
                        )
                    );
                    this.page2Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.workLocation.nativeElement
                        )
                    );
                    this.page2Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.majorityOfWork.nativeElement
                        )
                    );
                    this.insuranceTypeHeight = this.insuranceTypeWrapper.nativeElement.offsetHeight;
                    this.policyDateHeight = this.policyDateWrapper.nativeElement.offsetHeight;
                    this.maximumProjectDurationHeight = this.maximumProjectDurationWrapper.nativeElement.offsetHeight;
                    this.defectsLiabilityPeriodHeight = this.defectsLiabilityPeriodWrapper.nativeElement.offsetHeight;
                    this.projectTypeHeight = this.projectTypeWrapper.nativeElement.offsetHeight;
                    this.workLocationHeight = this.workLocationWrapper.nativeElement.offsetHeight;
                    this.majorityOfWorkHeight = this.majorityOfWorkWrapper.nativeElement.offsetHeight;
                }, 100);
                break;
            case 3:
                this.page3Offset = [];
                setTimeout(() => {
                    this.page3Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.ongoingWork.nativeElement
                        )
                    );
                    this.page3Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.projectInvolvements.nativeElement
                        )
                    );
                    this.ongoingWorkHeight = this.ongoingWorkWrapper.nativeElement.offsetHeight;
                    this.projectInvolvementsHeight = this.projectInvolvementsWrapper.nativeElement.offsetHeight;
                }, 100);
                break;
            case 4:
                this.page4Offset = [];
                setTimeout(() => {
                    this.page4Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.premiumDeposit.nativeElement
                        )
                    );
                    this.page4Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.materialDamage.nativeElement
                        )
                    );
                    this.page4Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.subLimit.nativeElement
                        )
                    );
                    this.page4Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.liability.nativeElement
                        )
                    );
                    // this.page4Offset.push(
                    //     this.quoteSrv.offsetCalculate(
                    //         this.tailorYourCover.nativeElement
                    //     )
                    // );
                    this.page4Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.enhanceYourCover.nativeElement
                        )
                    );
                    this.premiumDepositHeight = this.premiumDepositWrapper.nativeElement.offsetHeight;
                    this.materialDamageHeight = this.materialDamageWrapper.nativeElement.offsetHeight;
                    this.subLimitHeight = this.subLimitWrapper.nativeElement.offsetHeight;
                    this.liabilityHeight = this.liabilityWrapper.nativeElement.offsetHeight;
                    //this.tailorYourCoverHeight = this.tailorYourCoverWrapper.nativeElement.offsetHeight;
                    this.enhanceYourCoverHeight = this.enhanceYourCoverWrapper.nativeElement.offsetHeight;
                }, 100);
                break;
            case 5:
                this.page5Offset = [];
                setTimeout(() => {
                    this.page5Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.brokerCharges.nativeElement
                        )
                    );
                    this.page5Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.brokerComments.nativeElement
                        )
                    );
                    this.page5Offset.push(
                        this.quoteSrv.offsetCalculate(
                            this.attachments.nativeElement
                        )
                    );
                    this.brokerChargesHeight = this.brokerChargesWrapper.nativeElement.offsetHeight;
                    this.brokerCommentsHeight = this.brokerCommentsWrapper.nativeElement.offsetHeight;
                    this.attachmentsHeight = this.attachmentsWrapper.nativeElement.offsetHeight;
                }, 100);
                break;
            case 6:
                this.page6Offset = [];
                this.page6Offset.push(
                    this.questionSummary.nativeElement.offsetTop
                );
                this.page6Offset.push(
                    this.quoteSummary.nativeElement.offsetTop + 50
                );
                break;
        }
    }
    offsetRecheckEventEmitter(event: number) {
        this.offsetCheck(event);
    }
    edit(tab: number, order: number) {
        this.jumpTo(tab, order);
        this.go(tab);
    }
    editEventEmitter(edit: any) {
        this.edit(edit.tab, edit.order);
    }
    submit(): void {
        if (
            !this.quote.quick ||
            (this.quote.quick && this.quote.quickConfirm)
        ) {
            gtag("set", {
                user_id: GlobalStaticService.brokerProfile.contactId
            });
            gtag("event", "Quote", {});
            this.quoteSrv.openSubmit(
                "Submitting form",
                !!this.quote.referral.length
            );
            if (this.quote.newClientFlag) {
                this.commonApi.postClients(this.quote.client).subscribe(
                    res => {
                        this.quote.client.clientId = res.clientId;
                        this.submitQuote();
                    },
                    err => {
                        GlobalStaticService.openUnexpectedErrorModal();
                    }
                );
            } else {
                this.submitQuote();
            }
        } else {
            GlobalStaticService.openQuickQuoteCheckModal();
        }
    }
    submitQuote(): void {
        if (this.type === "create") {
            const date = new Date();
            this.quote.quoteDate = `${date.getFullYear()}-${date.getMonth() +
                1}-${date.getDate()}`;
            const dateExpired = new Date();
            dateExpired.setDate(dateExpired.getDate() + 30);
            this.quote.quoteExpiryDate = `${dateExpired.getFullYear()}-${dateExpired.getMonth() +
                1}-${dateExpired.getDate()}`;
            this.quote.briefDescription = "Online New Business";
            this.commonApi.postQuotes(this.quote).pipe(
                timeout(1000 * 105),
            ).subscribe(
                res => {
                    if (res.error) {
                        this.quote.quoteId = res.quoteId;
                        this.quote["_postError"] = true;
                        this.quote["_referral"] = true;
                        this.quote['_referralError'] = true;
                        this.quote.providerQuoteReference =
                            res.providerQuoteReference;
                        this.afterSubmission();
                    } else {
                        this.quote.quoteId = res.quote.QuoteId;
                        this.quote.providerQuoteReference =
                            res.quote.providerQuoteReference;
                        this.quote.underwriterName = res.quote.underwriterName;
                        this.quote.policyWording = res.quote.policyWording;
                        this.quote.origQuoteId = res.quote.origQuoteId;
                        this.quote.quoteTypeText = res.quote.quoteTypeText;
                        this.commonApi
                            .getQuote(res.quote.QuoteId)
                            .subscribe(res2 => {
                                GlobalStaticService.addRecent(
                                    "recentQuotes",
                                    res2
                                );
                                this.quote.needs = res2.needs;
                                let x = this.quote.needs["116217"];
                                if (x) {
                                    this.quote.territorialLimit = parseInt(x);
                                }

                                /*
                                if (this.quote.insuranceType !== "md") {
                                    this.quote.tailorYourCover = Number(
                                        QuoteMappingStaticService.mapOption(
                                            false,
                                            "tailorYourCoverLValue",
                                            this.quote.needs["116296"][1]
                                        )
                                    );
                                }
                                */
                                this.quote._raw_referrals = res2._raw_referrals
                                this.quote._referral = res2._referral;
                                this.quote._referralOverridden = res2._referralOverridden;
console.log("this.quote", this.quote.tailorYourCover, this.quote)
                                this.submitResultProcessing(res);
                            });
                    }
                },
                err => {
                    GlobalStaticService.openSubmitQuoteErrorModalAndReturnHome(this.router, false);
                }
            );
        } else {
            this.commonApi.putQuotes(this.quote).subscribe(
                res => {
                    this.submitResultProcessing(res);
                },
                err => {
                    GlobalStaticService.openSubmitQuoteErrorModalAndReturnHome(this.router, false);
                }
            );
        }
    }
    submitResultProcessing(res: any): void {
        let clientFinished = false;
        let wordingFinished = false;
        let facilityFinished = false;
        let sectionFinished = false;
        this.commonApi.getClient(this.quote.client.clientId).subscribe(res2 => {
            this.quote.client = res2;
            if (res2.tradingNames) {
                this.quote.client.tradingNames = res2.tradingNames;
                this.quote.tradingNames = JSON.parse(
                    JSON.stringify(this.quote.client.tradingNames)
                );
            }
            if (this.quote.newClientFlag) {
                GlobalStaticService.addRecent("recentClients", res2);
                this.quote.newClientFlag = false;
            }
            clientFinished = true;
            this.attachmentSubmitting(
                clientFinished,
                wordingFinished,
                sectionFinished,
                facilityFinished
            );
        });
        this.quote.option = res.option;
        if (res.option && res.option.OptionId) {
            this.commonApi
                .getQuoteFacility(this.quote.option.OptionId)
                .subscribe(res2 => {
                    this.quote.facility = [];
                    if (res2.length) {
                        this.quoteSrv
                            .getFacility(res2, this.quote.productId)
                            .then(facility => {
                                this.quote.facility = facility;
                                facilityFinished = true;
                                this.attachmentSubmitting(
                                    clientFinished,
                                    wordingFinished,
                                    sectionFinished,
                                    facilityFinished
                                );
                            });
                    } else {
                        facilityFinished = true;
                        this.attachmentSubmitting(
                            clientFinished,
                            wordingFinished,
                            sectionFinished,
                            facilityFinished
                        );
                    }
                });

            this.commonApi
                .getQuoteSections(this.quote.quoteId)
                .subscribe(res2 => {
                    this.quote.sections = [];
                    this.quote.sectionsMeconAdminFeeGst = 0;
                    if (res2.length) {
                        this.quote.sections = res2;
                        let loading = 0;
                        for (let i = 0; i < res2.length; i++) {
                            if (
                                this.quote.sections[i].sectionTitle ===
                                "Contractual Liability"
                            ) {
                                this.quote.sections.splice(i, 1);
                                loading++;
                                if (loading === res2.length) {
                                    sectionFinished = true;
                                    this.attachmentSubmitting(
                                        clientFinished,
                                        wordingFinished,
                                        sectionFinished,
                                        facilityFinished
                                    );
                                }
                            } else {
                                this.quote.sections[i].rating = new Rating();
                                this.commonApi
                                    .getQuoteRating(
                                        this.quote.option.OptionId,
                                        res2[i].productSectionId
                                    )
                                    .subscribe(res3 => {
                                        this.quote.sections[
                                            i
                                        ].rating = QuoteMappingStaticService.processRatingSection(
                                            res3
                                        );
                                        this.quote.sectionsMeconAdminFeeGst =
                                            this.quote
                                                .sectionsMeconAdminFeeGst +
                                            this.quote.sections[i].rating
                                                .meconAdminFeeGst;
                                        loading++;
                                        if (loading === res2.length) {
                                            sectionFinished = true;
                                            this.attachmentSubmitting(
                                                clientFinished,
                                                wordingFinished,
                                                sectionFinished,
                                                facilityFinished
                                            );
                                        }
                                    });
                            }
                        }
                    } else {
                        sectionFinished = true;
                        this.attachmentSubmitting(
                            clientFinished,
                            wordingFinished,
                            sectionFinished,
                            facilityFinished
                        );
                    }
                });
        } else {
            this.quote.facility = [];
            this.quote.sections = [];
            facilityFinished = true;
            sectionFinished = true;
            this.attachmentSubmitting(
                clientFinished,
                wordingFinished,
                sectionFinished,
                facilityFinished
            );
        }
        this.commonApi.getQuoteWording(this.quote.quoteId).subscribe(res2 => {
            this.quote.wording = "";
            if (res2.length) {
                this.quote.wording = res2
                    .filter(p => {
                        return p.paragraphId === 1;
                    })
                    .map(p => {
                        return p.paragraphText;
                    })
                    .join("")
                    .replace(/font-family:/gi, "");
            }
            wordingFinished = true;
            this.attachmentSubmitting(
                clientFinished,
                wordingFinished,
                sectionFinished,
                facilityFinished
            );
        });
        if (res.rating) {
            this.quote.rating = QuoteMappingStaticService.processRatingSection(
                res.rating
            );
        } else {
            this.quote.rating = new Rating();
        }
        this.quote = this.quoteSrv.copyMappingProcess(this.quote);
        this.quote.territorialLimit = Number(res.quote.territorialLimit);
        for (
            let i = 0;
            i <
            QuoteMappingStaticService.annualLookups[116217].valueLookups.length;
            i++
        ) {
            if (
                QuoteMappingStaticService.annualLookups[116217].valueLookups[i]
                    .valueId === this.quote.territorialLimit
            ) {
                this.quote.territorialLimitDescription =
                    QuoteMappingStaticService.annualLookups[116217].valueLookups[
                        i
                    ].value;
            }
        }
        if (res.quote.policyWording) {
            for (
                let i = 0;
                i <
                QuoteMappingStaticService.annualLookups[116214].valueLookups
                    .length;
                i++
            ) {
                if (
                    QuoteMappingStaticService.annualLookups[116214]
                        .valueLookups[i].valueId ===
                    Number(res.quote.policyWording)
                ) {
                    this.quote.policyWording =
                        QuoteMappingStaticService.annualLookups[116214].valueLookups[
                            i
                        ].value;
                }
            }
        }
    }
    attachmentSubmitting(
        clientFinished: boolean,
        wordingFinished: boolean,
        sectionFinished: boolean,
        facilityFinished: boolean
    ): void {
        if (
            clientFinished &&
            wordingFinished &&
            sectionFinished &&
            facilityFinished
        ) {
            this.quoteSrv.submitAttachment(this.quote).then(() => {
                this.afterSubmission();
            });
        }
    }
    afterSubmission(): void {
        this.type = "edit";
        this.offsetCheck(6);
        this.submittedQuote = QuoteMappingStaticService.deepCopy(this.quote);
        GlobalStaticService.closeSubmitLoading();
        setTimeout(() => {
            this.scrollTo(1);
        }, 100);
        GlobalStaticService.cleanQuoteAutoSave(this.quote.type === "single");
    }
    loadTemplateEmitter(quote: Quote): void {
        this.quote = QuoteMappingStaticService.deepCopy(quote);
        this.quote.createdInvalidDate = moment().add(30, "days");
        this.quote.quick = false;
        setTimeout(() => {
            this.quoteSrv.getEditScheme(48, this.quote);
        });
        GlobalStaticService.dydSetTimeout = setTimeout(() => {
            GlobalStaticService.isDydEnabled = !this.quote.reachSummary;
        }, 10000);
    }
    backToSummary(): void {
        if (
            this.quote.page1OkFlag &&
            this.quote.page2OkFlag &&
            this.quote.page3OkFlag &&
            this.quote.page4OkFlag &&
            this.quote.page5OkFlag
        ) {
            this.go(6);
        } else {
            for (let i = 1; i < 6; i++) {
                if (!this.quote["page" + i + "OkFlag"]) {
                    const status = "page" + i + "Status";
                    this.quote[status].sort(function(a, b) {
                        return a - b;
                    });
                    for (let j = 0; j < this.statusBenchmark[i - 1]; j++) {
                        if (this.quote[status][j] !== j + 1) {
                            if (this.currentPage === i) {
                                this.scrollTo(j);
                            } else {
                                this.edit(i, j);
                            }
                            return;
                        }
                    }
                    return;
                }
            }
        }
    }
    bindEventEmitter(event: boolean): void {
        if (event) {
            GlobalStaticService.isQuoteBind = true;
        }
    }
    private updateUiReferralFlags(): void {
        this.quote.referral = [];
       /*  if (this.quote.buildingLicenseReferral) {
            this.quote.referral.push("p1q3");
        } */
        if (this.quote.businessTypeReferral) {
      //      this.quote.referral.push("p1q4");
            this.quote.referral.push("p1q3");
        }
        if (this.quote.insuredDeclaration) {
        //    this.quote.referral.push("p1q5");
            this.quote.referral.push("p1q4");
        }
        if (this.quote.policyDateReferral) {
            this.quote.referral.push("p2q2");
        }
        if (this.quote.mpdReferral) {
            this.quote.referral.push("p2q3");
        }
        if (this.quote.dlpReferral) {
            this.quote.referral.push("p2q4");
        }
        if (
            this.quote.businessTypeReferralMd ||
            this.quote.businessTypeReferralL
        ) {
            this.quote.insuranceTypeReferral = true;
            this.quote.referral.push("p2q1");
        }
        if (this.quote.projectTypeReferral) {
            this.quote.referral.push("p2q5");
        }
        if (this.quote.workLocationReferral) {
            this.quote.referral.push("p2q6");
        }
        if (
            this.quote.materialDamageEstimationReferral ||
            this.quote.projectCostReferral ||
            this.quote.existingStructuresReferral ||
            this.quote.principalSuppliedReferral ||
            this.quote.contractorsPlantReferral
        ) {
            this.quote.referral.push("p4q2");
        }
        if (
            this.quote.liabilityEstimationReferral ||
            this.quote.publicLiabilityReferral ||
            this.quote.vibrationWeakeningRemovalOfSupportReferral ||
            this.quote.propertyInCareCustodyControlReferral ||
            this.quote.productLiabilityReferral
        ) {
            this.quote.referral.push("p4q4");
        }
        this.quote.cover3Referral =
            this.quote.cover3 &&
            this.quote.projectCost > QUOTE_REFERRAL.eaLimitAp;
        this.quote.cover6Referral = this.quote.cover6;
        if (
            this.quote.cover3Referral ||
            this.quote.cover6Referral ||
            (this.quote.businessTypeReferralCPL && this.quote.cover1)
        ) {
            this.quote.referral.push("p4q6");
        }
        // Broker comments
        if( this.quote.brokerComments && this.quote.brokerComments.length ){
            this.quote.referral.push("p5q2");
        }
        // attachments
        //if( this.quote.attachments && this.quote.attachments.length ){
        //    this.quote.referral.push("p5q3");
        //}
    }
    quickEventEmitter(event: any): void {
        if (event) {
            this.setDefaultOnGoing();
            // Majority of work
            this.setDefaultMajorityOfWork();
            this.quote = this.quoteSrv.editProcess(
                this.quote,
                this.statusBenchmark
            );
            this.updateUiReferralFlags();
            this.go(6);
        }
    }
    setDefaultOnGoing(): void {
        this.tempQuote.componentOnGoing.tab1Finished = true;
        this.tempQuote.componentOnGoing.tab2Finished = true;
        this.tempQuote.componentOnGoing.tab2Enabled = true;
        this.tempQuote.componentOnGoing.go(2);
    }
    setDefaultMajorityOfWork(): void {
        const max = Math.max(
            this.quote.workLocationNT,
            this.quote.workLocationQLDa + this.quote.workLocationQLDb,
            this.quote.workLocationWAa + this.quote.workLocationWAb,
            this.quote.workLocationSA,
            this.quote.workLocationNSW,
            this.quote.workLocationVIC,
            this.quote.workLocationACT,
            this.quote.workLocationTAS
        );
        let area, scope;
        if (this.quote.workLocationSA === max) {
            area = "5010";
            scope = "sa";
        }
        if (this.quote.workLocationNSW === max) {
            area = "2140";
            scope = "nsw";
        }
        if (this.quote.workLocationVIC === max) {
            area = "3060";
            scope = "vic";
        }
        if (this.quote.workLocationACT === max) {
            area = "2609";
            scope = "act";
        }
        if (this.quote.workLocationTAS === max) {
            area = "7250";
            scope = "tas";
        }
        if (this.quote.workLocationNT === max) {
            area = "0800";
            scope = "nt1,nt2";
        }
        if (this.quote.workLocationQLDa + this.quote.workLocationQLDb === max) {
            if (this.quote.workLocationQLDa >= this.quote.workLocationQLDb) {
                area = "4870";
            } else {
                area = "4170";
            }
            scope = "qld1,qld2";
        }
        if (this.quote.workLocationWAa + this.quote.workLocationWAb === max) {
            if (this.quote.workLocationWAa >= this.quote.workLocationWAb) {
                area = "6725";
            } else {
                area = "6105";
            }
            scope = "wa1,wa2";
        }
        this.commonApi.getPostcodes(area, scope).subscribe(res => {
            let suburb;
            switch (area) {
                case "6725":
                    suburb = res.filter(e => {
                        return e.suburb === "BROOME";
                    })[0];
                    break;
                case "4870":
                    suburb = res.filter(e => {
                        return e.suburb === "CAIRNS";
                    })[0];
                    break;
                default:
                    suburb = res[0];
                    break;
            }
            this.quote.suburb = `${suburb.suburb} / ${suburb.state} / ${
                suburb.postcode
            }`;
            this.quote.suburbData = res[0];
            this.quote.errorMajorityOfWork = false;
            this.quote.majorityOfWorkReferral = suburb.apReferralOnline;
            if (!suburb.apReferralOnline) {
                this.quote.majorityOfWorkReferral = !this.quoteSrv.embargoCheckQuote(this.quote);
            }
            if (this.quote.majorityOfWorkReferral) {
                this.quote.referral.push("p2q7");
            }
            this.quote.suburbId = suburb.townId;
        });
    }
    beforeBindQuote(): void {
        if (!GlobalStaticService.isQuoteBind) {
            if (this.quoteSrv.embargoCheckQuote(this.quote)) {
                this.quoteSrv.bindPolicyCheck(this.quote);
            } else {
                this.quoteSrv.embargoReferral(this.quote);
            }
        }
    }
    beforeEmail(): void {
        if (!GlobalStaticService.isQuoteBind) {
            if (this.quoteSrv.embargoCheckQuote(this.quote)) {
                this.quoteSrv.emailQuoteCheck(this.quote);
            } else {
                this.quoteSrv.embargoReferral(this.quote);
            }
        }
    }
    quickConfirmUpdate(): void {
        if (this.type === "create") {
            this.quote.quickConfirm = !this.quote.quickConfirm;
        }
    }
}
