export class SingleQuote {
    productId = 47;
    // InsuredName
    clientName: any;
    insuredName: any;
    clientId: any;
    quoteDate: string;
    quoteExpiryDate: string;
    effectiveDate: string;
    inceptionDate: string;
    termExpiryDate: string;
    briefDescription: string;
    needs = {};
    _referral: boolean;
    _referrals: {};
}
