import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { QuoteService } from "../../services/quote.service";
import { Quote } from "../../models/quote";
import { CommonApi } from "../../api/common.api";
import { STATE } from "../../configs/state";
import { QUOTE_MAPPING } from "../../configs/quote-mapping";
import { CommonUtility } from "../../utilities/common.utility";

@Component({
    selector: "app-add-address-modal-component",
    templateUrl: "./add-address-modal.component.html",
    styleUrls: ["./add-address-modal.component.scss"]
})
export class AddAddressModalComponent {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: any;
    @Input("type") type: string;
    lot: number;
    unit: number;
    street: string;
    suburb: string;
    finished = false;
    suburbs: any[] = [];
    searchActive = false;
    resultActive = false;
    tempReferral = false;
    error = false;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor(private commonApi: CommonApi, private quoteSrv: QuoteService) { }
    next(): void {
        if (this.finished && this.street && this.quote.stateId) {
            this.setAddress();
            this.quote.addressFinished = true;
            this.quote.errorAddress = false;
            this.continueEventEmitter.emit({
                finished: this.quote.addressFinished,
                noScroll: true,
                referral: this.quote.addressReferral
            });
            if (this.type === "policy") {
                GlobalStaticService.closeNewAddAddressModal();
            } else {
                GlobalStaticService.closeAddAddressModal();
            }
            this.clean();
        } else {
            this.error = true;
        }
    }
    change(): void {
        if (this.street && this.finished) {
            this.error = false;
        }
    }
    searchFocus(): void {
        if (this.suburb && this.suburb.length >= 3) {
            this.searchActive = true;
            this.search();
        } else {
            this.searchActive = false;
        }
    }
    searchChange(): void {
        this.finished = false;
        this.searchFocus();
        this.quote.addressReferral = false;
        this.tempReferral = false;
        this.quote.suburbId = null;
        this.continueEventEmitter.emit({
            finished: false,
            referral: this.quote.addressReferral
        });
    }
    search(): void {
        this.resultActive = true;
        this.commonApi.getPostcodesAll(this.suburb).subscribe(res => {
            this.suburbs = res;
            this.resultActive = false;
        });
    }
    searchBlur(): void {
        setTimeout(() => {
            this.searchActive = false;
            this.resultActive = false;
            this.suburbs = [];
        }, 200);
    }
    chooseSuburb(suburb: any): void {
        this.suburb = `${suburb.suburb} ${suburb.state} ${suburb.postcode}`;
        this.commonApi
            .getSuburbPostcode(suburb.postcode, suburb.suburb)
            .subscribe(
                res => {
                    this.quote.suburbData = res;
                    this.quote.suburbData.postcode = suburb.postcode;
                    this.quote.suburbData.state = suburb.state;
                    this.quote.suburbData.stateName =
                        STATE[suburb.state].stateName;
                    this.quote.suburbData.suburb = suburb.suburb.toUpperCase();
                    this.quote.suburbId = res.townId;
                    this.quote.addressReferral = res.spReferralOnline;
                    if (!res.spReferralOnline) {
                        this.quote.addressReferral = !this.quoteSrv.embargoCheckQuote(this.quote);
                    }
                    this.tempReferral = this.quote.addressReferral;
                    this.quote.stateId = res.stateId;
                },
                err => {
                    switch (err.status) {
                        case 404:
                            this.quote.suburbData = {};
                            this.quote.suburbData.postcode = suburb.postcode;
                            this.quote.suburbData.state = suburb.state;
                            this.quote.suburbData.stateName =
                                STATE[suburb.state].stateName;
                            this.quote.suburbData.suburb = suburb.suburb.toUpperCase();
                            this.quote.suburbData.stateId =
                                STATE[suburb.state].stateId;
                            this.quote.stateId = this.quote.suburbData.stateId;
                            this.quote.addressReferral = true;
                            this.tempReferral = true;
                            break;
                        default:
                            GlobalStaticService.openErrorSuburbModal();
                            break;
                    }
                }
            );
        this.finished = true;
        this.searchActive = false;
        this.resultActive = false;
        if (this.street) {
            this.error = false;
        }
        this.suburbs = [];
        if (this.type === "policy") {
            if (this.quote.addressReferral) {
                this.referral(true);
                return;
            }
            if (
                this.quote.type === "single" ||
                (this.quote.type === "annual" && this.quote.legacy)
            ) {
                if (!this.quote.territorialLimit) {
                    this.referral();
                    return;
                }
                switch (Number(this.quote.territorialLimit)) {
                    case QUOTE_MAPPING.territorialLimit.qldNotAbove:
                    case QUOTE_MAPPING.territorialLimitLegacy.qldNotAbove:
                        // QLD + Above false
                        if (
                            suburb.stateId !== 4 ||
                            suburb.isAbove25thParallel
                        ) {
                            this.referral();
                            return;
                        }
                        break;
                    case QUOTE_MAPPING.territorialLimitLegacy.qldNswNotAbove:
                        // QLD + NSW + Above false
                        if (
                            (suburb.stateId !== 4 && suburb.stateId !== 2) ||
                            suburb.isAbove25thParallel
                        ) {
                            this.referral();
                            return;
                        }
                        break;
                    case QUOTE_MAPPING.territorialLimit.qld:
                    case QUOTE_MAPPING.territorialLimitLegacy.qld:
                        // QLD
                        if (suburb.stateId !== 4) {
                            this.referral();
                            return;
                        }
                        break;
                    case QUOTE_MAPPING.territorialLimit.nt:
                    case QUOTE_MAPPING.territorialLimitLegacy.nt:
                        // NT
                        if (suburb.stateId !== 3) {
                            this.referral();
                            return;
                        }
                        break;
                    case QUOTE_MAPPING.territorialLimit.nsw:
                    case QUOTE_MAPPING.territorialLimitLegacy.nsw:
                        // NSW
                        if (suburb.stateId !== 2) {
                            this.referral();
                            return;
                        }
                        break;
                    case QUOTE_MAPPING.territorialLimitLegacy.nswVic:
                        // NSW + VIC
                        if (suburb.stateId !== 2 && suburb.stateId !== 7) {
                            this.referral();
                            return;
                        }
                        break;
                    case QUOTE_MAPPING.territorialLimit.act:
                    case QUOTE_MAPPING.territorialLimitLegacy.act:
                        // ACT
                        if (suburb.stateId !== 1) {
                            this.referral();
                            return;
                        }
                        break;
                    case QUOTE_MAPPING.territorialLimit.vic:
                    case QUOTE_MAPPING.territorialLimitLegacy.vic:
                        // VIC
                        if (suburb.stateId !== 7) {
                            this.referral();
                            return;
                        }
                        break;
                    case QUOTE_MAPPING.territorialLimitLegacy.vicTas:
                        // VIC + TAS
                        if (suburb.stateId !== 7 && suburb.stateId !== 6) {
                            this.referral();
                            return;
                        }
                        break;
                    case QUOTE_MAPPING.territorialLimit.tas:
                    case QUOTE_MAPPING.territorialLimitLegacy.tas:
                        // TAS
                        if (suburb.stateId !== 6) {
                            this.referral();
                            return;
                        }
                        break;
                    case QUOTE_MAPPING.territorialLimit.sa:
                    case QUOTE_MAPPING.territorialLimitLegacy.sa:
                        // SA
                        if (suburb.stateId !== 5) {
                            this.referral();
                            return;
                        }
                        break;
                    case QUOTE_MAPPING.territorialLimit.waNotAbove:
                    case QUOTE_MAPPING.territorialLimitLegacy.waNotAbove:
                        // WA + Above false
                        if (
                            suburb.stateId !== 8 ||
                            suburb.isAbove25thParallel
                        ) {
                            this.referral();
                            return;
                        }
                        break;
                    case QUOTE_MAPPING.territorialLimit.wa:
                    case QUOTE_MAPPING.territorialLimitLegacy.wa:
                        // WA
                        if (suburb.stateId !== 8) {
                            this.referral();
                            return;
                        }
                        break;
                    case QUOTE_MAPPING.territorialLimitLegacy.nswAct:
                        // NSW + ACT
                        if (suburb.stateId !== 2 || suburb.stateId !== 1) {
                            this.referral();
                            return;
                        }
                        break;
                    case QUOTE_MAPPING.territorialLimit.auNotAbove:
                    case QUOTE_MAPPING.territorialLimitLegacy.auNotAbove:
                        // AU + Above false
                        if (suburb.isAbove25thParallel) {
                            this.referral();
                            return;
                        }
                        break;
                    case QUOTE_MAPPING.territorialLimitLegacy.saNsw:
                        // NSW + SA
                        if (suburb.stateId !== 2 && suburb.stateId !== 5) {
                            this.referral();
                            return;
                        }
                        break;
                    case QUOTE_MAPPING.territorialLimit.au:
                    case QUOTE_MAPPING.territorialLimitLegacy.au:
                        // Within Australia + Above true
                        if (suburb.isAbove25thParallel) {
                            this.referral();
                            return;
                        }
                        break;
                    default:
                        // Other
                        this.referral();
                        return;
                }
                this.quote.errorAddress = false;
            } else {
                // annual + non legacy
                switch (suburb.stateId) {
                    case 1:
                        // ACT
                        if (!this.quote.workLocationACT) {
                            this.referral();
                            return;
                        }
                        break;
                    case 2:
                        // NSW
                        if (!this.quote.workLocationNSW) {
                            this.referral();
                            return;
                        }
                        break;
                    case 3:
                        // NT
                        if (!this.quote.workLocationNT) {
                            this.referral();
                            return;
                        }
                        break;
                    case 4:
                        // QLD;
                        if (suburb.isAbove25thParallel) {
                            if (!this.quote.workLocationQLDa) {
                                this.referral();
                                return;
                            }
                        } else {
                            if (!this.quote.workLocationQLDb) {
                                this.referral();
                                return;
                            }
                        }
                        break;
                    case 5:
                        // SA
                        if (!this.quote.workLocationSA) {
                            this.referral();
                            return;
                        }
                        break;
                    case 6:
                        // TAS
                        if (!this.quote.workLocationTAS) {
                            this.referral();
                            return;
                        }
                        break;
                    case 7:
                        // VIC
                        if (!this.quote.workLocationVIC) {
                            this.referral();
                            return;
                        }
                        break;
                    case 8:
                        // WA
                        if (suburb.isAbove25thParallel) {
                            if (!this.quote.workLocationWAa) {
                                this.referral();
                                return;
                            }
                        } else {
                            if (!this.quote.workLocationWAb) {
                                this.referral();
                                return;
                            }
                        }
                        break;
                }
                this.quote.errorAddress = false;
            }
        }
    }
    setAddress(): void {
        this.quote.address = null;
        if (this.lot) {
            this.quote.address = `Lot ${this.lot}, `;
        }
        if (this.unit) {
            if (this.quote.address) {
                this.quote.address = this.quote.address + `Unit ${this.unit}, `;
            } else {
                this.quote.address = `Unit ${this.unit}, `;
            }
        }
        if (this.quote.address) {
            this.quote.address =
                this.quote.address + `${this.street}, ${this.suburb}`;
        } else {
            this.quote.address = `${this.street}, ${this.suburb}`;
        }
    }
    clean(): void {
        this.lot = null;
        this.unit = null;
        this.street = null;
        this.suburb = null;
        this.finished = false;
        this.error = false;
        this.tempReferral = false;
    }
    close(): void {
        this.clean();
        this.quote.addressReferral = false;
        if (this.type === "policy") {
            GlobalStaticService.closeNewAddAddressModal();
        } else {
            GlobalStaticService.closeAddAddressModal();
        }
    }
    referral(special?: boolean): void {
        this.finished = false;
        if (special) {
            GlobalStaticService.infoModal = {
                title: "REFERRAL WARNING",
                content:
                    "Based on the specified location, you will need to contact MECON to discuss the current policy."
            };
        } else {
            GlobalStaticService.infoModal = {
                title: "REFERRAL WARNING",
                content:
                    "This location falls outside the location of projects to be insured. You will need to contact MECON to review the current policy limit."
            };
        }
        this.tempReferral = true;
        GlobalStaticService.openInfoModal();
        this.continueEventEmitter.emit({
            finished: false,
            noScroll: true
        });
    }
}
