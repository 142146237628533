import { Injectable } from "@angular/core";
import { CommonApi } from "../api/common.api";

const MD_EXCESS_VAL = 668;
const MD_EXCESS_VAL_FIELD = 4682;
const MD_EXCESS_DESC = 2451;
const MD_EXCESS_DESC_FIELD = 18264;

const PL_EXCESS_VAL = 1291;
const PL_EXCESS_VAL_FIELD = 9296;
const PL_EXCESS_DESC = 3687;
const PL_EXCESS_DESC_FIELD = 27472;

const CPL_EXCESS_VAL = 1292;
const CPL_EXCESS_VAL_FIELD = 9297;
const CPL_EXCESS_DESC = 3144;
const CPL_EXCESS_DESC_FIELD = 23100;

const CPL_LIMIT = 1295;
const CPL_LIMIT_FIELD = 9304;

const LOOKUP_META = {
    tables: [MD_EXCESS_VAL, MD_EXCESS_DESC, PL_EXCESS_VAL, PL_EXCESS_DESC, CPL_EXCESS_VAL, CPL_EXCESS_DESC, CPL_LIMIT],
    fields: {}
}
LOOKUP_META.fields[MD_EXCESS_VAL] = MD_EXCESS_VAL_FIELD;
LOOKUP_META.fields[MD_EXCESS_DESC] = MD_EXCESS_DESC_FIELD;
LOOKUP_META.fields[PL_EXCESS_VAL] = PL_EXCESS_VAL_FIELD;
LOOKUP_META.fields[PL_EXCESS_DESC] = PL_EXCESS_DESC_FIELD;
LOOKUP_META.fields[CPL_EXCESS_VAL] = CPL_EXCESS_VAL_FIELD;
LOOKUP_META.fields[CPL_EXCESS_DESC] = CPL_EXCESS_DESC_FIELD;
LOOKUP_META.fields[CPL_LIMIT] = CPL_LIMIT_FIELD;


@Injectable({
    providedIn: "root"
})
export class LookupService {

    public static mdExcessAmountTable(): number {
        return MD_EXCESS_VAL;
    }

    public static plExcessAmountTable(): number {
        return PL_EXCESS_VAL;
    }

    public static cplExcessAmountTable(): number {
        return CPL_EXCESS_VAL;
    }

    public static mdExcessDescTable(): number {
        return MD_EXCESS_DESC;
    }

    public static plExcessDescTable(): number {
        return PL_EXCESS_DESC;
    }

    public static cplExcessDescTable(): number {
        return CPL_EXCESS_DESC;
    }

    private cache = {};

    constructor() {}

    public synchronise(): boolean {
        let result:boolean = true

        LOOKUP_META.tables.forEach(element => {
            result = this.fetchTable(element);
        });

        return result
    }

    public get(tableId: number, key: number): string {
        let table:any = this.cache[tableId];
        return table[key];
    }

    public getTable(tableId: number): any {
        return this.cache[tableId];
    }

    private fetchTable(tableId: number): boolean {
        let result:boolean = true

        CommonApi.getInstance().getLookup(tableId).subscribe(res => {
            let table = {};
            this.cache[tableId] = table;
            res.fields.forEach(field => {
                if (field.fieldId === LOOKUP_META.fields[tableId]) {
                    field.values.forEach(value => {
                        table[value.valueId] = value.value;
                    });
                }
            });
        }, err => {
            result = false
        });

        return result
    }
}
