import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild
} from "@angular/core";
import { Quote } from "../../models/quote";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { TempQuote } from "../../models/temp-quote";

@Component({
    selector: "app-quote-ongoing-work-component",
    templateUrl: "./quote-ongoing-work.component.html",
    styleUrls: ["./quote-ongoing-work.component.scss"]
})
export class QuoteOngoingWorkComponent implements OnChanges, AfterViewInit {
    GlobalStaticSrv = GlobalStaticService;
    viewInit = false;
    @Input("quote") quote: Quote;
    @Input("tempQuote") tempQuote: TempQuote;
    currentTab = 1;
    @ViewChild("tab1") tab1: ElementRef;
    tab1Finished = false;
    @ViewChild("tab2") tab2: ElementRef;
    tab2Finished = false;
    tab2Enabled = false;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor() {}
    ngOnChanges(): void {
        if (this.tempQuote) {
            this.tempQuote.componentOnGoing = this;
        }
        if (this.viewInit) {
            this.init();
        }
    }
    ngAfterViewInit(): void {
        this.viewInit = true;
        this.init();
    }
    init(): void {
        if (this.quote.type === "annual") {
            if (this.quote.ongoingWork === "run-off" && !this.quote.quick) {
                this.tab2Enabled = true;
                this.tab1Finished = true;
                this.go(2);
                if (this.quote.page3Status.indexOf(1) !== -1) {
                    this.tab2Finished = true;
                }
            }
        }
    }
    cleanClassList(tab: string): void {
        this[tab].nativeElement.classList.remove("slideInLeft");
        this[tab].nativeElement.classList.remove("slideInRight");
        this[tab].nativeElement.classList.remove("slideOutLeft");
        this[tab].nativeElement.classList.remove("slideOutRight");
    }
    next(next?: number): void {
        if (!next) {
            next = 1;
        }
        this["tab" + this.currentTab + "Finished"] = true;
        if (this.currentTab === 2 && !this.tab2Enabled) {
            return;
        }
        this.cleanClassList("tab" + this.currentTab);
        this.cleanClassList("tab" + (this.currentTab + next));
        this["tab" + this.currentTab].nativeElement.classList.add(
            "slideOutLeft"
        );
        this["tab" + (this.currentTab + next)].nativeElement.classList.add(
            "slideInRight"
        );
        this["tab" + (this.currentTab + next)].nativeElement.classList.add(
            "active"
        );
        setTimeout(() => {
            this["tab" + this.currentTab].nativeElement.classList.remove(
                "active"
            );
            this.currentTab = this.currentTab + next;
        }, 500);
    }
    back(prev?: number): void {
        if (!prev) {
            prev = 1;
        }
        this.cleanClassList("tab" + this.currentTab);
        this.cleanClassList("tab" + (this.currentTab - prev));
        this["tab" + this.currentTab].nativeElement.classList.add(
            "slideOutRight"
        );
        this["tab" + (this.currentTab - prev)].nativeElement.classList.add(
            "slideInLeft"
        );
        this["tab" + (this.currentTab - prev)].nativeElement.classList.add(
            "active"
        );
        setTimeout(() => {
            this["tab" + this.currentTab].nativeElement.classList.remove(
                "active"
            );
            this.currentTab = this.currentTab - prev;
        }, 500);
    }
    go(tab: number): void {
        if (this.currentTab !== tab) {
            if (this.currentTab > tab) {
                this.back(this.currentTab - tab);
            } else {
                this.next(tab - this.currentTab);
            }
        }
    }
    changeSingle(): void {
        this.quote.ongoingWorkReferral = this.quote.ongoingWork;
        this.quote.errorOngoingWork = false;
        this.continueEventEmitter.emit({
            finished: true,
            referral: this.quote.ongoingWorkReferral
        });
    }
    changeAnnual(): void {
        this.tab1Finished = true;
        this.tab2Finished = false;
        this.quote.errorOngoingWork = false;
        if (this.quote.ongoingWork === "run-off") {
            this.tab2Enabled = true;
        } else {
            this.tab2Enabled = false;
            this.quote.ongoingWorkDetails = null;
            this.quote.ongoingWorkReferral = false;
        }
        this.continueEventEmitter.emit({
            finished: !this.tab2Enabled,
            referral: this.quote.ongoingWorkReferral
        });
        if (!this.quote.ongoingWork) {
            this.tab1Finished = false;
        }
        if (
            this.quote.ongoingWorkDetails === true ||
            this.quote.ongoingWorkDetails === false
        ) {
            this.tab2Finished = true;
        }
        if (this.tab2Enabled) {
            this.go(2);
        } else {
            this.go(1);
        }
    }
    changeOngoingWorkDetails(): void {
        this.quote.errorOngoingWork = false;
        this.tab2Finished = true;
        if (!this.quote.ongoingWorkDetails) {
            this.quote.ongoingWorkReferral = true;
            GlobalStaticService.infoModal = {
                title: "WARNING",
                content:
                    "This could result in a potential gap in cover for the client and this quote will be referred to a MECON Underwriter to discuss with you further"
            };
            GlobalStaticService.openInfoModal();
        } else {
            this.quote.ongoingWorkReferral = false;
        }
    }
    continueForm(): void {
        if (this.tab2Finished) {
            this.quote.errorOngoingWork = false;
            this.continueEventEmitter.emit({
                finished: true,
                referral: this.quote.ongoingWorkReferral
            });
        } else {
            this.quote.errorOngoingWork = true;
        }
    }
}
