import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild
} from "@angular/core";
import { Quote } from "../../models/quote";
import { TempQuote } from "../../models/temp-quote";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { QUOTE_REFERRAL } from "../../configs/quote-referral";
import { CommonUtility } from "../../utilities/common.utility";

@Component({
    selector: "app-quick-quote-liability-component",
    templateUrl: "./quick-quote-liability.component.html",
    styleUrls: ["./quick-quote-liability.component.scss"]
})
export class QuickQuoteLiabilityComponent implements OnChanges, AfterViewInit {
    viewInit = false;
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    @Input("tempQuickQuote") tempQuickQuote: TempQuote;
    currentTab = 1;
    @ViewChild("tab1") tab1: ElementRef;
    tab1Finished = false;
    @ViewChild("tab2") tab2: ElementRef;
    tab2Enabled = false;
    publicLiabilitySelectionFlag = false;
    vibrationWeakeningRemovalOfSupportSelectionFlag = false;
    productLiabilityFlag = false;
    propertyInCareCustodyControlSelectionFlag = false;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    specialErrorHint = false;
    constructor() {}
    ngOnChanges(): void {
        if (this.tempQuickQuote) {
            this.tempQuickQuote.componentLiability = this;
        }
        if (this.viewInit) {
            this.init();
        }
    }
    ngAfterViewInit(): void {
        this.viewInit = true;
        this.init();
    }
    init(): void {
        if (this.quote.type === "annual") {
            if (this.quote.page4Status.indexOf(4) !== -1) {
                this.tab1Finished = true;
                this.go(2);
            }
        }
    }
    cleanClassList(tab: string): void {
        this[tab].nativeElement.classList.remove("slideInLeft");
        this[tab].nativeElement.classList.remove("slideInRight");
        this[tab].nativeElement.classList.remove("slideOutLeft");
        this[tab].nativeElement.classList.remove("slideOutRight");
    }
    next(next?: number): void {
        if (!next) {
            next = 1;
        }
        if (!this["tab" + this.currentTab + "Finished"]) {
            return;
        }
        this.cleanClassList("tab" + this.currentTab);
        this.cleanClassList("tab" + (this.currentTab + next));
        this["tab" + this.currentTab].nativeElement.classList.add(
            "slideOutLeft"
        );
        this["tab" + (this.currentTab + next)].nativeElement.classList.add(
            "slideInRight"
        );
        this["tab" + (this.currentTab + next)].nativeElement.classList.add(
            "active"
        );
        setTimeout(() => {
            this["tab" + this.currentTab].nativeElement.classList.remove(
                "active"
            );
            this.currentTab = this.currentTab + next;
        }, 500);
    }
    back(prev?: number): void {
        if (!prev) {
            prev = 1;
        }
        this.cleanClassList("tab" + this.currentTab);
        this.cleanClassList("tab" + (this.currentTab - prev));
        this["tab" + this.currentTab].nativeElement.classList.add(
            "slideOutRight"
        );
        this["tab" + (this.currentTab - prev)].nativeElement.classList.add(
            "slideInLeft"
        );
        this["tab" + (this.currentTab - prev)].nativeElement.classList.add(
            "active"
        );
        setTimeout(() => {
            this["tab" + this.currentTab].nativeElement.classList.remove(
                "active"
            );
            this.currentTab = this.currentTab - prev;
        }, 500);
    }
    go(tab: number): void {
        if (this.currentTab !== tab) {
            if (this.currentTab > tab) {
                this.back(this.currentTab - tab);
            } else {
                this.next(tab - this.currentTab);
            }
        }
    }
    estimationChange(callReferralModal: boolean = true): void {
        if (this.quote.liabilityEstimation) {
            this.tab1Finished = true;
            this.tab2Enabled = true;
            this.specialErrorHint = false;
            this.quote.errorLiability = false;
        } else {
            this.tab1Finished = false;
            this.tab2Enabled = false;
            this.specialErrorHint = true;
        }
        if (
            this.quote.liabilityEstimation > QUOTE_REFERRAL.liabilityEstimation
        ) {
            this.quote.liabilityEstimationReferral = true;
            if (callReferralModal) {
                this.callReferralModal();
            }
        } else {
            this.quote.liabilityEstimationReferral = false;
        }
        this.checkFinished();
    }
    keyupEstimation(event: any): void {
        if (CommonUtility.shouldIgnoreKeyCode(event.keyCode)) {
            return;
        }
        let val = GlobalStaticService.numberFormatDecimal(event.target.value);
        event.target.value = GlobalStaticService.priceFormat(val);
        if (val !== this.quote.liabilityEstimation) {
            let callReferralModal = val > this.quote.liabilityEstimation;
            this.quote.liabilityEstimation = val;
            this.estimationChange(callReferralModal);
        }
    }
    errorFix($event: any): void {
        this.specialErrorHint = false;
        let val = $event.target ? $event.target.value: 0;
        $event.target.value = GlobalStaticService.priceFormat(val);
    }
    estimationSwitch(): void {
        if (this.quote.liabilityEstimation) {
            this.tab1Finished = true;
            this.tab2Enabled = true;
            this.quote.errorLiability = false;
            this.go(2);
        } else {
            this.tab1Finished = false;
            this.tab2Enabled = false;
            this.specialErrorHint = true;
            this.checkFinished();
        }
    }
    checkFinished(): void {
        this.quote.liabilityFinished =
            this.quote.insuranceType !== "md"
                ? !!this.quote.publicLiability
                : true;
        if (this.quote.type === "annual") {
            if (!this.quote.liabilityEstimation) {
                this.quote.liabilityFinished = false;
                this.tab1Finished = false;
            } else {
                this.tab1Finished = true;
            }
        }
        if (!this.quote.liabilityFinished) {
            this.emit();
        } else {
            this.emitNoScroll();
            this.quote.errorLiability = false;
        }
    }
    callReferralModal(): void {
        GlobalStaticService.infoModal = {
            title: "REFERRAL WARNING",
            content: "This sum insured requires MECON to provide terms for you"
        };
        GlobalStaticService.openInfoModal();
    }
    openPublicLiabilitySelection(event: any): void {
        event.target.blur();
        this.publicLiabilitySelectionFlag = true;
    }
    publicLiabilityChoose(option: any): void {
        if (this.publicLiabilitySelectionFlag) {
            this.publicLiabilitySelectionFlag = false;
            this.searchValue(
                "vibrationWeakeningRemovalOfSupport",
                option.value
            );
            if (this.quote.type === "annual") {
                this.searchValue("productLiability", option.value);
            }
            if (option.value > QUOTE_REFERRAL.publicLiability) {
                if (!this.quote.publicLiabilityReferral) {
                    this.quote.publicLiabilityReferral = true;
                    this.callReferralModal();    
                }
            } else {
                this.quote.publicLiabilityReferral = false;
            }
            this.quote.publicLiability = this.valueFormat(option.value);
            this.quote.publicLiabilityId = option.valueId;
            this.checkFinished();
        }
    }
    valueFormat(value: any): string {
        if (value.indexOf(".") !== -1) {
            value = value.split(".")[0];
        }
        return GlobalStaticService.priceFormat(value);
    }
    searchValue(key: string, value: any): void {
        for (const option of this.quote[key + "Option"]) {
            if (option.value === value) {
                this.quote[key] = this.valueFormat(value);
                this.quote[key + "Id"] = option.valueId;
                this.quote[key + "Referral"] =
                    value > QUOTE_REFERRAL[key] && QUOTE_REFERRAL[key];
                if (this.quote[key + "Referral"]) {
                    this.callReferralModal();
                }
            }
        }
    }
    openProductLiabilitySelection(event: any): void {
        event.target.blur();
        this.productLiabilityFlag = true;
    }
    productLiabilityChoose(option: any): void {
        let value = 0;
        if (option !== 0) {
            value = option.value;
        }
        if (this.productLiabilityFlag) {
            this.productLiabilityFlag = false;
            if (value > QUOTE_REFERRAL.productsLiability) {
                if (!this.quote.productLiabilityReferral) {
                    this.quote.productLiabilityReferral = true;
                    this.callReferralModal();
                }
            } else {
                this.quote.productLiabilityReferral = false;
            }
            if (value !== 0) {
                this.quote.productLiability = this.valueFormat(value);
                this.quote.productLiabilityId = option.valueId;
            } else {
                this.quote.productLiability = "Not Covered";
                this.quote.productLiabilityId = null;
            }
            this.checkFinished();
        }
    }
    openPropertyInCareCustodyControlSelection(event: any): void {
        event.target.blur();
        this.propertyInCareCustodyControlSelectionFlag = true;
    }
    propertyInCareCustodyControlChoose(option: any): void {
        let value = 0;
        if (option !== 0) {
            value = option.value;
        }
        if (this.propertyInCareCustodyControlSelectionFlag) {
            this.propertyInCareCustodyControlSelectionFlag = false;
            if (value > QUOTE_REFERRAL.propertyInCareCustodyControl) {
                if (!this.quote.propertyInCareCustodyControlReferral) {
                    this.quote.propertyInCareCustodyControlReferral = true;
                    this.callReferralModal();
                }
            } else {
                this.quote.propertyInCareCustodyControlReferral = false;
            }
            if (value !== 0) {
                this.quote.propertyInCareCustodyControl = this.valueFormat(
                    value
                );
                this.quote.propertyInCareCustodyControlId = option.valueId;
            } else {
                this.quote.propertyInCareCustodyControl = "Not Covered";
                this.quote.propertyInCareCustodyControlId = null;
            }
            this.checkFinished();
        }
    }
    continueForm(): void {
        if (!this.quote.liabilityFinished) {
            this.quote.errorLiability = true;
        }
        this.emit();
    }
    emit(): void {
        this.continueEventEmitter.emit({
            finished: this.quote.liabilityFinished,
            referral:
                this.quote.liabilityEstimationReferral ||
                this.quote.publicLiabilityReferral ||
                this.quote.vibrationWeakeningRemovalOfSupportReferral ||
                this.quote.propertyInCareCustodyControlReferral ||
                this.quote.productLiabilityReferral
        });
    }
    emitNoScroll(): void {
        this.continueEventEmitter.emit({
            finished: this.quote.liabilityFinished,
            referral:
                this.quote.liabilityEstimationReferral ||
                this.quote.publicLiabilityReferral ||
                this.quote.vibrationWeakeningRemovalOfSupportReferral ||
                this.quote.propertyInCareCustodyControlReferral ||
                this.quote.productLiabilityReferral,
            noScroll: true
        });
    }
}
