export class DialogConfig {
  width: number = 500;
  title: string = "WARNING";
  message: string = "";
  buttons: string[] = ["Close"];
  primaryButton: string;
  noCloseButton: boolean;

  isPrimaryButton(button: string) {
    return this.primaryButton === button;
  }
}
