import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginPageComponent } from "../pages/login/login.page";

const appRoutes: Routes = [
    {
        path: "",
        component: LoginPageComponent
    }
];

export const route: ModuleWithProviders = RouterModule.forRoot(appRoutes);
