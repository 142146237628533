import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from "@angular/core";

import { GlobalStaticService } from "../../static-services/global.static-service";
import { CommonApi } from "../../api/common.api";
import { Quote } from "../../models/quote";
import { Client } from "../../models/client";

@Component({
    selector: "app-add-client-modal-component",
    templateUrl: "./add-client-modal.component.html",
    styleUrls: ["./add-client-modal.component.scss"]
})
export class AddClientModalComponent {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    clientType = "individual";
    abn = "";
    needAbn = null;
    abnResult: any;
    clientName: string;
    companyName: string;
    individualName: string;
    tradingNames: string[] = [];
    abnVerified = false;
    abnDuplicated = false;
    @ViewChild("inputInsuredName") inputInsuredName: ElementRef;
    @ViewChild("inputAbn") inputAbn: ElementRef;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor(private commonApi: CommonApi) { }
    ngOnInit(): void {
    }
    changeAbn(): void {
        this.abnVerified = false;
        this.abnDuplicated = false;
        this.abn = this.abn
            .split("")
            .filter(number => {
                return /[0-9]/.test(number);
            })
            .join("");
    }
    checkAbn(): void {
        if (this.abn.length === 11) {
            this.commonApi.getClients(this.abn).subscribe(res => {
                if (res && res.length) {
                    this.abnVerified = false;
                    this.abnDuplicated = true;
                } else {
                    this.commonApi.getAbn(this.abn).subscribe(
                        res2 => {
                            if (res2.AbnStatus !== "Cancelled") {
                                this.abnResult = res2;
                                this.tradingNames = this.abnResult.BusinessName;
                                this.tradingNames = this.tradingNames.concat(
                                    this.abnResult.TradingName
                                );
                                if (this.abnResult.EntityTypeCode === "IND") { 
			      /**	if (this.abnResult.EntityTypeName === "Individual/Sole Trader") { */
                                    this.clientType = "individual";
                                 /**   this.individualName = */ this.clientName =
                                        this.abnResult.EntityName.split(
                                            ", "
                                        )[1] +
                                        " " +
                                        this.abnResult.EntityName.split(
                                            ", "
                                        )[0];
                                } else {
                                    this.clientType = "company";
                                    this.clientName = this.abnResult.EntityName;
                                }
                                this.abnVerified = true;
                            } else {
                                GlobalStaticService.infoModal = {
                                    title: "Warning",
                                    content: "ABN is expired, please check"
                                };
                                GlobalStaticService.openInfoModal();
                                this.abnVerified = false;
                            }
                        },
                        err => {
                            GlobalStaticService.infoModal = {
                                title: "Warning",
                                content: "ABN is invalid, please check"
                            };
                            GlobalStaticService.openInfoModal();
                            this.abnVerified = false;
                        }
                    );
                }
            }, err => {
                GlobalStaticService.openUnexpectedErrorModal();
                this.abnVerified = false;
            });
        } else {
            GlobalStaticService.infoModal = {
                title: "Warning",
                content: "ABN is invalid, please check"
            };
            GlobalStaticService.openInfoModal();
        }
    }
    clean(): void {
        this.modelClean();
        this.quote.insuredNameFinished = false;
        this.quote.insuredName = null;
        this.quote.client = null;
        this.quote.newClientFlag = false;
        this.continueEventEmitter.emit({
            finished: false
        });
    }
    modelClean(): void {
        this.abn = "";
        this.abnResult = null;
        this.clientName = null;
        this.companyName = null;
        this.individualName = null;
        this.tradingNames = [];
        this.abnVerified = false;
        this.abnDuplicated = false;
        this.clientType = "individual";
    }
    changeNeedAbn(needAbn: boolean): void {
        this.clean();
        this.needAbn = needAbn;
        if (needAbn) {
            setTimeout(() => {
                this.inputAbn.nativeElement.focus();
            }, 0);
        } else {
            setTimeout(() => {
                this.inputInsuredName.nativeElement.focus();
            }, 0);
        }
    }
    closeModal(): void {
        this.clean();
        this.needAbn = null;
        GlobalStaticService.closeAddClientModal();
    }
    finishModal(): void {
        this.modelClean();
        this.needAbn = null;
        GlobalStaticService.closeAddClientModal();
    }
    nameOnly(): void {
      /**  if (this.individualName) {
            this.individualName = this.individualName */
         if(this.clientName){
           this.clientName = this.clientName;
	   console.log(this.clientName);
/**	    .split("")
                .filter(letter => {
                    return /[a-zA-Z\-\.\ ]/.test(letter);
                })
                .join(""); */
        }
    }
    add(): void {
        if ((this.abn && this.abnVerified) || !this.abn) {
            if (this.needAbn === false && !this.clientName) {
                GlobalStaticService.infoModal = {
                    title: "Warning",
                    content: "Insured Name is a required field"
                };
                GlobalStaticService.openInfoModal();
                return;
            }
            this.quote.client = new Client();
            this.quote.clientName = this.clientName;
            this.quote.insuredName = this.clientName;
            this.quote.client.clientName = this.clientName;
            if (this.clientType === "individual") {
                this.quote.client.clientTypeId = 2;
            } else {
                this.quote.client.clientTypeId = 1;
            }
            this.quote.client.tradingNames = [];
            this.tradingNames.forEach(name =>
                this.quote.client.tradingNames.push({ name: name })
            );
            this.quote.client.abn = this.abn;
            this.quote.insuredNameFinished = true;
            this.quote.errorInsuredName = false;
            this.quote.newClientFlag = true;
            this.finishModal();
        }
    }
}
