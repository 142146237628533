import { Directive, HostListener, ElementRef } from "@angular/core";

@Directive({
    selector: "[appFirstLetterCapital]"
})
export class FirstLetterCapitalDirective {
    constructor(private el: ElementRef) {}
    @HostListener("keyup", ["$event"]) onKeyup(event) {
        this.el.nativeElement.value = this.el.nativeElement.value
            .split(" ")
            .map(word => {
                return word.substr(0, 1).toUpperCase() + word.substr(1);
            })
            .join(" ");
    }
}
