import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { Quote } from "../../models/quote";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { QUOTE_REFERRAL } from "../../configs/quote-referral";

@Component({
    selector: "app-quote-defects-liability-period-component",
    templateUrl: "./quote-defects-liability-period.component.html",
    styleUrls: ["./quote-defects-liability-period.component.scss"]
})
export class QuoteDefectsLiabilityPeriodComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    dlpFlag = false;
    @Input("quote") quote: Quote;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor() {}
    ngOnChanges(): void {}
    openDLPSelection(event: any): void {
        event.target.blur();
        this.dlpFlag = true;
    }
    dlpChoose(option: any) {
        if (this.dlpFlag) {
            this.dlpFlag = false;
            this.quote.defectsLiabilityPeriod = Number(option.value);
            this.quote.defectsLiabilityPeriodId = option.valueId;
            if (this.quote.type === "single") {
                this.quote.dlpReferral =
                    this.quote.defectsLiabilityPeriod >
                    QUOTE_REFERRAL.dlpLimitSp;
            } else {
                this.quote.dlpReferral =
                    this.quote.defectsLiabilityPeriod >
                    QUOTE_REFERRAL.dlpLimitAp;
            }
            this.continueEventEmitter.emit({
                finished: true,
                referral: this.quote.dlpReferral,
                noScroll: true
            });
        }
    }
    next() {
        this.continueEventEmitter.emit({
            finished: true,
            referral: this.quote.dlpReferral
        });
    }
}
