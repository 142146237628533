import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { CommonApi } from "../../api/common.api";
import { Quote } from "../../models/quote";
import { QuoteService } from "../../services/quote.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import * as moment from "moment";
import * as cloneDeep from "lodash/cloneDeep";
import { CommonUtility } from "../../utilities/common.utility";
declare let gtag: any;

@Component({
    selector: "app-modal-bind-policy-component",
    templateUrl: "./modal-bind-policy.component.html",
    styleUrls: ["./modal-bind-policy.component.scss"]
})
export class ModalBindPolicyComponent implements OnInit {
    GlobalStaticSrv = GlobalStaticService;
    CommonUtil = CommonUtility;
    today = new Date();
    @Input("quote") quote: Quote;
    // Other name
    showAll = true;
    nameSearch = "";
    // Interested party
    ipName: string;
    ipType: string;
    ipTypeName: string;
    // Binding
    emailForm: FormGroup;
    finished = false;
    @Output() bindEventEmitter: EventEmitter<boolean> = new EventEmitter();
    currentTab = 1;
    @ViewChild("tab1") tab1: ElementRef;
    @ViewChild("tab2") tab2: ElementRef;
    @ViewChild("tab3") tab3: ElementRef;
    @ViewChild("tab4") tab4: ElementRef;
    @ViewChild("scrollBody") scrollBody: ElementRef;
    @ViewChild("scrollBox") scrollBox: ElementRef;
    switching = false;
    constructor(
        private fb: FormBuilder,
        private commonApi: CommonApi,
        private quoteSrv: QuoteService,
        private router: Router
    ) { }
    ngOnInit(): void {
        this.emailForm = this.fb.group({
            choice: ["broker", Validators.required],
            custom: [null, [Validators.email]]
        });
        this.emailForm.controls["choice"].valueChanges.subscribe(value => {
            if (value === "broker") {
                this.emailForm.controls["custom"].setValidators([]);
            } else {
                this.emailForm.controls["custom"].setValidators([
                    Validators.required,
                    Validators.email
                ]);
            }
        });
    }
    cleanClassList(tab: string): void {
        this[tab].nativeElement.classList.remove("slideInLeft");
        this[tab].nativeElement.classList.remove("slideInRight");
        this[tab].nativeElement.classList.remove("slideOutLeft");
        this[tab].nativeElement.classList.remove("slideOutRight");
    }
    next(next?: number): void {
        if (!next) {
            next = 1;
        }
        if (this.switching) {
            return;
        }
        this.switching = true;
        this.cleanClassList("tab" + this.currentTab);
        this.cleanClassList("tab" + (this.currentTab + next));
        this["tab" + this.currentTab].nativeElement.classList.add(
            "slideOutLeft"
        );
        this["tab" + (this.currentTab + next)].nativeElement.classList.add(
            "slideInRight"
        );
        this["tab" + (this.currentTab + next)].nativeElement.classList.add(
            "active"
        );
        setTimeout(() => {
            this["tab" + this.currentTab].nativeElement.classList.remove(
                "active"
            );
            this.currentTab = this.currentTab + next;
            this.switching = false;
        }, 500);
    }
    back(prev?: number): void {
        if (!prev) {
            prev = 1;
        }
        if (this.switching) {
            return;
        }
        this.switching = true;
        this.cleanClassList("tab" + this.currentTab);
        this.cleanClassList("tab" + (this.currentTab - prev));
        this["tab" + this.currentTab].nativeElement.classList.add(
            "slideOutRight"
        );
        this["tab" + (this.currentTab - prev)].nativeElement.classList.add(
            "slideInLeft"
        );
        this["tab" + (this.currentTab - prev)].nativeElement.classList.add(
            "active"
        );
        setTimeout(() => {
            this["tab" + this.currentTab].nativeElement.classList.remove(
                "active"
            );
            this.currentTab = this.currentTab - prev;
            this.switching = false;
        }, 500);
    }
    go(tab: number): void {
        if (this.currentTab !== tab) {
            if (this.currentTab > tab) {
                this.back(this.currentTab - tab);
            } else {
                this.next(tab - this.currentTab);
            }
        }
    }
    dataChangeStart(e: any): void {
        let startDate = moment(e.targetElement.value, "DD/MM/YYYY");
        if (
            !startDate.isValid() &&
            startDate.isBefore(this.today, "day") &&
            startDate.isAfter(this.quote.createdInvalidDate, "day")
        ) {
            startDate = moment(this.today, "DD/MM/YYYY");
        }
        this.quote.tempPolicyEnd = cloneDeep(startDate)
            .add(this.quote.policyPeriod, "days")
            .toDate();
    }
    clean(): void {
        // Binding
        this.showAll = true;
        this.nameSearch = "";
        // Interested Party
        this.ipName = null;
        this.ipType = null;
        this.ipTypeName = null;
        // Binding
        this.emailForm.setValue({
            choice: "broker",
            custom: ""
        });
    }
    close(): void {
        GlobalStaticService.closeBindPolicyModal();
        this.clean();
        this.go(1);
    }
    // Trading Name
    get scrollHeight(): number {
        return this.scrollBody.nativeElement.offsetHeight;
    }
    get scrollTop(): number {
        return this.scrollBox.nativeElement.scrollTop;
    }
    get tradingName(): string[] {
        if (this.quote.client && this.quote.client.tradingNames.length) {
            let tradingNames;
            if (this.showAll) {
                tradingNames = this.quote.client.tradingNames;
            } else {
                tradingNames = this.quote.tradingNames;
            }
            return tradingNames
                .filter(
                    tradingName =>
                        tradingName.name
                            .toLowerCase()
                            .indexOf(this.nameSearch.toLowerCase()) !== -1
                )
                .sort();
        } else {
            return [];
        }
    }
    chooseTradingName(name: any): void {
        for (let i = 0; i < this.quote.tradingNames.length; i++) {
            if (this.quote.tradingNames[i].id === name.id) {
                this.quote.tradingNames.splice(i, 1);
                return;
            }
        }
        this.quote.tradingNames.push(name);
    }
    checkTradingName(name: any): boolean {
        if (this.quote.tradingNames) {
            for (let i = 0; i < this.quote.tradingNames.length; i++) {
                if (this.quote.tradingNames[i].id === name.id) {
                    return true;
                }
            }
        }
        return false;
    }
    selectAll(): void {
        this.nameSearch = "";
        if (
            this.quote.tradingNames.length ===
            this.quote.client.tradingNames.length
        ) {
            this.quote.tradingNames = [];
        } else {
            this.quote.tradingNames = JSON.parse(
                JSON.stringify(this.quote.client.tradingNames)
            );
        }
    }
    // Interested Party
    changeType(): void {
        if (this.ipType !== "other") {
            this.ipTypeName = "";
        }
    }
    addIp(): void {
        if (
            !this.ipName ||
            !this.ipType ||
            (this.ipType === "other" && !this.ipTypeName)
        ) {
        } else {
            const ip = {
                name: this.ipName,
                type: this.ipType === "other" ? this.ipTypeName : this.ipType
            };
            this.removeIp(ip);
            if (!this.quote.interestedParties) {
                this.quote.interestedParties = [];
            }
            this.quote.interestedParties.push(ip);
            this.clean();
        }
    }
    removeIp(ip: any) {
        if (
            this.quote.interestedParties &&
            this.quote.interestedParties.length
        ) {
            for (let i = 0; i < this.quote.interestedParties.length; i++) {
                if (
                    this.quote.interestedParties[i].type === ip.type &&
                    this.quote.interestedParties[i].name === ip.name
                ) {
                    this.quote.interestedParties.splice(i, 1);
                    return;
                }
            }
        }
    }
    // Binding
    focus(choice: string): void {
        if (this.emailForm.controls["choice"].value !== choice) {
            this.emailForm.setValue({
                choice: choice,
                custom: ""
            });
        }
    }
    submit(): void {
        if (
            this.emailForm.controls["choice"].value !== "broker" &&
            this.emailForm.controls["custom"].invalid
        ) {
        } else {
            GlobalStaticService.closeBindPolicyModal();
            this.quoteSrv.openSubmit("Binding policy");
            // call email api
            let nameList = [];
            if (this.quote.tradingNames && this.quote.tradingNames.length) {
                nameList = this.quote.tradingNames.map(name => {
                    return name.id;
                });
            }
            this.commonApi.putQuoteOtherName(this.quote.quoteId, nameList).subscribe(
                res => {
                    this.binding();
                },
                err => {
                    GlobalStaticService.openUnexpectedErrorModal();
                }
            );
        }
    }
    binding(): void {
        const start = `${this.quote.tempPolicyStart.getFullYear()}-${this.quote.tempPolicyStart.getMonth() +
            1}-${this.quote.tempPolicyStart.getDate()}`;
        const end = `${this.quote.tempPolicyEnd.getFullYear()}-${this.quote.tempPolicyEnd.getMonth() +
            1}-${this.quote.tempPolicyEnd.getDate()}`;
        this.commonApi
            .postBind(
                this.quote.quoteId,
                this.quote.option.OptionId,
                this.quote.interestedParties,
                start,
                end,
                this.emailForm.get("choice").value !== "broker",
                this.emailForm.get("custom").value
            )
            .subscribe(
                res => {
                    gtag("set", { user_id: GlobalStaticService.brokerProfile.contactId });
                    gtag("event", "Bind", {});
                    this.quote.policyStart = cloneDeep(
                        this.quote.tempPolicyStart
                    );
                    this.quote.policyEnd = cloneDeep(this.quote.tempPolicyEnd);
                    this.commonApi.getPolicy(res.policyId).subscribe(res2 => {
                        GlobalStaticService.addRecent("recentPolicies", res2);
                        this.bindEventEmitter.emit(true);
                        GlobalStaticService.infoModal = {
                            title: "",
                            content: `
                                    <div class="text-center"><i class="u-icon icon-88 icon-circle-purple-success"></i></div>
                                    <h2 class="text-center f-mgt-10 f-mgb-10">The Policy has been successfully created!</h2>
                                    <div class="text-center f-mgb-10">
                                        <div class="u-badge">
                                            <strong>Policy #</strong> ${
                                res.policyNumber
                                }
                                        </div>
                                    </div>
                                    <div class="text-center f-mgb-20">
                                        <small class="minor">
                                            You will find this policy in the "Policies" page.<br>
                                            An email with the policy online documents<br>
                                            has been sent. Please check your inbox.
                                        </small>
                                    </div>
                                `,
                            buttons: [
                                {
                                    style: "purple",
                                    text: "Ok",
                                    callback: () => {
                                        this.clean();
                                        this.router.navigate([
                                            "/dashboard/home"
                                        ]);
                                        GlobalStaticService.closeInfoModal();
                                    }
                                }
                            ]
                        };
                        GlobalStaticService.closeSubmitLoading();
                        GlobalStaticService.openInfoModal();
                    });
                },
                err => {
                    this.bindEventEmitter.emit(false);
                    GlobalStaticService.closeSubmitLoading();
                    GlobalStaticService.openUnexpectedErrorModal();
                }
            );
    }
}
