import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { QuoteMappingStaticService } from "../../static-services/quote-mapping.static-service";
import { Quote } from "../../models/quote";
import { TempQuote } from "../../models/temp-quote";
import { CommonApi } from "../../api/common.api";
import { QuoteService } from "../../services/quote.service";

@Component({
    selector: "app-quick-quote-project-type-component",
    templateUrl: "./quick-quote-project-type.component.html",
    styleUrls: ["./quick-quote-project-type.component.scss"]
})
export class QuickQuoteProjectTypeComponent
    implements OnChanges, AfterViewInit {
    GlobalStaticSrv = GlobalStaticService;
    viewInit = false;
    @Input("quote") quote: Quote;
    @Input("tempQuickQuote") tempQuickQuote: TempQuote;
    currentTab = 1;
    @ViewChild("tab1") tab1: ElementRef;
    tab1Finished = false;
    @ViewChild("tab2") tab2: ElementRef;
    tab2Finished = false;
    @ViewChild("tab3") tab3: ElementRef;
    tab3Finished = false;
    number = 0;
    nrbId = 1;
    ncibId = 2;
    nsrfaId = 3;
    arId = 4;
    acibId = 5;
    otherId = 6;
    total = 0;
    percentTabEnabled = false;
    otherTabEnabled = false;
    ownerBuilderSpecialError = false;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() offsetRecheckEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor(private commonApi: CommonApi, private quoteSrv: QuoteService) { }
    ngOnChanges(): void {
        this.tempQuickQuote.componentProjectType = this;
        if (this.viewInit) {
            this.init();
        }
    }
    ngAfterViewInit(): void {
        this.viewInit = true;
        this.init();
    }
    init(): void {
        if (this.quote.type === "single") {
            if (this.quote.page2Status.indexOf(4) !== -1) {
                if (this.quote.projectType === "other") {
                    this.otherTabEnabled = true;
                    this.tab2Finished = true;
                    this.go(2);
                }
            }
        } else {
            if (this.quote.page2Status.indexOf(5) !== -1) {
                this.otherTabEnabled = this.quote.projectTypeOther;
                this.percentTabCheck();
                if (this.otherTabEnabled || this.percentTabEnabled) {
                }
                if (this.otherTabEnabled) {
                    this.go(3);
                    this.tab3Finished = true;
                } else if (this.percentTabEnabled) {
                    this.go(2);
                    this.tab2Finished = true;
                }
            }
        }
    }
    cleanClassList(tab: string): void {
        this[tab].nativeElement.classList.remove("slideInLeft");
        this[tab].nativeElement.classList.remove("slideInRight");
        this[tab].nativeElement.classList.remove("slideOutLeft");
        this[tab].nativeElement.classList.remove("slideOutRight");
    }
    next(next?: number): void {
        if (!next) {
            next = 1;
        }
        this["tab" + this.currentTab + "Finished"] = true;
        this.cleanClassList("tab" + this.currentTab);
        this.cleanClassList("tab" + (this.currentTab + next));
        this["tab" + this.currentTab].nativeElement.classList.add(
            "slideOutLeft"
        );
        this["tab" + (this.currentTab + next)].nativeElement.classList.add(
            "slideInRight"
        );
        this["tab" + (this.currentTab + next)].nativeElement.classList.add(
            "active"
        );
        setTimeout(() => {
            this["tab" + this.currentTab].nativeElement.classList.remove(
                "active"
            );
            this.currentTab = this.currentTab + next;
        }, 500);
    }
    back(prev?: number): void {
        if (!prev) {
            prev = 1;
        }
        if (this.currentTab === 3 && !this.percentTabEnabled) {
            prev = 2;
        }
        this.cleanClassList("tab" + this.currentTab);
        this.cleanClassList("tab" + (this.currentTab - prev));
        this["tab" + this.currentTab].nativeElement.classList.add(
            "slideOutRight"
        );
        this["tab" + (this.currentTab - prev)].nativeElement.classList.add(
            "slideInLeft"
        );
        this["tab" + (this.currentTab - prev)].nativeElement.classList.add(
            "active"
        );
        setTimeout(() => {
            this["tab" + this.currentTab].nativeElement.classList.remove(
                "active"
            );
            this.currentTab = this.currentTab - prev;
        }, 500);
    }
    go(tab: number): void {
        if (this.currentTab !== tab) {
            if (this.currentTab > tab) {
                this.back(this.currentTab - tab);
            } else {
                this.next(tab - this.currentTab);
            }
        }
    }
    changeSingle(): void {
        this.tab1Finished = true;
        let finished = false;
        if (this.quote.projectType === "other") {
            this.otherTabEnabled = true;
            this.ownerBuilderSpecialError = false;
        } else {
            this.otherTabEnabled = false;
            this.quote.projectTypeDetails = null;
            if (
                this.quote.insuredType === "ownerBuilder" &&
                (this.quote.projectType === "ncib" ||
                    this.quote.projectType === "acib")
            ) {
                this.ownerBuilderSpecialError = true;
                this.quote.projectType = null;
            } else {
                finished = true;
                this.ownerBuilderSpecialError = false;
                this.getSingleScheme();
            }
        }

        this.quote.projectTypeReferral = this.quoteSrv.projectTypeReferralCheck(
            QuoteMappingStaticService.mapOption(
                true,
                "projectType",
                this.quote.projectType
            ),
            this.quote.productId
        );
        this.quote.errorProjectType = false;
        this.continueEventEmitter.emit({
            finished: finished,
            referral: this.quote.projectTypeReferral
        });
        if (this.otherTabEnabled) {
            this.go(2);
        }
    }
    changeAnnual(choice: string): void {
        this.total = 0;
        this.quote.projectTypePercentNRB = null;
        this.quote.projectTypePercentNCIB = null;
        this.quote.projectTypePercentNSRFA = null;
        this.quote.projectTypePercentAR = null;
        this.quote.projectTypePercentACIB = null;
        this.quote.projectTypePercentOther = null;
        this.tab2Finished = false;
        this.tab3Finished = false;
        this.quote.projectTypeDetails = null;
        this.quote.projectTypeReferral = false;
        switch (choice) {
            case "nrb":
                this.quote.projectTypeNRB = !this.quote.projectTypeNRB;
                break;
            case "ncib":
                this.quote.projectTypeNCIB = !this.quote.projectTypeNCIB;
                break;
            case "nsrfa":
                this.quote.projectTypeNSRFA = !this.quote.projectTypeNSRFA;
                break;
            case "ar":
                this.quote.projectTypeAR = !this.quote.projectTypeAR;
                break;
            case "acib":
                this.quote.projectTypeACIB = !this.quote.projectTypeACIB;
                break;
            case "other":
                this.quote.projectTypeOther = !this.quote.projectTypeOther;
                this.otherTabEnabled = this.quote.projectTypeOther;
                break;
        }
        this.percentTabCheck();
        this.tab1Finished = this.number !== 0;
        this.quote.errorProjectType = false;
        this.continueEventEmitter.emit({
            finished: this.number === 1 && !this.quote.projectTypeOther,
            noScroll: true,
            referral: this.quote.projectTypeReferral
        });
    }
    percentTabCheck(): void {
        this.number = 0;
        const annualProjectType = [];
        if (this.quote.projectTypeNRB) {
            this.number++;
            this.nrbId = this.number;
            annualProjectType.push(
                QuoteMappingStaticService.mapOption(true, "projectType", "nrb")
            );
        }
        if (this.quote.projectTypeNCIB) {
            this.number++;
            this.ncibId = this.number;
            annualProjectType.push(
                QuoteMappingStaticService.mapOption(true, "projectType", "ncib")
            );
        }
        if (this.quote.projectTypeNSRFA) {
            this.number++;
            this.nsrfaId = this.number;
            annualProjectType.push(
                QuoteMappingStaticService.mapOption(
                    true,
                    "projectType",
                    "nsrfa"
                )
            );
        }
        if (this.quote.projectTypeAR) {
            this.number++;
            this.arId = this.number;
            annualProjectType.push(
                QuoteMappingStaticService.mapOption(true, "projectType", "ar")
            );
        }
        if (this.quote.projectTypeACIB) {
            this.number++;
            this.acibId = this.number;
            annualProjectType.push(
                QuoteMappingStaticService.mapOption(true, "projectType", "acib")
            );
        }
        if (this.quote.projectTypeOther) {
            this.number++;
            this.otherId = this.number;
            annualProjectType.push(
                QuoteMappingStaticService.mapOption(
                    true,
                    "projectType",
                    "other"
                )
            );
        }
        this.quote.projectTypeReferral = this.quoteSrv.annualProjectTypeReferralCheck(
            annualProjectType
        );
        this.percentTabEnabled = this.number > 1;
    }
    detailChange(): void {
        if (this.quote.projectTypeDetails) {
            if (this.quote.type === "single") {
                this.tab2Finished = true;
            } else {
                this.tab3Finished = true;
            }
            this.quote.errorProjectType = false;
        } else {
            if (this.quote.type === "single") {
                this.tab2Finished = false;
            } else {
                this.tab3Finished = false;
            }
        }
    }
    otherContinueForm(): void {
        if (!this.quote.projectTypeDetails) {
            this.quote.errorProjectType = true;
            return;
        }
        if (this.quote.type === "single") {
            this.quote.projectTypeReferral = this.quoteSrv.projectTypeReferralCheck(
                QuoteMappingStaticService.mapOption(
                    true,
                    "projectType",
                    this.quote.projectType
                ),
                this.quote.productId
            );
        } else {
            this.percentTabCheck();
        }
        this.continueEventEmitter.emit({
            finished:
                this.quote.type === "single"
                    ? this.tab2Finished
                    : this.tab3Finished,
            referral: this.quote.projectTypeReferral
        });
        if (this.quote.type === "single") {
            this.getSingleScheme();
        } else {
            this.getAnnualScheme();
        }
    }
    annualContinueForm(): void {
        if (this.tab1Finished) {
            this.quote.errorProjectType = false;
            this.percentTabCheck();
            if (this.percentTabEnabled) {
                this.go(2);
                return;
            }
            if (this.otherTabEnabled) {
                this.go(3);
                return;
            }
            this.continueEventEmitter.emit({
                finished: true,
                referral: this.quote.projectTypeReferral
            });
            this.getAnnualScheme();
        } else {
            this.quote.errorProjectType = true;
        }
    }
    percentChange(): void {
        this.total = 0;
        let filled = 0;
        if (this.quote.projectTypeNRB && this.quote.projectTypePercentNRB) {
            filled++;
            this.total = this.total + this.quote.projectTypePercentNRB;
        }
        if (this.quote.projectTypeNCIB && this.quote.projectTypePercentNCIB) {
            filled++;
            this.total = this.total + this.quote.projectTypePercentNCIB;
        }
        if (this.quote.projectTypeNSRFA && this.quote.projectTypePercentNSRFA) {
            filled++;
            this.total = this.total + this.quote.projectTypePercentNSRFA;
        }
        if (this.quote.projectTypeAR && this.quote.projectTypePercentAR) {
            filled++;
            this.total = this.total + this.quote.projectTypePercentAR;
        }
        if (this.quote.projectTypeACIB && this.quote.projectTypePercentACIB) {
            filled++;
            this.total = this.total + this.quote.projectTypePercentACIB;
        }
        if (this.quote.projectTypeOther && this.quote.projectTypePercentOther) {
            filled++;
            this.total = this.total + this.quote.projectTypePercentOther;
        }
        this.tab2Finished = this.total === 100 && filled === this.number;
        if (this.total === 100 && filled === this.number) {
            this.quote.errorProjectType = false;
        }
    }
    annualContinueForm2(): void {
        if (this.tab2Finished) {
            this.quote.errorProjectType = false;
            this.percentTabCheck();
            if (this.otherTabEnabled) {
                this.go(3);
                return;
            }
            this.continueEventEmitter.emit({
                finished: true,
                referral: this.quote.projectTypeReferral
            });

            this.getAnnualScheme();
        } else {
            this.quote.errorProjectType = true;
        }
    }
    getSingleScheme(): void {
        const projectTypeId = QuoteMappingStaticService.mapOption(
            true,
            "projectType",
            this.quote.projectType
        );
        const insuredTypeId = QuoteMappingStaticService.mapOption(
            true,
            "insuredType",
            this.quote.insuredType
        );
        this.commonApi.getScheme(47, projectTypeId, insuredTypeId).subscribe(
            res => {
                this.quoteSrv.setScheme(this.quote, res);
            },
            err => {
                this.quote._scheme = -1;
                this.quoteSrv.resetReferralLimit(47);
            }
        );
    }
    getAnnualScheme(): void {
        let maxType;
        if (this.number === 1) {
            if (this.quote.projectTypeNRB) {
                maxType = "nrb";
            } else if (this.quote.projectTypeNCIB) {
                maxType = "ncib";
            } else if (this.quote.projectTypeNSRFA) {
                maxType = "nsrfa";
            } else if (this.quote.projectTypeAR) {
                maxType = "ar";
            } else if (this.quote.projectTypeACIB) {
                maxType = "acib";
            } else {
                maxType = "other";
            }
        } else {
            const max = Math.max(
                this.quote.projectTypePercentNRB,
                this.quote.projectTypePercentNCIB,
                this.quote.projectTypePercentNSRFA,
                this.quote.projectTypePercentAR,
                this.quote.projectTypePercentACIB,
                this.quote.projectTypePercentOther
            );
            if (max === this.quote.projectTypePercentNRB) {
                maxType = "nrb";
            } else if (max === this.quote.projectTypePercentNCIB) {
                maxType = "ncib";
            } else if (max === this.quote.projectTypePercentNSRFA) {
                maxType = "nsrfa";
            } else if (max === this.quote.projectTypePercentAR) {
                maxType = "ar";
            } else if (max === this.quote.projectTypePercentACIB) {
                maxType = "acib";
            } else {
                maxType = "other";
            }
        }
        const projectTypeId = QuoteMappingStaticService.mapOption(
            true,
            "projectType",
            maxType
        );
        const insuredTypeId = QuoteMappingStaticService.mapOption(
            true,
            "insuredType",
            this.quote.insuredType
        );
        this.commonApi.getScheme(48, projectTypeId, insuredTypeId).subscribe(
            res => {
                this.quoteSrv.setScheme(this.quote, res);
            },
            err => {
                this.quote._scheme = -1;
                this.quoteSrv.resetReferralLimit(48);
            }
        );
    }
}
