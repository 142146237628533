import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

/*
 * Filter to Sanitize HTML to prevent XSS attacks, while injecting html content through [innerHTML] property in Angular 2/4
 * Usage example:
 *   `<div [innerHTML]="unsafeHTMLContent | sanitizeHtml"></div>`
 */

@Pipe({
    name: "sanitizeHtml"
})
export class SanitizeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}
