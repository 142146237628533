import { Component } from "@angular/core";

import { GlobalStaticService } from "../../static-services/global.static-service";

@Component({
    selector: "app-submit-loading-component",
    templateUrl: "./submit-loading.component.html",
    styleUrls: ["./submit-loading.component.scss"]
})
export class SubmitLoadingComponent {
    GlobalStaticSrv = GlobalStaticService;
    constructor() {}
}
