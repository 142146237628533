import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { Policy } from "../../models/policy";
import * as moment from "moment";
import { Dialog } from "../app-dialog/app-dialog.service";

@Component({
    selector: "app-policy-policy-date-component",
    templateUrl: "./policy-policy-date.component.html",
    styleUrls: ["./policy-policy-date.component.scss"]
})
export class PolicyPolicyDateComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    @Input("policy") policy: Policy;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor() {}
    ngOnChanges(): void {}
    dataChangeStart(e: any): void {
        let startDate = moment(e.targetElement.value, "DD/MM/YYYY");
        if (
            !startDate.isValid() ||
            (startDate.isValid() &&
                startDate.isBefore(this.policy.start, "day")) ||
            (startDate.isValid() && startDate.isAfter(this.policy.end, "day"))
        ) {
            Dialog.warn("Policy Date entered falls outside of current policy period of insurance.").subscribe(() => {
                
            });
            startDate = moment(this.policy.start, "DD/MM/YYYY");
        }
        this.policy.policyStart = startDate.toDate();
        const endDate = moment(this.policy.end, "DD/MM/YYYY");
        this.policy.policyEnd = endDate.toDate();
    }
    dataChangeEnd(e: any): void {
        let endDate = moment(e.targetElement.value, "DD/MM/YYYY");
        if (
            !endDate.isValid() ||
            (endDate.isValid() &&
                endDate.isBefore(this.policy.policyStart, "day")) ||
            (endDate.isValid() && endDate.isAfter(this.policy.end, "day"))
        ) {
            Dialog.warn("Policy Date entered falls outside of current policy period of insurance. ").subscribe(() => {
                
            })
            endDate = moment(this.policy.end, "DD/MM/YYYY");
        }
        this.policy.policyEnd = endDate.toDate();
    }
    next(): void {
        this.continueEventEmitter.emit({
            finished: true
        });
    }
}
