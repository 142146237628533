import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output
} from "@angular/core";

import { CommonApi } from "../../api/common.api";

import { Policy } from "../../models/policy";
import { Client } from "../../models/client";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { QuoteService } from "../../services/quote.service";
import { Quote } from "../../models/quote";
import { QuoteMappingStaticService } from "../../static-services/quote-mapping.static-service";
import { CommonUtility } from "../../utilities/common.utility";
import { Rating } from "../../models/rating";
import { STATE } from "../../configs/state";
import { QUOTE_MAPPING } from "../../configs/quote-mapping";

@Component({
    selector: "app-policy-component",
    templateUrl: "./policy.component.html",
    styleUrls: ["./policy.component.scss"]
})
export class PolicyComponent implements OnChanges, OnDestroy {
    CommonUtil = CommonUtility;
    GlobalStaticSrv = GlobalStaticService;
    loading = 0;
    loadingRequired = 0;
    policy: Policy;
    client: Client = new Client();
    @Input("id") id: any;
    @Output() backEventEmitter: EventEmitter<boolean> = new EventEmitter();
    @Output() goEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() policyEventEmitter: EventEmitter<any> = new EventEmitter();
    policy$$: any;
    facility$$: any;
    otherName$$: any;
    legacyDisable = false;
    constructor(private commonApi: CommonApi, public quoteSrv: QuoteService) { }
    ngOnChanges(): void {
        if (this.id) {
            this.loading = 0;
            this.loadingRequired = 0;
            this.policy$$ = this.commonApi.getPolicy(this.id).subscribe(
                res => {
                    this.policy = res;
                    //this.commonApi.postBeginConversation(this.id, 'policy');
                    switch (this.policy.productId) {
                        case 47:
                            try {
                                this.policy = QuoteMappingStaticService.processGetSingle(
                                    this.policy
                                );
                            } catch (err) {
                                this.back(true);
                                return;
                            }
                            this.policy.type = "single";
                            break;
                        case 48:
                            try {
                                this.policy = QuoteMappingStaticService.processGetAnnual(
                                    this.policy
                                );
                            } catch (err) {
                                this.back(true);
                                return;
                            }
                            this.policy.type = "annual";
                            break;
                        case 41:
                            try {
                                this.policy = QuoteMappingStaticService.processGetLegacySingle(
                                    this.policy
                                );
                            } catch (err) {
                                this.back(true);
                                return;
                            }
                            this.policy.type = "single";
                            this.policy.legacy = true;
                            break;
                        case 42:
                            try {
                                this.policy = QuoteMappingStaticService.processGetLegacyAnnual(
                                    this.policy
                                );
                            } catch (err) {
                                this.back(true);
                                return;
                            }
                            this.policy.type = "annual";
                            this.policy.legacy = true;
                            break;
                        default:
                            this.back(true);
                            return;
                    }
                    switch (this.policy.productId) {
                        case 47:
                        case 48:
                            this.policy.suburbData = {};
                            this.policy.suburbData.suburb = this.policy.town;
                            this.policy.suburbData.postcode = this.policy.postcode;
                            this.policy.suburbData.stateId = Number(
                                this.policy.stateId
                            );
                            for (const state in STATE) {
                                if (STATE.hasOwnProperty(state)) {
                                    if (
                                        STATE[state].stateId ===
                                        Number(this.policy.stateId)
                                    ) {
                                        this.policy.suburbData.state = state;
                                        this.policy.suburbData.stateName =
                                            STATE[state].stateName;
                                        break;
                                    }
                                }
                            }
                            if (this.policy.type === "single") {
                                this.policy.address =
                                    this.policy.address +
                                    this.policy.suburbData.suburb +
                                    " " +
                                    this.policy.suburbData.state +
                                    " " +
                                    this.policy.suburbData.postcode;
                            } else {
                                this.policy.suburb =
                                    this.policy.suburbData.suburb +
                                    " / " +
                                    this.policy.suburbData.state +
                                    " / " +
                                    this.policy.suburbData.postcode;
                            }
                            this.legacyDisable = false;
                            break;
                        case 41:
                        case 42:
                            this.commonApi
                                .getSuburb(this.policy.suburbId)
                                .subscribe(res2 => {
                                    this.policy.suburbData = {};
                                    this.policy.suburbData.suburb =
                                        res2.townName;
                                    this.policy.suburbData.postcode =
                                        res2.postcode;
                                    this.policy.suburbData.stateId =
                                        res2.stateId;
                                    this.policy.suburbData.state =
                                        res2.stateCode;
                                    this.policy.suburbData.stateName =
                                        res2.state;
                                    if (this.policy.type === "single") {
                                        this.policy.address =
                                            this.policy.address +
                                            this.policy.suburbData.suburb +
                                            " " +
                                            this.policy.suburbData.state +
                                            " " +
                                            this.policy.suburbData.postcode;
                                    } else {
                                        this.policy.suburb =
                                            this.policy.suburbData.suburb +
                                            " / " +
                                            this.policy.suburbData.state +
                                            " / " +
                                            this.policy.suburbData.postcode;
                                    }
                                });
                            if (this.policy.insuredName && this.policy.territorialLimitDescription && this.policy.policyStart && this.policy.policyEnd && this.policy.businessType && ((this.policy.insuranceType !== 'l' && this.policy.projectCost) || this.policy.insuranceType === 'l') && ((this.policy.insuranceType !== "md" && this.policy.publicLiability) || this.policy.insuranceType === "md")) {
                                this.legacyDisable = false;
                            } else {
                                this.legacyDisable = true;
                            }
                            break;
                    }
                    this.policy.valid = true;
                    this.policy.option = res.option;
                    if (res.rating) {
                        this.policy.rating = QuoteMappingStaticService.processRatingSection(
                            res.rating
                        );
                    } else {
                        this.policy.rating = new Rating();
                    }
                    this.loadingRequired++;
                    this.facility$$ = this.commonApi
                        .getPolicyFacility(this.policy.policyId)
                        .subscribe(
                            res2 => {
                                if (res2.length) {
                                    this.quoteSrv
                                        .getFacility(
                                            res2,
                                            this.policy.productId
                                        )
                                        .then(facility => {
                                            this.policy.facility = facility;
                                            this.loading++;
                                        });
                                } else {
                                    this.policy.facility = [];
                                    this.loading++;
                                }
                            },
                            err2 => {
                                this.back(true);
                            }
                        );
                    this.loadingRequired++;
                    this.otherName$$ = this.commonApi
                        .getPolicyOtherName(this.id)
                        .subscribe(
                            res2 => {
                                this.policy.tradingNames = res2;
                                this.loading++;
                            },
                            err2 => {
                                this.back(true);
                            }
                        );
                    GlobalStaticService.addRecent(
                        "recentPolicies",
                        this.policy
                    );
                    this.policyEventEmitter.emit(this.policy);
                    this.commonApi.getClient(this.policy.clientId).subscribe(
                        res2 => {
                            this.client = res2;
                        },
                        err2 => {
                            this.back(true);
                        }
                    );
                },
                err => {
                    this.back(true);
                    return;
                }
            );
        }
    }
    ngOnDestroy(): void {
    }
    cleanData(): void {
        this.policy = null;
        this.loading = 0;
        this.loadingRequired = 0;
        if (this.policy$$) {
            this.policy$$.unsubscribe();
        }
        if (this.facility$$) {
            this.facility$$.unsubscribe();
        }
        if (this.otherName$$) {
            this.otherName$$.unsubscribe();
        }
        this.legacyDisable = false;
    }
    back(alert?: boolean): void {
        if (alert) {
            GlobalStaticService.openUnexpectedErrorModal();
        }
        setTimeout(() => {
            this.cleanData();
        }, 500);
        this.backEventEmitter.emit(true);
    }
    go(nextPage: string, nextId: any): void {
        setTimeout(() => {
            this.cleanData();
        }, 500);
        this.goEventEmitter.emit({
            currentPage: "policyPage",
            nextPage: nextPage,
            nextId: nextId
        });
    }
    goCoc(): void {
        if (this.legacyDisable) {
            GlobalStaticService.infoModal = {
                title: "WARNING",
                content:
                    "Certificate of Currency cannot be issued online. Please contact Mecon."
            };
            GlobalStaticService.openInfoModal();
        } else if (
            this.policy.policyStatusText === "Bound" &&
            !CommonUtility.isDateBeforeToday(this.policy.policyEnd)
        ) {
            this.go("createCocPage", this.policy);
        }
    }
}
