import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { CommonApi } from "../../api/common.api";
import { Client } from "../../models/client";
import { Quote } from "../../models/quote";
declare let gtag: any;

@Component({
    selector: "app-quick-quote-insured-name-component",
    templateUrl: "./quick-quote-insured-name.component.html",
    styleUrls: ["./quick-quote-insured-name.component.scss"]
})
export class QuickQuoteInsuredNameComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    client: Client;
    clients: Client[] = [];
    form: any;
    searchActive = false;
    resultActive = false;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    search$$: any;
    constructor(private commonApi: CommonApi) { }
    ngOnChanges(): void { }
    searchFocus(): void {
        if (this.quote.insuredName && this.quote.insuredName.length >= 3) {
            this.searchActive = true;
            this.search();
        } else {
            this.searchActive = false;
        }
    }
    searchChange(): void {
        this.quote.insuredNameFinished = false;
        this.quote.newClientFlag = false;
        this.quote.client = null;
        this.quote.clientName = null;
        this.client = null;
        this.searchFocus();
        this.continueEventEmitter.emit({
            finished: false
        });
    }
    search(): void {
        this.resultActive = true;
        if (this.search$$) {
            this.search$$.unsubscribe();
        }
        this.search$$ = this.commonApi
            .getClients(this.quote.insuredName)
            .subscribe(res => {
                this.clients = res;
                this.resultActive = false;
            });
    }
    searchBlur(): void {
        setTimeout(() => {
            this.searchActive = false;
            this.resultActive = false;
            this.clients = [];
        }, 200);
    }
    chooseClient(client: Client): void {
        this.client = client;
        this.quote.client = client;
        this.quote.tradingNames = client.tradingNames;
        this.quote.clientName = this.quote.client.clientName;
        this.quote.insuredName = this.quote.client.clientName;
        this.quote.insuredNameFinished = true;
        this.quote.newClientFlag = false;
        this.searchActive = false;
        this.resultActive = false;
        this.clients = [];
        this.quote.errorInsuredName = false;
    }
    openAddClientModal(): void {
        GlobalStaticService.openAddClientModal();
    }
    clean(): void {
        this.quote.insuredName = null;
        this.quote.insuredNameFinished = false;
        this.quote.client = null;
        this.continueEventEmitter.emit({
            finished: false
        });
    }
    next(): void {
        gtag("set", { user_id: GlobalStaticService.brokerProfile.contactId });
        gtag("event", "QuickQuote", {});
        this.quote.errorInsuredName = !this.quote.insuredNameFinished;
        this.continueEventEmitter.emit({
            finished: this.quote.insuredNameFinished
        });
    }
}
