export let QUOTE_MAPPING = {
    boolean: {
        yes: 17182,
        no: 17183
    },
    insuredType: {
        principal: 1055920,
        ownerBuilder: 1055921,
        contractor: 1055922,
        subcontractor: 1055923
    },
    projectType: {
        nrb: 2075064,
        ncib: 2075066,
        nsrfa: 2075070,
        ar: 2075065,
        acib: 2075067,
        other: 2075095
    },
    existingStructuresB: {
        Poor: 1056021,
        Average: 1056022,
        Good: 1056023
    },
    tailorYourCoverMdValue: {
        "1000": 1055937,
        "2500": 1055938,
        "5000": 1055939,
        "10000": 1055940
    },
    tailorYourCoverLValue: {
        "1000": 1055950,
        "2500": 1055951,
        "5000": 1055952,
        "10000": 1055953
    },
    // excess description (115689)
    tailorYourCoverMd: {
        default: 3083027, // "each and every event"
        a: 2098263, // "minor hazard each and every event"
        b: 2098265 // "major hazard each and every event"
    },
    tailorYourCoverL: {
        a: 4161107
    },
    ongoingWork: {
        "run-off": 1055942,
        turnover: 1055941
    },
    premiumDeposit: {
        a: 2110132
    },
    insuredTypeLegacy: {
        principal: 17184,
        ownerBuilder: 17185,
        contractor: 17186,
        subcontractor: 17187
    },
    projectTypeLegacy: {
        nrb: 17196,
        ncib: [17198, 17200],
        nsrfa: 17202,
        ar: 17197,
        acib: [17199, 17201],
        other: 1004016
    },
    ongoingWorkLegacy: {
        "run-off": 17002,
        turnover: 17001
    },
    maximumExcavationDepth: {
        "0-3": 1055980,
        "6-10": 1055984
    },
    territorialLimit: {
        qldNotAbove: 2129818,
        waNotAbove: 3079383,
        auNotAbove: 2129825,
        nsw: 2129819,
        act: 3079058,
        vic: 2129820,
        tas: 2129821,
        sa: 2129822,
        wa: 2129823,
        au: 3079382,
        nt: 2129824,
        qld: 2129817
    },
    territorialLimitLegacy: {
        qldNotAbove: 50792,
        waNotAbove: 1000357,
        auNotAbove: 50799,
        qldNswNotAbove: 1005500,
        nsw: 50793,
        act: 1000032,
        vic: 50794,
        tas: 50795,
        sa: 50796,
        wa: 0,
        nt: 0,
        qld: 0,
        au: 0,
        nswVic: 50801,
        vicTas: 51029,
        nswAct: 1065093,
        saNsw: 1014127
    },
    cpl: {
        "0": 1024332,
        "250000": 1024334,
        "500000": 1024336,
        "1000000": 1024338
    }
};
