import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild
} from "@angular/core";
import { Quote } from "../../models/quote";
import { GlobalStaticService } from "../../static-services/global.static-service";

@Component({
    selector: "app-quote-broker-charges-component",
    templateUrl: "./quote-broker-charges.component.html",
    styleUrls: ["./quote-broker-charges.component.scss"]
})
export class QuoteBrokerChargesComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    referral = false;
    @Input("quote") quote: Quote;
    brokerCommissionSelectionFlag = false;
    @ViewChild("broker2") broker2: ElementRef;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor() {}
    ngOnChanges(): void {}
    brokerFeeKeyUp(event: any): void {
        this.quote.brokerFee = GlobalStaticService.numberFormatDecimal(event.target.value);
    }
    fieldSwitch(event: any): void {
        if (event.keyCode === 13) {
            this.broker2.nativeElement.focus();
        }
    }
    openBrokerCommissionSelection(event: any): void {
        event.target.blur();
        this.brokerCommissionSelectionFlag = true;
    }
    brokerCommissionChoose(option: any): void {
        if (this.brokerCommissionSelectionFlag) {
            this.brokerCommissionSelectionFlag = false;
            this.quote.brokerCommission = Number(option.value) * 100 + "%";
            this.quote.brokerCommissionId = option.valueId;
        }
    }
    brokerCommissionChooseDefault(): void {
        if (this.brokerCommissionSelectionFlag) {
            this.brokerCommissionSelectionFlag = false;
            this.quote.brokerCommission = "15%";
            for (let i = 0; i < this.quote.brokerCommissionOption.length; i++) {
                if (
                    Number(this.quote.brokerCommissionOption[i].value) === 0.15
                ) {
                    this.quote.brokerCommissionId = this.quote.brokerCommissionOption[
                        i
                    ].valueId;
                }
            }
        }
    }
    next(): void {
        this.continueEventEmitter.emit({
            finished: true
        });
    }
}
