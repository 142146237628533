import { Injectable } from "@angular/core";

import { Client } from "../models/client";
import { Claim } from "../models/claim";
import { Quote } from "../models/quote";
import { Template } from "../models/template";
import { Policy } from "../models/policy";
import { Broker } from "../models/broker";
import { Rating } from "../models/rating";

import { CONFIG } from "../configs/config";

import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/interval";

import { Storage } from "@ionic/storage";
import { CommonUtility } from "../utilities/common.utility";
import { CommonApi } from "../api/common.api";

import * as moment from "moment";
import * as cloneDeep from "lodash/cloneDeep";
import { SchemeTrackerService } from "../services/scheme-tracker.service";
import { StorageKey } from "../constants";
import { EventBus } from "../services/event_bus";
import { UcEvent } from "./events";
import { Dialog } from "../components/app-dialog/app-dialog.service";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class GlobalStaticService {
    static data: any;
    private static token: string;
    static backoffice: string;
    static mediator: string;
    static signupAddressFinished = false;
    static lastActionTimestamp: number;
    static lastActionTimestampUpdate1$$: any;
    static lastActionTimestampUpdate2$$: any;
    static lastActionTimestampUpdate3$$: any;
    static lastActionTimestampUpdate4$$: any;
    static lastActionTimestampUpdate5$$: any;
    static autoLogoutAction$$: any;
    static infos = [];
    static infoModal: any = {
        title: "",
        content: ""
    };
    static submitProgress = 0;
    static submitReferral = false;
    static submitItem = "Submitting form";
    static submitStage = 1;
    static submitStage2$: any;
    static submitStage3$: any;
    static brokerProfile: Broker = new Broker();
    static isDydEnabled = false;
    static dydSetTimeout: any;
    static dydTitle: string;
    static dydMsg: string;
    static isTutorialActive = false;
    static tutorialPage = 1;
    static tutorialStage = 1;
    static autoSingle = false;
    static autoAnnual = false;
    static autoReferral = false;
    static autoSummary = false;
    static recentClients: Client[] = [];
    static recentQuotes: Quote[] = [];
    static recentTemplates: Template[] = [];
    static recentPolicies: Policy[] = [];
    static recentClaims: Claim[] = [];
    static recentBusinessOfInsured: number[] = [];
    static clientQuote = false;
    static template = false;
    static autoSingleQuote: Quote;
    static autoAnnualQuote: Quote;
    static templates: Template[] = [];
    static isQuoteBind = false;
    static cocFormData: any;
    static businessOfInsuredIsNormal: boolean;
    static quoteOptionsDynamicReferral: any[] = [];
    static quoteOptionsMiscReferral: any[] = [];
    static workLocationNT = null;
    static workLocationQLDa = null;
    static workLocationQLDb = null;
    static workLocationWAa = null;
    static workLocationWAb = null;
    static workLocationSA = null;
    static workLocationNSW = null;
    static workLocationVIC = null;
    static workLocationACT = null;
    static workLocationTAS = null;
    static quickModal = false;
    static scheme = false;
    static quoteOptionsMDValue: any[] = [];
    static quoteOptionsPLValue: any[] = [];
    static quoteOptionsMDDes: any[] = [];
    static quoteOptionsPLDes: any[] = [];
    static clientEdit = false;

    static cleanRecent(): void {
        GlobalStaticService.recentClients = [];
        GlobalStaticService.recentQuotes = [];
        GlobalStaticService.recentTemplates = [];
        GlobalStaticService.recentClaims = [];
        GlobalStaticService.recentPolicies = [];
        GlobalStaticService.recentBusinessOfInsured = [];
        GlobalStaticService.autoSingleQuote = null;
        GlobalStaticService.autoAnnualQuote = null;
    }
    static logout(): void {
        GlobalStaticService.clearToken();
        GlobalStaticService.lastActionTimestamp = 9999999999999;
        GlobalStaticService.cancelAutoLogoutAction();
    }
    static clearToken(): void {
        GlobalStaticService.token = null;
        sessionStorage.removeItem(StorageKey.SK_BEAR_TOKEN);
    }
    static setToken(token: string): void {
        GlobalStaticService.token = token;
        if (token) {
            sessionStorage.setItem(StorageKey.SK_BEAR_TOKEN, token);
        } else {
            sessionStorage.removeItem(StorageKey.SK_BEAR_TOKEN);
        }
    }
    static getToken(loadFromStorage: boolean = false): string {
        if (!GlobalStaticService.token && loadFromStorage) {
            GlobalStaticService.token = sessionStorage.getItem(
                StorageKey.SK_BEAR_TOKEN
            );
        }
        return GlobalStaticService.token;
    }
    static goHome(router: Router): void {
        router.navigate(["/"]);
    }
    static addRecent(queue, newRecent): void {
        GlobalStaticService[queue].forEach((queueRecent, index) => {
            switch (queue) {
                case "recentClaims":
                    if (queueRecent.claimId === newRecent.claimId) {
                        GlobalStaticService[queue].splice(index, 1);
                    }
                    break;
                case "recentClients":
                    if (queueRecent.clientId === newRecent.clientId) {
                        GlobalStaticService[queue].splice(index, 1);
                    }
                    break;
                case "recentPolicies":
                    if (queueRecent.policyId === newRecent.policyId) {
                        GlobalStaticService[queue].splice(index, 1);
                    }
                    break;
                case "recentQuotes":
                    if (queueRecent.quoteId === newRecent.quoteId) {
                        GlobalStaticService[queue].splice(index, 1);
                    }
                    break;
                case "recentTemplates":
                    if (queueRecent.templateTitle === newRecent.templateTitle) {
                        GlobalStaticService[queue].splice(index, 1);
                    }
                    break;
                case "recentBusinessOfInsured":
                    if (queueRecent === newRecent) {
                        GlobalStaticService[queue].splice(index, 1);
                    }
                    break;
            }
        });
        GlobalStaticService[queue].splice(0, 0, newRecent);
        let max = 10;
        if (queue === "recentClients") {
            max = 3;
        }
        if (GlobalStaticService[queue].length > max) {
            GlobalStaticService[queue].splice(max, 1);
        }
        const recent = {
            id: GlobalStaticService.brokerProfile.contactId,
            recentClients: GlobalStaticService.recentClients,
            recentQuotes: GlobalStaticService.recentQuotes,
            recentTemplates: GlobalStaticService.recentTemplates,
            recentPolicies: GlobalStaticService.recentPolicies,
            recentClaims: GlobalStaticService.recentClaims,
            recentBusinessOfInsured: GlobalStaticService.recentBusinessOfInsured
        };
        const storage: Storage = new Storage({ driverOrder: ["localstorage"] });
        storage.set("recent", recent);
    }
    static tutorialAutoTrigger(type: string): void {
        const tutorial = {
            autoSingle: GlobalStaticService.autoSingle,
            autoAnnual: GlobalStaticService.autoAnnual,
            autoReferral: GlobalStaticService.autoReferral,
            autoSummary: GlobalStaticService.autoSummary
        };
        tutorial[type] = true;
        GlobalStaticService[type] = true;
        const storage: Storage = new Storage({ driverOrder: ["localstorage"] });
        storage.set("tutorial", tutorial);
    }
    static saveAutoQuote(
        quote: Quote,
        isSingle: boolean,
        schemeTracker: SchemeTrackerService
    ): void {
        const storage: Storage = new Storage({ driverOrder: ["localstorage"] });
        if (isSingle) {
            GlobalStaticService.autoSingleQuote = CommonUtility.cloneQuote(
                quote
            );
            storage.set("singleQuote", GlobalStaticService.autoSingleQuote);
        } else {
            GlobalStaticService.autoAnnualQuote = CommonUtility.cloneQuote(
                quote
            );
            storage.set("annualQuote", GlobalStaticService.autoAnnualQuote);
        }
        schemeTracker.saveIntoStorage(storage);
    }
    static loadFromStorage(schemeTracker: SchemeTrackerService): void {
        const storage: Storage = new Storage({ driverOrder: ["localstorage"] });
        storage.get("recent").then(recent => {
            if (
                recent &&
                recent.id === GlobalStaticService.brokerProfile.contactId
            ) {
                GlobalStaticService.recentClients = recent.recentClients || [];
                GlobalStaticService.recentClaims = recent.recentClaims || [];
                GlobalStaticService.recentPolicies =
                    recent.recentPolicies || [];
                GlobalStaticService.recentTemplates =
                    recent.recentTemplates || [];
                GlobalStaticService.recentQuotes = recent.recentQuotes || [];
                GlobalStaticService.recentBusinessOfInsured =
                    recent.recentBusinessOfInsured || [];

                storage.get("singleQuote").then(singleQuote => {
                    if (singleQuote) {
                        if (typeof singleQuote.policyStart === "string") {
                            singleQuote.policyStart = moment(
                                singleQuote.policyStart
                            ).toDate();
                        }
                        if (typeof singleQuote.policyEnd === "string") {
                            singleQuote.policyEnd = moment(
                                singleQuote.policyEnd
                            ).toDate();
                        }
                        GlobalStaticService.autoSingleQuote = singleQuote;
                        schemeTracker.loadFromStorage(storage);
                    }
                });
                storage.get("annualQuote").then(annualQuote => {
                    if (annualQuote) {
                        if (typeof annualQuote.policyStart === "string") {
                            annualQuote.policyStart = moment(
                                annualQuote.policyStart
                            ).toDate();
                        }
                        if (typeof annualQuote.policyEnd === "string") {
                            annualQuote.policyEnd = moment(
                                annualQuote.policyEnd
                            ).toDate();
                        }
                        GlobalStaticService.autoAnnualQuote = annualQuote;
                        schemeTracker.loadFromStorage(storage);
                    }
                });
            } else {
                const recentPlaceholder = {
                    id: GlobalStaticService.brokerProfile.contactId,
                    recentClients: [],
                    recentQuotes: [],
                    recentTemplates: [],
                    recentPolicies: [],
                    recentClaims: [],
                    recentBusinessOfInsured: []
                };
                GlobalStaticService.cleanRecent();
                const storage: Storage = new Storage({
                    driverOrder: ["localstorage"]
                });
                storage.set("recent", recentPlaceholder);
                schemeTracker.reset();
            }
        });
    }
    static getTemplates(commonApi: CommonApi, contactId: number): void {
        commonApi.getTemplates(contactId).subscribe(templates => {
            if (templates) {
                GlobalStaticService.templates = [];
                for (let i = 0; i < templates.length; i++) {
                    delete templates[i].json.quote.insuredDeclaration;
                    delete templates[i].json.quote.insuredDeclarationDetails;
                    delete templates[i].json.quote.insuredDeclarationModalViewed;
                    delete templates[i].json.quote.unlicensedBuilder;
                    delete templates[i].json.quote.licenseLending;
                    delete templates[i].json.quote.errorBuildingLicense;
                    delete templates[i].json.quote.buildingLicenseReferral;
                    delete templates[i].json.quote._attachDeclarationConfirmed;
                    templates[i].json.quote.referral = templates[i].json.quote.referral.filter(function(value, index, arr){
                        // declaration
                        //return value !== 'p1q5'
                        return value !== 'p1q4'
                            // building license
                            //&& value !== 'p1q3';
                    });
                    if (
                        typeof templates[i].json.quote.policyStart === "string"
                    ) {
                        templates[i].json.quote.policyStart = moment(
                            templates[i].json.quote.policyStart
                        ).toDate();
                    }
                    if (typeof templates[i].json.quote.policyEnd === "string") {
                        templates[i].json.quote.policyEnd = moment(
                            templates[i].json.quote.policyEnd
                        ).toDate();
                    }
                    templates[i].json.templateId = templates[i].id;
                    GlobalStaticService.templates.push(templates[i].json);
                }
            }
        });
    }
    static templateSave(template: Template, commonApi: any): void {
        // Template Don't save insured name
        template.quote.insuredName = null;
        template.quote.insuredNameFinished = false;
        template.quote.unlicensedBuilder = null;
        template.quote.licenseLending = null;
        template.quote.errorBuildingLicense = false;
        template.quote.client = new Client();
        template.quote.clientId = null;
        template.quote.page1OkFlag = false;
        template.quote.attachments = [];
        template.quote.reachSummary = false;
        template.quote.rating = new Rating();
        template.quote.facility = [];
        template.quote.wording = null;
        template.quote.sections = [];
        template.quote.sectionsMeconAdminFeeGst = 0;
        template.quote.policyWording = null;
        template.quote.option = {};
        template.quote.quoteId = null;
        template.quote.providerQuoteReference = null;
        template.quote.underwriterName = null;
        template.quote.origQuoteId = null;
        template.quote.quoteTypeText = null;
        if (!template.quote.workLocationNT) {
            template.quote.workLocationNT = 0;
        }
        if (!template.quote.workLocationQLDa) {
            template.quote.workLocationQLDa = 0;
        }
        if (!template.quote.workLocationQLDb) {
            template.quote.workLocationQLDb = 0;
        }
        if (!template.quote.workLocationWAa) {
            template.quote.workLocationWAa = 0;
        }
        if (!template.quote.workLocationWAb) {
            template.quote.workLocationWAb = 0;
        }
        if (!template.quote.workLocationSA) {
            template.quote.workLocationSA = 0;
        }
        if (!template.quote.workLocationNSW) {
            template.quote.workLocationNSW = 0;
        }
        if (!template.quote.workLocationVIC) {
            template.quote.workLocationVIC = 0;
        }
        if (!template.quote.workLocationACT) {
            template.quote.workLocationACT = 0;
        }
        if (!template.quote.workLocationTAS) {
            template.quote.workLocationTAS = 0;
        }
        for (let i = 0; i < template.quote.page1Status.length; i++) {
            if (template.quote.page1Status[i] === 1) {
                template.quote.page1Status.splice(i, 1);
            }
        }
        // Also remove building license from being marked as completed
        /* for (let i = 0; i < template.quote.page1Status.length; i++) {
            if (template.quote.page1Status[i] === 3) {
                template.quote.page1Status.splice(i, 1);
            }
        } */
        // Also remove declaration from being marked as completed
        for (let i = 0; i < template.quote.page1Status.length; i++) {
   //         if (template.quote.page1Status[i] === 5) {
            if (template.quote.page1Status[i] === 4) {
                template.quote.page1Status.splice(i, 1);
            }
        }
        template.quote.interestedParties = [];
        // Need to remove id and other unique attributes as well
        commonApi
            .postTemplate(GlobalStaticService.brokerProfile.contactId, template)
            .subscribe(res => {
                template.templateId = res.id;
                GlobalStaticService.getTemplates(
                    commonApi,
                    GlobalStaticService.brokerProfile.contactId
                );
            });
        // const storage: Storage = new Storage({ driverOrder: ["localstorage"] });
        // storage.set("templates", GlobalStaticService.templates);
    }
    static templateDelete(template: Template, commonApi: any): void {
        commonApi.deleteTemplate(template.templateId).subscribe(res => {
            GlobalStaticService.templates.forEach((templates, index) => {
                if (templates.templateTitle === template.templateTitle) {
                    GlobalStaticService.templates.splice(index, 1);
                }
            });
            GlobalStaticService.recentTemplates.forEach(
                (queueRecent, index) => {
                    if (queueRecent.templateTitle === template.templateTitle) {
                        GlobalStaticService.recentTemplates.splice(index, 1);
                    }
                }
            );
        });
        const storage: Storage = new Storage({ driverOrder: ["localstorage"] });
        const recent = {
            id: GlobalStaticService.brokerProfile.contactId,
            recentClients: GlobalStaticService.recentClients,
            recentQuotes: GlobalStaticService.recentQuotes,
            recentTemplates: GlobalStaticService.recentTemplates,
            recentPolicies: GlobalStaticService.recentPolicies,
            recentClaims: GlobalStaticService.recentClaims
        };
        storage.set("recent", recent);
    }
    static cleanQuoteAutoSave(isSingle: boolean): void {
        const storage: Storage = new Storage({ driverOrder: ["localstorage"] });
        if (isSingle) {
            storage.set("singleQuote", null);
            GlobalStaticService.autoSingleQuote = null;
        } else {
            storage.set("annualQuote", null);
            GlobalStaticService.autoAnnualQuote = null;
        }
    }
    static hideDyd(page: number): void {
        if (page === 1 || page === 4) {
            GlobalStaticService.isDydEnabled = false;
            clearTimeout(GlobalStaticService.dydSetTimeout);
        }
    }
    static setupAutoLogoutAction(commonApi: CommonApi): void {
        GlobalStaticService.lastActionTimestampUpdate();
        GlobalStaticService.lastActionTimestampUpdate1$$ = Observable.fromEvent(
            document,
            "mousedown"
        ).subscribe(() => {
            GlobalStaticService.lastActionTimestampUpdate();
        });
        GlobalStaticService.lastActionTimestampUpdate2$$ = Observable.fromEvent(
            document,
            "touchstart"
        ).subscribe(() => {
            GlobalStaticService.lastActionTimestampUpdate();
        });
        GlobalStaticService.lastActionTimestampUpdate3$$ = Observable.fromEvent(
            document,
            "mousemove"
        ).subscribe(() => {
            GlobalStaticService.lastActionTimestampUpdate();
        });
        GlobalStaticService.lastActionTimestampUpdate4$$ = Observable.fromEvent(
            window,
            "wheel"
        ).subscribe(() => {
            GlobalStaticService.lastActionTimestampUpdate();
        });
        GlobalStaticService.lastActionTimestampUpdate5$$ = Observable.fromEvent(
            window,
            "keyup"
        ).subscribe(() => {
            GlobalStaticService.lastActionTimestampUpdate();
        });
        GlobalStaticService.autoLogoutAction$$ = Observable.interval(
            10000
        ).subscribe(e => {
            const date = new Date();
            if (
                GlobalStaticService.lastActionTimestamp + CONFIG.SESSION_TIME <
                    date.getTime() &&
                GlobalStaticService.token
            ) {
                GlobalStaticService.cancelAutoLogoutAction();
                GlobalStaticService.infoModal = {
                    noClose: true,
                    title: "WARNING",
                    content:
                        "It looks like you have been inactive for a while. Would you like to stay logged in?",
                    buttons: [
                        {
                            style: "",
                            text: "STAY LOGGED IN",
                            callback: () => {
                                GlobalStaticService.setupAutoLogoutAction(
                                    commonApi
                                );
                                GlobalStaticService.closeInfoModal();
                            }
                        },
                        {
                            style: "purple",
                            text: "LOG OUT",
                            callback: () => {
                                commonApi.getLogout().subscribe(res => {
                                    GlobalStaticService.logout();
                                    document.body.className = "";
                                    GlobalStaticService.closeInfoModal();
                                });
                            }
                        }
                    ]
                };
                GlobalStaticService.openInfoModal();
            }
        });
    }
    static cancelAutoLogoutAction(): void {
        if (GlobalStaticService.lastActionTimestampUpdate1$$) {
            GlobalStaticService.lastActionTimestampUpdate1$$.unsubscribe();
        }
        if (GlobalStaticService.lastActionTimestampUpdate2$$) {
            GlobalStaticService.lastActionTimestampUpdate2$$.unsubscribe();
        }
        if (GlobalStaticService.lastActionTimestampUpdate3$$) {
            GlobalStaticService.lastActionTimestampUpdate3$$.unsubscribe();
        }
        if (GlobalStaticService.lastActionTimestampUpdate4$$) {
            GlobalStaticService.lastActionTimestampUpdate4$$.unsubscribe();
        }
        if (GlobalStaticService.lastActionTimestampUpdate5$$) {
            GlobalStaticService.lastActionTimestampUpdate5$$.unsubscribe();
        }
        if (GlobalStaticService.autoLogoutAction$$) {
            GlobalStaticService.autoLogoutAction$$.unsubscribe();
        }
    }
    static lastActionTimestampUpdate(): void {
        const date = new Date();
        GlobalStaticService.lastActionTimestamp = date.getTime();
    }
    static openInfoModal(): void {
        document.body.classList.add("lock");
        document.body.classList.add("info-modal");
    }
    static closeInfoModal(): void {
        GlobalStaticService.removeBodyLock();
        document.body.classList.remove("info-modal");
    }
    static openLoadTemplateModal(): void {
        document.body.classList.add("lock");
        document.body.classList.add("load-template-modal");
    }
    static closeLoadTemplateModal(): void {
        GlobalStaticService.removeBodyLock();
        document.body.classList.remove("load-template-modal");
    }
    static openSaveTemplateModal(): void {
        document.body.classList.add("lock");
        document.body.classList.add("save-template-modal");
    }
    static closeSaveTemplateModal(): void {
        GlobalStaticService.removeBodyLock();
        document.body.classList.remove("save-template-modal");
    }
    static openQuickQuoteModal(): void {
        document.body.classList.add("lock");
        document.body.classList.add("quick-quote-modal");
    }
    static closeQuickQuoteModal(): void {
        GlobalStaticService.removeBodyLock();
        document.body.classList.remove("quick-quote-modal");
    }
    static openLoadTemplateDoneModal(): void {
        GlobalStaticService.infoModal = {
            size: "small-size",
            title: "",
            content: `
                <div class="text-center"><i class="u-icon icon-70 icon-template-loaded circle"></i></div>
                <h2 class="text-center f-mgt-10">Template Loaded</h2>
                <div class="text-center f-mgt-20 f-mgb-40">
                Your information has been loaded<br>
                to your current form
                </div>
            `,
            buttons: [
                {
                    style: "",
                    text: "Ok"
                }
            ]
        };
        GlobalStaticService.openInfoModal();
    }
    static openSaveTemplateDoneModal(): void {
        GlobalStaticService.infoModal = {
            size: "small-size",
            title: "",
            content: `
                <div class="text-center"><i class="u-icon icon-64 icon-saved"></i></div>
                <h2 class="text-center f-mgt-10">Saved!</h2>
                <div class="text-center f-mgt-20">
                    <small>
                        Saved templates can be accessed<br>
                        through "Load a quote template" at the<br>
                        beginning of a quote request
                    </small>
                </div>
            `,
            buttons: [
                {
                    style: "",
                    text: "Ok"
                }
            ]
        };
        GlobalStaticService.openInfoModal();
    }
    static openCreateQuoteModal(router: any, client?: Client): void {
        GlobalStaticService.infoModal = {
            title: "Create a new quote",
            content: null,
            buttons: [
                {
                    style: "grey padding",
                    text: "Single<br>Construction<br>Quote",
                    callback: () => {
                        if (client) {
                            GlobalStaticService.clientQuote = true;
                            GlobalStaticService.autoSingleQuote = new Quote();
                            GlobalStaticService.autoSingleQuote.type = "single";
                            GlobalStaticService.autoSingleQuote.client = client;
                            GlobalStaticService.autoSingleQuote.tradingNames =
                                client.tradingNames;
                            GlobalStaticService.autoSingleQuote.clientName =
                                client.clientName;
                            GlobalStaticService.autoSingleQuote.insuredName =
                                client.clientName;
                            GlobalStaticService.autoSingleQuote.insuredNameFinished = true;
                            GlobalStaticService.autoSingleQuote.newClientFlag = false;
                        }
                        GlobalStaticService.closeInfoModal();
                        router.navigate(["/dashboard/quotes/single/create"]);
                    }
                },
                {
                    style: "grey padding",
                    text: "Annual<br>Construction<br>Quote",
                    callback: () => {
                        if (client) {
                            GlobalStaticService.clientQuote = true;
                            GlobalStaticService.autoAnnualQuote = new Quote();
                            GlobalStaticService.autoAnnualQuote.type = "annual";
                            GlobalStaticService.autoAnnualQuote.client = client;
                            GlobalStaticService.autoAnnualQuote.tradingNames =
                                client.tradingNames;
                            GlobalStaticService.autoAnnualQuote.clientName =
                                client.clientName;
                            GlobalStaticService.autoAnnualQuote.insuredName =
                                client.clientName;
                            GlobalStaticService.autoAnnualQuote.insuredNameFinished = true;
                            GlobalStaticService.autoAnnualQuote.newClientFlag = false;
                        }
                        GlobalStaticService.closeInfoModal();
                        router.navigate(["/dashboard/quotes/annual/create"]);
                    }
                }
            ]
        };
        GlobalStaticService.openInfoModal();
    }
    static openAddClientModal(): void {
        document.body.classList.add("lock");
        document.body.classList.add("add-client-modal");
    }
    static closeAddClientModal(): void {
        GlobalStaticService.removeBodyLock();
        document.body.classList.remove("add-client-modal");
    }
    static openAddBusinessTypeModal(normal: boolean): void {
        GlobalStaticService.businessOfInsuredIsNormal = normal;
        document.body.classList.add("lock");
        document.body.classList.add("add-business-type-modal");
    }
    static closeAddBusinessTypeModal(): void {
        GlobalStaticService.removeBodyLock();
        document.body.classList.remove("add-business-type-modal");
        EventBus.trigger(UcEvent.BUSINESS_TYPE_MODAL_CLOSED);
    }
    static openAddAddressModal(): void {
        document.body.classList.add("lock");
        document.body.classList.add("add-address-modal");
    }
    static closeAddAddressModal(): void {
        GlobalStaticService.removeBodyLock();
        document.body.classList.remove("add-address-modal");
    }
    static openNewAddAddressModal(): void {
        document.body.classList.add("new-lock");
        document.body.classList.add("add-address-modal");
    }
    static closeNewAddAddressModal(): void {
        document.body.classList.remove("new-lock");
        document.body.classList.remove("add-address-modal");
    }
    static openEnhanceCoverModal(): void {
        document.body.classList.add("lock");
        document.body.classList.add("enhance-cover-modal");
    }
    static closeEnhanceCoverModal(): void {
        GlobalStaticService.removeBodyLock();
        document.body.classList.remove("enhance-cover-modal");
    }
    static openWorkLocationModal(quote: Quote, quick: boolean): void {
        GlobalStaticService.quickModal = quick;
        this.workLocationNT = quote.workLocationNT;
        this.workLocationQLDa = quote.workLocationQLDa;
        this.workLocationQLDb = quote.workLocationQLDb;
        this.workLocationWAa = quote.workLocationWAa;
        this.workLocationWAb = quote.workLocationWAb;
        this.workLocationSA = quote.workLocationSA;
        this.workLocationNSW = quote.workLocationNSW;
        this.workLocationVIC = quote.workLocationVIC;
        this.workLocationACT = quote.workLocationACT;
        this.workLocationTAS = quote.workLocationTAS;
        document.body.classList.add("lock");
        document.body.classList.add("work-location-modal");
    }
    static closeWorkLocationModal(): void {
        GlobalStaticService.removeBodyLock();
        document.body.classList.remove("work-location-modal");
    }
    static openSubmitLoading(item: string, referral?: boolean): void {
        GlobalStaticService.submitReferral = referral;
        GlobalStaticService.submitProgress = 0;
        setTimeout(() => {
            GlobalStaticService.submitProgress = 100;
        }, 100);
        if (item === "Submitting form") {
            GlobalStaticService.submitStage = 1;
            if (GlobalStaticService.submitStage2$) {
                clearTimeout(GlobalStaticService.submitStage2$);
            }
            GlobalStaticService.submitStage2$ = setTimeout(() => {
                GlobalStaticService.submitStage = 2;
            }, 10000);
            if (GlobalStaticService.submitStage3$) {
                clearTimeout(GlobalStaticService.submitStage3$);
            }
            GlobalStaticService.submitStage3$ = setTimeout(() => {
                GlobalStaticService.submitStage = 3;
            }, 26000);
        }
        document.body.classList.add("lock");
        document.body.classList.add("submit");
        GlobalStaticService.submitItem = item;
    }
    static closeSubmitLoading(): void {
        GlobalStaticService.removeBodyLock();
        document.body.classList.remove("submit");
        GlobalStaticService.submitProgress = 0;
    }
    static openEmailQuoteModal(): void {
        document.body.classList.add("lock");
        document.body.classList.add("email-quote-modal");
    }
    static closeEmailQuoteModal(): void {
        GlobalStaticService.removeBodyLock();
        document.body.classList.remove("email-quote-modal");
    }
    static openEmailPolicyModal(): void {
        document.body.classList.add("lock");
        document.body.classList.add("email-policy-modal");
    }
    static closeEmailPolicyModal(): void {
        GlobalStaticService.removeBodyLock();
        document.body.classList.remove("email-policy-modal");
    }
    static openEmailCocModal(): void {
        document.body.classList.add("lock");
        document.body.classList.add("email-coc-modal");
    }
    static closeEmailCocModal(): void {
        GlobalStaticService.removeBodyLock();
        document.body.classList.remove("email-coc-modal");
    }
    static openBindPolicyModal(): void {
        document.body.classList.add("lock");
        document.body.classList.add("bind-policy-modal");
    }
    static closeBindPolicyModal(): void {
        GlobalStaticService.removeBodyLock();
        document.body.classList.remove("bind-policy-modal");
    }
    static errorOpenQuote(quote: Quote) {
        GlobalStaticService.openUnexpectedErrorModal(
            "Error open quote",
            `The quote ${quote.quoteId} has an error. Please contact MECON support.`
        );
    }
    static openSubmitQuoteErrorModalAndReturnHome(router: Router, isSingle: boolean): void {
        GlobalStaticService.closeSubmitLoading();
        GlobalStaticService.cleanQuoteAutoSave(isSingle);
        Dialog.open({
            title: "Unexpected Error",
            message: "An unexpected error occurred with your quote. Please contact Mecon now to resolve the issue.",
            buttons: ["Ok"]
        }).subscribe(() => {
            router.navigate(['/dashboard/home']);
        });
    }
    static openUnexpectedErrorModal(
        title: string = "UNEXPECTED ERROR",
        message: string = "An unexpected error occurred. Please try again in a few minutes or contact support."
    ): void {
        GlobalStaticService.infoModal = {
            title: title,
            content: message
        };
        GlobalStaticService.openInfoModal();
        if (document.body.classList.contains("submit")) {
            GlobalStaticService.closeSubmitLoading();
        }
    }
    static openQuickQuoteCheckModal(): void {
        GlobalStaticService.infoModal = {
            title: "WARNING",
            content: "Please review the information in this quote and select the checkbox to confirm that it is correct"
        };
        GlobalStaticService.openInfoModal();
    }
    static openErrorSuburbModal(): void {
        GlobalStaticService.infoModal = {
            title: "UNEXPECTED ERROR",
            content:
                "An unexpected error occurred with the selection, instead please add the address by clicking on the plus icon."
        };
        GlobalStaticService.openInfoModal();
    }
    static removeBodyLock(): void {
        if (document.body.classList.contains("new-lock")) {
            document.body.classList.remove("lock");
        } else {
            if (document.body.classList.length === 2) {
                document.body.classList.remove("lock");
            }
        }
    }
    static removeTutorialBodyLock(): void {
        if (document.body.classList.length === 1) {
            document.body.classList.remove("lock");
        }
    }
    static callTutorial(initPage: number, stage: number): void {
        document.body.classList.add("lock");
        GlobalStaticService.tutorialPage = initPage;
        GlobalStaticService.tutorialStage = stage;
        GlobalStaticService.isTutorialActive = true;
    }
    static pdfAddHeader(doc: any): any {
        const imgData = CONFIG.LOGO_BASE64;
        doc.addImage(imgData, "PNG", 74, 28, 150, 62);

        doc.setFontSize(10);
        doc.text("MECON Insurance Pty Ltd", 408, 38);
        doc.text("A.B.N 29 059 310 904", 428, 50);
        doc.text("AFSL 253106", 465, 62);

        doc.setFontSize(9);
        doc.text("www.mecon.com.au", 444, 86);

        return doc;
    }
    static pdfAddFooter(doc: any, policyId: number): any {
        const columns = [
            { title: "C1", dataKey: "c1" },
            { title: "C2", dataKey: "c2" },
            { title: "C3", dataKey: "c3" },
            { title: "C4", dataKey: "c4" }
        ];
        const rows = [
            { c1: "SYDNEY", c2: "MELBOURNE", c3: "BRISBANE", c4: "PERTH" },
            {
                c1: "PO Box R1789",
                c2: "236a Lennox Street",
                c3: "PO Box 6037",
                c4: "Suite 5, 996 Hay Street"
            },
            {
                c1: "Royal Exchange NSW 1225",
                c2: "Richmond VIC 3121",
                c3: "Upper Mt Gravatt QLD 4122",
                c4: "Perth WA 600"
            },
            { c1: "", c2: "", c3: "", c4: "" },
            {
                c1: "Tel: (02) 9252 1040",
                c2: "Tel: (02) 9421 6379",
                c3: "Tel: (07) 3146 0100",
                c4: "Tel: (08) 9322 4529"
            }
        ];
        doc.autoTable(columns, rows, {
            startY: 710,
            margin: { left: 74, right: 74 },
            tableWidth: "auto",
            styles: { overflow: "linebreak" },
            showHeader: "never",
            bodyStyles: {
                fillColor: [255, 255, 255],
                textColor: 0
            },
            alternateRowStyles: {
                fillColor: [255, 255, 255]
            },
            columnStyles: {
                c1: {
                    fontSize: 8,
                    cellPadding: 1,
                    halign: "center"
                },
                c2: {
                    fontSize: 8,
                    cellPadding: 1,
                    halign: "center"
                },
                c3: {
                    fontSize: 8,
                    cellPadding: 1,
                    halign: "center"
                },
                c4: {
                    fontSize: 8,
                    cellPadding: 1,
                    halign: "center"
                }
            }
        });
        doc.setFontSize(8);
        doc.text(
            "Transaction Ref: " + policyId,
            74,
            doc.autoTable.previous.finalY + 20
        );

        return doc;
    }
    static bindPolicy(quote: Quote): void {
        if (!GlobalStaticService.isQuoteBind) {
            quote.tempPolicyStart = cloneDeep(quote.policyStart);
            quote.tempPolicyEnd = cloneDeep(quote.policyEnd);
            const start = moment(quote.tempPolicyStart);
            const end = moment(quote.tempPolicyEnd);
            quote.policyPeriod = end.diff(start, "days");
            if (start.isBefore(moment(), "day")) {
                quote.tempPolicyStart = moment().toDate();
                quote.tempPolicyEnd = moment()
                    .add(quote.policyPeriod, "days")
                    .toDate();
            }
            GlobalStaticService.openBindPolicyModal();
        }
    }

    static priceFormat(price: any, minWidth = 0): string {
        if (!price) return "";
        const decimalArray = price.toString().split(".");
        let temp = decimalArray[0]
            .split("")
            .filter(e => {
                return /[0-9]/.test(e);
            })
            .join("");
        const length = temp.length;
        let result = "";
        if (length % 3) {
            result = temp.substr(0, length % 3);
            temp = temp.substr(length % 3);
        }
        for (let i = 0; i < Math.floor(length / 3); i++) {
            if (result) {
                result = result + "," + temp.substr(3 * i, 3);
            } else {
                result = temp.substr(3 * i, 3);
            }
        }
        if (decimalArray.length === 2 && decimalArray[1] !== "") {
            result = `$${result}.${decimalArray[1].substring(0, 2)}`;
        } else {
            result = `$${result}`;
        }
        return this.padRight(result, '\u2007', minWidth);
    }
    static padRight(s: string, c = ' ', minWidth = 0): string {
        let gap = minWidth - s.length;
        if (gap > 0) {
            s = s + new Array(gap + 1).join(c);
        }
        return s;
    }
    static numberFormat(price: any): number {
        if (typeof price !== "number") {
            if (price) {
                return Number(
                    price
                        .split(".")[0]
                        .split("")
                        .filter(number => {
                            return /[0-9]/.test(number);
                        })
                        .join("")
                );
            } else {
                return 0;
            }
        } else {
            return price;
        }
    }
    static numberFormatDecimal(price: any): number {
        if (typeof price !== "number") {
            if (price.indexOf(".") !== -1) {
                return Number(
                    price
                        .split(".")[0]
                        .split("")
                        .filter(number => {
                            return /[0-9]/.test(number);
                        })
                        .join("") +
                        "." +
                        price.split(".")[1]
                );
            } else {
                return Number(
                    price
                        .split("")
                        .filter(number => {
                            return /[0-9]/.test(number);
                        })
                        .join("")
                );
            }
        } else {
            return price;
        }
    }

    static acceptNumberOnly(e: any): void {
        if (!/^\d+$/.test(e.target.value)) {
            // wrong input
            e.target.value = "";
        }
    }
    static acceptDecimalNumberOnly(e: any): void {
        if (!/^(\d+|\d+\.\d{0,2})$/.test(e.target.value)) {
            // wrong input
            e.target.value = "";
        }
    }
    static acceptNumberOnlyInline(e: any): any {
        if (!/^\d+$/.test(e)) {
            // wrong input
            return null;
        }
        return Number(e);
    }
    constructor() {}
}
