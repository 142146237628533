import { Component } from "@angular/core";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { QuoteService } from "../../services/quote.service";

@Component({
    selector: "app-did-you-know-component",
    templateUrl: "./did-you-know.component.html",
    styleUrls: ["./did-you-know.component.scss"]
})
export class DidYouKnowComponent {
    GlobalStaticSrv = GlobalStaticService;
    constructor(private quoteSrv: QuoteService) {
        this.quoteSrv.getDyd();
    }
}
