import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalStaticService } from "../static-services/global.static-service";
import { Quote } from "../models/quote";
import { QuoteMappingStaticService } from "../static-services/quote-mapping.static-service";
import { CommonApi } from "../api/common.api";
import { Observable, Subject } from "rxjs/Rx";
import { TempQuote } from "../models/temp-quote";
import { CommonUtility } from "../utilities/common.utility";
import { QUOTE_REFERRAL } from "../configs/quote-referral";
import * as moment from "moment";
import { QUOTE_MAPPING } from "../configs/quote-mapping";
import { SchemeTrackerService } from "./scheme-tracker.service";
import { EventBus } from "./event_bus";
import { UcEvent } from "../static-services/events";
import { LookupService } from "./lookup.service";
declare let gtag: any;

@Injectable({
    providedIn: "root"
})
export class QuoteService {

    constructor(
        private router: Router,
        private commonApi: CommonApi,
        private schemeTracker: SchemeTrackerService,
        private lookupService: LookupService,
        private eventBus: EventBus
    ) { }

    getDyd(): void {
        this.commonApi.getDykRandom().subscribe(res => {
            GlobalStaticService.dydTitle = res.title;
            GlobalStaticService.dydMsg = res.brokerMsg;
        });
    }
    reachSummary(): void {
        this.autoSummary();
        GlobalStaticService.isDydEnabled = false;
    }
    continueProcess(
        fieldOrder: number,
        event: any,
        quote: Quote,
        currentPage: number,
        statusBenchmark: any,
        type: string
    ): void {
console.log("continueProcess", arguments, statusBenchmark);
        const referralPosition = quote.referral.indexOf(
            "p" + currentPage + "q" + fieldOrder
        );
        if (referralPosition !== -1) {
            quote.referral.splice(referralPosition, 1);
        }
        if (event.referral) {
            quote.referral.push("p" + currentPage + "q" + fieldOrder);
        }
        const status = "page" + currentPage + "Status";
        const statusPosition = quote[status].indexOf(fieldOrder);
        if (statusPosition !== -1) {
            quote[status].splice(statusPosition, 1);
        }
        if (event.finished) {
            quote[status].push(fieldOrder);
            if (currentPage !== 6) {
                if (fieldOrder > quote["page" + currentPage + "Progress"]) {
                    quote["page" + currentPage + "Progress"] = fieldOrder;
                }
            }
        }
console.log("quote[status]", quote[status]);
        quote["page" + currentPage + "OkFlag"] = quote[status].length === statusBenchmark[currentPage - 1];
        // how I can identify whether value has been changed!!!
        if (type === "create") {
            GlobalStaticService.saveAutoQuote(quote, quote.type === "single", this.schemeTracker);
        }
    }
    diff(type: string, quote1: Quote, quote2: Quote): string {
        if (type === "edit" || type === "modified") {
            let quote1S, quote2S;
            if (quote1.type === "single") {
                quote1S = QuoteMappingStaticService.processPostSingle(quote1);
                quote2S = QuoteMappingStaticService.processPostSingle(quote2);
            } else {
                quote1S = QuoteMappingStaticService.processPostAnnual(quote1);
                quote2S = QuoteMappingStaticService.processPostAnnual(quote2);
            }
            for (let i = 0; i < quote1.attachments.length; i++) {
                if (!quote1.attachments[i].submitted) {
                    return "modified";
                }
            }
            if (quote1S.insuredName !== quote2S.insuredName) {
                return "modified";
            }
            if (quote1S.clientId !== quote2S.clientId) {
                return "modified";
            }
            if (quote1S.quoteDate !== quote2S.quoteDate) {
                return "modified";
            }
            if (quote1S.quoteExpiryDate !== quote2S.quoteExpiryDate) {
                return "modified";
            }
            if (quote1S.inceptionDate !== quote2S.inceptionDate) {
                return "modified";
            }
            if (quote1S.policyStart !== quote2S.policyStart) {
                return "modified";
            }
            if (quote1S.policyEnd !== quote2S.policyEnd) {
                return "modified";
            }
            if (this.needsCheck(quote1S, quote2S) === "modified") {
                return "modified";
            } else {
                return this.needsCheck(quote2S, quote1S);
            }
        } else {
            return "create";
        }
    }
    copyMappingProcess(quote: any): any {
        const date = new Date();
        quote.quoteDate =
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate();
        quote.tailorYourCoverMdList = QuoteMappingStaticService.getMDExcessList(quote, this.lookupService);
        quote.tailorYourCoverLList = QuoteMappingStaticService.getPLExcessList(quote, this.lookupService);
        quote.tailorYourCoverCPLList = QuoteMappingStaticService.getCPLExcessList(quote, this.lookupService);

        if (quote.needs["115699"]) {
            for (
                let j = 0;
                j <
                QuoteMappingStaticService.singleLookups[115699].valueLookups
                    .length;
                j++
            ) {
                if (
                    QuoteMappingStaticService.singleLookups[115699]
                        .valueLookups[j].valueId ===
                    Number(quote.needs["115699"])
                ) {
                    quote.cpl = Number(
                        QuoteMappingStaticService.singleLookups[115699]
                            .valueLookups[j].value
                    );
                    break;
                }
            }
            if (!quote.cpl) {
                quote.cpl = 0;
            }
        } else if (quote.needs["116291"]) {
            for (
                let j = 0;
                j <
                QuoteMappingStaticService.annualLookups[116291].valueLookups
                    .length;
                j++
            ) {
                if (
                    QuoteMappingStaticService.annualLookups[116291]
                        .valueLookups[j].valueId ===
                    Number(quote.needs["115699"])
                ) {
                    quote.cpl = Number(
                        QuoteMappingStaticService.annualLookups[116291]
                            .valueLookups[j].value
                    );
                    break;
                }
            }
            if (!quote.cpl) {
                quote.cpl = 0;
            }
        } else {
            quote.cpl = 0;
        }

        return quote;
    }
    needsCheck(quote1S, quote2S): string {
console.log("needsCheck",  arguments)
        for (const p in quote1S.needs) {
            if (quote1S.needs.hasOwnProperty(p)) {
                if (
                    p === "tailorYourCoverMd" ||
                    p === "tailorYourCoverL" ||
                    p === "projectType"
                ) {
                    if (quote1S.needs[p].length === quote2S.needs[p].length) {
                        switch (p) {
                            case "tailorYourCoverMd":
                                if (quote1S.productId === 47) {
                                    if (
                                        quote1S.needs[p][0][115688] !==
                                        quote2S.needs[p][0][115688]
                                    ) {
                                        return "modified";
                                    }
                                } else {
                                    if (
                                        quote1S.needs[p][0][116280] !==
                                        quote2S.needs[p][0][116280]
                                    ) {
                                        return "modified";
                                    }
                                }
                                break;
                            case "tailorYourCoverL":
                                // Refer: UND-959
                                // if (quote1S.productId === 47) {
                                //     if (
                                //         quote1S.needs[p][0][115704] !==
                                //         quote2S.needs[p][0][115704]
                                //     ) {
                                //         return "modified";
                                //     }
                                // } else {
                                //     if (
                                //         quote1S.needs[p][0][116296] !==
                                //         quote2S.needs[p][0][116296]
                                //     ) {
                                //         return "modified";
                                //     }
                                // }
                                break;
                            case "projectType":
                                for (
                                    let j = 0;
                                    j < quote1S.needs[p].length;
                                    j++
                                ) {
                                    if (
                                        quote1S.needs[p][j][116802] !==
                                        quote2S.needs[p][j][116802] ||
                                        quote1S.needs[p][j][116803] !==
                                        quote2S.needs[p][j][116803]
                                    ) {
                                        return "modified";
                                    }
                                }
                                break;
                        }
                    } else {
                        return "modified";
                    }
                } else if (quote1S.needs[p] !== quote2S.needs[p]) {
                    return "modified";
                }
            }
        }
        return "edit";
    }
    offsetCalculate(element: any): number {
        return element.offsetTop - 90;
    }
    submitAttachment(quote: Quote): any {
        return new Promise((resolve, reject) => {
            if (quote.attachments && quote.attachments.length) {
                const ajax = [];
                for (let i = 0; i < quote.attachments.length; i++) {
                    if (!quote.attachments[i].submitted) {
                        quote.attachments[i].submitted = true;
                        const formData = new FormData();
                        formData.append("attachment", quote.attachments[i]);
                        ajax.push(
                            this.commonApi.postQuoteAttachment(
                                quote.quoteId,
                                formData
                            )
                        );
                    }
                }
                if (ajax && ajax.length) {
                    Observable.forkJoin(ajax).subscribe(
                        results => {
                            resolve(true);
                        },
                        err => {
                            console.error("error", err);
                        }
                    );
                } else {
                    resolve(true);
                }
            } else {
                resolve(true);
            }
        });
    }
    editProcess(quote: Quote, statusBenchMark: number[]): Quote {
        quote.reachSummary = true;
        quote.referral = [];
        for (let i = 0; i < statusBenchMark.length; i++) {
            quote["page" + (i + 1) + "OkFlag"] = true;
            quote["page" + (i + 1) + "Progress"] = statusBenchMark[i];
            quote["page" + (i + 1) + "Status"] = [];
            for (let j = 0; j < statusBenchMark[i]; j++) {
                quote["page" + (i + 1) + "Status"].push(j + 1);
            }
        }
        quote.insuredNameFinished = true;
        quote.addressFinished = true;
        quote.materialDamageFinished = true;
        quote.liabilityFinished = true;
        return quote;
    }
    editProcessComponent(tempQuote: TempQuote): void {
        tempQuote.componentProjectType.tab1Finished = true;
    }
    resetDate(quote: Quote): void {
        if (quote.policyStart) {
            const startDate = moment(quote.policyStart);
            const today = new Date();
            const today30 = new Date();
            today30.setDate(today30.getDate() + 30);
            const today30Date = moment(today30);
            const todayDate = moment(today);
            if (
                startDate.isBefore(todayDate, "day") ||
                startDate.isAfter(today30Date, "day")
            ) {
                quote.policyStart = new Date();
                quote.policyEnd = new Date();
                quote.policyEnd.setFullYear(quote.policyEnd.getFullYear() + 1);
            }
        }
    }
    nameList(list: any[]): string[] {
        const nameList = [];
        list.forEach(name => {
            nameList.push(name.name);
        });
        return nameList;
    }
    openSubmit(item: string, referral?: boolean): void {
        GlobalStaticService.openSubmitLoading(item, referral);
    }
    autoQuote(quote: Quote): void {
        if (quote.type === "single") {
            if (!GlobalStaticService.autoSingle) {
                GlobalStaticService.tutorialAutoTrigger("autoSingle");
                GlobalStaticService.callTutorial(1, 1);
            }
        } else {
            if (!GlobalStaticService.autoAnnual) {
                GlobalStaticService.tutorialAutoTrigger("autoAnnual");
                GlobalStaticService.callTutorial(1, 1);
            }
        }
    }
    autoReferral(): boolean {
        if (!GlobalStaticService.autoReferral) {
            GlobalStaticService.tutorialAutoTrigger("autoReferral");
            GlobalStaticService.callTutorial(6, 2);
            return true;
        }
        return false;
    }
    autoSummary(): void {
        if (!GlobalStaticService.autoSummary) {
            GlobalStaticService.tutorialAutoTrigger("autoSummary");
            GlobalStaticService.callTutorial(7, 3);
        }
    }
    cancel(): void {
        GlobalStaticService.infoModal = {
            title: null,
            content: `
                <div class="text-center"><i class="u-icon icon-100 icon-cancel"></i></div>
                <div class="text-center modal-text">
                    <strong>
                        Are you sure that you<br>
                        would like to cancel?<br>
                    </strong>
                    By cancelling all data will be lost
                </div>
            `,
            buttons: [
                {
                    style: "",
                    text: "Back",
                    callback: () => {
                        GlobalStaticService.closeInfoModal();
                    }
                },
                {
                    style: "purple",
                    text: "Cancel",
                    callback: () => {
                        GlobalStaticService.cleanQuoteAutoSave(
                            this.router.url.indexOf("single") !== -1
                        );
                        GlobalStaticService.closeInfoModal();
                        this.router.navigate(["/dashboard/home"]);
                    }
                }
            ]
        };
        GlobalStaticService.openInfoModal();
    }
    saveAsTemplate(): void {
        gtag("set", { user_id: GlobalStaticService.brokerProfile.contactId });
        gtag("event", "QuoteTemp", {});
        GlobalStaticService.openSaveTemplateModal();
    }
    loadTemplate(): void {
        // GlobalStaticService.openSaveTemplateModal();
        GlobalStaticService.openLoadTemplateModal();
    }
    save(): void {
        // GlobalStaticService.cleanQuoteAutoSave(this.router.url.indexOf('single') !== -1);
    }
    setDefaultDefectsLiabilityPeriod(quote: Quote): void {
        for (let i = 0; i < quote.defectsLiabilityPeriodOption.length; i++) {
            if (quote.defectsLiabilityPeriodOption[i].value === "12") {
                quote.defectsLiabilityPeriod = 12;
                quote.defectsLiabilityPeriodId =
                    quote.defectsLiabilityPeriodOption[i].valueId;
            }
        }
    }
    clearDefectsLiabilityPeriod(quote: Quote): void {
        quote.defectsLiabilityPeriod = 0;
        quote.defectsLiabilityPeriodId = null;
    }
    setDefaultMaximumProjectDurationOption(quote: Quote): void {
        for (let i = 0; i < quote.maximumProjectDurationOption.length; i++) {
            if (quote.maximumProjectDurationOption[i].value === "12") {
                quote.maximumProjectDuration = 12;
                quote.maximumProjectDurationId =
                    quote.maximumProjectDurationOption[i].valueId;
            }
        }
    }
    getFacility(response: any, productId: number): any {
        return new Promise(resolve => {
            const facility = [];
            response.forEach(e => {
                for (let i = 0; i < facility.length; i++) {
                    if (facility[i].facilityId === e.facilityId) {
                        return;
                    }
                }
                facility.push({
                    facilityId: e.facilityId
                });
            });
            let loading = 0;
            for (let i = 0; i < facility.length; i++) {
                this.commonApi
                    .getFacilityInsurer(facility[i].facilityId)
                    .subscribe(res => {
                        facility[i].insurerId = res[0].insurerId;
                        facility[i].percent = res[0].premiumPercent;
                        this.commonApi
                            .getInsurer(facility[i].insurerId)
                            .subscribe(res2 => {
                                facility[i].entityName = res2.entityName;
                                facility[i].abn = res2.abn;
                                facility[i].afsl = res2.aFSLicence;
                                loading++;
                                if (loading === facility.length) {
                                    resolve(facility);
                                }
                            });
                    });
            }
        });
    }
    sortBusinessOfInsured(boi: any[]): any[] {
        return boi.sort(function (a, b) {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }
    resetBrokerCommission(quote: Quote): void {
        if (
            GlobalStaticService.brokerProfile.contactCategoryText
                .toLowerCase()
                .indexOf("scheme") !== -1
        ) {
            quote.brokerCommission = "Scheme Commission";
            quote.brokerCommissionId = null;
        } else {
            quote.brokerCommission = "15%";
            for (let i = 0; i < quote.brokerCommissionOption.length; i++) {
                if (Number(quote.brokerCommissionOption[i].value) === 0.15) {
                    quote.brokerCommissionId =
                        quote.brokerCommissionOption[i].valueId;
                    break;
                }
            }
        }
    }
    resetReferralLimit(productId: number): void {
        GlobalStaticService.scheme = false;
        QUOTE_REFERRAL.materialDamageEstimation = 0;
        QUOTE_REFERRAL.projectCost = 0;
        QUOTE_REFERRAL.existingStructures = 0;
        QUOTE_REFERRAL.principalSupplied = 0;
        QUOTE_REFERRAL.contractorsPlant = 0;
        QUOTE_REFERRAL.liabilityEstimation = 0;
        QUOTE_REFERRAL.publicLiability = 0;
        QUOTE_REFERRAL.vibrationWeakeningRemovalOfSupport = 0;
        QUOTE_REFERRAL.productsLiability = 0;
        QUOTE_REFERRAL.propertyInCareCustodyControl = 0;
        QUOTE_REFERRAL.percentES = 0;
        QUOTE_REFERRAL.percentPSM = 0;
        QUOTE_REFERRAL.percentPlant = 0;
        QUOTE_REFERRAL.tailorYourCover = 0;
        QUOTE_REFERRAL.eaLimitAp = 0;
        QUOTE_REFERRAL.eaLimitSp = 0;
        QUOTE_REFERRAL.eoLimitAp = 0;
        QUOTE_REFERRAL.eoLimitSp = 0;
        QUOTE_REFERRAL.ldLimitAp = 0;
        QUOTE_REFERRAL.ldLimitSp = 0;
        QUOTE_REFERRAL.dlpLimitSp = 0;
        QUOTE_REFERRAL.dlpLimitAp = 0;
        QUOTE_REFERRAL.sqLimit = 0;
        QUOTE_REFERRAL.aqLimit = 12;
        QUOTE_REFERRAL.mpdLimit = 0;
        this.resetReferralMisc();
        this.referralUpdate(productId);
    }
    resetReferralMisc(): void {
        for (
            let i = 0;
            i < GlobalStaticService.quoteOptionsMiscReferral.length;
            i++
        ) {
            switch (GlobalStaticService.quoteOptionsMiscReferral[i].name) {
                case "LQProjectCost":
                    if (
                        GlobalStaticService.quoteOptionsMiscReferral[i]
                            .apReferral
                    ) {
                        QUOTE_REFERRAL.ldLimitAp =
                            GlobalStaticService.quoteOptionsMiscReferral[
                                i
                            ].curValue;
                    }
                    if (
                        GlobalStaticService.quoteOptionsMiscReferral[i]
                            .spReferral
                    ) {
                        QUOTE_REFERRAL.ldLimitSp =
                            GlobalStaticService.quoteOptionsMiscReferral[
                                i
                            ].curValue;
                    }
                    break;
                case "EOProjectCost":
                    if (
                        GlobalStaticService.quoteOptionsMiscReferral[i]
                            .apReferral
                    ) {
                        QUOTE_REFERRAL.eoLimitAp =
                            GlobalStaticService.quoteOptionsMiscReferral[
                                i
                            ].curValue;
                    }
                    if (
                        GlobalStaticService.quoteOptionsMiscReferral[i]
                            .spReferral
                    ) {
                        QUOTE_REFERRAL.eoLimitSp =
                            GlobalStaticService.quoteOptionsMiscReferral[
                                i
                            ].curValue;
                    }
                    break;
                case "EWProjectCost":
                    if (
                        GlobalStaticService.quoteOptionsMiscReferral[i]
                            .apReferral
                    ) {
                        QUOTE_REFERRAL.eaLimitAp =
                            GlobalStaticService.quoteOptionsMiscReferral[
                                i
                            ].curValue;
                    }
                    if (
                        GlobalStaticService.quoteOptionsMiscReferral[i]
                            .spReferral
                    ) {
                        QUOTE_REFERRAL.eaLimitSp =
                            GlobalStaticService.quoteOptionsMiscReferral[
                                i
                            ].curValue;
                    }
                    break;
                case "DLPDuration":
                    if (
                        GlobalStaticService.quoteOptionsMiscReferral[i]
                            .apReferral
                    ) {
                        QUOTE_REFERRAL.dlpLimitAp =
                            GlobalStaticService.quoteOptionsMiscReferral[
                                i
                            ].intValue;
                    }
                    if (
                        GlobalStaticService.quoteOptionsMiscReferral[i]
                            .spReferral
                    ) {
                        QUOTE_REFERRAL.dlpLimitSp =
                            GlobalStaticService.quoteOptionsMiscReferral[
                                i
                            ].intValue;
                    }
                    break;
                case "MaxProjectDuration":
                    QUOTE_REFERRAL.mpdLimit =
                        GlobalStaticService.quoteOptionsMiscReferral[
                            i
                        ].intValue;
                    break;
                case "ProjectDuration":
                    if (
                        GlobalStaticService.quoteOptionsMiscReferral[i]
                            .spReferral
                    ) {
                        QUOTE_REFERRAL.sqLimit =
                            GlobalStaticService.quoteOptionsMiscReferral[
                                i
                            ].intValue;
                    }
                    break;
            }
        }
    }
    referralUpdate(productId: number): void {
        let typeId = 0;
        let lookupProductId = 0;
        let valueId = 0;
        for (
            let i = 0;
            i < GlobalStaticService.quoteOptionsDynamicReferral.length;
            i++
        ) {
            if (
                GlobalStaticService.quoteOptionsDynamicReferral[i].fieldId ===
                18365
            ) {
                lookupProductId = i;
            }
            if (
                GlobalStaticService.quoteOptionsDynamicReferral[i].fieldId ===
                18366
            ) {
                typeId = i;
            }
            if (
                GlobalStaticService.quoteOptionsDynamicReferral[i].fieldId ===
                18367
            ) {
                valueId = i;
            }
        }
        for (
            let i = 0;
            i <
            GlobalStaticService.quoteOptionsDynamicReferral[typeId].values
                .length;
            i++
        ) {
            if (
                GlobalStaticService.quoteOptionsDynamicReferral[typeId].values[
                    i
                ].parentValue ===
                GlobalStaticService.brokerProfile.contactCategoryText
            ) {
                const value = Number(
                    GlobalStaticService.quoteOptionsDynamicReferral[valueId]
                        .values[i].value
                );
                if (
                    Number(
                        GlobalStaticService.quoteOptionsDynamicReferral[
                            lookupProductId
                        ].values[i].value
                    ) === productId
                ) {
                    switch (
                    GlobalStaticService.quoteOptionsDynamicReferral[typeId]
                        .values[i].value
                    ) {
                        case "ProjectCost":
                            QUOTE_REFERRAL.projectCost = value;
                            break;
                        case "ExistingStructures":
                            QUOTE_REFERRAL.existingStructures = value;
                            break;
                        case "PSM":
                            QUOTE_REFERRAL.principalSupplied = value;
                            break;
                        case "ContractorsPlant":
                            QUOTE_REFERRAL.contractorsPlant = value;
                            break;
                        case "PublicLiability":
                            QUOTE_REFERRAL.publicLiability = value;
                            break;
                        case "VWRS":
                            QUOTE_REFERRAL.vibrationWeakeningRemovalOfSupport = value;
                            break;
                        case "PCCC":
                            QUOTE_REFERRAL.propertyInCareCustodyControl = value;
                            break;
                        case "MDEstimationTotal":
                            QUOTE_REFERRAL.materialDamageEstimation = value;
                            break;
                        case "PLEstimationTotal":
                            QUOTE_REFERRAL.liabilityEstimation = value;
                            break;
                        case "ProductLiability":
                            QUOTE_REFERRAL.productsLiability = value;
                            break;
                    }
                }
            }
        }
        this.eventBus.cast(UcEvent.REFERRAL_LIMIT_CHANGED);
    }
    getEditScheme(productId: number, quote: Quote): void {
        if (quote['_scheme']) return; // already set
        if (!quote.insuredType) return; // invalid data
        quote._scheme = 'pending';
        const insuredTypeId = QuoteMappingStaticService.mapOption(
            true,
            "insuredType",
            quote.insuredType
        );
        let projectTypeId;
        if (productId === 47) {
            if (!quote.projectType) {
                return;
            }
            projectTypeId = QuoteMappingStaticService.mapOption(
                true,
                "projectType",
                quote.projectType
            );
        } else {
            let maxType;
            let max = Math.max(
                quote.projectTypePercentNRB || 0,
                quote.projectTypePercentNCIB || 0,
                quote.projectTypePercentNSRFA || 0,
                quote.projectTypePercentAR || 0,
                quote.projectTypePercentACIB || 0,
                quote.projectTypePercentOther || 0
            );
            if (max) {
                if (max === quote.projectTypePercentNRB) {
                    maxType = "nrb";
                } else if (max === quote.projectTypePercentNCIB) {
                    maxType = "ncib";
                } else if (max === quote.projectTypePercentNSRFA) {
                    maxType = "nsrfa";
                } else if (max === quote.projectTypePercentAR) {
                    maxType = "ar";
                } else if (max === quote.projectTypePercentACIB) {
                    maxType = "acib";
                } else {
                    maxType = "other";
                }
            } else {
                if (quote.projectTypeNRB) {
                    maxType = "nrb";
                } else if (quote.projectTypeNCIB) {
                    maxType = "ncib";
                } else if (quote.projectTypeNSRFA) {
                    maxType = "nsrfa";
                } else if (quote.projectTypeAR) {
                    maxType = "ar";
                } else if (quote.projectTypeACIB) {
                    maxType = "acib";
                } else if (quote.projectTypeOther) {
                    maxType = "other";
                } else {
                    return;
                }
            }
            projectTypeId = QuoteMappingStaticService.mapOption(
                true,
                "projectType",
                maxType
            );
        }
        this.commonApi
            .getScheme(productId, projectTypeId, insuredTypeId, true).subscribe(
                res => {
                    this.setScheme(quote, res);
                },
                err => {
                    quote._scheme = -1;
                    this.resetReferralLimit(productId);
                }
            );
    }
    setScheme(quote: Quote, scheme: any) {
        if (scheme == null) {
            return;
        }
        quote['_scheme'] = scheme.id.valueId;
        quote.tailorYourCover = this.calculateSchemeExcess(
            scheme.excessAmtDescLkv,
            scheme.excessAmtAmount
        );
        // MD TURNOVER
        if (quote.type === "annual") {
            if (scheme.sublimitsDefLimit["md-turnover"]) {
                quote.materialDamageEstimation = Number(
                    scheme.sublimitsDefLimit["md-turnover"]
                );
            }
        }
        // PROJECT COST DEFAULT
        if (scheme.sublimitsDefLimit["project-cost"]) {
            quote.projectCost = Number(
                scheme.sublimitsDefLimit["project-cost"]
            );
        }
        // ES DEFAULT
        if (
            scheme.sublimitsDefLimitPercent.es &&
            scheme.sublimitsDefLimitPercent.es.value
        ) {
            QUOTE_REFERRAL.percentES = Number(
                scheme.sublimitsDefLimitPercent.es.value
            );
            if (quote.projectCost) {
                quote.existingStructures = (quote.projectCost * QUOTE_REFERRAL.percentES) / 100;
            }
        } else {
            if (scheme.sublimitsDefLimit.es) {
                quote.existingStructures = Number(scheme.sublimitsDefLimit.es.value);
            }
        }
        if (quote.projectCost) {
            if (quote.type === "single") {
                if (!quote.existingStructures) {
                    quote.materialDamageFinished = true;
                }
            } else {
                if (quote.materialDamageEstimation) {
                    quote.materialDamageFinished = true;
                }
            }
        }
        // PSM DEFAULT
        if (
            scheme.sublimitsDefLimitPercent.psm &&
            scheme.sublimitsDefLimitPercent.psm.value
        ) {
            QUOTE_REFERRAL.percentPSM = Number(
                scheme.sublimitsDefLimitPercent.psm.value
            );
            if (quote.projectCost) {
                quote.principalSupplied =
                    (quote.projectCost * QUOTE_REFERRAL.percentPSM) / 100;
            }
        } else {
            if (scheme.sublimitsDefLimit.psm) {
                quote.principalSupplied = Number(
                    scheme.sublimitsDefLimit.psm.value
                );
            }
        }
        // PLANT DEFAULT
        if (
            scheme.sublimitsDefLimitPercent.plant &&
            scheme.sublimitsDefLimitPercent.plant.value
        ) {
            QUOTE_REFERRAL.percentPlant = Number(
                scheme.sublimitsDefLimitPercent.plant.value
            );
            if (quote.projectCost) {
                quote.contractorsPlant =
                    (quote.projectCost * QUOTE_REFERRAL.percentPlant) / 100;
            }
        } else {
            if (scheme.sublimitsDefLimit.plant && !quote.contractorsPlant) {
                quote.contractorsPlant = Number(
                    scheme.sublimitsDefLimit.plant.value
                );
            }
        }
        // PL TURNOVER DEFAULT
        if (quote.type === "annual") {
            if (scheme.sublimitsDefLimit["pl-turnover"]) {
                quote.liabilityEstimation = Number(
                    scheme.sublimitsDefLimit["pl-turnover"]
                );
            }
        }
        // PL DEFAULT
        if (scheme.sublimitsDefLimit.pl) {
            quote.publicLiability = GlobalStaticService.priceFormat(
                Number(scheme.sublimitsDefLimit.pl)
            );
            if (quote.type === "single") {
                quote.liabilityFinished = true;
            } else {
                if (quote.liabilityEstimation) {
                    quote.liabilityFinished = true;
                }
            }
            if (quote.type === "single") {
                for (
                    let i = 0;
                    i <
                    QuoteMappingStaticService.singleLookups[115690].valueLookups
                        .length;
                    i++
                ) {
                    if (
                        Number(
                            QuoteMappingStaticService.singleLookups[115690]
                                .valueLookups[i].value
                        ) ===
                        GlobalStaticService.numberFormat(quote.publicLiability)
                    ) {
                        quote.publicLiabilityId =
                            QuoteMappingStaticService.singleLookups[115690].valueLookups[
                                i
                            ].valueId;
                    }
                }
            } else {
                for (
                    let i = 0;
                    i <
                    QuoteMappingStaticService.annualLookups[116282].valueLookups
                        .length;
                    i++
                ) {
                    if (
                        Number(
                            QuoteMappingStaticService.annualLookups[116282]
                                .valueLookups[i].value
                        ) ===
                        GlobalStaticService.numberFormat(quote.publicLiability)
                    ) {
                        quote.publicLiabilityId =
                            QuoteMappingStaticService.annualLookups[116282].valueLookups[
                                i
                            ].valueId;
                    }
                }
            }
        }
        // VWRS DEFAULT
        if (scheme.sublimitsDefLimit.vwrs) {
            if (!quote.vibrationWeakeningRemovalOfSupport) {
                quote.vibrationWeakeningRemovalOfSupport = GlobalStaticService.priceFormat(
                    Number(scheme.sublimitsDefLimit.vwrs.value)
                );
            }
            if (quote.type === "single") {
                for (
                    let i = 0;
                    i <
                    QuoteMappingStaticService.singleLookups[115692].valueLookups
                        .length;
                    i++
                ) {
                    if (
                        Number(
                            QuoteMappingStaticService.singleLookups[115692]
                                .valueLookups[i].value
                        ) ===
                        GlobalStaticService.numberFormat(
                            quote.vibrationWeakeningRemovalOfSupport
                        )
                    ) {
                        quote.vibrationWeakeningRemovalOfSupportId =
                            QuoteMappingStaticService.singleLookups[115692].valueLookups[
                                i
                            ].valueId;
                    }
                }
            } else {
                for (
                    let i = 0;
                    i <
                    QuoteMappingStaticService.annualLookups[116284].valueLookups
                        .length;
                    i++
                ) {
                    if (
                        Number(
                            QuoteMappingStaticService.annualLookups[116284]
                                .valueLookups[i].value
                        ) ===
                        GlobalStaticService.numberFormat(
                            quote.vibrationWeakeningRemovalOfSupport
                        )
                    ) {
                        quote.vibrationWeakeningRemovalOfSupportId =
                            QuoteMappingStaticService.annualLookups[116284].valueLookups[
                                i
                            ].valueId;
                    }
                }
            }
        }
        // PCCC DEFAULT
        if (scheme.sublimitsDefLimit.pccc) {
            quote.propertyInCareCustodyControl = GlobalStaticService.priceFormat(
                Number(scheme.sublimitsDefLimit.pccc.value)
            );
            if (quote.type === "single") {
                for (
                    let i = 0;
                    i <
                    QuoteMappingStaticService.singleLookups[115693].valueLookups
                        .length;
                    i++
                ) {
                    if (
                        Number(
                            QuoteMappingStaticService.singleLookups[115693]
                                .valueLookups[i].value
                        ) ===
                        GlobalStaticService.numberFormat(
                            quote.propertyInCareCustodyControl
                        )
                    ) {
                        quote.propertyInCareCustodyControlId = Number(
                            QuoteMappingStaticService.singleLookups[115693]
                                .valueLookups[i].valueId
                        );
                    }
                }
            } else {
                for (
                    let i = 0;
                    i <
                    QuoteMappingStaticService.annualLookups[116285].valueLookups
                        .length;
                    i++
                ) {
                    if (
                        Number(
                            QuoteMappingStaticService.annualLookups[116285]
                                .valueLookups[i].value
                        ) ===
                        GlobalStaticService.numberFormat(
                            quote.propertyInCareCustodyControl
                        )
                    ) {
                        quote.propertyInCareCustodyControlId = Number(
                            QuoteMappingStaticService.annualLookups[116285]
                                .valueLookups[i].valueId
                        );
                    }
                }
            }
        }
        // PRODUCT LIABILITY DEFAULT
        if (quote.type === "annual") {
            if (scheme.sublimitsDefLimit["prod-liab"]) {
                if (!quote.productLiability) {
                    quote.productLiability = GlobalStaticService.priceFormat(
                        Number(scheme.sublimitsDefLimit["prod-liab"].value)
                    );
                }
                for (
                    let i = 0;
                    i <
                    QuoteMappingStaticService.annualLookups[116957].valueLookups
                        .length;
                    i++
                ) {
                    if (
                        Number(
                            QuoteMappingStaticService.annualLookups[116957]
                                .valueLookups[i].value
                        ) ===
                        GlobalStaticService.numberFormat(quote.productLiability)
                    ) {
                        quote.productLiabilityId = Number(
                            QuoteMappingStaticService.annualLookups[116957]
                                .valueLookups[i].valueId
                        );
                    }
                }
            }
        }
        if (scheme.sublimitsDefLimit.cpl) {
            quote.cpl = Number(scheme.sublimitsDefLimit.cpl.value);
            quote.cover1 = true;
            quote.enhance1A = false;
            quote.enhance1B = false;
            quote.enhance1C = false;
            quote.enhance1D = false;
        } else {
            quote.cover1 = false;
            quote.enhance1A = false;
            quote.enhance1B = false;
            quote.enhance1C = false;
            quote.enhance1D = false;
        }
        // ENHANCE YOUR COVER
        if ((scheme.sublimitsName.camd && quote.insuranceType !== 'l') || (scheme.sublimitsName.capl && quote.insuranceType !== 'md')) {
            quote.cover2 = true;
        } else {
            quote.cover2 = false;
        }
        if (scheme.sublimitsName.ew) {
            quote.cover3 = true;
        } else {
            quote.cover3 = false;
        }
        if (scheme.sublimitsName.pw) {
            quote.cover6 = true;
        } else {
            quote.cover6 = false;
        }
        // MAX LIMIT
        this.setSchemeLimit(quote, scheme);
    }
    calculateSchemeExcess(excessDesc: any, excessAmount: any): number {
console.log("calculateSchemeExcess")
        for (let i = 0; i < excessDesc.md.length; i++) {
            if( Number(excessDesc.md[i].value) === QUOTE_MAPPING.tailorYourCoverMd.default ){
                return Number(excessAmount.md[i].value);
            }
        }
        return 0;
    }
    setSchemeLimit(quote: Quote, scheme: any): void {
        if (scheme == null) {
            return;
        }
        GlobalStaticService.scheme = true;
        if (scheme.sublimitsDefLimit.cpl) {
            quote.cpl = Number(scheme.sublimitsDefLimit.cpl.value);
        }
        QUOTE_REFERRAL.excessAmtAmount = scheme.excessAmtAmount;
        QUOTE_REFERRAL.excessAmtAmountLkv = scheme.excessAmtAmountLkv;
        QUOTE_REFERRAL.excessAmtDescLkv = scheme.excessAmtDescLkv;
        QUOTE_REFERRAL.tailorYourCover = this.calculateSchemeExcess(
            QUOTE_REFERRAL.excessAmtDescLkv,
            QUOTE_REFERRAL.excessAmtAmount
        );
        if (
            scheme.sublimitsMaxLimitApplies["md-turnover"] &&
            scheme.sublimitsMaxLimitApplies["md-turnover"].value === "True"
        ) {
            QUOTE_REFERRAL.materialDamageEstimation = Number(
                scheme.sublimitsMaxLimit["md-turnover"].value
            );
        }
        if (
            scheme.sublimitsMaxLimitApplies["project-cost"] &&
            scheme.sublimitsMaxLimitApplies["project-cost"].value === "True"
        ) {
            QUOTE_REFERRAL.projectCost = Number(
                scheme.sublimitsMaxLimit["project-cost"].value
            );
        }
        if (
            scheme.sublimitsMaxLimitApplies.es &&
            scheme.sublimitsMaxLimitApplies.es.value === "True"
        ) {
            QUOTE_REFERRAL.existingStructures = Number(
                scheme.sublimitsMaxLimit.es.value
            );
        }
        if (
            scheme.sublimitsMaxLimitApplies.psm &&
            scheme.sublimitsMaxLimitApplies.psm.value === "True"
        ) {
            QUOTE_REFERRAL.principalSupplied = Number(
                scheme.sublimitsMaxLimit.psm.value
            );
        }
        if (
            scheme.sublimitsMaxLimitApplies.plant &&
            scheme.sublimitsMaxLimitApplies.plant.value === "True"
        ) {
            QUOTE_REFERRAL.contractorsPlant = Number(
                scheme.sublimitsMaxLimit.plant.value
            );
        }
        if (
            scheme.sublimitsMaxLimitApplies["pl-turnover"] &&
            scheme.sublimitsMaxLimitApplies["pl-turnover"].value === "True"
        ) {
            QUOTE_REFERRAL.liabilityEstimation = Number(
                scheme.sublimitsMaxLimit["pl-turnover"].value
            );
        }
        if (
            scheme.sublimitsMaxLimitApplies.pl &&
            scheme.sublimitsMaxLimitApplies.pl.value === "True"
        ) {
            QUOTE_REFERRAL.publicLiability = Number(
                scheme.sublimitsMaxLimit.pl.value
            );
        }
        if (
            scheme.sublimitsMaxLimitApplies.vwrs &&
            scheme.sublimitsMaxLimitApplies.vwrs.value === "True"
        ) {
            QUOTE_REFERRAL.vibrationWeakeningRemovalOfSupport = Number(
                scheme.sublimitsMaxLimit.vwrs.value
            );
        }
        if (
            scheme.sublimitsMaxLimitApplies.pccc &&
            scheme.sublimitsMaxLimitApplies.pccc.value === "True"
        ) {
            QUOTE_REFERRAL.propertyInCareCustodyControl = Number(
                scheme.sublimitsMaxLimit.pccc.value
            );
        }
        if (
            scheme.sublimitsMaxLimitApplies["prod-liab"] &&
            scheme.sublimitsMaxLimitApplies["prod-liab"].value === "True"
        ) {
            QUOTE_REFERRAL.productsLiability = Number(
                scheme.sublimitsMaxLimit["prod-liab"].value
            );
        }

console.log("setSchemeLimit", scheme);

        this.eventBus.cast(UcEvent.REFERRAL_LIMIT_CHANGED);
    }

    bindPolicyCheck(quote: Quote): void {
        GlobalStaticService.bindPolicy(quote);
    }
    emailQuoteCheck(quote: Quote): void {
        GlobalStaticService.openEmailQuoteModal();
    }
    embargoCheckQuote(quote: Quote): boolean {
        if (quote._referral && quote._referralOverridden && quote._raw_referrals && quote._raw_referrals.length) {
            for (var i = 0; i < quote._raw_referrals.length; ++i) {
                var ref = quote._raw_referrals[i];
                if (ref && ref.ReferralText && ref.ReferralText.includes("location of")) return true;
            }
        }
        return this.embargoCheck(Number(quote.suburbData.postcode), quote.productId);
    }
    embargoCheck(postcode: number, productId: number): boolean {
        let embargoPostcodeArrayId = 0;
        let singleId = 0;
        let annualId = 0;
        for (let i = 0; i < QuoteMappingStaticService.embargo.length; i++) {
            if (QuoteMappingStaticService.embargo[i].fieldId === 4363) {
                embargoPostcodeArrayId = i;
            }
            if (QuoteMappingStaticService.embargo[i].fieldId === 27681) {
                singleId = i;
            }
            if (QuoteMappingStaticService.embargo[i].fieldId === 27685) {
                annualId = i;
            }
        }
        for (
            let j = 0;
            j <
            QuoteMappingStaticService.embargo[embargoPostcodeArrayId].values
                .length;
            j++
        ) {
            const today = new Date();
            let effectiveDate, expiryDate;
            if (
                QuoteMappingStaticService.embargo[embargoPostcodeArrayId]
                    .values[j].effectiveDate
            ) {
                effectiveDate = moment(
                    QuoteMappingStaticService.embargo[
                        embargoPostcodeArrayId
                    ].values[j].effectiveDate.split("T")[0],
                    "YYYY-MM-DD"
                );
            }
            if (
                QuoteMappingStaticService.embargo[embargoPostcodeArrayId]
                    .values[j].expiryDate
            ) {
                expiryDate = moment(
                    QuoteMappingStaticService.embargo[
                        embargoPostcodeArrayId
                    ].values[j].expiryDate.split("T")[0],
                    "YYYY-MM-DD"
                );
            }
            if (
                ((effectiveDate &&
                    (effectiveDate.isBefore(today) ||
                        effectiveDate.isSame(today, "day"))) ||
                    !effectiveDate) &&
                ((expiryDate &&
                    (expiryDate.isAfter(today) ||
                        expiryDate.isSame(today, "day"))) ||
                    !expiryDate)
            ) {
                if (
                    postcode ===
                    Number(
                        QuoteMappingStaticService.embargo[
                            embargoPostcodeArrayId
                        ].values[j].value
                    )
                ) {
                    if (
                        (productId === 47 &&
                            QuoteMappingStaticService.embargo[singleId].values[
                                j
                            ].value.toLowerCase() === "true") ||
                        (productId === 48 &&
                            QuoteMappingStaticService.embargo[annualId].values[
                                j
                            ].value.toLowerCase() === "true")
                    ) {
                        return false;
                    }
                }
            }
        }
        let above25PostcodeArrayId = 0;
        let valueId = 0;
        for (let i = 0; i < QuoteMappingStaticService.above25th.length; i++) {
            if (QuoteMappingStaticService.above25th[i].fieldId === 4362) {
                above25PostcodeArrayId = i;
            }
            if (QuoteMappingStaticService.above25th[i].fieldId === 4491) {
                valueId = i;
            }
        }
        for (
            let j = 0;
            j <
            QuoteMappingStaticService.above25th[above25PostcodeArrayId].values
                .length;
            j++
        ) {
            const today = new Date();
            let effectiveDate, expiryDate;
            if (
                QuoteMappingStaticService.above25th[above25PostcodeArrayId]
                    .values[j].effectiveDate
            ) {
                effectiveDate = moment(
                    QuoteMappingStaticService.above25th[
                        above25PostcodeArrayId
                    ].values[j].effectiveDate.split("T")[0],
                    "YYYY-MM-DD"
                );
            }
            if (
                QuoteMappingStaticService.above25th[above25PostcodeArrayId]
                    .values[j].expiryDate
            ) {
                expiryDate = moment(
                    QuoteMappingStaticService.above25th[
                        above25PostcodeArrayId
                    ].values[j].expiryDate.split("T")[0],
                    "YYYY-MM-DD"
                );
            }
            if (
                ((effectiveDate &&
                    (effectiveDate.isBefore(today) ||
                        effectiveDate.isSame(today, "day"))) ||
                    !effectiveDate) &&
                ((expiryDate &&
                    (expiryDate.isAfter(today) ||
                        expiryDate.isSame(today, "day"))) ||
                    !expiryDate)
            ) {
                if (
                    postcode ===
                    Number(
                        QuoteMappingStaticService.above25th[
                            above25PostcodeArrayId
                        ].values[j].value
                    )
                ) {
                    if (
                        QuoteMappingStaticService.above25th[valueId].values[
                            j
                        ].value.toLowerCase() === "true"
                    ) {
                        return false;
                    }
                }
            }
        }
        return true;
    }
    embargoReferral(quote: Quote): void {
        this.commonApi
            .putRate(quote.quoteId, quote.option.OptionId)
            .subscribe();
        quote._referral = true;
        quote._referralOverridden = false;
        GlobalStaticService.infoModal = {
            title: "Referral",
            content: `This quote is not valid and has been referred to MECON due to the location of the risk.`,
            buttons: [
                {
                    style: "",
                    text: "Ok",
                    callback: () => {
                        GlobalStaticService.closeInfoModal();
                    }
                }
            ]
        };
        GlobalStaticService.openInfoModal();
    }
    projectTypeReferralCheck(
        projectTypeId: number,
        productId: number
    ): boolean {
        let arrayId = 0;
        let search = 0;
        if (productId === 47) {
            // arrayId = 24;
            search = 27677;
        } else if (productId === 48) {
            // arrayId = 26;
            search = 27679;
        } else {
            return false;
        }
        for (let i = 0; i < QuoteMappingStaticService.projectType.length; i++) {
            if (QuoteMappingStaticService.projectType[i].fieldId === search) {
                arrayId = i;
                break;
            }
        }
        for (
            let i = 0;
            i < QuoteMappingStaticService.projectType[arrayId].values.length;
            i++
        ) {
            const today = new Date();
            let effectiveDate, expiryDate;
            if (
                QuoteMappingStaticService.projectType[arrayId].values[i]
                    .effectiveDate
            ) {
                effectiveDate = moment(
                    QuoteMappingStaticService.projectType[arrayId].values[
                        i
                    ].effectiveDate.split("T")[0],
                    "YYYY-MM-DD"
                );
            }
            if (
                QuoteMappingStaticService.projectType[arrayId].values[i]
                    .expiryDate
            ) {
                expiryDate = moment(
                    QuoteMappingStaticService.projectType[arrayId].values[
                        i
                    ].expiryDate.split("T")[0],
                    "YYYY-MM-DD"
                );
            }
            if (
                ((effectiveDate &&
                    (effectiveDate.isBefore(today) ||
                        effectiveDate.isSame(today, "day"))) ||
                    !effectiveDate) &&
                ((expiryDate &&
                    (expiryDate.isAfter(today) ||
                        expiryDate.isSame(today, "day"))) ||
                    !expiryDate)
            ) {
                if (
                    Number(
                        QuoteMappingStaticService.projectType[arrayId].values[i]
                            .parentValueId
                    ) === projectTypeId
                ) {
                    if (
                        QuoteMappingStaticService.projectType[arrayId].values[i]
                            .value === "True"
                    ) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
    annualProjectTypeReferralCheck(projectTypes: number[]): boolean {
        for (let i = 0; i < projectTypes.length; i++) {
            if (this.projectTypeReferralCheck(projectTypes[i], 48)) {
                return true;
            }
        }
        return false;
    }
}
