import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild
} from "@angular/core";
import { InterfaceStaticService } from "../../static-services/interface.static-service";
import { CommonApi } from "../../api/common.api";

import { Policy } from "../../models/policy";

import { GlobalStaticService } from "../../static-services/global.static-service";
import { QuoteService } from "../../services/quote.service";
import { CommonUtility } from "../../utilities/common.utility";

import { DateOnlyPipe } from "../../pipes/date-only.pipe";
import { Quote } from "../../models/quote";

declare let jsPDF: any;

@Component({
    selector: "app-create-certificate-of-currency-component",
    templateUrl: "./create-certificate-of-currency.component.html",
    styleUrls: ["./create-certificate-of-currency.component.scss"]
})
export class CreateCertificateOfCurrencyComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    InterfaceStaticSrv = InterfaceStaticService;
    @Input("policy") policy: Policy;
    currentPage = 2;
    @ViewChild("page1") page1: ElementRef;
    page1Position = 0;
    page1Offset: number[] = [];
    @ViewChild("policyDate") policyDate: ElementRef;
    @ViewChild("policyDateWrapper") policyDateWrapper: ElementRef;
    policyDateHeight: number;
    @ViewChild("interestedParty") interestedParty: ElementRef;
    @ViewChild("interestedPartyWrapper") interestedPartyWrapper: ElementRef;
    interestedPartyHeight: number;
    @ViewChild("materialDamage") materialDamage: ElementRef;
    @ViewChild("materialDamageWrapper") materialDamageWrapper: ElementRef;
    materialDamageHeight: number;
    @ViewChild("address") address: ElementRef;
    @ViewChild("addressWrapper") addressWrapper: ElementRef;
    addressHeight: number;
    @ViewChild("page2") page2: ElementRef;
    @Output() backEventEmitter: EventEmitter<boolean> = new EventEmitter();
    @Output() goEventEmitter: EventEmitter<any> = new EventEmitter();
    navigationHide = true;
    navigating = false;
    switching = false;
    constructor(
        private quoteSrv: QuoteService,
        private commonApi: CommonApi,
        private dateOnlyPipe: DateOnlyPipe,
        private cd: ChangeDetectorRef
    ) {}
    ngOnChanges(): void {
        if (this.policy) {
            this.offsetCheck();
            this.policy.page1OkFlag = true;
            this.policy.page1Progress = 0;
            this.policy.page1Status = [];
            // policy date
            const startDate = this.policy.effectiveDate
                .split("T")[0]
                .split("-");
            this.policy.policyStart = new Date();
            this.policy.policyStart.setFullYear(Number(startDate[0]));
            this.policy.policyStart.setDate(Number(startDate[2]));
            this.policy.policyStart.setMonth(Number(Number(startDate[1]) - 1));
            this.policy.start = new Date();
            this.policy.start.setFullYear(Number(startDate[0]));
            this.policy.start.setDate(Number(startDate[2]));
            this.policy.start.setMonth(Number(Number(startDate[1]) - 1));
            const endDate = this.policy.termExpiryDate.split("T")[0].split("-");
            this.policy.policyEnd = new Date();
            this.policy.policyEnd.setFullYear(Number(endDate[0]));
            this.policy.policyEnd.setDate(Number(endDate[2]));
            this.policy.policyEnd.setMonth(Number(Number(endDate[1]) - 1));
            this.policy.end = new Date();
            this.policy.end.setFullYear(Number(endDate[0]));
            this.policy.end.setDate(Number(endDate[2]));
            this.policy.end.setMonth(Number(Number(endDate[1]) - 1));
            // interested party
            if (!this.policy.interestedParties) {
                this.policy.interestedParties = [];
            }
            // MD
            this.policy.limitProjectCost = this.policy.projectCost;
            if (this.policy.existingStructures) {
                this.policy.limitExistingStructures = this.policy.existingStructures;
            } else {
                this.policy.limitExistingStructures = 0;
            }
            if (this.policy.principalSupplied) {
                this.policy.limitPrincipalSupplied = this.policy.principalSupplied;
            } else {
                this.policy.limitPrincipalSupplied = 0;
            }
            if (this.policy.contractorsPlant) {
                this.policy.limitContractorsPlant = this.policy.contractorsPlant;
            } else {
                this.policy.limitContractorsPlant = 0;
            }
            this.policy.page1Status.push(1);
            this.policy.page1Status.push(2);
            this.policy.page1Status.push(3);
            this.policy.materialDamageFinished = true;
            if (this.policy.type === "single") {
                this.policy.page1Status.push(4);
                this.policy.page1Progress = 4;
                this.policy.page1OkFlag = true;
            } else {
                this.policy.page1Progress = 3;
                this.policy.page1OkFlag = false;
            }
            setTimeout(() => {
                this.navigationHide = false;
            }, 200);
        }
    }
    cleanData(): void {
        this.currentPage = 2;
        this.policy = null;
    }
    back(): void {
        this.navigationHide = true;
        setTimeout(() => {
            this.cleanData();
        }, 500);
        this.backEventEmitter.emit(true);
    }
    go(nextPage: string, nextId: any): void {
        setTimeout(() => {
            this.cleanData();
        }, 500);
        this.goEventEmitter.emit({
            currentPage: "createCocPage",
            nextPage: nextPage,
            nextId: nextId
        });
    }
    switchPage(): void {
        this.pageGo(2);
    }
    continueEventEmitter(fieldOrder: number, event: any): void {
        const statusPosition = this.policy.page1Status.indexOf(fieldOrder);
        if (statusPosition !== -1) {
            this.policy.page1Status.splice(statusPosition, 1);
        }
        if (event.finished) {
            this.policy.page1Status.push(fieldOrder);
        }
        // single & annual
        this.policy.page1OkFlag = this.policy.page1Status.length === 4;

        if (event.noScroll) {
            this.offsetCheck();
        }
        if (event.noScroll || fieldOrder === 4) {
        } else {
            if (event.finished) {
                if (fieldOrder > this.policy.page1Progress) {
                    this.policy.page1Progress = fieldOrder;
                }
                this.scrollTo(fieldOrder);
            }
        }
    }
    navigationScroll(position: number): void {
        if (this.navigating) {
            return;
        }
        this.navigating = true;
        this.scrollTo(position);
        setTimeout(() => {
            this.navigating = false;
        }, 300);
    }
    offsetCheck(): void {
        this.page1Offset = [];
        setTimeout(() => {
            this.page1Offset.push(
                this.quoteSrv.offsetCalculate(this.policyDate.nativeElement)
            );
            this.policyDateHeight = this.policyDateWrapper.nativeElement.offsetHeight;
            this.page1Offset.push(
                this.quoteSrv.offsetCalculate(
                    this.interestedParty.nativeElement
                )
            );
            this.interestedPartyHeight = this.interestedPartyWrapper.nativeElement.offsetHeight;
            this.page1Offset.push(
                this.quoteSrv.offsetCalculate(this.materialDamage.nativeElement)
            );
            this.materialDamageHeight = this.materialDamageWrapper.nativeElement.offsetHeight;
            this.page1Offset.push(
                this.quoteSrv.offsetCalculate(this.address.nativeElement)
            );
            this.addressHeight = this.addressWrapper.nativeElement.offsetHeight;
        }, 100);
    }
    jumpTo(tab: number, order: number) {
        this["page" + tab].nativeElement.scrollTop = this[
            "page" + tab + "Offset"
        ][order];
        this["page" + tab + "Position"] = order;
    }
    edit(order: number) {
        this.jumpTo(1, order);
        this.pageGo(1);
    }
    cleanClassList(page: string): void {
        this[page].nativeElement.classList.remove("slideInLeft");
        this[page].nativeElement.classList.remove("slideInRight");
        this[page].nativeElement.classList.remove("slideOutLeft");
        this[page].nativeElement.classList.remove("slideOutRight");
    }
    checkFinished(aimPage: number): boolean {
        let page = 0,
            order = 0;
        for (let i = 1; i < aimPage; i++) {
            if (!this.policy["page" + i + "OkFlag"]) {
                page = i;
                break;
            }
        }
        if (page) {
            const status = "page" + page + "Status";
            this.policy[status].sort(function(a, b) {
                return a - b;
            });
            for (let i = 0; i < 4; i++) {
                if (this.policy[status][i] !== i + 1) {
                    order = i + 1;
                    break;
                }
            }
            this.callAlert(page, order);
            if (this.currentPage === page) {
                this.scrollTo(order);
            } else {
                this.edit(order);
            }
        }
        return !page;
    }
    scrollTo(order: number): void {
        let go = 0;
        if (this.currentPage === 2) {
            go = order;
        } else {
            go = 0;
            for (let i = 0; i < order; i++) {
                if (
                    this.policy["page" + this.currentPage + "Status"].indexOf(
                        i + 1
                    ) === -1
                ) {
                    this.callAlert(this.currentPage, i + 1);
                    break;
                } else {
                    go = i + 1;
                }
            }
        }
        const end = this["page" + this.currentPage + "Offset"][go];
        this["page" + this.currentPage].nativeElement.scroll({
            top: end,
            left: 0,
            behavior: "smooth"
        });
        this.page1Position = go;
    }
    callAlert(page: number, order: number): void {
        // switch (order) {
        //     case 1:
        //         this.quote.errorInsuredName = true;
        //         break;
        //     case 2:
        //         this.quote.errorInsuredType = true;
        //         break;
        //     case 3:
        //         this.quote.errorBusinessOfInsured = true;
        //         break;
        //     case 4:
        //         this.quote.errorInsuredDeclaration = true;
        //         break;
        // }
    }
    pageNext(next?: number): void {
        if (!next) {
            next = 1;
        }
        if (!this.checkFinished(this.currentPage + next)) {
            return;
        }
        if (!this.switching) {
            this.switching = true;
            this.cleanClassList("page" + this.currentPage);
            this.cleanClassList("page" + (this.currentPage + next));
            this["page" + this.currentPage].nativeElement.classList.add(
                "slideOutLeft"
            );
            this[
                "page" + (this.currentPage + next)
            ].nativeElement.classList.add("slideInRight");
            setTimeout(() => {
                this[
                    "page" + (this.currentPage + next)
                ].nativeElement.classList.add("active");
            });
            setTimeout(() => {
                this["page" + this.currentPage].nativeElement.classList.remove(
                    "active"
                );
                this.cleanClassList("page" + this.currentPage);
                this.cleanClassList("page" + (this.currentPage + next));
                this.currentPage = this.currentPage + next;
                this.switching = false;
            }, 500);
        }
    }
    pageBack(prev?: number): void {
        if (!prev) {
            prev = 1;
        }
        if (!this.switching) {
            this.switching = true;
            this.cleanClassList("page" + this.currentPage);
            this.cleanClassList("page" + (this.currentPage - prev));
            this["page" + this.currentPage].nativeElement.classList.add(
                "slideOutRight"
            );
            this[
                "page" + (this.currentPage - prev)
            ].nativeElement.classList.add("slideInLeft");
            setTimeout(() => {
                this[
                    "page" + (this.currentPage - prev)
                ].nativeElement.classList.add("active");
            });
            setTimeout(() => {
                this["page" + this.currentPage].nativeElement.classList.remove(
                    "active"
                );
                this.cleanClassList("page" + this.currentPage);
                this.cleanClassList("page" + (this.currentPage - prev));
                this.currentPage = this.currentPage - prev;
                this.switching = false;
            }, 500);
        }
    }
    pageGo(page: number): void {
        if (this.currentPage !== page) {
            if (this.currentPage > page) {
                this.pageBack(this.currentPage - page);
            } else {
                this.pageNext(page - this.currentPage);
            }
        }
    }
    generateTest(): void {
        if (this.policy.page1OkFlag) {
            this.generateCoc();
        } else {
            this.edit(3);
        }
    }
    generateCoc(): void {
        let doc = new jsPDF("p", "pt");
        doc = GlobalStaticService.pdfAddHeader(doc);

        doc.setFontSize(10);
        doc.setFontType("bold");
        const date = new Date();
        doc.text(CommonUtility.getFullDate(date), 74, 110);
        let type, project;
        if (this.policy.type === "single") {
            type = "SP";
            if (this.policy.legacy) {
                project = "Single Construction";
            } else {
                project = "Single Project";
            }
        } else {
            type = "AP";
            if (this.policy.legacy) {
                project = "Annual Construction";
            } else {
                project = "Annual Project";
            }
        }
        doc.text(
            "Policy Number: " + this.policy.providerPolicyReference,
            379,
            110
        );

        doc.setFontSize(12);
        doc.setFillColor(0, 149, 119);
        doc.rect(74, 122, 450, 14, "F");
        doc.setTextColor(255, 255, 255);
        doc.text("CERTIFICATE OF CURRENCY", 212, 133);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(10);
        doc.setFontType("normal");
        doc.text("Dear Sir/Madam", 74, 155);
        doc.text(
            "This is to certify that the undermentioned policy is current to the due date shown below.",
            74,
            167
        );

        const columns = [
            { title: "TITLE", dataKey: "title" },
            { title: "VALUE", dataKey: "value" }
        ];
        let rows = [
            { title: "Type of Insurance: ", value: project },
            { title: "Insured Name: ", value: this.policy.insuredName }
        ];
        if (this.policy.tradingNames && this.policy.tradingNames.length) {
            let names = this.policy.tradingNames[0].name;
            for (var i = 1; i < this.policy.tradingNames.length; ++i) {
                names = names + ', ' + this.policy.tradingNames[i].name;
            }
            rows.push({
                title: "",
                value: names
            });
        }
        rows.push(
            {
                title: "Territorial Limit: ",
                value: this.policy.territorialLimitDescription
            },
            {
                title: "Current Period of Insurance: ",
                value:
                    CommonUtility.getFullDateNum(this.policy.policyStart, "/") +
                    " to " +
                    CommonUtility.getFullDateNum(this.policy.policyEnd, "/") +
                    " at 4pm local time"
            },
            { title: "Business Details: ", value: this.policy.businessType },
            { title: "Address: ", value: this.policy.address},
            { title: "", value: "" }
        );
        if (
            this.policy.interestedParties &&
            !this.policy.interestedParties.length
        ) {
            rows.push({ title: "Interested Party: ", value: "" });
        } else {
            for (let i = 0; i < this.policy.interestedParties.length; i++) {
                if (i === 0) {
                    rows.push({
                        title: "Interested Party: ",
                        value:
                            this.policy.interestedParties[i].type +
                            " - " +
                            this.policy.interestedParties[i].name
                    });
                } else {
                    rows.push({
                        title: "",
                        value:
                            this.policy.interestedParties[i].type +
                            " - " +
                            this.policy.interestedParties[i].name
                    });
                }
            }
        }
        doc.autoTable(columns, rows, {
            startY: 180,
            margin: { left: 74, right: 74 },
            styles: { overflow: "linebreak" },
            showHeader: "never",
            bodyStyles: {
                fillColor: [255, 255, 255],
                textColor: 0
            },
            alternateRowStyles: {
                fillColor: [255, 255, 255]
            },
            columnStyles: {
                title: {
                    fontStyle: "bold",
                    cellPadding: 2,
                    cellWidth: 100
                },
                value: {
                    cellPadding: 2,
                    cellWidth: 900
                }
            }
        });

        doc.setFillColor(0, 149, 119);
        doc.rect(74, doc.autoTable.previous.finalY + 5, 450, 12, "F");
        doc.setTextColor(255, 255, 255);
        doc.setFontType("bold");
        doc.text(
            "Section 1 - Material Damage",
            76,
            doc.autoTable.previous.finalY + 14
        );

        if (this.policy.insuranceType === "l") {
            rows = [
                {
                    title: "1.02 Maximum Project Value",
                    value: "Not Covered"
                },
                {
                    title: "1.03 Principal Supplied Materials",
                    value: "Not Covered"
                },
                { title: "1.04 Existing Structures", value: "Not Covered" },
                {
                    title:
                        "1.05 Contractors Plant, Tools and Re-usable Equipment",
                    value: "Not Covered"
                },
                {
                    title: "1.06 Variations and Escalation",
                    value: "Not Covered"
                },
                { title: "1.07 Removal of Debris", value: "Not Covered" },
                { title: "1.08 Professional Fees", value: "Not Covered" },
                { title: "1.09 Expediting Costs", value: "Not Covered" },
                { title: "1.10 Mitigation Costs", value: "Not Covered" }
            ];
        } else {
            rows = [
                {
                    title: "1.02 Maximum Project Value",
                    value:
                        CommonUtility.priceFormat(this.policy.projectCost) +
                        " E.E.E."
                },
                {
                    title: "1.03 Principal Supplied Materials",
                    value:
                        this.policy.insuredType === "ownerBuilder1"
                            ? "Already Included"
                            : this.policy.principalSupplied
                            ? CommonUtility.priceFormat(
                                  this.policy.principalSupplied
                              ) + " E.E.E."
                            : "Not Covered"
                },
                {
                    title: "1.04 Existing Structures",
                    value: this.policy.existingStructures
                        ? CommonUtility.priceFormat(
                              this.policy.existingStructures
                          ) + " E.E.E."
                        : "Not Covered"
                },
                {
                    title:
                        "1.05 Contractors Plant, Tools and Re-useable Equipment",
                    value: this.policy.contractorsPlant
                        ? CommonUtility.priceFormat(
                              this.policy.contractorsPlant
                          ) + " E.E.E."
                        : "Not Covered"
                },
                {
                    title: "1.06 Variations and Escalation",
                    value:
                        CommonUtility.priceFormat(
                            (GlobalStaticService.numberFormat(
                                this.policy.projectCost
                            ) +
                                GlobalStaticService.numberFormat(
                                    this.policy.principalSupplied
                                )) *
                                0.2
                        ) + " E.E.E."
                },
                {
                    title: "1.07 Removal of Debris",
                    value:
                        CommonUtility.priceFormat(
                            (GlobalStaticService.numberFormat(
                                this.policy.projectCost
                            ) +
                                GlobalStaticService.numberFormat(
                                    this.policy.principalSupplied
                                ) +
                                GlobalStaticService.numberFormat(
                                    this.policy.contractorsPlant
                                ) +
                                GlobalStaticService.numberFormat(
                                    this.policy.existingStructures
                                )) *
                                0.1
                        ) + " E.E.E."
                },
                {
                    title: "1.08 Professional Fees",
                    value:
                        CommonUtility.priceFormat(
                            (GlobalStaticService.numberFormat(
                                this.policy.projectCost
                            ) +
                                GlobalStaticService.numberFormat(
                                    this.policy.principalSupplied
                                )) *
                                0.1
                        ) + " E.E.E."
                },
                {
                    title: "1.09 Expediting Costs",
                    value:
                        CommonUtility.priceFormat(
                            (GlobalStaticService.numberFormat(
                                this.policy.projectCost
                            ) +
                                GlobalStaticService.numberFormat(
                                    this.policy.principalSupplied
                                ) +
                                GlobalStaticService.numberFormat(
                                    this.policy.existingStructures
                                )) *
                                0.05
                        ) + " E.E.E."
                },
                {
                    title: "1.10 Mitigation Costs",
                    value:
                        CommonUtility.priceFormat(
                            (GlobalStaticService.numberFormat(
                                this.policy.projectCost
                            ) +
                                GlobalStaticService.numberFormat(
                                    this.policy.principalSupplied
                                ) +
                                GlobalStaticService.numberFormat(
                                    this.policy.existingStructures
                                )) *
                                0.05
                        ) + " E.E.E."
                }
            ];
        }
        doc.autoTable(columns, rows, {
            startY: doc.autoTable.previous.finalY + 22,
            margin: { left: 74, right: 74 },
            tableWidth: "auto",
            styles: { overflow: "linebreak" },
            showHeader: "never",
            bodyStyles: {
                fillColor: [255, 255, 255],
                textColor: 0
            },
            alternateRowStyles: {
                fillColor: [255, 255, 255]
            },
            columnStyles: {
                title: {
                    fontStyle: "bold",
                    cellPadding: 1
                },
                value: {
                    cellPadding: 1,
                    columnWidth: "auto",
                    halign: "right"
                }
            }
        });
        doc.setFontSize(8);
        doc.setFontType("normal");
        doc.setFontStyle("italic");
        doc.text(
            "E.E.E. means each and every event",
            74,
            doc.autoTable.previous.finalY + 6
        );

        doc.setFontStyle("normal");
        doc.setFontSize(10);
        doc.setFillColor(0, 149, 119);
        doc.rect(74, doc.autoTable.previous.finalY + 17, 450, 12, "F");
        doc.setTextColor(255, 255, 255);
        doc.setFontType("bold");
        doc.text(
            "Section 2 - Public Liability",
            76,
            doc.autoTable.previous.finalY + 26
        );

        if (this.policy.insuranceType === "md") {
            if (this.policy.type === "single") {
                rows = [
                    { title: "6.01 Public Liability", value: "Not Covered" },
                    { title: "Sub Limits", value: "" },
                    {
                        title:
                            "6.02 Vibration Weakening or the Removal of Support",
                        value: "Not Covered"
                    },
                    {
                        title: "6.03 Property in Care, Custody and Control",
                        value: "Not Covered"
                    },
                    { title: "", value: "" },
                    {
                        title: "Contractors Pollution Liability",
                        value: "Not Covered"
                    }
                ];
            } else {
                rows = [
                    { title: "6.01 Public Liability", value: "Not Covered" },
                    { title: "Sub Limits", value: "" },
                    {
                        title:
                            "6.02 Vibration Weakening or the Removal of Support",
                        value: "Not Covered"
                    },
                    {
                        title: "6.03 Property in Care, Custody and Control",
                        value: "Not Covered"
                    },
                    {
                        title: "6.04 Property in Care, Custody and Control",
                        value: "Not Covered"
                    },
                    { title: "", value: "" },
                    {
                        title: "Contractors Pollution Liability",
                        value: "Not Covered"
                    }
                ];
            }
        } else {
            if (this.policy.type === "single") {
                rows = [
                    {
                        title: "6.01 Public Liability",
                        value:
                            CommonUtility.priceFormat(
                                this.policy.publicLiability
                            ) + " E.E.O."
                    },
                    { title: "Sub Limits", value: "" },
                    {
                        title:
                            "6.02 Vibration Weakening or the Removal of Support",
                        value: this.policy.vibrationWeakeningRemovalOfSupport
                            ? CommonUtility.priceFormat(
                                  this.policy.vibrationWeakeningRemovalOfSupport
                              ) + " A.O.P.I."
                            : "Not Covered"
                    },
                    {
                        title: "6.03 Property in Care, Custody and Control",
                        value: this.policy.propertyInCareCustodyControl
                            ? CommonUtility.priceFormat(
                                  this.policy.propertyInCareCustodyControl
                              ) + " A.O.P.I."
                            : "Not Covered"
                    },
                    { title: "", value: "" },
                    {
                        title: "Contractors Pollution Liability",
                        value: this.policy.cpl ? CommonUtility.priceFormat(
                            this.policy.cpl
                        ) + " A.O.P.I."  : "Not Covered"
                    }
                ];
            } else {
                rows = [
                    {
                        title: "6.01 Public Liability",
                        value:
                            CommonUtility.priceFormat(
                                this.policy.publicLiability
                            ) + " E.E.O."
                    },
                    { title: "Sub Limits", value: "" },
                    {
                        title: "6.02 Products Liability",
                        value: this.policy.productLiability
                            ? CommonUtility.priceFormat(
                                  this.policy.productLiability
                              ) + " A.O.P.I."
                            : "Not Covered"
                    },
                    {
                        title:
                            "6.03 Vibration Weakening or the Removal of Support",
                        value: this.policy.vibrationWeakeningRemovalOfSupport
                            ? CommonUtility.priceFormat(
                                  this.policy.vibrationWeakeningRemovalOfSupport
                              ) + " A.O.P.I."
                            : "Not Covered"
                    },
                    {
                        title: "6.04 Property in Care, Custody and Control",
                        value: this.policy.propertyInCareCustodyControl
                            ? CommonUtility.priceFormat(
                                  this.policy.propertyInCareCustodyControl
                              ) + " A.O.P.I."
                            : "Not Covered"
                    },
                    { title: "", value: "" },
                    {
                        title: "Contractors Pollution Liability",
                        value: this.policy.cpl ? CommonUtility.priceFormat(
                            this.policy.cpl
                        ) + " A.O.P.I."  : "Not Covered"
                    }
                ];
            }
        }
        doc.autoTable(columns, rows, {
            startY: doc.autoTable.previous.finalY + 30,
            margin: { left: 74, right: 74 },
            tableWidth: "auto",
            styles: { overflow: "linebreak" },
            showHeader: "never",
            bodyStyles: {
                fillColor: [255, 255, 255],
                textColor: 0
            },
            alternateRowStyles: {
                fillColor: [255, 255, 255]
            },
            columnStyles: {
                title: {
                    fontStyle: "bold",
                    cellPadding: 1
                },
                value: {
                    cellPadding: 1,
                    halign: "right"
                }
            }
        });

        doc.setFontSize(8);
        doc.setFontType("normal");
        doc.setFontStyle("italic");
        doc.text(
            "E.E.O. means each and every occurrence",
            74,
            doc.autoTable.previous.finalY + 6
        );
        doc.text(
            "A.O.P.I means in the aggregate of all occurrences in any one period of insurance",
            74,
            doc.autoTable.previous.finalY + 16
        );

        doc.setFontStyle("normal");
        doc.setFontSize(10);
        doc.setFillColor(0, 149, 119);
        doc.rect(74, doc.autoTable.previous.finalY + 25, 450, 12, "F");
        doc.setTextColor(255, 255, 255);
        doc.setFontType("bold");
        doc.text("INSURER", 76, doc.autoTable.previous.finalY + 34);
        doc.text("PERCENT", 473, doc.autoTable.previous.finalY + 34);

        rows = [];
        if (this.policy.facility.length) {
            this.policy.facility.forEach(e => {
                rows.push({
                    title: `${e.entityName}, ABN ${e.abn}, AFSL ${e.afsl}`,
                    value: e.percent.toFixed(2) + "%"
                });
            });
        } else {
            rows.push({
                title: "N/A",
                value: "N/A"
            });
        }
        // rows
        doc.autoTable(columns, rows, {
            startY: doc.autoTable.previous.finalY + 38,
            margin: { left: 74, right: 74 },
            tableWidth: "auto",
            styles: { overflow: "linebreak" },
            showHeader: "never",
            bodyStyles: {
                fillColor: [255, 255, 255],
                textColor: 0
            },
            alternateRowStyles: {
                fillColor: [255, 255, 255]
            },
            columnStyles: {
                title: {
                    fontStyle: "bold",
                    cellPadding: 1
                },
                value: {
                    cellPadding: 1,
                    halign: "right"
                }
            }
        });

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(10);
        doc.setFontType("normal");
        doc.text("Yours Faithfully", 74, doc.autoTable.previous.finalY + 25);
        doc.text("Ross Webster", 74, doc.autoTable.previous.finalY + 37);

        doc = GlobalStaticService.pdfAddFooter(doc, this.policy.policyId);
        // doc.addPage();
        const data = new FormData();
        data.append(
            "coc",
            doc.output("blob"),
            "COC-" + this.policy.providerPolicyReference + ".pdf"
        );
        GlobalStaticService.cocFormData = data;
        GlobalStaticService.openEmailCocModal();
    }
}
