import { Directive, HostListener, ElementRef } from "@angular/core";

@Directive({
    selector: "[appNameOnly]"
})
export class NameOnlyDirective {
    constructor(private el: ElementRef) {}
    @HostListener("keyup", ["$event"]) onKeyup(event) {
        // Only allow a-z & blank
        this.el.nativeElement.value = this.el.nativeElement.value
            .split("")
            .filter(letter => {
                return /[a-zA-Z\-\.\ ]/.test(letter);
            })
            .join("");
    }
}
