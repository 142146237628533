import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { Quote } from "../../models/quote";
import { GlobalStaticService } from "../../static-services/global.static-service";
import { CommonUtility } from "../../utilities/common.utility";

@Component({
    selector: "app-quick-quote-work-location-component",
    templateUrl: "./quick-quote-work-location.component.html",
    styleUrls: ["./quick-quote-work-location.component.scss"]
})
export class QuickQuoteWorkLocationComponent implements OnChanges {
    GlobalStaticSrv = GlobalStaticService;
    @Input("quote") quote: Quote;
    @Output() continueEventEmitter: EventEmitter<any> = new EventEmitter();
    constructor() {}
    ngOnChanges(): void {}
    next(): void {
        if (
            this.quote.workLocationNSW +
                this.quote.workLocationQLDb +
                this.quote.workLocationACT +
                this.quote.workLocationWAb +
                this.quote.workLocationVIC +
                this.quote.workLocationTAS +
                this.quote.workLocationSA +
                this.quote.workLocationQLDa +
                this.quote.workLocationWAa +
                this.quote.workLocationNT ===
            100
        ) {
            this.quote.errorWorkLocation = false;
            this.continueEventEmitter.emit({
                finished: true,
                referral: this.quote.workLocationReferral
            });
        } else {
            this.quote.errorWorkLocation = true;
        }
    }
}
