import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

import { CommonApi } from "../../api/common.api";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/fromEvent";

import { GlobalStaticService } from "../../static-services/global.static-service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
    selector: "app-main-header-component",
    templateUrl: "./main-header.component.html",
    styleUrls: ["./main-header.component.scss"]
})
export class MainHeaderComponent implements OnInit {
    GlobalStaticSrv = GlobalStaticService;
    @ViewChild("header") header: ElementRef;
    resetForm: FormGroup;
    profileMenu = false;
    profilePage = false;
    profilePageContent = 1;
    passwordUpdated = false;
    constructor(
        private fb: FormBuilder,
        private router: Router,
        private commonApi: CommonApi
    ) {}
    ngOnInit(): void {
        this.header.nativeElement.classList.add("slideInDown");
        this.reset();
    }

    changeProfileMenu(): void {
        this.profileMenu = !this.profileMenu;
    }
    hideProfileMenu(): void {
        this.profileMenu = false;
    }
    logout(): void {
        //this.commonApi.postEndConversation();
        this.commonApi.getLogout().subscribe(res => {
            GlobalStaticService.logout();
            //this.router.navigate(["/"]);
            /* Force browser refresh in case of version update */
            window.location.href = "/"
        });
    }
    profile(way: boolean): void {
        this.profilePage = way;
        this.passwordUpdated = false;
    }
    cancel(): void {
        this.profilePageContent = 1;
        this.reset();
    }
    reset(): void {
        const upperCasePattern = /[A-Z]/;
        const lowerCasePattern = /[a-z]/;
        const numericPattern = /[0-9]/;
        this.resetForm = this.fb.group(
            {
                current: [null, [Validators.required, Validators.minLength(6)]],
                password: [
                    null,
                    [
                        Validators.required,
                        Validators.minLength(8),
                        Validators.pattern(upperCasePattern),
                        Validators.pattern(lowerCasePattern),
                        Validators.pattern(numericPattern)
                    ]
                ],
                repeatPassword: [null, [Validators.required]]
            },
            {
                validator: PasswordValidation.MatchPassword // your validation method
            }
        );
    }
    submitResetForm(): void {
        if (!this.submitResetFormCheck()) {
            return;
        }
        this.commonApi
            .putPassword(
                this.resetForm.get("current").value,
                this.resetForm.get("password").value
            )
            .subscribe(
                res => {
                    this.profilePageContent = 1;
                    this.passwordUpdated = true;
                    this.reset();
                },
                err => {
                    switch (err.status) {
                        case 400:
                            GlobalStaticService.infoModal = {
                                title: "WARNING",
                                content:
                                    "Password must be different to previous password."
                            };
                            GlobalStaticService.openInfoModal();
                            break;
                        default:
                            GlobalStaticService.openUnexpectedErrorModal();
                            break;
                    }
                }
            );
    }
    submitResetFormCheck(): boolean {
        let validation = true;
        for (const i in this.resetForm.controls) {
            if (this.resetForm.controls.hasOwnProperty(i)) {
                this.resetForm.controls[i].markAsDirty();
                if (this.resetForm.controls[i].invalid) {
                    validation = false;
                }
            }
        }
        return validation;
    }
}

import { AbstractControl } from "@angular/forms";
export class PasswordValidation {
    static MatchPassword(AC: AbstractControl) {
        const password = AC.get("password").value;
        const repeatPassword = AC.get("repeatPassword").value;
        if (password !== repeatPassword) {
            AC.get("repeatPassword").setErrors({ MatchPassword: true });
        } else {
            return null;
        }
    }
}
