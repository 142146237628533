import { Injectable, Pipe, PipeTransform } from "@angular/core";

@Injectable({
    providedIn: "root"
})
@Pipe({
    name: "abnPipe"
})
export class AbnPipe implements PipeTransform {
    constructor() {}

    transform(abn: string): string {
        return (
            abn.toString().substr(0, 2) +
            " " +
            abn.toString().substr(2, 3) +
            " " +
            abn.toString().substr(5, 3) +
            " " +
            abn.toString().substr(8, 3)
        );
    }
}
